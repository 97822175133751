import logo from "../../../images/logo2.png";
import logo2 from "../../../images/mazebaan-logo.png";
import user from "../../../images/dashboard-avatar.jpg";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../Homepage/Homepage.css";
import React from "react";
import { get } from "../../Axios/get";
import { UserLogoutEndPoint, UserProfileEndPoint } from "../../Axios/EndPoints";
import { useNavigate } from "react-router";
import HandleTokenExpired from "../TokenExpired/TokenExpired";

const NavBar = (props) => {
  const navigate = useNavigate();
  const [NAVBAR_DROPDOWN, setNAVBAR_DROPDOWN] = React.useState(false);

  const HandleLogout = () => {
    if (localStorage.getItem("token")) {
      get(UserLogoutEndPoint, "LOCAL", "", true, localStorage.getItem("token"))
        .then((res) => {
          console.log("res =", res);
          if (res.data.status === 200) {
            // alert(res.data.message);
            localStorage.setItem("tokenExpired", "true");
            localStorage.removeItem("item");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("avatar");
            localStorage.removeItem("username");
            localStorage.removeItem("id");
            localStorage.removeItem("unread_messages");
            localStorage.removeItem("token");
            navigate("/", {
              state: {
                message: res.data.message,
                status: res.data.status,
                exist: true,
              },
            });
          }
          else if (res.data.status == 401)
          {
            HandleTokenExpired(); 
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      alert("User not logged in!");
    }
  };

  const [
    NavBar_UserProfileDropdown_Active,
    setNavBar_UserProfileDropdown_Active,
  ] = React.useState(false);

  const [
    NavBar_UserProfileDropdown_Selected,
    setNavBar_UserProfileDropdown_Selected,
  ] = React.useState("Options");

  const [message, setmessage] = React.useState();
  const [username, setUsername] = React.useState("");
  const [avatar, setAvatar] = React.useState(user);

  const currentRoute = useLocation();

  let headerType = "";
  let stickyClass = "";
  let dashboardNavigation = "";
  let userDropdown = " d-none";
  let loginButtonText = "Login";
  let loginButtonLink = "/login";

  let logoDisplay = {};

  const OpenDropDownMenu = () => {
    setNAVBAR_DROPDOWN(!NAVBAR_DROPDOWN);
  };

  const DropDownMenuLoseFocus = () => {
    setNAVBAR_DROPDOWN(!NAVBAR_DROPDOWN);
  };

  if (currentRoute.pathname === "/") {
    headerType = " transparent-header";
  }

  if (props.hideHeaderLogoDisplay) {
    logoDisplay = { visibility: "hidden" };
  }

  if (props.stickyNavbar) {
    stickyClass = " fixed";
  }

  if (props.dashboardNavigation) {
    dashboardNavigation = " dashboard";
  }

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      setUsername(localStorage.getItem("username"));
      setAvatar(localStorage.getItem("avatar"));
    }
  });
  if (localStorage.getItem("token") !== null) {
    userDropdown = "";
    loginButtonText = "Add Listing";
    loginButtonLink = "/dashboard-add-listing";
  }

  return localStorage.getItem("token") ? (
    <>
      <header
        id="header-container"
        className={"fullwidth" + headerType + stickyClass}
      >
        {/* Header */}
        <div id="header">
          <div className="container">
            {/* Left Side Content */}
            <div className="left-side">
              {/* Logo */}
              <div id="logo" style={logoDisplay} >
                <a href="/">
                  <img src={logo2} alt="" className={'nav-logo'} />
                </a>
              </div>
              {/* Mobile Navigation */}
              <div className="mmenu-trigger">
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
              {/* Main Navigation */}
              <nav id="navigation" className={"style-1" + dashboardNavigation}>
                <ul id="responsive">
                  <li>
                    <a href={"/dashboard-bookings"}>My Bookings</a>
                  </li>
                  <li>
                    <a href={"/dashboard-bookmarks"}>Saved Trips</a>
                  </li>
                  <li>
                    <a href={"/dashboard-messages"}>Messages</a>
                  </li>
                  <li>
                    <a href={"/blog"}>Blog</a>
                  </li>
                  <li>
                    <a href={"/contact-us"}>Contact Us</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <div className="right-side">
                <div className="header-widget">
                  {/* User Menu */}

                  <a onClick={OpenDropDownMenu}>
                    <div
                      onBlur={DropDownMenuLoseFocus}
                      tabIndex={0}
                      className={
                        NAVBAR_DROPDOWN ? "user-menu" + " active" : "user-menu"
                      }
                    >
                      <div className="user-name">
                        <span>
                          <img src={avatar} alt="" />
                        </span>
                        Hi, {username}
                      </div>
                      <ul>
                        <li>
                          <a href="/dashboard">
                            <i className="sl sl-icon-settings"></i> Dashboard
                          </a>
                        </li>
                        {/* <li>
                          <a href="/dashboard-add-listing">
                            <i className="sl sl-icon-layers"></i> Add New Listing
                          </a>
                        </li>{" "} */}
                        <li>
                          <a href="/dashboard-my-listings">
                            <i className="sl sl-icon-layers"></i> My Listings
                          </a>
                        </li>{" "}
                        <li>
                          <a href="/dashboard-reviews">
                            <i className="sl sl-icon-star"></i> Reviews
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard-bookmarks">
                            <i className="sl sl-icon-heart"></i> Bookmarks
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard-messages">
                            <i className="sl sl-icon-envelope-open"></i> Messages{" "}
                            <span class="nav-tag messages"></span>
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard-bookings">
                            <i className="fa fa-calendar-check-o"></i> My Bookings
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard-booking-requests-approved">
                            <i className="fa fa-calendar-check-o"></i> Bookings
                            Request
                          </a>
                        </li>
                        <li>
                          <a href="/dashboard-my-profile">
                            <i className="sl sl-icon-user"></i> My Profile
                          </a>
                        </li>
                        <li>
                          <a onClick={HandleLogout}>
                            <i className="sl sl-icon-power"></i> Logout
                          </a>
                        </li>
                      </ul>
                      {/*<select
                      defaultValue={NavBar_UserProfileDropdown_Selected}
                      onChange={setNavBar_UserProfileDropdown_Selected}
                    >
                      <option value="Dashboard">Dashboard</option>
                      <option value="Messages">Messages</option>
                      <option value="Bookings">Bookings</option>
                      <option value="Bookings">login</option>
                    </select>*/}
                    </div>
                  </a>
                  <a
                    href={loginButtonLink}
                    className="button border login-button-navbar with-icon"
                  >
                    <i className="sl sl-icon-login" /> {loginButtonText}
                  </a>
                </div>
              </div>
              {/* Right Side Content / End */}
              {/* Sign In Popup */}
              <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
                <div className="small-dialog-header">
                  <h3>Sign In</h3>
                </div>
                <div></div>
                {/*Tabs */}
              </div>
            </div>
            {/* Sign In Popup / End */}
          </div>
        </div>
        {/* Header / End */}
      </header>
    </>
  ) : (
    <>
      <header
        id="header-container"
        className={"fullwidth" + headerType + stickyClass}
      >
        {/* Header */}
        <div id="header">
          <div className="container">
            {/* Left Side Content */}
            <div className="left-side">
              {/* Logo */}
              <div id="logo" style={logoDisplay}>
                <a href="/">
                  <img src={logo2} alt="" className={'nav-logo'}/>
                </a>
              </div>
              {/* Mobile Navigation */}
              <div className="mmenu-trigger">
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
              {/* Main Navigation */}
              <nav id="navigation" className={"style-1" + dashboardNavigation}>
                <ul id="responsive">
                  <li>
                    <a href={"/about-us"}>About Us</a>
                  </li>
                  <li>
                    <a href={"/blog"}>Blog</a>
                  </li>
                  <li>
                    <a href={"/become-a-host"}>Become a Host</a>
                  </li>
                  <li>
                    <a href={"/contact-us"}>Contact Us</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <div className="right-side">
                <div className="header-widget">
                  {/* User Menu */}
                  <div className={"user-menu" + userDropdown}>
                    <div className="user-name">
                      <span>
                        <img src={avatar} alt="" />
                      </span>
                      Hi, {username}
                    </div>
                    <ul>
                      <li>Dashboard</li>
                      <li>My Bookings</li>
                      <li>My Listings</li>
                      <li>Logout</li>
                    </ul>
                    {/*<select
                      defaultValue={NavBar_UserProfileDropdown_Selected}
                      onChange={setNavBar_UserProfileDropdown_Selected}
                    >
                      <option value="Dashboard">Dashboard</option>
                      <option value="Messages">Messages</option>
                      <option value="Bookings">Bookings</option>
                      <option value="Bookings">login</option>
                    </select>*/}
                  </div>
                  <a
                    href={loginButtonLink}
                    className="button border login-button-navbar with-icon bg-white text-black"
                  >
                    <i className="sl sl-icon-login" /> {loginButtonText}
                  </a>
                </div>
              </div>
              {/* Right Side Content / End */}
              {/* Sign In Popup */}
              <div id="sign-in-dialog" className="zoom-anim-dialog mfp-hide">
                <div className="small-dialog-header">
                  <h3>Sign In</h3>
                </div>
                <div></div>
                {/*Tabs */}
              </div>
            </div>
            {/* Sign In Popup / End */}
          </div>
        </div>
        {/* Header / End */}
      </header>
    </>
  );
};

export default NavBar;

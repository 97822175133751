import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <div>
        <div id="titlebar" className="solid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Privacy Policy</h2>
                <nav
                  id="breadcrumbs"
                  // xmlns:v="http://rdf.data-vocabulary.org/#"
                >
                  <ul>
                    {/* Breadcrumb NavXT 6.6.0 */}
                    <li className="home">
                      <span property="itemListElement" typeof="ListItem">
                        <a
                          property="item"
                          typeof="WebPage"
                          title="Go to Mazebaan."
                          href="/"
                          className="home"
                        >
                          <span property="name">Mazebaan</span>
                        </a>
                        <meta property="position" content={1} />
                      </span>
                    </li>
                    <li className="post post-page current-item">
                      <span property="itemListElement" typeof="ListItem">
                        <span
                          property="name"
                          className="post post-page current-item"
                        >
                          Privacy Policy
                        </span>
                        <meta
                          property="url"
                          content="/privacy-policy"
                        />
                        <meta property="position" content={2} />
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container full-width">
          <div className="row">
            <article
              id="post-404"
              className="col-md-12 page-container-col post-404 page type-page status-publish hentry"
            >
              <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>Thank you for using Mazebaan.</p>
                          <p>
                            When the policy mentions words like&nbsp;
                            “Mazebaan”, “we” or “our” it refers to the Mazebaan
                            company.
                          </p>
                          <p>
                            The information that is shared by the users with
                            Mazebaan helps us to improve their experience with
                            Mazebaan. The privacy policy explains how Mazebaan
                            collects and uses the personal information of its
                            users in relation to the use of Mazebaan by the
                            users.
                          </p>
                          <p>
                            “ In order to comply with the law and prevent any
                            fraud or legal mishap, the guests must at all times
                            carry their CNICs(Computerized National Identity
                            Cards) and other legal documents(if required)&nbsp;
                            to prove and verify their identities to the hosts at
                            Check-in and it is the sole responsibility of the
                            hosts to ask for and check the CNICs and other legal
                            documents(if required) of the guests to verify the
                            identities at Check-in.”
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Information Collected
                            </span>
                          </h1>
                          <h2>
                            <span style={{ fontWeight: 400 }}>
                              User Provided Content
                            </span>
                          </h2>
                          <p>
                            Mazebaan collects the following personal information
                            from the users when they use Mazebaan.com which is
                            necessary for the apt performance of the website and
                            the resulting contract between the users and
                            Mazebaan in order to provide the required services.
                          </p>
                          <p>
                            Identity Verification: Mazebaan may collect identity
                            verification information such as images of national
                            ID and CNIC number for the verification of users and
                            also to complete some of the payment procedures
                            while using a particular payment method.
                          </p>
                          <p>
                            Account Sign up: At the sign up, Mazebaan requires
                            users to provide certain information such as the
                            name, email address and mobile number.
                          </p>
                          <p>
                            Listings: Mazebaan requires the hosts to provide the
                            additional information while publishing the
                            listings&nbsp; such as the address and etc.
                          </p>
                          <p>
                            Payment Information: Mazebaan&nbsp; requires the
                            hosts to provide their account or credit card number
                            in order to process their payments.
                          </p>
                          <p>&nbsp;</p>
                          <h2>
                            <span style={{ fontWeight: 400 }}>
                              Automatically collected Information
                            </span>
                          </h2>
                          <p>
                            Mazebaan automatically collects some personal
                            information about the services the users use and how
                            they use them while accessing Mazebaan.com which is
                            necessary for the apt performance of the agreement
                            between the users and Mazebaan in order to provide
                            and improve the functionalities of the Mazebaan.com.
                          </p>
                          <p>
                            Usage Information: Mazebaan collects the information
                            about the users’ interaction and use of Mazebaan.com
                            like the viewed content, searches and bookings etc.
                          </p>
                          <p>
                            Location Information: Mazebaan may collect
                            information about the location of users via the GPS
                            in order to help users find the apt listings and
                            also to provide accurate location while publishing
                            the listings in order to facilitate the users.
                          </p>
                          <p>
                            Transaction Information: Mazebaan collects
                            information related to transactions made via
                            Mazebaan.com including the payment method used, the
                            ime, amount, account information etc to improve the
                            services.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Information Usage
                            </span>
                          </h1>
                          <p>
                            Mazebaan stores and uses the collected information
                            in order to:
                          </p>
                          <p>
                            Improve the user experience by providing facilities
                            that
                          </p>
                          <p>&nbsp;</p>
                          <ul>
                            <li>
                              allow the users to access Mazebaan.com and
                              communicate with other users.
                            </li>
                          </ul>
                          <ul>
                            <li>include customer help service.</li>
                          </ul>
                          <ul>
                            <li>
                              send the users service or support messages,
                              updates, security alerts, and account
                              notifications.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              help optimize the experience at Mazebaan.com that
                              help customize user experience with respect to the
                              data collected.
                            </li>
                          </ul>
                          <p>&nbsp;</p>
                          <p>
                            Ensure safety to its maximum by providing facilities
                            that
                          </p>
                          <p>&nbsp;</p>
                          <ul>
                            <li>
                              may help prevent fraud, spam and other harmful
                              activities.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              allows the conduction of security investigations
                              and the risk assessments.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              help in the verification of information and
                              identifications provided.
                            </li>
                          </ul>
                          <p>&nbsp;</p>
                          <p>
                            Facilitate the payment procedures by providing
                            facilities that
                          </p>
                          <p>&nbsp;</p>
                          <ul>
                            <li>
                              enable the users to access&nbsp; the payment
                              services.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              may help prevent fraud, spam and other harmful
                              activities.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              allows the conduction of security investigations
                              and the risk assessments.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              helps in the conduction checks against the
                              information sources.
                            </li>
                          </ul>
                          <p>&nbsp;</p>
                          <p>
                            Personalize the user experience and also use the
                            relevant content for the marketing of Mazebaan of
                            various platform. By agreeing on the Terms and
                            Conditions, the users agree to grant Mazebaan all
                            the due rights to do so.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Information Sharing
                            </span>
                          </h1>
                          <h2>
                            <span style={{ fontWeight: 400 }}>
                              Advertisement and Marketing
                            </span>
                          </h2>
                          <p>
                            Mazebaan may use some particular personal
                            information of the users like the email address and
                            share it on social media platforms in order to
                            promote Mazebaan and its services.
                          </p>
                          <p>
                            The user content like the pictures and etc can be
                            used by Mazebaan for its advertising and drive
                            traffic to Mazebaan.com. By agreeing to the Terms
                            and Conditions, the users grant the rights to
                            Mazebaan to do so.
                          </p>
                          <p>&nbsp;</p>
                          <h2>
                            <span style={{ fontWeight: 400 }}>
                              Sharing between Members
                            </span>
                          </h2>
                          <p>
                            Mazebaan shares certain information of the members
                            between one and other in order to carry out the
                            required procedures for apt and authentic bookings.
                            Such as:
                          </p>
                          <p>&nbsp;</p>
                          <ul>
                            <li>
                              when a guest submits a booking request, certain
                              information about the guest is shared with the
                              host, like the profile, full name and other
                              information that the guest agrees to share. Once
                              the booking is confirmed, additional
                              information,like the phone number, will be
                              disclosed in order to assist the coordination.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              when a host has a confirmed booking, certain
                              information about the host is shared with the
                              guest, like the profile, full name and other
                              information that the host agrees to share. Once
                              the booking is confirmed, additional
                              information,like the phone number, will be
                              disclosed in order to assist the coordination.
                            </li>
                          </ul>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                          <h2>
                            <span style={{ fontWeight: 400 }}>
                              Profiles and Listings
                            </span>
                          </h2>
                          <p>
                            Mazebaan.com allows the users to publish information
                            that is visible to the public. Such as:
                          </p>
                          <p>&nbsp;</p>
                          <ul>
                            <li>
                              name, description, and city are visible to the
                              public.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              the listings along with the description, calendar
                              availability, profile photo and other information
                              provided by the host are visible to the public.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              the ratings and reviews that the guests and users
                              give to each other are visible to the public.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              the listings or the graphical content which
                              Mazebaan uses for the advertisement of Mazebaan on
                              social media platforms is visible to the public.
                            </li>
                          </ul>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                          <h2>
                            <span style={{ fontWeight: 400 }}>
                              Compliance with the Law
                            </span>
                          </h2>
                          <p>
                            Mazebaan has the right to disclose the information
                            of users to the law implementing agencies, if
                            required by the law or if necessary for Mazebaan’s
                            legal obligations, for claims asserted against
                            Mazebaan, for criminal investigation or suspected
                            illegal activity, to enforce and administer
                            Mazebaan’s Terms and Conditions and other Policies,
                            the Payment Terms or to protect the rights of
                            Mazebaan.
                          </p>
                          <p>
                            Mazebaan also has the right to review the
                            communications on Mazebaan.com for the prevention of
                            fraud, risk assessment, investigations and customer
                            support purposes.
                          </p>
                          <p>
                            “ In order to comply with the law and prevent any
                            fraud or legal mishap, the guests must at all times
                            carry their CNICs and other legal documents(if
                            required) to prove and verify their identities to
                            the hosts at Check-in and it is the sole
                            responsibility of the hosts to ask for and check the
                            CNICs and other legal documents(if required) of the
                            guests to verify the identities at Check-in.”
                          </p>
                          <p>
                            Mazebaan should not be held liable for any of the
                            actions, recorde or profile of any of the members,
                            either guest or host, and neither will Mazebaan be a
                            proponent of a side or a part of the actions in case
                            of any legal inquiry or action against the hosts or
                            the guests by the law implementing authorities.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>User Rights</span>
                          </h1>
                          <p>
                            The users can always update their information
                            through the account settings as it is the
                            responsibility of the users to keep their&nbsp;
                            personal information up-to-date.
                          </p>
                          <p>
                            The users can contact Mazebaan to amend any&nbsp;
                            inaccurate or incomplete personal information about
                            the user, if the user is unable to do so.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Changes to the Privacy Policy
                            </span>
                          </h1>
                          <p>
                            Mazebaan reserves all&nbsp; the right to modify this
                            Privacy Policy.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>Feedback</span>
                          </h1>
                          <p>
                            Mazebaan welcomes the feedback from its users for
                            improvements on Mazebaan.com. The feedback of the
                            users will be non-confidential and non-proprietary
                            and Mazebaan will have the right to the materials
                            without any compensation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default PrivacyPolicy;

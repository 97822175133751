import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import StarRatings from "react-star-ratings";
import { useState, useEffect } from "react";
import { get } from "../../Components/Axios/get";
import { DashboardMyReviewEndPoint } from "../../Components/Axios/EndPoints";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import redirectToLogin from "../../utility/redirectToLogin";
const Reviews = (props) => {
  const [AllListingDropdown, setAllListingDropdown] = useState(false);

  const HandleDropdownListing = () => {
    setAllListingDropdown(!AllListingDropdown);
  };

  const [MyReview, setMyReview] = useState();
  const [MyReviewCount, setMyReviewCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [Pagination, setPagintion] = useState(null);

  const [AllListingDropDownData, setAllListingDropDownData] = useState(null);
  const [AllListingDropDownSelectedData, setAllListingDropDownSelectedData] =
    useState(null);

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  useEffect(() => {
    redirectToLogin(props);
    let mounted = true;

    let ENDPOINT = "?page=" + CurrentPage;

    if (
      AllListingDropDownSelectedData != null &&
      AllListingDropDownSelectedData != ""
    ) {
      ENDPOINT = ENDPOINT + "&" + "listing=" + AllListingDropDownSelectedData;
    }

    get(
      DashboardMyReviewEndPoint + ENDPOINT,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        console.log('res = ', res.data)
        if (res.data.status === 200) {
          setMyReview(res.data.response.reviews);
          setMyReviewCount(res.data.response.reviews.length);
          setAllListingDropDownData(res.data.response.listings);
          setPagintion(res.data.response.pagination);
          setLoading(false);
          window.scrollTo(0, 0);
        } else if (res.data.status === 204) {
          setMyReviewCount(res.data.response.reviews.length);
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, [CurrentPage, AllListingDropDownSelectedData]);

  if (loading) {
    return <div className="loading"></div>;
  }

  return MyReviewCount > 0 ? (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-reviews"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Reviews</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-reviews">Reviews</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                {/* Sort by */}
                <div className="sort-by">
                  <div className="sort-by-select">
                    <select
                      form="listeo_core-search-form"
                      name="listeo_core_order"
                      data-placeholder="Default order"
                      className="select2-sortby orderby select2-hidden-accessible"
                      data-select2-id="select2-data-1-53j9"
                      tabIndex={-1}
                      value={AllListingDropDownSelectedData}
                      onChange={(e) =>
                        setAllListingDropDownSelectedData(e.target.value)
                      }
                      aria-hidden="true"
                    >
                      <option
                        selected="selected"
                        value=""
                        data-select2-id="select2-data-15-jb04"
                      >
                        All Listings
                      </option>
                      {AllListingDropDownData.map((value, index) => (
                        <option
                          selected=""
                          value={value.id}
                          // data-select2-id="select2-data-3-1mt0"
                          // data-option-array-index={'1'}
                        >
                          {value.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <h4>Visitor Reviews</h4>
                {/* Reply to review popup */}
                {/* <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                  <div className="small-dialog-header">
                    <h3>Reply to review</h3>
                  </div>
                  <div className="message-reply margin-top-0">
                    <textarea cols={40} rows={3} defaultValue={""} />
                    <button className="button">Reply</button>
                  </div>
                </div> */}
                <ul>
                  {Object.values(MyReview).map((value) => (
                    <li>
                      <div className="comments listing-reviews">
                        <ul>
                          {/* {console.log('avaar =', value['user']['name'])} */}
                          <li>
                            <div className="avatar">
                              <img src={value["user"]["avatar"]} alt="" />
                            </div>
                            <div className="comment-content">
                              <div className="arrow-comment" />
                              <div className="comment-by">
                               {" "} { value["user"]["name"] + " "}
                                <div className="comment-by-listing">
                                  on{" "}
                                  <a
                                    href={
                                      "/listing-detail/" +
                                      value["property"]["uid"]
                                    }
                                  >
                                    {value["property"]["name"]}
                                  </a>
                                </div>
                                <span className="date">
                                  {value["review_at"]}
                                </span>
                                <div className="star-rating" data-rating={5}>
                                  <StarRatings
                                    rating={value["property"]["rating"]}
                                    starRatedColor="#ffc600"
                                    numberOfStars={5}
                                    starDimension="18px"
                                    starSpacing="1px"
                                    name="rating"
                                  />
                                </div>
                              </div>
                              <p>{value["review"]}</p>
                              {/* <div className="review-images mfp-gallery-container">
                              <a
                                href="images/review-image-01.jpg"
                                className="mfp-gallery"
                              >
                                <img src="images/review-image-01.jpg" alt="" />
                              </a>
                            </div> */}
                              {/* <a
                              href="#small-dialog"
                              className="rate-review popup-with-zoom-anim"
                            >
                              <i className="sl sl-icon-action-undo" /> Reply to
                              this review
                            </a> */}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Pagination */}
              {MyReviewCount > 10 ? (
                <>
                  {/* Pagination */}
                  <div className="pagination-container margin-top-15 margin-bottom-40">
                    <nav className="pagination">
                      <ul>
                        <li className={CurrentPage > 1 ? "" : "hidden"}>
                          <a onClick={() => Handle_PreviousPageClick()}>
                            <i className="sl sl-icon-arrow-left" />
                          </a>
                        </li>
                        <li>
                          <a
                            // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                            className={"current-page"}
                          >
                            {CurrentPage}
                          </a>
                        </li>
                        <li
                          className={
                            Pagination.last_page >= CurrentPage + 1
                              ? ""
                              : "hidden"
                          }
                        >
                          <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                            {CurrentPage + 1}
                          </a>
                        </li>
                        <li
                          className={
                            Pagination.last_page >= CurrentPage + 2
                              ? ""
                              : "hidden"
                          }
                        >
                          <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                            {CurrentPage + 2}
                          </a>
                        </li>
                        <li
                          className={
                            Pagination.last_page >= CurrentPage + 1
                              ? ""
                              : "hidden"
                          }
                        >
                          <a onClick={Handle_NextPageClick}>
                            <i className="sl sl-icon-arrow-right" />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>{" "}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-reviews"} />
        {/* Navigation / End */}
        {/* Content
================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Reviews</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-reviews">Reviews</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                {/* Sort by */}
                <div className="sort-by">
                  <div className="sort-by-select">
                    <select
                      form="listeo_core-search-form"
                      name="listeo_core_order"
                      data-placeholder="Default order"
                      className="select2-sortby orderby select2-hidden-accessible"
                      data-select2-id="select2-data-1-53j9"
                      tabIndex={-1}
                      value={AllListingDropDownSelectedData}
                      onChange={(e) =>
                        setAllListingDropDownSelectedData(e.target.value)
                      }
                      aria-hidden="true"
                    >
                      <option
                        selected="selected"
                        value=""
                        data-select2-id="select2-data-15-jb04"
                      >
                        All Listings
                      </option>
                      {AllListingDropDownData.map((value, index) => (
                        <option
                          selected="selected"
                          value={value.id}
                          // data-select2-id="select2-data-3-1mt0"
                          // data-option-array-index={'1'}
                        >
                          {value.name}
                        </option>
                      ))}
                    </select>{" "}
                  </div>
                </div>
                <h4>Visitor Reviews</h4>
                {/* Reply to review popup */}
                <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                  <div className="small-dialog-header">
                    <h3>Reply to review</h3>
                  </div>
                  <div className="message-reply margin-top-0">
                    <textarea cols={40} rows={3} defaultValue={""} />
                    <button className="button">Reply</button>
                  </div>
                </div>
                <ul>
                  <li>No Reviews Found!</li>
                </ul>
              </div>
              {/* Pagination */}
              {/* <div className="clearfix" />
            <div className="pagination-container margin-top-30 margin-bottom-0">
              <nav className="pagination">
                <ul>
                  <li>
                    <a href="#" className="current-page">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="sl sl-icon-arrow-right" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
              {/* Pagination / End */}
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  );
};

export default Reviews;

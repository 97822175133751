import Modal from "react-modal";
import { get } from "../../Axios/get";
import { post } from "../../Axios/post";
import {
  GetJazzCashMobileWalletEndPoint,
  EasyPaisaGetPaymentURLEndPoint,
  EasyPaisaPayMobileAccount,
} from "../../Axios/EndPoints";
import { useState, useEffect } from "react";
import HandleTokenExpired from "../../Main/TokenExpired/TokenExpired";
import { Button } from "bootstrap";
import { Form } from "react-bootstrap";

Modal.setAppElement(document.getElementById("pay-btn-bookingrequest"));

let runonce = false;

const Pay = (props) => {
  console.log("im in props:", props.amount, props.bookingID);
  const [JazzLinkToPost, setJazzLinkToPost] = useState(null);
  const [JazzLinkToPostData, setJazzLinkToPostData] = useState(null);
  const [EasyPaisaEmail, setEasyPaisaEmail] = useState(null);
  const [EasyPaisaMobile, setEasyPaisaMobile] = useState(null);
  const [EasyPaisaOption, setEasyPaisaOption] = useState(false);
  const [Loading, setLoading] = useState(false);
  let CC = JSON.parse(localStorage.getItem("settings"));

  const HandleEasyPaisaOption = () => {
    setEasyPaisaOption(!EasyPaisaOption);
  };

  const HandleLoading = () => {
    setLoading(!Loading);
  };

  // let JazzLinkToPost = null;
  // let JazzLinkToPostData = null;
  if (!runonce) {
    // props.CallJazzMobileAccount(props.amount, props.bookingID);
    // props.CallJazzCard(props.amount, props.bookingID);
    // props.CallJazzVoucher(props.amount, props.bookingID);
    runonce = true;
  }

  let endpoint = "?" + "amount=" + props.amount;

  // console.log("cc =", CC);
  // console.log("in props =", document.getElementById(props.JazzCashCreditDebitButtonID));

  useEffect(() => {
    // if (props.JazzCashCreditDebitButtonID != null) {
    //   document.getElementById(props.JazzCashCreditDebitButtonID).click();
    // }
  }, []);

  const HandleEasyPaisa = () => {
    let data = {
      amount: props.amount,
      booking_id: props.bookingID,
    };

    post(
      EasyPaisaGetPaymentURLEndPoint,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          window.location.replace(res.data.response.generatedUrl);
          // setSendMessage(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          alert(res.data.message);
          // setSendMessage(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleEasyPaisaMobile = () => {
    setLoading(!Loading);
    let data = {
      amount: props.amount,
      mobile_number: EasyPaisaMobile,
      email: EasyPaisaEmail,
      booking_id: props.bookingID,
    };

    post(
      EasyPaisaPayMobileAccount,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          window.location.reload();
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          props.closeModal();
          HandleLoading();
          props.setLoading(false);
          alert(res.data.message);
          // setSendMessage(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleJazzCashMobileAccount = () => {
    console.log(
      "in handle jazz mobile account click =",
      props.JazzCashMobileAccountButtonID
    );
    // props.CallJazzMobileAccount(props.amount,props.bookingID);
    props.JazzAppTempFunc(props.amount,props.bookingID);
    // document.getElementById(props.JazzCashMobileAccountButtonID).click();
    // setTimeout(() => {
    // }, 2000);
  };

  const HandleJazzCardAccount = () => {
    // props.CallJazzCard(props.amount,props.bookingID);
    props.JazzCCTempFunc(props.amount,props.bookingID);
    // document.getElementById(props.JazzCashCreditDebitButtonID).click();
    // setTimeout(() => {
    // }, 20000);
  };

  const HandleJazzVoucher = () => {
    // props.CallJazzCard(props.amount,props.bookingID);

    props.JazzVoucherTempFunc(props.amount,props.bookingID);
    // document.getElementById(props.JazzCashVoucherButtonID).click();
    // setTimeout(() => {
    // }, 20000);
  };

  if (Loading) {
    props.setLoading(Loading);
  }

  // console.log(JazzLinkToPost);
  return (
    <>
      <Modal
        isOpen={props.modalIsOpen}
        // ariaHideApp={true}
        // onAfterOpen={props.afterOpenModal}
        onRequestClose={props.closeModal}
        onreq
        style={props.customStyles}
        // contentLabel="Example Modal"
      >
        {/* <a onClick={props.closeModal}> */}
        <div className="row">
          <div className="col-md-3">
            <h1 className="Mazebaan h1-payement">Easy Paisa</h1>
          </div>
          {CC.PAY_WITH_CC == "EASYPAISA" ? (
            <div className="col-md-4"  >
              <a
                id="pay-btn-bookingrequest"
                className="button mazebaan payment-btn"
                onClick={HandleEasyPaisa}
              >
                <i class="sl sl-icon-check"></i>
                <span>EasyPaisaCard</span>
              </a>
            </div>
          ) : (
            ""
          )}{" "}
          <div className="col-md-4">
            <a
              id="pay-btn-bookingrequest"
              className="button mazebaan payment-btn"
              onClick={HandleEasyPaisaOption}
            >
              <i
                class={
                  EasyPaisaOption ? "sl sl-icon-close" : "sl sl-icon-check"
                }
              ></i>
              <span>EasyPaisaMobile</span>
            </a>
          </div>
          {/* <div className="col-md-3">
              <a
                id="pay-btn-bookingrequest"
                className="button mazebaan payment-btn"
                // onClick={() => submitForm()}
              >
                <i class="sl sl-icon-check"></i>
                <span>EasyPaisa Account</span>
              </a>
            </div> */}
          {/* <div className="col-md-3">
              <a
                id="pay-btn-bookingrequest"
                className="button mazebaan payment-btn"
                // onClick={() => submitForm()}
              >
                <i class="sl sl-icon-check"></i>
                <span>EasyPaisa Card</span>
              </a>
            </div> */}
        </div>{" "}
        {!EasyPaisaOption && <hr />}
        {EasyPaisaOption && (
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-9">
              <div className="input-group">
                <div className="input-group-area">
                  <input
                    type="text"
                    onChange={(e) => setEasyPaisaEmail(e.target.value)}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-group-area">
                  <input
                    type="tel"
                    onChange={(e) => setEasyPaisaMobile(e.target.value)}
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className="form-row">
                <input
                  type="submit"
                  onClick={HandleEasyPaisaMobile}
                  className="button border margin-top-5 bg-yellow padding-left-40 padding-right-40"
                  defaultValue="Login"
                />
              </div>
            </div>
          </div>
        )}
        {EasyPaisaOption && <hr />}
        <div className="row">
          <div className="col-md-3">
            <h1 className="Mazebaan h1-payement">Jazz</h1>
          </div>
          <div className="col-md-3">
            <a
              id="pay-btn-bookingrequest"
              className="button mazebaan payment-btn"
              // onClick={() => submitForm()}
              onClick={HandleJazzVoucher}
            >
              <i class="sl sl-icon-check"></i>
              <span>JazzVoucher</span>
            </a>
          </div>
          <div className="col-md-3">
            <a
              id="pay-btn-bookingrequest"
              className="button mazebaan payment-btn"
              // onClick={() => submitForm()}
              onClick={HandleJazzCashMobileAccount}
            >
              <i class="sl sl-icon-check"></i>
              <span>JazzAccount</span>
            </a>
          </div>
          {CC.PAY_WITH_CC == "JAZZCASH" ? (
            <div className="col-md-3">
              <a
                className="button mazebaan payment-btn"
                // onClick={() => submitForm()}
                onClick={HandleJazzCardAccount}
              >
                <i class="sl sl-icon-check"></i>
                <span>JazzCard</span>
              </a>
            </div>
          ) : (
            ""
          )}{" "}
        </div>{" "}
        <hr />
        <div className="row">
          <div className="col-md-12">
            <a onClick={props.closeModal} class="button mazebaan">
              <i class="sl sl-icon-close"></i>
              Close
            </a>
          </div>
        </div>
        {/* <div className="row">
            <div className="col-md-12">
              <img
                className="refund-policy-image"
                src={ReturnPolicyInfographic}
              ></img>
            </div>
          </div> */}
        {/* </a> */}
      </Modal>
    </>
  );
};

export default Pay;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import MultiRangeSlider from "multi-range-slider-react";
import React, { useEffect, useState } from "react";
import "../Homepage/Homepage.css";
import { PropertyListing } from "../../Components/Listing/listing";
import Geocode from "react-geocode";
import { GoogleMapsKey } from "../../Components/GoogleKeys/GoogleKeys";
import GoogleMaps from "../../Components/GoogleMaps/GoogleMaps";
import { NewListingEndPoint } from "../../Components/Axios/EndPoints";
import { get } from "../../Components/Axios/get";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useLocation } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { useJsApiLoader } from "@react-google-maps/api";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";

let MoreFilterArray = [];
let ARRAY_HOUSE_RULES =  [];
let Property_Type_Id = [];

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

const Listing = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapsKey,
    libraries: ["places", "geometry", "drawing"],
  });
  const loc = useLocation();

  const APIKey = GoogleMapsKey;

  const [minValue, set_minValue] = useState(
    loc.state != null ? loc.state.min : localStorage.getItem('minValue') ? localStorage.getItem('minValue') : 0
  );
  const [maxValue, set_maxValue] = useState(
    loc.state != null ? loc.state.max : 100000
  );
  const [Guests, setGuests] = React.useState(
    loc.state != null ? loc.state.guest : "0"
  );
  const [locations, setLocation] = useState(
    loc.state != null ? loc.state.location : null
  );

  const [minDistanceValue, set_minDistanceValue] = useState(2);
  const [maxDistanceValue, set_maxDistanceValue] = useState(1000);
  // const [Guests, setGuests] = React.useState(0);
  // const [locations, setLocation] = useState(null);

  const [guestsField, setGuestsField] = React.useState(0);
  const [locationField, setLocationField] = React.useState(null);

  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const handleDistanceInput = (e) => {
    set_minDistanceValue(e.minValue);
    set_maxDistanceValue(e.maxValue);
  };

  const [ApplyFilter, setApplyFilter] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [Loading, setLoading] = React.useState(true);
  const [map, setMap] = React.useState(null);
  const [Lat, setLat] = React.useState(33.659669);
  const [Lng, setLng] = React.useState(73.0543899);
  const [SortBy, setSortBy] = React.useState(null);

  const [DropDownCategories, setDropDownCategories] = React.useState(false);
  const [DropDownMoreFilter, setDropDownMoreFilter] = React.useState(false);
  const [DropDownHouseRules, setDropDownHouseRules] = React.useState(false);
  const [DropDownPriceFilter, setDropDownPriceFilter] = React.useState(false);
  const [DropDownSortBy, setDropDownSortBy] = React.useState(false);

  const [PropertyTypes, setPropertyTypes] = React.useState(null);
  const [HouseRules, setHouseRules] = React.useState(null);
  const [Amenities, setAmenities] = React.useState(null);

  const HandleClearFilter = () => {

    console.log('Property_Type_Id', Property_Type_Id)
    console.log('ARRAY_HOUSE_RULES', ARRAY_HOUSE_RULES)
    console.log('minValue', minValue)
    console.log('maxValue', maxValue)
    console.log('Guests', Guests)
    console.log('location', locations)
    console.log('MoreFilterArray', MoreFilterArray)
    console.log('minDistanceValue', minDistanceValue)
    console.log('maxDistanceValue', maxDistanceValue)
    // set_minValue(0);
    // set_maxValue(100000);
    // setGuests("0");
    // set_minDistanceValue(2);
    // set_maxDistanceValue(1000);



    // let i;
    // let size = Property_Type_Id.length;
    // console.log("IDS  = ", Property_Type_Id.at(-1));
    // if (size != 0) {
    //   for (i = 0; i < size; i++) {
    //     if (Property_Type_Id.length > 0) {
    //       // console.log('Index =', Property_Type_Id.length);
    //       document.getElementById(Property_Type_Id.at(-1)).checked = false;
    //       Property_Type_Id.pop();
    //     }
    //   }
    // }

    // size = ARRAY_HOUSE_RULES.length;
    // if (size != 0) {
    //   for (i = 0; i < size; i++) {
    //     if (ARRAY_HOUSE_RULES.length != 0) {
    //       document.getElementById(ARRAY_HOUSE_RULES.pop()).checked = false;
    //     }
    //   }
    // }

    // size = MoreFilterArray.length;
    // if (size != 0) {
    //   for (i = 0; i < size; i++) {
    //     if (MoreFilterArray.length != 0) {
    //       document.getElementById(MoreFilterArray.pop()).checked = false;
    //     }
    //   }
    // }

    // MoreFilterArray = [];
    // Property_Type_Id = [];
    // ARRAY_HOUSE_RULES = [];
    // set_minValue(0);
    // set_maxValue(100000);
    // setGuests("0");
    // setApplyFilter(!ApplyFilter);
    // set_minDistanceValue(2);
    // set_maxDistanceValue(1000);

    // document.location.reload();


  };

  const HandleDropDownCategories = () => {
    setDropDownCategories(!DropDownCategories);
  };
  const HandleDropDownMoreFilter = () => {
    setDropDownMoreFilter(!DropDownMoreFilter);
  };
  const HandleDropDownHouseRules = () => {
    setDropDownHouseRules(!DropDownHouseRules);
  };
  const HandleDropDownPriceFilter = () => {
    setDropDownPriceFilter(!DropDownPriceFilter);
  };
  const HandleDropDownSortBy = () => {
    setDropDownSortBy(!DropDownSortBy);
  };

  const HandleDropDownSortByLoseFocus = () => {
    setDropDownSortBy(false);
  };
  const HandleDropDownPriceFilterLoseFocus = () => {
    setDropDownPriceFilter(false);
  };
  const HandleDropDownHouseRulesLoseFocus = () => {
    setDropDownHouseRules(false);
  };
  const HandleDropDownMoreFilterLoseFocus = () => {
    setDropDownMoreFilter(false);
  };
  const HandleDropDownCategoriesLoseFocus = () => {
    setDropDownCategories(false);
  };

  const GetLocation = () => {
    let lat = "",
      lng = "";

    navigator.geolocation.getCurrentPosition(function (position) {
      lat = position.coords.latitude;
      lng = position.coords.longitude;
      setLat(lat);
      setLng(lng);

      Geocode.fromLatLng(lat, lng, APIKey).then(
        (response) => {
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          setLocation(address);
          setLocationField(address);
        },
        (error) => {
          console.log("geocode error =", error);
          console.error(error);
        }
      );
    });
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const changelocation = () => {
    setLat(40.712776);
    setLng(-74.005974);
  };

  const Highlight_Listing = () => {
    // console.log("in highight");
    window.location.href = "#listing1";
    // windows.href='#listing1';
  };


  useEffect(() => {
    get(NewListingEndPoint, "LOCAL", "", true, localStorage.getItem("token"))
      .then((res) => {
        if (res.data.status === 200) {
          setPropertyTypes(res.data.response.property_types);
          setAmenities(res.data.response.amenities);
          setHouseRules(res.data.response.house_rules);
          setLoading(false);
          // setLanguages(res.data.response.languages);
          // setPaymentMethod(res.data.response.payment_methods);
          // setBanks(res.data.response.banks);
          // setCities(res.data.response.cities);
          // SetGettingAPIData(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          console.log("false response");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const forceUpdate = useForceUpdate();

  return Loading ? (
    <>
      <div className="loading" />
    </>
  ) : (
    <>
      <NavBar stickyNavbar={true} />
      <div className="fs-container margin-top-40">
        <div className="fs-inner-container content">
          <div className="fs-content">
            {/* Search */}
            <section className="search">
              <div className="row">
                <div className="col-md-12">
                  {/* Row With Forms */}
                  <div className="row with-forms">
                    {/* Main Search Input */}
                    <div className="col-md-4">
                      <div className="input-with-icon location">
                        <div
                          id="autocomplete-container"
                          data-autocomplete-tip="type and hit enter"
                        >
                          <Autocomplete
                            // apiKey={GoogleMapsKey}
                              value={locationField}
                              onChange={(e)=>console.log(e.target.value)}
                            onPlaceSelected={
                              (place) => {
                                if (place.formatted_address !== undefined) {
                                  setLocationField(place.formatted_address);
                                  setLat(place.geometry.location.lat());
                                  setLng(place.geometry.location.lng());
                                } else {
                                  alert("Please select correct location.");
                                }
                              }
                            }
                          />
                        </div>
                        <a onClick={GetLocation}>
                          <i className="fa fa-map-marker" />
                        </a>
                      </div>
                    </div>
                    {/* Main Search Input */}
                    <div className="col-md-4">
                      <div className="calender-in-listing">
                        <DateRangePickerComponent
                          min={startDate}
                          showPopupButton={true}
                          // startDate={startDate}
                          // onClick={()=>{jQuery("#ej2-datetimepicker_0").trigger('click')}}
                          // showDropdowns={}
                          // showDropdowns={true}
                          // onClick={()=>eventFire(document.getElementById('ej2-datetimepicker_0'),'click')}
                          placeholder="Pick a Date"
                          // applyButtonClasses="main-search-input-item"
                          // buttonClasses="main-search-input-item"
                        />{" "}
                      </div>
                    </div>
                    {/* Main Search Input */}
                    <div className="col-md-4">
                      <div className="input-with-icon">
                        <div className="input-with-icon">
                          <i className="fa fa-users" />
                          <input
                            type="text"
                            placeholder="Guests"
                            value={loc.state != null ? Guests : guestsField}
                            onChange={(e) => {
                              if (loc.state != null) {
                                setGuests(e.target.value)
                              } else {
                                setGuestsField(parseInt(e.target.value))
                                localStorage.setItem('GuestsField', e.target.value)
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-fs-12 panel-wrapper">
                      {/* Panel Dropdown */}
                      <div
                        onClick={HandleDropDownCategories}
                        className={
                          DropDownCategories
                            ? "panel-dropdown wide active"
                            : "panel-dropdown wide"
                        }
                        tabIndex={0}
                        onBlur={HandleDropDownCategoriesLoseFocus}
                        id="tax-listing_category-panel"
                      >
                        <a>
                          Categories{" "}
                          {Property_Type_Id.length > 0 && (
                            <span className="tag-listing-filter">
                              {Property_Type_Id.length}
                            </span>
                          )}
                        </a>
                        <div className="panel-dropdown-content checkboxes ">
                          <div className="row  ">
                            <div className="panel-checkboxes-container">
                              {  Object.values(PropertyTypes).map((value,index) => (
                                <div key={index} className="col-md-6">
                                  <div className="panel-checkbox-wrap">
                                    <input
                                      id={value.uid}
                                      value={value.id}
                                      type="checkbox"
                                      name="check"
                                      onClick={(e) => {
                                        if (
                                          Property_Type_Id.indexOf(
                                            e.target.value
                                          ) == -1
                                        ) {
                                          Property_Type_Id.push(e.target.value);
                                          localStorage.setItem("Property_Type_Id",Property_Type_Id)
                                        } else {
                                          Property_Type_Id =
                                            Property_Type_Id.filter(
                                              (item) => item != e.target.value
                                            );
                                          localStorage.setItem("Property_Type_Id",Property_Type_Id)
                                        }
                                      }}
                                    />
                                    <label htmlFor={value.uid}>
                                      {value.name}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>{" "}
                          {/* Panel Dropdown */}
                        </div>
                      </div>
                      {/* Panel Dropdown */}
                      <div
                        onClick={HandleDropDownMoreFilter}
                        className={
                          DropDownMoreFilter
                            ? "panel-dropdown wide active"
                            : "panel-dropdown wide"
                        }
                        id="tax-listing_feature-panel"
                        onBlur={HandleDropDownMoreFilterLoseFocus}
                        tabIndex={0}
                      >
                        <a>
                          Amenities{" "}
                          {MoreFilterArray.length > 0 && (
                            <span className="tag-listing-filter">
                              {MoreFilterArray.length}
                            </span>
                          )}
                        </a>
                        <div
                          className="panel-dropdown-content checkboxes "
                          id="amen"
                        >
                          <div className="row">
                            <div className="panel-checkboxes-container">
                              {Object.values(Amenities).map((value,index) => (
                                <div key={index} className="col-md-6">
                                  <div className="panel-checkbox-wrap">
                                    <input
                                      id={value.uid}
                                      value={value.id}
                                      type="checkbox"
                                      onClick={(e) => {
                                        if (
                                          MoreFilterArray.indexOf(
                                            e.target.value
                                          ) == -1
                                        ) {
                                          MoreFilterArray.push(e.target.value);
                                          localStorage.setItem("MoreFilterArray",MoreFilterArray)
                                        } else {
                                          MoreFilterArray =
                                            MoreFilterArray.filter(
                                              (item) => item != e.target.value
                                            );
                                          localStorage.setItem("MoreFilterArray",MoreFilterArray)
                                        }
                                      }}
                                      name="check"
                                    />
                                    <label htmlFor={value.uid}>
                                      {value.name}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>{" "}
                          {/* Panel Dropdown */}
                        </div>
                      </div>
                      {/* Panel Dropdown */}
                      <div
                        onClick={HandleDropDownHouseRules}
                        className={
                          DropDownHouseRules
                            ? "panel-dropdown wide active"
                            : "panel-dropdown wide"
                        }
                        id="tax-house-rules-panel"
                        onBlur={HandleDropDownHouseRulesLoseFocus}
                        tabIndex={0}
                      >
                        <a>
                          House rules
                          {ARRAY_HOUSE_RULES.length > 0 && (
                            <span className="tag-listing-filter">
                              {ARRAY_HOUSE_RULES.length}
                            </span>
                          )}
                        </a>
                        <div className="panel-dropdown-content checkboxes ">
                          <div className="row  ">
                            <div className="panel-checkboxes-container">
                              {Object.values(HouseRules).map((value,index) => (
                                <div key={index} className="col-md-6">
                                  <div className="panel-checkbox-wrap">
                                    <input
                                      id={value.uid}
                                      value={value.id}
                                      onClick={(e) => {
                                        if (
                                          ARRAY_HOUSE_RULES.indexOf(
                                            e.target.value
                                          ) == -1
                                        ) {
                                          ARRAY_HOUSE_RULES.push(
                                            e.target.value
                                          );
                                          localStorage.setItem("ARRAY_HOUSE_RULES",ARRAY_HOUSE_RULES)
                                          forceUpdate();
                                        } else {
                                          ARRAY_HOUSE_RULES =
                                            ARRAY_HOUSE_RULES.filter(
                                              (item) => item != e.target.value
                                            );
                                          localStorage.setItem("ARRAY_HOUSE_RULES",ARRAY_HOUSE_RULES)
                                          forceUpdate();
                                        }
                                      }}
                                      type="checkbox"
                                      name="check"
                                    />
                                    <label htmlFor={value.uid}>
                                      {value.rule}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>{" "}
                          {/* Panel Dropdown */}
                        </div>
                      </div>
                      {/* Panel Dropdown */}
                      <div
                        onClick={HandleDropDownPriceFilter}
                        className={
                          DropDownPriceFilter
                            ? "panel-dropdown active"
                            : "panel-dropdown"
                        }
                        id="_price-panel"
                        tabIndex={0}
                        onBlur={HandleDropDownPriceFilterLoseFocus}
                      >
                        <a>
                          Price Filter
                          {(minValue > 0 || maxValue < 100000) &&
                            // <span className="tag-listing-filter">
                            //   {"x"}
                            // </span>
                            "*"}
                        </a>
                        <div className="panel-dropdown-content  ">
                          {/* Range Slider */}
                          <div className="">
                            {/* Range Slider */}
                            <div className="range-slider-container disabled">
                              <span className="range-slider-headline">
                                Price Filter{" "}
                              </span>
                              <div className="range-slider-subtitle">
                                Select min and max price range
                              </div>
                              <MultiRangeSlider
                                min={0}
                                max={100000}
                                step={500}
                                ruler={false}
                                label={false}
                                preventWheel={false}
                                minValue={minValue}
                                maxValue={maxValue}
                                onInput={(e) => {
                                  handleInput(e)
                                  localStorage.setItem("minValue", minValue)
                                  localStorage.setItem("maxValue", maxValue)
                                }}
                              />
                            </div>
                          </div>{" "}
                          {/* Panel Dropdown */}
                        </div>
                      </div>
                      {/* Panel Dropdown */}
                      <div
                        onClick={HandleDropDownSortBy}
                        className={
                          DropDownSortBy
                            ? "panel-dropdown active"
                            : "panel-dropdown"
                        }
                        id="_sortby-panel"
                        tabIndex={0}
                        onBlur={HandleDropDownSortByLoseFocus}
                      >
                        <a>
                          Distance Radius
                          {(minDistanceValue > 2 || maxDistanceValue < 1000) &&
                            // <span className="tag-listing-filter">
                            //   {"x"}
                            // </span>
                            "*"}
                        </a>
                        <div className="panel-dropdown-content  ">
                          {/* Range Slider */}
                          <div className="">
                            {/* Range Slider */}
                            <div className="range-slider-container disabled">
                              <span className="range-slider-headline">
                                Distance Filter{" "}
                              </span>
                              <div className="range-slider-subtitle">
                                Select min Distance radius
                              </div>
                              <MultiRangeSlider
                                min={2}
                                max={1000}
                                step={10}
                                ruler={false}
                                label={false}
                                preventWheel={false}
                                minValue={minDistanceValue}
                                maxValue={maxDistanceValue}
                                onInput={(e) => {
                                  handleDistanceInput(e)
                                  localStorage.setItem('minDistanceValue',minDistanceValue)
                                  localStorage.setItem('maxDistanceValue',maxDistanceValue)
                                }}
                              />
                            </div>
                          </div>{" "}
                          {/* Panel Dropdown */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-2">
                          <a
                            class="button fullwidth border-radius-5 bg-yellow filter-btn-height-fix"
                            onClick={() => setApplyFilter(!ApplyFilter)}
                          >
                            <i class="sl sl-icon-check"></i> Apply
                          </a>
                        </div>
                        <div className="col-md-2">
                          {/* <a
                            onClick={HandleClearFilter}
                            class="button with-icon clear-filter"
                          >
                          </a> */}
                          <a
                            class="button fullwidth border-radius-5 bg-gray filter-btn-height-fix"
                            onClick={HandleClearFilter}
                          >
                            <i class="sl sl-icon-close"></i> Clear
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Filters */}
                    {/* <div className="col-fs-12">
                      <div className="panel-dropdown">
                          <select
                            data-placeholder="Default order"
                            className="chosen-select-no-single"
                          >
                            <option>Categories</option>
                            <option>Shops</option>
                            <option>Hotels</option>
                            <option>Eatr</option>
                            <option>Burger House</option>
                            <option>Airport</option>
                            <option>Think Coffee</option>
                          </select>
                        </div>
                      <div className="panel-dropdown wide">

                        <select
                          data-placeholder="Default order"
                          className="chosen-select-no-single"
                        >
                          <option>More Filter</option>
                          <option>Tom's Restaurant</option>
                          <option>Sticky Band</option>
                          <option>Hotel Govendor</option>
                          <option>Burger House</option>
                          <option>Airport</option>
                          <option>Think Coffee</option>
                        </select>
                      </div>
                      <div className="panel-dropdown">
                        <select
                          data-placeholder="Default order"
                          className="chosen-select-no-single"
                        >
                          <option>House Rules</option>
                          <option>Tom's Restaurant</option>
                          <option>Sticky Band</option>
                          <option>Hotel Govendor</option>
                          <option>Burger House</option>
                          <option>Airport</option>
                          <option>Think Coffee</option>
                        </select>
                      </div>
                      <div className="panel-dropdown">
                        <select
                          data-placeholder="Default order"
                          className="chosen-select-no-single"
                        >
                          <option>Price Filters</option>
                          <option>Tom's Restaurant</option>
                          <option>Sticky Band</option>
                          <option>Hotel Govendor</option>
                          <option>Burger House</option>
                          <option>Airport</option>
                          <option>Think Coffee</option>
                        </select>
                      </div>
                      <div className="panel-dropdown">
                        <select
                          data-placeholder="Default order"
                          className="chosen-select-no-single"
                        >
                          <option>Sort by</option>
                          <option>Tom's Restaurant</option>
                          <option>Sticky Band</option>
                          <option>Hotel Govendor</option>
                          <option>Burger House</option>
                          <option>Airport</option>
                          <option>Think Coffee</option>
                        </select>
                      </div>{" "}
                    </div> */}
                    {/* Filters / End */}
                  </div>
                  {/* Row With Forms / End */}
                </div>
              </div>
            </section>
            <div className="sort-by" data-select2-id="select2-data-5-79r8">
              <div className="sort-by-select">
                <select
                  form="listeo_core-search-form"
                  name="listeo_core_order"
                  data-placeholder="Default order"
                  className="select2-sortby orderby select2-hidden-accessible"
                  data-select2-id="select2-data-1-53j9"
                  tabIndex={-1}
                  value={SortBy}
                  onChange={(e) => {
                    setSortBy(e.target.value)
                    localStorage.setItem('SortBy',e.target.value);
                  }}
                  aria-hidden="true"
                >
                  <option
                      defaultValue="selected"
                    value="1"
                    data-select2-id="select2-data-3-1mt0"
                  >
                    Highest Rated
                  </option>
                  <option
                    value="2"
                    data-select2-id="select2-data-3-1mt0"
                  >
                    Newest Listings
                  </option>
                  <option value="3" data-select2-id="select2-data-15-jb04">
                    Low to High Price
                  </option>
                  <option value="4" data-select2-id="select2-data-16-r3lf">
                    High to Low Price
                  </option>
                  <option value="5" data-select2-id="select2-data-17-vc63">
                    Most Reviewed
                  </option>
                  <option value="6" data-select2-id="select2-data-18-xd9m">
                    Featured
                  </option>
                  {/* <option  value="default">Default Order</option>
                <option  value="date-asc">Oldest Listings</option>
                <option  value="highest-rated">Highest Rated</option>
				        <option  value="views">Most Views</option>
			          <option  value="rand">Random</option>*/}
                </select>
                <span
                  className="select2 select2-container select2-container--default select2-container--focus select2-container--below"
                  dir="ltr"
                  data-select2-id="select2-data-2-va8c"
                  style={{ width: "100%" }}
                >
                  <span className="selection">
                    <span
                      className="select2-selection select2-selection--single"
                      role="combobox"
                      aria-haspopup="true"
                      aria-expanded="false"
                      tabIndex={0}
                      aria-disabled="false"
                      aria-labelledby="select2-listeo_core_order-zo-container"
                    >
                      {/* <span
                        className="select2-selection__rendered"
                        id="select2-listeo_core_order-zo-container"
                        role="textbox"
                        aria-readonly="true"
                        title="Newest Listings"
                      >
                        Newest Listings
                      </span> */}
                      <span
                        className="select2-selection__arrow icon-hover-change"
                        role="presentation"
                      >
                        <b role="presentation" />
                      </span>
                    </span>
                  </span>
                  <span className="dropdown-wrapper" aria-hidden="true" />
                </span>
              </div>
            </div>
            {/* Search / End */}
            <PropertyListing
              ApplyFilter={ApplyFilter}
              SortBy={SortBy}
              PropertyTypeId={Property_Type_Id}
              Amenities={MoreFilterArray}
              HouseRules={ARRAY_HOUSE_RULES}
              Location={locationField}
              minValue={minValue}
              maxValue={maxValue}
              minDistance={minDistanceValue}
              maxDistance={maxDistanceValue}
              Guests={loc.state != null ? Guests : guestsField}
              setLat={setLat}
              setLng={setLng}
              Lat={Lat}
              Lng={Lng}
            />
          </div>
        </div>
        <div className="fs-inner-container map-fixed">
          {/* Map */}
          <div id="map-container">
            <div id="map" data-map-scroll="true">
              {/* map goes here */}
              <GoogleMaps lat={Lat} lng={Lng} isLoaded={isLoaded} />
            </div>
          </div>
        </div>
      </div>
      {/* Wrapper / End */}
    </>
  );
};

export default Listing;

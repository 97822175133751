import { post } from "../../Axios/post";
import React from "react";
import { RegEmail } from "../../Regex/Regex";
import { ADDReviewEndPoint } from "../../Axios/EndPoints";
import HandleTokenExpired from "../../Main/TokenExpired/TokenExpired";
import { NUMBERS } from "../../constants/DetailValidation";
import Toast from "../../Main/Toast/Toast";
let Numb = NUMBERS;

const AddAReview = (props) => {
  const [Email, setEmail] = React.useState("");
  // const [EmailError, setEmailError] = React.useState(false);
  const [Name, setName] = React.useState("");
  const [Review, setReview] = React.useState("");
  const [ReviewError, setReviewError] = React.useState(false);

  const [Cleanliness, setCleanliness] = React.useState(0);
  const [Service, setService] = React.useState(0);
  const [Location, setLocation] = React.useState(0);
  const [ValueOfMoney, setValueOfMoney] = React.useState(0);
  const [Alert, setAlert] = React.useState(false);
  const [AlertData, setAlertData] = React.useState(null);

  // const EmailValidation = () =>
  // {
  //   if (!RegEmail.test(Email))
  //   {
  //     setEmailError(true);
  //   }
  //   else
  //   {
  //     setEmailError(false);
  //   }
  // }

  const ReviewValidation = () => {
    return Review === "" ? false : true;
  };

  const HandleReviewSubmit = () => {
    if (ReviewValidation()) {
      let data = {
        review: Review,
        service_rating: Service,
        value_for_money_rating: ValueOfMoney,
        location_rating: Location,
        cleanliness_rating: Cleanliness,
      };

      post(
        ADDReviewEndPoint + props.UID,
        "LOCAL",
        data,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            // alert(res.data.message);
            setAlertData(res.data);
          } else if (res.data.status == 401) {
            HandleTokenExpired();
          } else {
            setAlertData(res.data);
            // alert(res.data.message);
          }
          setAlert(true);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Review Field is Empty");
    }
  };
  console.log("alertdata =", AlertData);

  //  console.log('cleanliness = ', Cleanliness);
  //  console.log('service = ', Service);
  //  console.log('ValueofMoney = ', ValueOfMoney);
  //  console.log('Location = ', Location);

  const validateReview = () => {
    let review = Review;
    let rev,
      counter = 0;
    for (let i = 0; i < review.length; i++) {
      rev = review[i];
      if (!isNaN(rev)) {
        counter++;
      } else if (isNaN(rev)) {
        counter = 0;
        // setReviewError(false);
      }
    }
    let arr = [];
    Numb.map((numberCheck) => {
      if (review.includes(numberCheck)) {
        arr.push(true);
      }
    });

    if (arr.length > 0 || counter > 4) {
      setReviewError(true);
      // alert('You Cannot Enter Phone Number in Review')
    } else {
      setReviewError(false);
    }
  };

  return (
    <>
      <div id="add-review" className="add-review-box">
        {/* Add Review */}
        {Alert == true ? (
          <Toast status={AlertData.status} message={AlertData.message} />
        ) : (
          ""
        )}
        <h3 className="listing-desc-headline margin-bottom-10">Add Review</h3>
        {/* <p className="comment-notes">
          Your email address will not be published.
        </p> */}
        {/* Subratings Container */}
        <div className="sub-ratings-container">
          <div className="add-sub-rating">
            <div className="sub-rating-title">
              Service{" "}
              <i
                className="tip"
                data-tip-content="Quality of customer service and attitude to work with you"
              />
            </div>
            <div className="sub-rating-stars">
              <div className="clearfix" />
              <form className="leave-rating">
                <input
                  type="radio"
                  name="rating"
                  id="rating-1"
                  defaultValue={5}
                  onChange={(e) => setService(e.target.value)}
                />
                <label htmlFor="rating-1" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-2"
                  defaultValue={4}
                  onChange={(e) => setService(e.target.value)}
                />
                <label htmlFor="rating-2" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-3"
                  defaultValue={3}
                  onChange={(e) => setService(e.target.value)}
                />
                <label htmlFor="rating-3" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-4"
                  defaultValue={2}
                  onChange={(e) => setService(e.target.value)}
                />
                <label htmlFor="rating-4" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-5"
                  defaultValue={1}
                  onChange={(e) => setService(e.target.value)}
                />
                <label htmlFor="rating-5" className="fa fa-star" />
              </form>
            </div>
          </div>
          <div className="add-sub-rating">
            <div className="sub-rating-title">
              Value for Money{" "}
              <i
                className="tip"
                data-tip-content="Overall experience received for the amount spent"
              />
            </div>
            <div className="sub-rating-stars">
              <div className="clearfix" />
              <form className="leave-rating">
                <input
                  type="radio"
                  name="rating"
                  id="rating-11"
                  defaultValue={5}
                  onChange={(e) => setValueOfMoney(e.target.value)}
                />
                <label htmlFor="rating-11" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-12"
                  defaultValue={4}
                  onChange={(e) => setValueOfMoney(e.target.value)}
                />
                <label htmlFor="rating-12" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-13"
                  defaultValue={3}
                  onChange={(e) => setValueOfMoney(e.target.value)}
                />
                <label htmlFor="rating-13" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-14"
                  defaultValue={2}
                  onChange={(e) => setValueOfMoney(e.target.value)}
                />
                <label htmlFor="rating-14" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-15"
                  defaultValue={1}
                  onChange={(e) => setValueOfMoney(e.target.value)}
                />
                <label htmlFor="rating-15" className="fa fa-star" />
              </form>
            </div>
          </div>
          <div className="add-sub-rating">
            <div className="sub-rating-title">
              Location{" "}
              <i
                className="tip"
                data-tip-content="Visibility, commute or nearby parking spots"
              />
            </div>
            <div className="sub-rating-stars">
              <div className="clearfix" />
              <form className="leave-rating">
                <input
                  type="radio"
                  name="rating"
                  id="rating-21"
                  defaultValue={5}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label htmlFor="rating-21" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-22"
                  defaultValue={4}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label htmlFor="rating-22" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-23"
                  defaultValue={3}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label htmlFor="rating-23" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-24"
                  defaultValue={2}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label htmlFor="rating-24" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-25"
                  defaultValue={1}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <label htmlFor="rating-25" className="fa fa-star" />
              </form>
            </div>
          </div>
          <div className="add-sub-rating">
            <div className="sub-rating-title">
              Cleanliness{" "}
              <i
                className="tip"
                data-tip-content="The physical condition of the business"
              />
            </div>
            <div className="sub-rating-stars">
              <div className="clearfix" />
              <form className="leave-rating">
                <input
                  type="radio"
                  name="rating"
                  id="rating-31"
                  defaultValue={5}
                  onChange={(e) => setCleanliness(e.target.value)}
                />
                <label htmlFor="rating-31" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-32"
                  defaultValue={4}
                  onChange={(e) => setCleanliness(e.target.value)}
                />
                <label htmlFor="rating-32" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-33"
                  defaultValue={3}
                  onChange={(e) => setCleanliness(e.target.value)}
                />
                <label htmlFor="rating-33" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-34"
                  defaultValue={2}
                  onChange={(e) => setCleanliness(e.target.value)}
                />
                <label htmlFor="rating-34" className="fa fa-star" />
                <input
                  type="radio"
                  name="rating"
                  id="rating-35"
                  defaultValue={1}
                  onChange={(e) => setCleanliness(e.target.value)}
                />
                <label htmlFor="rating-35" className="fa fa-star" />
              </form>
            </div>
          </div>
          {/* <div className="uploadButton margin-top-15">
            <input
              className="uploadButton-input"
              type="file"
              name="attachments[]"
              accept="image/*, application/pdf"
              id="upload"
              multiple
            />
            <label
              className="uploadButton-button ripple-effect"
              htmlFor="upload"
            >
              Add Photos
            </label>
            <span className="uploadButton-file-name" />
          </div> */}
        </div>
        {/* Subratings Container / End */}
        {/* Review Comment */}
        <form id="add-comment" className="add-comment">
          <fieldset>
            {/* <div className="row">
              <div className="col-md-6">
                <label>Name:</label>
                <input type="text" placeholder="e.g Picasso" defaultValue={""}
                value={Name}
                onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label>Email:</label>
                {EmailError && <span className="error">Please Enter A Valid Email</span>}
                <input type="text" placeholder="e.g abc@email.com" defaultValue={""} 
                value={Email}
                onInput={() => EmailValidation()}
                onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div> */}
            <div>
              <label>Review:</label>
              {ReviewError && (
                <span className="error">
                  Please do not Enter Mobile Number in Message i.e sifr, zero,
                  one ...
                </span>
              )}
              <textarea
                cols={40}
                rows={3}
                defaultValue={""}
                value={Review}
                onKeyUp={validateReview}
                onChange={(e) => setReview(e.target.value)}
              />
            </div>
          </fieldset>
          <div className="clearfix" />
        </form>
        <button
          onClick={
            ReviewError
              ? () => alert("Do Not Include Phone Number in Review")
              : HandleReviewSubmit
          }
          className="button"
        >
          Submit Review
        </button>
      </div>
    </>
  );
};

export default AddAReview;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import { useState, useEffect } from "react";
import { get } from "../../Components/Axios/get";
import "../Homepage/Homepage.css";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  DashboardBookingRequestEndPoint,
  DashboardApproveRequestEndPoint,
  DashboardCancelBookingEndPoint,
  DashboardDeleteBookingEndPoint,
  ChatSendMessageEndPoint,
} from "../../Components/Axios/EndPoints";
import { useLocation } from "react-router";
import { put } from "../../Components/Axios/put";
import SendMessageModal from "../../Components/Main/SendMessage/SendMessage";
import { post } from "../../Components/Axios/post";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import Toast from "../../Components/Main/Toast/Toast";
import redirectToLogin from "../../utility/redirectToLogin";

let StartDate = "",
  EndDate = "";

const Bookings = (props) => {
  const Location = useLocation();
  const [startDate, setStartDate] = useState(new Date());

  const [AllListingDropdown, setAllListingDropdown] = useState(false);
  const [AllStatusesDropdown, setAllStatusesDropdown] = useState(false);
  const [Dates, setDates] = useState("");
  const [SendMessage, setSendMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [ModalIndex, setModalIndex] = useState(0);
  const [MessageToSendInfo, setMessageToSendInfo] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Alert, setAlert] = useState(false);
  const [PendingAlert, setPendingAlert] = useState(false);
  const [ApprovedAlert, setApprovedAlert] = useState(false);
  const [CancelledAlert, setCancelledAlert] = useState(false);

  const [AlertData, setAlertData] = useState(null);

  const [AllListingDropDownData, setAllListingDropDownData] = useState(null);
  const [AllListingDropDownSelectedData, setAllListingDropDownSelectedData] =
    useState(null);

  const [
    PaymentStatusDropDownSelectedData,
    setPaymentStatusDropDownSelectedData,
  ] = useState(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  let ArrayPending = [],
    ArrayConfirmed = [],
    ArrayCancelled = [];

  const HandleSendMessage = () => {
    if (Message != "") {
      let data = {
        message: Message,
        message_id:
          "message_" + MessageToSendInfo + "_" + localStorage.getItem("id"),
      };

      post(
        ChatSendMessageEndPoint,
        "LOCAL",
        data,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            setAlertData(res.data);
            // alert(res.data.message);
            setSendMessage(false);
          } else if (res.data.status == 401) {
            HandleTokenExpired();
          } else {
            setAlertData(res.data);
            // alert(res.data.message);
            setSendMessage(false);
          }
          setAlert(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please Enter Your Message");
    }

    // alert("message send");
    // setModalIndex(index);
  };

  // console.log("URL = ", Location.pathname);
  // console.log("start date  = ", StartDate + "   enddate =", EndDate);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  if (Dates != "") {
    // console.log("Dates = ", Dates);
    if (Dates != null) {
      StartDate = convert(Dates[0]);
      EndDate = convert(Dates[1]);
    }
  }

  const HandleModalCall = (value, index) => {
    setSendMessage(!SendMessage);

    // {console.log("location = ", )}{" "}
    if (Location.pathname.slice(1) == "dashboard-booking-requests-pending") {
      for (let i = 0; i < ArrayPending.length; i++) {
        if (ArrayPending[i].uid == value["uid"]) {
          setModalIndex(i);
        }
      }
    } else if (
      Location.pathname.slice(1) == "dashboard-booking-requests-approved"
    ) {
      for (let i = 0; i < ArrayConfirmed.length; i++) {
        if (ArrayConfirmed[i].uid == value["uid"]) {
          setModalIndex(i);
        }
      }
    } else if (
      Location.pathname.slice(1) == "dashboard-booking-requests-cancelled"
    ) {
      for (let i = 0; i < ArrayCancelled.length; i++) {
        if (ArrayCancelled[i].uid == value["uid"]) {
          setModalIndex(i);
        }
      }
    }
    // setModalIndex(index);
    setMessageToSendInfo(value["customer_id"]);
  };

  const HandleDropdownListing = () => {
    if (AllStatusesDropdown) {
      setAllStatusesDropdown(false);
      setAllListingDropdown(!AllListingDropdown);
    } else {
      setAllListingDropdown(!AllListingDropdown);
    }
  };

  const HandleDropdownStatuses = () => {
    if (AllListingDropdown) {
      setAllListingDropdown(false);
      setAllStatusesDropdown(!AllStatusesDropdown);
    } else {
      setAllStatusesDropdown(!AllStatusesDropdown);
    }
  };

  const [BookingRequest, setBookingRequest] = useState();
  const [BookingRequestCount, setBookingRequestCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [Pagination, setPagintion] = useState(null);
  const [JazzMWalletLinkToPostData, setMWalletJazzLinkToPostData] =
    useState(null);
  const [JazzCardLinkToPostData, setJazzCardLinkToPostData] = useState(null);

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      "border-radius": "15px",
      // background-image:
    },
    overlay: {
      zIndex: 1000,
      // opacity: 0.45,
    },
  };

  useEffect(() => {
    redirectToLogin(props);
    let mounted = true;
    // getJazzCashMWalletPaymentData();
    // getJazzCashCCPaymentData();
    let ENDPOINT = "?page=" + CurrentPage;
    if (Location.pathname.slice(1) == "dashboard-booking-requests-pending") {
      ENDPOINT = ENDPOINT + "&" + "type=" + "PENDING";
    } else if (
      Location.pathname.slice(1) == "dashboard-booking-requests-approved"
    ) {
      ENDPOINT = ENDPOINT + "&" + "type=" + "APPROVED";
    } else if (
      Location.pathname.slice(1) == "dashboard-booking-requests-cancelled"
    ) {
      ENDPOINT = ENDPOINT + "&" + "type=" + "CANCELLED";
    }

    if (Dates != "" && Dates != null) {
      ENDPOINT =
        ENDPOINT + "&" + "date_from=" + StartDate + "&" + "date_to=" + EndDate;
    }
    console.log(
      "PaymentStatusDropDownSelectedData =",
      PaymentStatusDropDownSelectedData
    );
    if (
      PaymentStatusDropDownSelectedData != null &&
      PaymentStatusDropDownSelectedData != ""
    ) {
      ENDPOINT = ENDPOINT + "&" + "status=" + PaymentStatusDropDownSelectedData;
    }
    console.log(
      "AllListingDropDownSelectedData =",
      AllListingDropDownSelectedData
    );
    if (
      AllListingDropDownSelectedData != null &&
      AllListingDropDownSelectedData != ""
    ) {
      ENDPOINT = ENDPOINT + "&" + "listing=" + AllListingDropDownSelectedData;
    }

    get(
      DashboardBookingRequestEndPoint + ENDPOINT,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          setBookingRequest(res.data.response.bookings);
          setBookingRequestCount(res.data.response.bookings.length);
          setAllListingDropDownData(res.data.response.listings);
          setPagintion(res.data.response.pagination);
          window.scrollTo(0, 0);
          setLoading(false);
          for (let i = 0; i < res.data.response.bookings.length; i++) {
            if (res.data.response.bookings[i].status_id == 1) {
              ArrayPending.push(res.data.response.bookings[i]);
            } else if (
              res.data.response.bookings[i].status_id == 2 ||
              res.data.response.bookings[i].status_id == 3
            ) {
              ArrayConfirmed.push(res.data.response.bookings[i]);
            } else if (
              res.data.response.bookings[i].status_id == 4 ||
              res.data.response.bookings[i].status_id == 5
            ) {
              ArrayCancelled.push(res.data.response.bookings[i]);
            }
          }
          // console.log("booking rquest = ", ArrayPending);
        } else if (res.data.status === 204) {
          setBookingRequestCount(res.data.response.bookings.length);
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, [
    CurrentPage,
    Dates,
    AllListingDropDownSelectedData,
    PaymentStatusDropDownSelectedData,
  ]);

  if (loading) {
    return <div className="loading"></div>;
  }

  return BookingRequestCount > 0 ? (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-booking-requests"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Booking Requests</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-booking-requests">Booking Requests</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {Alert == true ? (
              <Toast status={AlertData.status} message={AlertData.message} />
            ) : (
              ""
            )}

            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                {/* Booking Requests Filters  */}
                <div className="booking-requests-filter">
                  {/* Sort by */}
                  {/* {console.log('data=', AllListingDropDownSelectedData == null ? "null":AllListingDropDownSelectedData )} */}
                  <div className="sort-by">
                    <div className="sort-by-select">
                      <select
                        form="listeo_core-search-form"
                        name="listeo_core_order"
                        data-placeholder="Default order"
                        className="select2-sortby orderby select2-hidden-accessible"
                        data-select2-id="select2-data-1-53j9"
                        tabIndex={-1}
                        value={AllListingDropDownSelectedData}
                        onChange={(e) =>
                          setAllListingDropDownSelectedData(e.target.value)
                        }
                        aria-hidden="true"
                      >
                        <option
                          selected="selected"
                          value=""
                          data-select2-id="select2-data-15-jb04"
                        >
                          All Listings
                        </option>
                        {AllListingDropDownData.map((value, index) => (
                          <option
                            selected="selected"
                            value={value.id}
                            // data-select2-id="select2-data-3-1mt0"
                            // data-option-array-index={'1'}
                          >
                            {value.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* {console.log("filter =", AllListingDropDownSelectedData)}
                  {console.log(
                    "Payment filter =",
                    PaymentStatusDropDownSelectedData
                  )} */}

                  {Location.pathname.slice(1) ==
                  "dashboard-booking-requests-pending" ? (
                    ""
                  ) : (
                    <div className="sort-by">
                      <div className="sort-by-select">
                        <select
                          className="select2-sortby orderby select2-hidden-accessible"
                          tabIndex={-1}
                          value={PaymentStatusDropDownSelectedData}
                          onChange={(e) =>
                            setPaymentStatusDropDownSelectedData(e.target.value)
                          }
                          aria-hidden="true"
                        >
                          <option
                            selected="selected"
                            value=""
                            data-select2-id="select2-data-15-jb04"
                          >
                            All Statuses
                          </option>
                          <option
                            selected="selected"
                            value={2}
                            // data-select2-id="select2-data-3-1mt0"
                            // data-option-array-index={'1'}
                          >
                            UnPaid
                          </option>
                          <option
                            selected="selected"
                            value={3}
                            // data-select2-id="select2-data-3-1mt0"
                            // data-option-array-index={'1'}
                          >
                            Paid
                          </option>
                        </select>
                      </div>
                    </div>
                  )}
                  {/* Date Range */}
                  <div id="booking-date-range">
                    <DateRangePickerComponent
                      value={Dates}
                      onChange={(e) => setDates(e.target.value)}
                      min={startDate}
                      placeholder="Pick a Date"
                    />{" "}
                  </div>
                </div>
                {/* Reply to review popup */}
                {SendMessage == true ? (
                  <>
                    <SendMessageModal
                      placeholder={"Your message"}
                      index={ModalIndex}
                      value={Message}
                      setvalue={setMessage}
                      sendmessage={HandleSendMessage}
                      closemodal={setSendMessage}
                    />
                  </>
                ) : (
                  ""
                )}{" "}
                <h4>Booking Requests</h4>
                <ul>
                  {/* {console.log("location = ", Location.pathname.slice(1))}{" "} */}
                  {Object.values(BookingRequest).map((value, index) =>
                    Location.pathname.slice(1) ===
                      "dashboard-booking-requests-pending" &&
                    value["status_id"] === 1 ? (
                      <li
                        className={
                          value["status_id"] === 1
                            ? "pending-booking"
                            : value["status_id"] === 2
                            ? "approved-booking"
                            : value["status_id"] === 3
                            ? "approved-booking"
                            : value["status_id"] === 4
                            ? "canceled-booking"
                            : value["status_id"] === 5
                            ? "canceled-booking"
                            : ""
                        }
                      >
                        <div className="list-box-listing bookings">
                          <div className="list-box-listing-img">
                            <img src={value["property_image"]} alt="" />
                          </div>
                          <div className="list-box-listing-content">
                            <div className="inner">
                              <h3>
                                <a
                                  href={
                                    "/listing-detail/" + value["property_uid"]
                                  }
                                >
                                  {value["property_name"]}
                                </a>
                                <span
                                  className={
                                    value["status_id"] === 1
                                      ? "booking-status"
                                      : value["status_id"] === 2
                                      ? "booking-status"
                                      : value["status_id"] === 3
                                      ? "booking-status"
                                      : value["status_id"] === 4
                                      ? "booking-status"
                                      : ""
                                  }
                                >
                                  {value["status"]}
                                </span>
                                {/* {value["status_id"] === 1 ? (
                                  <span className="booking-status unpaid">
                                    Unpaid
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {value["status_id"] === 2 ? (
                                  <span className="booking-status paid">
                                    Paid
                                  </span>
                                ) : (
                                  <></>
                                )} */}
                              </h3>
                              <div className="inner-booking-list">
                                <h5>Booking Date:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["booking_from"]} -{" "}
                                    {value["booking_to"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Booking Details:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {" "}
                                    {value["number_of_guests"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Booking Location:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["location"]}{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Price:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["property_rate_type"]}{" "}
                                    {value["property_rate"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Client:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["property_vendor_name"]}
                                  </li>
                                  {/* <li>john@example.com</li>
                              <li>123-456-789</li> */}
                                </ul>
                              </div>
                              {value["message"] == "" ? (
                                ""
                              ) : (
                                <div className="inner-booking-list">
                                  <h5>Message:</h5>
                                  <ul className="booking-list">
                                    <li className="highlighted">
                                      {value["message"]}
                                    </li>
                                  </ul>
                                </div>
                              )}{" "}
                              <div className="inner-booking-list">
                                <h5>Booking requested on:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["booking_created_date"]}
                                  </li>
                                </ul>
                              </div>
                              {localStorage.getItem("id") !=
                              value["property_vendor_id"] ? (
                                <a
                                  onClick={() => HandleModalCall(value, index)}
                                  className="rate-review popup-with-zoom-anim"
                                >
                                  {/* {console.log("index = ", ModalIndex)} */}
                                  <i className="sl sl-icon-envelope-open" />{" "}
                                  Send Message
                                </a>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="buttons-to-right">
                          {value["status_id"] === 1 ? (
                            <>
                              <a
                                onClick={() => {
                                  put(
                                    DashboardApproveRequestEndPoint +
                                      value["uid"],
                                    "LOCAL",
                                    true,
                                    localStorage.getItem("token")
                                  )
                                    .then((res) => {
                                      if (res.data.status === 200) {
                                        // alert(res.data.message);
                                        setAlertData(res.data);
                                      } else if (res.data.status == 401) {
                                        HandleTokenExpired();
                                      }
                                      setAlert(true);
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                  // setAlert(true);
                                }}
                                className="button mazebaan"
                              >
                                <i className="sl sl-icon-check" /> Approve
                              </a>
                              <a
                                onClick={() => {
                                  put(
                                    DashboardCancelBookingEndPoint +
                                      value["uid"],
                                    "LOCAL",
                                    true,
                                    localStorage.getItem("token")
                                  )
                                    .then((res) => {
                                      if (res.data.status === 200) {
                                        // alert(res.data.message);
                                        setAlertData(res.data);
                                        window.location.href =
                                          "/dashboard-booking-requests-pending";
                                      } else if (res.data.status == 401) {
                                        HandleTokenExpired();
                                      }
                                      setAlert(true);
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }}
                                className="button mazebaan cancel"
                              >
                                <i className="sl sl-icon-close" /> Reject
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    ) : Location.pathname.slice(1) ===
                        "dashboard-booking-requests-approved" &&
                      (value["status_id"] === 2 || value["status_id"] === 3) ? (
                      <li
                        className={
                          value["status_id"] === 1
                            ? "pending-booking"
                            : value["status_id"] === 2
                            ? "approved-booking"
                            : value["status_id"] === 3
                            ? "approved-booking"
                            : value["status_id"] === 4
                            ? "canceled-booking"
                            : value["status_id"] === 5
                            ? "canceled-booking"
                            : ""
                        }
                      >
                        <div className="list-box-listing bookings">
                          <div className="list-box-listing-img">
                            <img src={value["property_image"]} alt="" />
                          </div>
                          <div className="list-box-listing-content">
                            <div className="inner">
                              <h3>
                                <a
                                  href={
                                    "/listing-detail/" + value["property_uid"]
                                  }
                                >
                                  {value["property_name"]}
                                </a>
                                <span
                                  className={
                                    value["status_id"] === 1
                                      ? "booking-status"
                                      : value["status_id"] === 2
                                      ? "booking-status"
                                      : value["status_id"] === 3
                                      ? "booking-status"
                                      : value["status_id"] === 4
                                      ? "booking-status"
                                      : ""
                                  }
                                >
                                  {value["status"]}
                                </span>
                                {/* {value["status_id"] === 1 ? (
                                  <span className="booking-status unpaid">
                                    Unpaid
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {value["status_id"] === 2 ? (
                                  <span className="booking-status paid">
                                    Paid
                                  </span>
                                ) : (
                                  <></>
                                )} */}
                              </h3>
                              <div className="inner-booking-list">
                                <h5>Booking Date:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["booking_from"]} -{" "}
                                    {value["booking_to"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Booking Details:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {" "}
                                    {value["number_of_guests"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Booking Location:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["location"]}{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Price:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["property_rate"] + " "}
                                    {value["property_rate_type"]}{" "}
                                  </li>
                                </ul>
                              </div>
                              {/* {value["status_id"] == 3 ? (
                                <>
                                  {/* <div className="inner-booking-list">
                                    <h5>CNIC:</h5>
                                    <ul className="booking-list">
                                      <li className="highlighted">
                                        {value["property_vendor_cnic"]}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="inner-booking-list">
                                    <h5>Phone:</h5>
                                    <ul className="booking-list">
                                      <li className="highlighted">
                                        {value["customer_phone_number"]}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="inner-booking-list">
                                    <h5>Email:</h5>
                                    <ul className="booking-list">
                                      <li className="highlighted">
                                        {value["customer_email"]}
                                      </li>
                                    </ul>
                                  </div>

                                </>
                              ) : (
                                <></>
                              )}{" "} */}
                              <div className="inner-booking-list">
                                <h5>Client:</h5>
                                <ul className="booking-list">
                                  <li>
                                    {value["customer_first_name"] +
                                      " " +
                                      value["customer_last_name"] +
                                      " | "}
                                    {value["status_id"] == 3 ? (
                                      <>
                                        {value["customer_phone_number"] + " | "}
                                        {value["customer_email"]}
                                      </>
                                    ) : (
                                      <></>
                                    )}{" "}
                                  </li>
                                  {/* <li>john@example.com</li>
                              <li>123-456-789</li> */}
                                </ul>
                              </div>
                              {value["message"] == "" ? (
                                ""
                              ) : (
                                <div className="inner-booking-list">
                                  <h5>Message:</h5>
                                  <ul className="booking-list">
                                    <li className="highlighted">
                                      {value["message"]}
                                    </li>
                                  </ul>
                                </div>
                              )}{" "}
                              <div className="inner-booking-list">
                                <h5>Booking requested on:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["booking_created_date"]}
                                  </li>
                                </ul>
                              </div>
                              <a
                                onClick={() => HandleModalCall(value, index)}
                                className="rate-review popup-with-zoom-anim"
                              >
                                <i className="sl sl-icon-envelope-open" /> Send
                                Message
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buttons-to-right">
                          {value["status_id"] === 2 ? (
                            <>
                              <a
                                onClick={() => {
                                  put(
                                    DashboardCancelBookingEndPoint +
                                      value["uid"],
                                    "LOCAL",
                                    true,
                                    localStorage.getItem("token")
                                  )
                                    .then((res) => {
                                      if (res.data.status === 200) {
                                        // alert(res.data.message);
                                        setAlertData(res.data);
                                        window.location.href =
                                          "/dashboard-booking-requests-approved";
                                      } else if (res.data.status == 401) {
                                        HandleTokenExpired();
                                      }
                                      setAlert(true);
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }}
                                className="button mazebaan cancel"
                              >
                                <i className="sl sl-icon-close" /> Cancel
                              </a>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    ) : Location.pathname.slice(1) ===
                        "dashboard-booking-requests-cancelled" &&
                      (value["status_id"] === 4 || value["status_id"] === 5) ? (
                      <li
                        className={
                          value["status_id"] === 1
                            ? "pending-booking"
                            : value["status_id"] === 2
                            ? "approved-booking"
                            : value["status_id"] === 3
                            ? "approved-booking"
                            : value["status_id"] === 4
                            ? "canceled-booking"
                            : value["status_id"] === 5
                            ? "canceled-booking"
                            : ""
                        }
                      >
                        <div className="list-box-listing bookings">
                          <div className="list-box-listing-img">
                            <img src={value["property_image"]} alt="" />
                          </div>
                          <div className="list-box-listing-content">
                            <div className="inner">
                              <h3>
                                <a
                                  href={
                                    "/listing-detail/" + value["property_uid"]
                                  }
                                >
                                  {value["property_name"]}
                                </a>
                                <span
                                  className={
                                    value["status_id"] === 1
                                      ? "booking-status"
                                      : value["status_id"] === 2
                                      ? "booking-status"
                                      : value["status_id"] === 3
                                      ? "booking-status"
                                      : value["status_id"] === 4
                                      ? "booking-status"
                                      : value["status_id"] === 5
                                      ? "booking-status"
                                      : ""
                                  }
                                >
                                  {value["status"]}
                                </span>
                                {/* {value["status_id"] === 1 ? (
                                  <span className="booking-status unpaid">
                                    Unpaid
                                  </span>
                                ) : (
                                  <></>
                                )}
                                {value["status_id"] === 2 ? (
                                  <span className="booking-status paid">
                                    Paid
                                  </span>
                                ) : (
                                  <></>
                                )} */}
                              </h3>
                              <div className="inner-booking-list">
                                <h5>Booking Date:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["booking_from"]} -{" "}
                                    {value["booking_to"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Booking Details:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {" "}
                                    {value["number_of_guests"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Booking Location:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["location"]}{" "}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Price:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["property_rate_type"]}{" "}
                                    {value["property_rate"]}
                                  </li>
                                </ul>
                              </div>
                              <div className="inner-booking-list">
                                <h5>Client:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["property_vendor_name"]}
                                  </li>
                                  {/* <li>john@example.com</li>
                              <li>123-456-789</li> */}
                                </ul>
                              </div>
                              {value["message"] == "" ? (
                                ""
                              ) : (
                                <div className="inner-booking-list">
                                  <h5>Message:</h5>
                                  <ul className="booking-list">
                                    <li className="highlighted">
                                      {value["message"]}
                                    </li>
                                  </ul>
                                </div>
                              )}{" "}
                              <div className="inner-booking-list">
                                <h5>Booking requested on:</h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {value["booking_created_date"]}
                                  </li>
                                </ul>
                              </div>
                              <a
                                onClick={() => HandleModalCall(value, index)}
                                className="rate-review popup-with-zoom-anim"
                              >
                                <i className="sl sl-icon-envelope-open" /> Send
                                Message
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buttons-to-right">
                          {value["status_id"] === 4 ||
                          value["status_id"] === 5 ? (
                            <a
                              onClick={() => {
                                put(
                                  DashboardDeleteBookingEndPoint + value["uid"],
                                  "LOCAL",
                                  true,
                                  localStorage.getItem("token")
                                )
                                  .then((res) => {
                                    if (res.data.status === 200) {
                                      // alert(res.data.message);
                                      setAlertData(res.ddata);
                                      window.location.href =
                                        "/dashboard-booking-requests-cancelled";
                                    } else if (res.data.status == 401) {
                                      HandleTokenExpired();
                                    }
                                    setAlert(true);
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              }}
                              className="button mazebaan cancel"
                            >
                              <i className="sl sl-icon-trash" /> Delete
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              </div>
              {/* Pagination */}
              <div className="pagination-container margin-top-15 margin-bottom-40">
                <nav className="pagination">
                  <ul>
                    <li className={CurrentPage > 1 ? "" : "hidden"}>
                      <a onClick={() => Handle_PreviousPageClick()}>
                        <i className="sl sl-icon-arrow-left" />
                      </a>
                    </li>
                    <li>
                      <a
                        // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                        className={"current-page"}
                      >
                        {CurrentPage}
                      </a>
                    </li>
                    <li
                      className={
                        Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                      }
                    >
                      <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                        {CurrentPage + 1}
                      </a>
                    </li>
                    <li
                      className={
                        Pagination.last_page >= CurrentPage + 2 ? "" : "hidden"
                      }
                    >
                      <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                        {CurrentPage + 2}
                      </a>
                    </li>
                    <li
                      className={
                        Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                      }
                    >
                      <a onClick={Handle_NextPageClick}>
                        <i className="sl sl-icon-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {/* Copyrights */}
            <div className="col-md-12">
              {/* <div className="copyrights">
                © 2021 Mazebaan. All Rights Reserved.
              </div> */}
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-booking-requests"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Booking Requests</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-booking-requests">Booking Requests</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                {/* Booking Requests Filters  */}
                <div className="booking-requests-filter">
                  {/* Sort by */}
                  {/* {console.log('data=', AllListingDropDownSelectedData == null ? "null":AllListingDropDownSelectedData )} */}
                  <div className="sort-by">
                    <div className="sort-by-select">
                      <select
                        form="listeo_core-search-form"
                        name="listeo_core_order"
                        data-placeholder="Default order"
                        className="select2-sortby orderby select2-hidden-accessible"
                        data-select2-id="select2-data-1-53j9"
                        tabIndex={-1}
                        value={AllListingDropDownSelectedData}
                        onChange={(e) =>
                          setAllListingDropDownSelectedData(e.target.value)
                        }
                        aria-hidden="true"
                      >
                        <option
                          selected="selected"
                          value=""
                          data-select2-id="select2-data-15-jb04"
                        >
                          All Listings
                        </option>
                        {AllListingDropDownData.map((value, index) => (
                          <option
                            selected="selected"
                            value={value.id}
                            // data-select2-id="select2-data-3-1mt0"
                            // data-option-array-index={'1'}
                          >
                            {value.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* {console.log("filter =", AllListingDropDownSelectedData)}
                  {console.log(
                    "Payment filter =",
                    PaymentStatusDropDownSelectedData
                  )} */}

                  <div className="sort-by">
                    <div className="sort-by-select">
                      <select
                        className="select2-sortby orderby select2-hidden-accessible"
                        tabIndex={-1}
                        value={PaymentStatusDropDownSelectedData}
                        onChange={(e) =>
                          setPaymentStatusDropDownSelectedData(e.target.value)
                        }
                        aria-hidden="true"
                      >
                        <option
                          selected="selected"
                          value=""
                          data-select2-id="select2-data-15-jb04"
                        >
                          All Statuses
                        </option>
                        <option
                          selected="selected"
                          value={2}
                          // data-select2-id="select2-data-3-1mt0"
                          // data-option-array-index={'1'}
                        >
                          UnPaid
                        </option>
                        <option
                          selected="selected"
                          value={3}
                          // data-select2-id="select2-data-3-1mt0"
                          // data-option-array-index={'1'}
                        >
                          Paid
                        </option>
                      </select>
                    </div>
                  </div>
                  {/* Date Range */}
                  <div id="booking-date-range">
                    <DateRangePickerComponent
                      value={Dates}
                      onChange={(e) => setDates(e.target.value)}
                      min={startDate}
                      placeholder="Pick a Date"
                    />{" "}
                  </div>
                </div>
                {/* Reply to review popup */}
                <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                  <div className="small-dialog-header">
                    <h3>Send Message</h3>
                  </div>
                  <div className="message-reply margin-top-0">
                    <textarea
                      cols={40}
                      rows={3}
                      placeholder="Your Message to Kathy"
                      defaultValue={""}
                    />
                    <button className="button">Send</button>
                  </div>
                </div>
                <h4>Booking Requests</h4>
                <ul>
                  <li>No Booking Request Found!</li>
                </ul>
              </div>
            </div>
            {/* Copyrights */}
            <div className="col-md-12">
              {/* <div className="copyrights">
                © 2021 Mazebaan. All Rights Reserved.
              </div> */}
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  );
};

export default Bookings;

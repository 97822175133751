import GOOGLELOGIN from "../Login/GoogleLogin";
import { post } from "../Axios/post";
import { UserRegisterEndPoint } from "../Axios/EndPoints";
import Toast from "../Main/Toast/Toast";
import { useState } from "react";
import { useNavigate } from "react-router";

const SignUpTab = (props) => {
  const navigate = useNavigate();
  const [LoginError, setLoginError] = useState(null);
  const [Error, setError] = useState(false);

  const [Email, setEmail] = useState("");
  const [ErrorEmail, setErrorEmail] = useState(false);

  const HandleEmailVerification = () =>
  {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))
    {
      setErrorEmail(false);
    }
    else
    {
      setErrorEmail(true);
    }
    }


  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {
      first_name: event.target.first_name.value,
      last_name: event.target.last_name.value,
      email: event.target.email.value,
      username: event.target.username.value,
      password: event.target.password.value,
      password_confirmation: event.target.password_confirmation.value,
    };
    post(UserRegisterEndPoint, "LOCAL", data, false)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("tokenExpired", "false");
          localStorage.setItem("token", res.data.response.token);
          localStorage.setItem("name", res.data.response.name);
          localStorage.setItem("email", res.data.response.email);
          localStorage.setItem("avatar", res.data.response.avatar);
          localStorage.setItem("username", res.data.response.username);
          localStorage.setItem("id", res.data.response.id);
          localStorage.setItem(
            "unread_messages",
            res.data.response.unread_messages
          );
          navigate("/", {
            state: {
              message: res.data.message,
              status: res.data.status,
              exist: true,
            },
          });
          // window.location.href = '/';
        } else {
          // alert(res.data.message);
          setLoginError(res.data);
          setError(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="tab-content" id="tab2">
      <form className="register" onSubmit={(e) => handleSubmit(e)}>
        <p className="form-row form-row-wide">
          <label htmlFor="first_name">
            First Name:
            <i className="im im-icon-Male" />
            <input
              type="text"
              className="input-text"
              name="first_name"
              id="first_name"
            ></input>
          </label>
        </p>
        {Error && (
          <>
            <Toast
              status={LoginError.status}
              message={LoginError.message}
              setError={setError}
            />
            {setTimeout(() => {
              setError(false);
            }, 5000)}
          </>
        )}
        <p className="form-row form-row-wide">
          <label htmlFor="last_name">
            Last Name:
            <i className="im im-icon-Male" />
            <input
              type="text"
              className="input-text"
              name="last_name"
              id="last_name"
            />
          </label>
        </p>
        {ErrorEmail && (
          <span className="error">Please Enter A Valid Email</span>
        )}
        <p className="form-row form-row-wide">
          <label htmlFor="email2">
            Email Address:
            <i className="im im-icon-Mail" />
            <input
              type="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-text"
              name="email"
              onKeyDown={HandleEmailVerification}
              id="email2"
            />
          </label>
        </p>
        <p className="form-row form-row-wide">
          <label htmlFor="username2">
            Username:
            <i className="im im-icon-Male" />
            <input
              type="text"
              className="input-text"
              name="username"
              id="username2"
            />
          </label>
        </p>
        <p className="form-row form-row-wide">
          <label htmlFor="password">
            Password:
            <i className="im im-icon-Lock-2" />
            <input
              className="input-text"
              type="password"
              name="password"
              id="password"
            />
          </label>
        </p>
        <p className="form-row form-row-wide">
          <label htmlFor="password_confirmation">
            Retype Password:
            <i className="im im-icon-Lock-2" />
            <input
              className="input-text"
              type="password"
              name="password_confirmation"
              id="password_confirmation"
            />
          </label>
        </p>
        <input
          type="submit"
          className="button border fw margin-top-10"
          name="register"
          defaultValue="Register"
        />
      </form>
      <GOOGLELOGIN />
    </div>
  );
};

export default SignUpTab;

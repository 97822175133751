export const NUMBERS = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "sifr",
    "aik",
    "teen",
    "char",
    "panch",
    "chay",
    "sat",
    "aath",
    "nao",
    "ZERO",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "EIGHT",
    "NINE",
    "SIFR",
    "AIK",
    "TEEN",
    "CHAR",
    "PANCH",
    "CHAY",
    "SAT",
    "AATH",
    "NAO",
];
import React, { useEffect, useState } from "react";
// import {post} from "../Axios/post";
import {
  ADDBookmarkListingEndPoint,
  DELETEBookmarkListingPoint,
  ListingEndPoint,
} from "../Axios/EndPoints";
import { get } from "../Axios/get";
import { put } from "../Axios/put";
import HandleTokenExpired from "../Main/TokenExpired/TokenExpired";
import Toast from "../Main/Toast/Toast";

export const PropertyListing = (props) => {
  const [listing, setListing] = useState();
  const [listingCount, setListingCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [Pagination, setPagintion] = useState(null);
  const [CurrentPage, SetCurrentPage] = useState(1);

  const [Alert, setAlert] = React.useState(false);
  const [AlertData, setAlertData] = React.useState(null);

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  const [Amen, setAmen] = React.useState(props.Amenities);
  // console.log('current page =', Pagination.last_page)

  useEffect(() => {
    let mounted = true;
    setLoading(true);

    let API_Request = ListingEndPoint + "?page=" + CurrentPage;

    // console.log('props.location =', props.Location)
    if (props.SortBy != null) {
      API_Request = API_Request + "&" + "sort_by=" + props.SortBy;
    }

    if (props.Location != null) {
      API_Request =
        API_Request +
        "&" +
        "latitude=" +
        props.Lat +
        "&" +
        "longitude=" +
        props.Lng;
    }

    if (props.PropertyTypeId.length > 0) {
      API_Request =
        API_Request +
        "&" +
        "property_type_id=" +
        props.PropertyTypeId.join(",");
    }

    if (props.Amenities.length > 0) {
      API_Request =
        API_Request + "&" + "amenities=" + props.Amenities.join(",");
    }
    if (props.HouseRules.length > 0) {
      API_Request =
        API_Request + "&" + "house_rules=" + props.HouseRules.join(",");
    }

    if (props.minValue >= 0 || props.maxValue <= 100000) {
      API_Request =
        API_Request +
        "&" +
        "price_from=" +
        props.minValue +
        "&" +
        "price_to=" +
        props.maxValue;
    }

    if (props.minDistance > 2 || props.Location != null) {
      if (props.Location != null) {
        API_Request = API_Request + "&" + "distance_radius=" + "10";
      } else {
        API_Request =
          API_Request + "&" + "distance_radius=" + props.minDistance;
      }
    }

    if (props.Guests > 0) {
      API_Request = API_Request + "&" + "guests=" + props.Guests;
    }

    // adds params to URL
    let new_url = window.location.origin + API_Request;
    window.history.pushState('', '', new_url);

    if (localStorage.getItem("token")) {
      get(
        // ListingEndPoint + "?page=" + CurrentPage + "&" +"amenities=" + '1,2',
        API_Request,
        "LOCAL",
        "",
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            if (CurrentPage > res.data.response.pagination.last_page) {
              SetCurrentPage(1);
              setPagintion(res.data.response.pagination);
            } else if (res.data.status == 401) {
              HandleTokenExpired();
            } else {
              setPagintion(res.data.response.pagination);
            }
            setListing(res.data.response.listings);
            if (res.data.response.listings.length != 0) {
              // console.log('in lat setting',res.data.response.listings[0].latitude)
              props.setLat(res.data.response.listings[0].latitude);
              props.setLng(res.data.response.listings[0].longitude);
            }
            setListingCount(res.data.response.listings.length);
            setLoading(false);
            window.scrollTo({ behavior: "smooth", top: "0px" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      get(API_Request, "LOCAL", "", false, "")
        .then((res) => {
          if (res.data.status === 200) {
            if (CurrentPage > res.data.response.pagination.last_page) {
              SetCurrentPage(1);
              setPagintion(res.data.response.pagination);
            } else {
              setPagintion(res.data.response.pagination);
            }
            setListing(res.data.response.listings);
            if (res.data.response.listings.length != 0) {
              props.setLat(res.data.response.listings[0].latitude);
              props.setLng(res.data.response.listings[0].longitude);
            }
            setListingCount(res.data.response.listings.length);
            setLoading(false);
            window.scrollTo({ behavior: "smooth", top: "0px" });

          }
        })
        .catch((error) => {
          console.log(error);
        });
    }


    return () => (mounted = false);
  }, [CurrentPage, props.ApplyFilter]);

  if (loading) {
    return <div style={{
      "z-index": 1,
      "margin-left": "25%"
    }}  className="loading"></div>;
  }

  // console.log("count =", listingCount);
  return listingCount > 0 ? (
    <section className="listings-container margin-top-30">
      {/* Sorting / Layout Switcher */}

      <div className="row fs-switcher">
        <div className="col-md-10">
          {/* Showing Results */}
          <p className="showing-results">{listingCount} Results Found</p>
        </div>
        <div className="col-md-2">
          <p className="showing-results"> Page {CurrentPage}</p>
        </div>
      </div>
      {Alert == true ? (
        <Toast status={AlertData.status} message={AlertData.message} />
      ) : (
        ""
      )}
      {/* Listings */}
      <div className="row fs-listings">
        {/* Listing Item */}
        {Object.values(listing).map((value,index) => (
          <div key={index} className="col-lg-12 col-md-12">
            <div
              className="listing-item-container list-layout listing-type-rental"
              data-marker-id={1}
            >
              {/* {console.log("uid = ", value["uid"])} */}
              {/* <button onClick={changelocation}>change location</button> */}
              <a
                href={"/listing-detail/" + value["uid"]}
                className="listing-item"
              >
                {/* Image */}
                <div className="listing-item-image">
                  <img src={value["property_image"]} alt="" />
                  <span className="tag">{value["property_type"]}</span>
                </div>
                {/* Content */}
                <div
                  id={"listing" + value["uid"]}
                  className="listing-item-content"
                >
                  <div
                    className={
                      value["is_featured"] ? "listing-badge now-open" : "hidden"
                    }
                  >
                    Featured
                  </div>
                  <div className="listing-item-inner">
                    <h3>
                      {value["name"]}{" "}
                      <i
                        className={value["is_verified"] ? "verified-icon" : ""}
                      />
                    </h3>
                    <span>
                      {value["city"] === ""
                        ? "-"
                        : value["city"] + "," + value["country"]}
                    </span>
                    <div className="listing-list-small-badges-container">
                      <div className="listing-small-badge pricing-badge">
                        <i className="fa fa-group"></i>
                        {value["maximum_number_of_guests_per_reservation"]}{" "}
                        Guests (Maximum) &emsp;
                        <div className="listing-small-badge pricing-badge">
                          <i className="fa fa-hotel"></i>
                          {value["number_of_beds"]} Bed(s)
                        </div>
                      </div>
                    </div>

                    <div className="tag">
                      {value["amenities"] === undefined ||
                      value["amenities"] === ""
                        ? ""
                        : value["amenities"].slice(0, 4).join(",")}
                    </div>
                    <div className="listing-list-small-badges-container">
                      <div className="listing-small-badge pricing-badge">
                        <i className="fa fa-tag"></i>
                        {parseFloat(" " + value["rate"])} PKR/Night
                      </div>
                    </div>
                    <div className="listing-list-small-badges-container">
                      <div
                        className="star-rating"
                        data-rating={value["rating"]}
                      >
                        <div className="rating-counter">
                          ({value["review_count"]} reviews)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a
                className="AddBookmark"
                onClick={() => {
                  localStorage.getItem("token") && value["is_bookmarked"] === 0
                    ? put(
                        ADDBookmarkListingEndPoint + value["uid"],
                        "LOCAL",
                        true,
                        localStorage.getItem("token")
                      )
                        .then((res) => {
                          setAlert(true);
                          if (res.data.status === 200) {
                            setAlertData(res.data);
                            // alert(res.data.message);
                          } else if (res.data.status == 401) {
                            HandleTokenExpired();
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                    : localStorage.getItem("token") &&
                      value["is_bookmarked"] === 1
                    ? put(
                        DELETEBookmarkListingPoint + value["uid"],
                        "LOCAL",
                        true,
                        localStorage.getItem("token")
                      )
                        .then((res) => {
                          setAlert(true);
                          if (res.data.status === 200) {
                            setAlertData(res.data);
                            // alert(res.data.message);
                          } else if (res.data.status == 401) {
                            HandleTokenExpired();
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                    : (window.location.href = "/login");
                }}
              >
                <span
                  className={
                    value["is_bookmarked"] === 1
                      ? "save like-icon tooltip left liked"
                      : "save like-icon tooltip left"
                  }
                />
              </a>
            </div>
          </div>
        ))}
        {/* Listing Item / End */}
      </div>
      {/* Listings Container / End */}
      {/* Pagination Container */}
      <div className="row fs-listings">
        <div className="col-md-12">
          {/* Pagination */}
          <div className="clearfix" />
          <div className="row">
            <div className="col-md-12">
              {/* Pagination */}
              <div className="pagination-container margin-top-15 margin-bottom-40">
                <nav className="pagination">
                  <ul>
                    <li className={CurrentPage > 1 ? "" : "hidden"}>
                      <a onClick={() => Handle_PreviousPageClick()}>
                        <i className="sl sl-icon-arrow-left" />
                      </a>
                    </li>
                    <li>
                      <a
                        // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                        className={"current-page"}
                      >
                        {CurrentPage}
                      </a>
                    </li>
                    <li
                      className={
                        Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                      }
                    >
                      <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                        {CurrentPage + 1}
                      </a>
                    </li>
                    <li
                      className={
                        Pagination.last_page >= CurrentPage + 2 ? "" : "hidden"
                      }
                    >
                      <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                        {CurrentPage + 2}
                      </a>
                    </li>
                    <li
                      className={
                        Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                      }
                    >
                      <a onClick={Handle_NextPageClick}>
                        <i className="sl sl-icon-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          {/* Pagination / End */}
          {/* Copyrights */}
          <div className="copyrights margin-top-0">
            © 2021 Mazebaan. All Rights Reserved.
          </div>
        </div>
      </div>
      {/* Pagination Container / End */}
    </section>
  ) : (
    <section className="listings-container margin-top-30">
      {/* Sorting / Layout Switcher */}
      <div className="row fs-switcher">
        <div className="col-md-10">
          {/* Showing Results */}
          <p className="showing-results">{listingCount} Results Found</p>
        </div>
        <div className="col-md-2">
          <p className="showing-results"> Page {CurrentPage}</p>
        </div>
      </div>
      {/* Listings */}
      {/* Copyrights */}
      <div className="copyrights margin-top-0">
        © 2021 Mazebaan. All Rights Reserved.
      </div>
      {/* Pagination Container / End */}
    </section>
  );
};

export const UserLoginEndPoint = "/user/login";
export const UserRegisterEndPoint = "/user/register";
export const UserProfileEndPoint = "/user/profile";
export const NewListingEndPoint = "/dashboard/new-listing";
export const NewestListingsEndPoint = "/listings?type=newest"
export const ListingEndPoint = "/listings";
export const ListingDetailEndPoint = "/listing/";
export const CreateNewListingEndPoint = "/dashboard/create-new-listing";
export const EditListingEndPoint = "/dashboard/edit-listing/";
export const UpdateListingEndPoint = "/dashboard/update-listing/";
export const UserLogoutEndPoint = "/user/logout";
export const BookListing = "/book/listing";
export const BookingCheckDatesEndPoint = "/booking/check-dates/";
export const DashboardMyBookingEndPoint = "/dashboard/my-bookings";
export const DashboardMyListingEndPoint = "/dashboard/my-listings";
export const DashboardMyListingDeleteEndPoint = "/dashboard/delete/listing/";
export const DashboardDeleteBookingEndPoint = "/dashboard/delete-booking/";
export const DashboardCancelBookingEndPoint = "/dashboard/cancel-booking/";
export const DashboardMyBookmarkEndPoint = "/bookmark/all";
export const DashboardBookingRequestEndPoint = "/dashboard/booking-requests";
export const DashboardMyProfileEndPoint = "/user/profile";
export const ADDBookmarkListingEndPoint = "/bookmark/add/";
export const DELETEBookmarkListingPoint = "/bookmark/remove/";
export const REMOVEBookmarkListingEndPoint = "/bookmark/remove/";
export const ADDReviewEndPoint = "/review/add/";
export const DashboardMyReviewEndPoint = "/dashboard/reviews";
export const DashboardApproveRequestEndPoint = "/dashboard/approve-booking-request/";
export const UpdateProfileEndPoint = "/user/update-profile";
export const UpdatePasswordEndPoint = "/user/change-password";
export const ClaimListingEndPoint = "/claim-request/";
export const BlogEndPoint = "/blog";
export const BlogSearchEndPoint = "/blog/search";
export const BlogDetailEndPoint = "/blog/";
export const AddCommentOnBlogEndPoint = "/blog/comment/";
export const AllChatEndPoint = "/chat/all";
export const CreateNewChatEndPoint = "/chat/new";
export const ChatDetailEndPoint = "/chat?message_id=";
export const ChatSendMessageEndPoint = "/chat/send-message";
export const ChatDeleteEndPoint = "/chat/delete/";
export const GetJazzCashMobileWalletEndPoint = "/payment/get-jazz-cash-mobile-wallet-data";
export const GetJazzCashCreditCardEndPoint = "/payment/get-jazz-cash-credit-card-data";
export const GetJazzCashVoucherEndPoint = "/payment/get-jazz-cash-voucher-data";
export const ForgotPasswordEndPoint = "/user/forgot-password";
export const ResetPasswordEndPoint = "/user/reset-password";
export const ContactUsEndpoint = "/contact-us";
export const EasyPaisaGetPaymentURLEndPoint = "/payment/easypaisa-get-payment-url";
export const EasyPaisaPayMobileAccount = "/payment/easypaisa-pay-mobile-account";
export const SettingsEndpoint = "/settings";
export const DashboardEndpoint = "/dashboard";

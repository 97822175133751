import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
const TermsandConditions = () => {
  return (
    <>
      <NavBar />
      <div>
        <div id="titlebar" className="solid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Terms &amp; Conditions</h2>
                <nav
                  id="breadcrumbs"
                  // xmlns:v="http://rdf.data-vocabulary.org/#"
                >
                  <ul>
                    {/* Breadcrumb NavXT 6.6.0 */}
                    <li className="home">
                      <span property="itemListElement" typeof="ListItem">
                        <a
                          property="item"
                          typeof="WebPage"
                          title="Go to Mazebaan."
                          href="/"
                          className="home"
                        >
                          <span property="name">Mazebaan</span>
                        </a>
                        <meta property="position" content={1} />
                      </span>
                    </li>
                    <li className="post post-page current-item">
                      <span property="itemListElement" typeof="ListItem">
                        <span
                          property="name"
                          className="post post-page current-item"
                        >
                          Terms &amp; Conditions
                        </span>
                        <meta
                          property="url"
                          content="/terms-conditions"
                        />
                        <meta property="position" content={2} />
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container full-width">
          <div className="row">
            <article
              id="post-408"
              className="col-md-12 page-container-col post-408 page type-page status-publish hentry"
            >
              <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>Thank you for using Mazebaan.</p>
                          <p>
                            These terms form an agreement between the user and
                            Mazebaan relating to one’s access and use of
                            Mazebaan.com.
                          </p>
                          <p>
                            These Terms and Conditions are intended to the
                            parties to the agreement only with no partnership or
                            any joint venture arising out of the agreement
                            between the parties.
                          </p>
                          <p>
                            If any provision of the Terms and Condition is held
                            to be invalid or unenforceable that provision will
                            alone be struck with no effect on the enforceability
                            of the remaining provisions.
                          </p>
                          <p>
                            When these terms and conditions mention words
                            like&nbsp; “Mazebaan”, “we” or “our” it refers to
                            the Mazebaan company the users are making an
                            agreement with.
                          </p>
                          <p>
                            The hosts&nbsp; are solely&nbsp; responsible for
                            identifying and complying with all the laws and
                            rules that apply to their listings.
                          </p>
                          <p>
                            “ In order to comply with the law and prevent any
                            fraud or legal mishap, the guests must at all times
                            carry their CNICs(Computerized National Identity
                            Cards) and other legal documents(if required) to
                            prove and verify their identities to the hosts at
                            Check-in and it is the sole responsibility of the
                            hosts to ask for and check the CNICs and other legal
                            documents(if required) of the guests to verify the
                            identities at Check-in.”
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              About and Modifications
                            </span>
                          </h1>
                          <p>
                            Mazebaan is an online public-steered marketplace
                            that allows registered users&nbsp; to publish their
                            host services on tMazebaan.com in the form of
                            listings and to communicate one on one with users
                            that are looking to book such host services. Host
                            services include the offering of&nbsp; accomodations
                            in the forms of vacation or other types of
                            properties.
                          </p>
                          <p>
                            Mazebaan does not own, create, control or manage any
                            of the listings and neither is Mazebaan a real
                            estate broker or related to it. Hosts alone are
                            responsible for their listings and host services.
                            When users make a booking they&nbsp; enter into a
                            contract with each other. Mazebaan is not a party
                            to&nbsp; any contract based relationship between its
                            users.
                          </p>
                          <p>
                            Mazebaan does not become a guarantor to the
                            existence, quality or safety of any listings,&nbsp;
                            the accuracy of the descriptions, the ratings and
                            reviews or the ethical conduct including the
                            trustworthiness of the other user by not endorsing
                            any individual. The users are advised to be careful
                            while booking an accommodation, accepting a booking
                            or communicating with other members.
                          </p>
                          <p>
                            The relationship of the members with Mazebaan is
                            solely limited as being an independent and not an
                            employee partner of Mazebaan, working only for one’s
                            own benefit. By posting the listings on
                            Mazebaan.com, the members agree that they have
                            complete discretion whether to list host services or
                            not.
                          </p>
                          <p>
                            Being an online platform, Mazebaan does not
                            guarantee a completely uninterrupted accessibility
                            of the website and may also&nbsp; restrict the
                            access to the website to carry out certain
                            maintenance measures to ensure&nbsp; improved
                            functioning of the website as Mazebaan will improve
                            and modify the website from time to time.
                          </p>
                          <p>
                            Mazebaan reserves all&nbsp; the right to modify
                            these Terms and Conditions.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Account Registration
                            </span>
                          </h1>
                          <p>
                            The users need to register an account in order to
                            access certain features of Mazebaan.com which
                            include the publishing and booking aof listings.
                          </p>
                          <p>
                            Users need to provide accurate and complete
                            information during the registration process and keep
                            their information up-to-date in their profiles.
                          </p>
                          <p>
                            The users should not register more than one account
                            on Mazebaan.com unless authorized to do so by
                            Mazebaan.
                          </p>
                          <p>
                            The users should refrain from assigning their
                            account to another party since the users are
                            responsible for maintaining the confidentiality and
                            security of their account credentials.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Information and Content
                            </span>
                          </h1>
                          <p>
                            Mazebaan can allow its members to create, upload,
                            post, send, receive and store content like text,
                            photos and other materials and information on and
                            via the website.
                          </p>
                          <p>
                            Mazebaan can access this information and any content
                            that Mazebaan itself makes available including
                            proprietary content of Mazebaan.
                          </p>
                          <p>
                            The users agree that Mazebaan.com, including all the
                            associated intellectual property rights, is solely
                            the property of Mazebaan. The users will not alter
                            or remove any copyright, trademark&nbsp; or any
                            other proprietary rights notices incorporated in the
                            website.
                          </p>
                          <p>
                            All the trademarks, logos and the other source
                            identifiers of Mazebaan used on Mazebaan.com are the
                            registered trademarks of Mazebaan.
                          </p>
                          <p>
                            By posting, sending, receiving, storing, or any
                            other means making available any content of the
                            member on the website, the user grants Mazebaan a
                            license to the information&nbsp; to access, store,
                            modify, distribute, transmit, broadcast, and
                            otherwise exploit in any manner the content to&nbsp;
                            promote Mazebaan.com on any media or platform.
                          </p>
                          <p>
                            Without the specific consent of the user, Mazebaan
                            does not claim any ownership rights over the content
                            of members and nothing in the Terms and Conditions
                            will be deemed to restrict any rights that the user
                            may have to exploit the content of members.
                          </p>
                          <p>
                            The users are solely responsible for the content of
                            members that one makes available on Mazebaan.com.
                            The users likewise represent themselves as the sole
                            owner of the provided information and having all the
                            rights.
                          </p>
                          <p>
                            The users are not allowed to post or transmit any
                            content of members that is fraudulent, misleading,
                            defamatory, pornographic, vulgar,offensive,
                            threatening, promotes violence, illegal or harmful
                            activities or violates Mazebaan’s policies.
                          </p>
                          <p>
                            Mazebaan can deny the access to any member violating
                            the terms, even without prior notice.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Service Fees
                            </span>
                          </h1>
                          <p>
                            Mazebaan charges fees to the hosts and guests in
                            return for their use of the Mazebaan.com.
                          </p>
                          <p>
                            The applicable service fees, including any
                            applicable Taxes, are displayed to the guests before
                            booking a listing. Mazebaan reserves with itself all
                            the right to change the service fees at any period
                            of time,however, such changes in the fee will not be
                            applicable on any bookings made prior to the
                            effective date of the service fee change.
                          </p>
                          <p>
                            The users must pay the service fees&nbsp; owed to
                            Mazebaan. The applicable service fees including all
                            the applicable taxes are collected by Mazebaan which
                            deducts the host fees from the listing price before
                            remitting the payout to the host by Mazebaan.
                          </p>
                          <p>
                            The guest fees are included in the total amount
                            collected by Mazebaan.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Terms Specific for Hosts
                            </span>
                          </h1>
                          <p>
                            When creating a listing on Mazebaan.com the host
                            should provide complete and authentic information
                            and mark clearly the restrictions and requirements
                            applicable on guests and&nbsp; provide any other
                            relevant information required.
                          </p>
                          <p>
                            The placement of the listings in search results on
                            Mazebaan.com may depend on factors like the search
                            location and preferences price, availability,
                            quality of Images, ratings and the type of host
                            required by the guest.
                          </p>
                          <p>
                            The&nbsp; graphical content used in the listings
                            must aptly portray the condition of the host
                            services provided. Mazebaan reserves the right to
                            require the listings having a minimum number of
                            Images and a certain format and resolution.
                          </p>
                          <p>
                            When the host approves a booking request by a guest
                            one enters into an agreement with the guest and is
                            required to provide the services mentioned in the
                            listing when the booking request is made.
                          </p>
                          <p>
                            The hosts must set a price for their listings which
                            cannot be altered once a guest requests a booking of
                            the host’s listing.
                          </p>
                          <p>
                            The user must also keep the listing up-to-date at
                            all times, including the calendar and pricing.
                          </p>
                          <p>
                            “The hosts must ask for and check the CNIC and other
                            legal documents(if required) of the guests to verify
                            the identities at Check-in.”
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Terms specific for Guests
                            </span>
                          </h1>
                          <p>
                            By completing the requirements of Mazebaan and the
                            host, the guest can book an accommodation listed on
                            Mazebaan.com by following the booking process. The
                            total fees including the service charges will be
                            displayed before the confirmation.
                          </p>
                          <p>
                            An agreement is formed between the guest and the
                            host after the confirmation. Mazebaan will collect
                            the total fees at the time of the booking
                            confirmation.
                          </p>
                          <p>
                            The confirmed booking is a limited license granted
                            to the guest by the host to use the accommodation
                            for the duration of the stay as per the booking,
                            during which time the host has the right to enter
                            the accommodation as per the agreement.
                          </p>
                          <p>
                            The guest cannot&nbsp; bring any additional
                            individuals unless one was added as an additional
                            guest during the booking process on Mazebaan.com.
                          </p>
                          <p>
                            The guest has to leave the accommodation no later
                            than the checkout time that is specified by the
                            host.
                          </p>
                          <p>
                            “The guests must carry their CNICs and other legal
                            documents(if required) to prove and verify their
                            identities to the hosts at Check-in.”
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Prohibited Activities
                            </span>
                          </h1>
                          <p>
                            The users are solely responsible for the compliance
                            with&nbsp; all the rules and regulations that apply
                            to the use of Mazebaan.com.
                          </p>
                          <p>
                            The users of Mazebaan.com are not allowed to use the
                            website for any other commercial purposes, access
                            the personal information of other members, offer
                            accommodations owned by others without the consent,
                            make or accept the payments in person outside the
                            platform provided by Mazebaan, discriminate amongst
                            the members on any ethical or other related basis
                            and exchange information (like phone numbers) other
                            than the relevant information about the booking
                            before the confirmation of the booking.
                          </p>
                          <p>
                            Mazebaan has all the rights to deny the access of
                            any users violating these terms and conditions.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Ratings and Reviews
                            </span>
                          </h1>
                          <p>
                            After the completion of the stay, both the guests
                            and hosts can submit a public review along with a
                            star rating for each other.
                          </p>
                          <p>
                            The ratings and reviews must be authentic and clear
                            of offensive language and remarks.
                          </p>
                          <p>
                            The ratings and reviews reflect the personal
                            opinions of the members and not that of Mazebaan.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Damage to the accommodations
                            </span>
                          </h1>
                          <p>
                            The guests must leave the accommodation in the
                            condition as of that at check-in.
                          </p>
                          <p>
                            The guests are responsible for their acts and
                            omissions and the host has the right to claim the
                            damage with mutual agreement defined at check-in.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>Liabilities</span>
                          </h1>
                          <p>
                            The users agree of the possibility&nbsp; that some
                            or any host experience may involve certain risks
                            like illness or other body injuries in any of the
                            hosting services and by participating in the host
                            services the users agree to assume those possible
                            risks voluntarily.
                          </p>
                          <p>
                            In case of any infant brought along, the users are
                            solely responsible for the supervision throughout
                            the stay.
                          </p>
                          <p>
                            The users are solely responsible for the choices
                            made before, during and after the participation in
                            any experience and the users agree to hold Mazebaan
                            harmless from any liabilities.
                          </p>
                          <p>
                            The users agree that any risks arising out of any
                            experience with or via Mazebaan solely lies with the
                            users with no involvement of or liability on
                            Mazebaan.
                          </p>
                          <p>
                            Mazebaan will by no means be liable for any kind of
                            social, emotional, mental, financial or any other
                            kind of damage arising out of or related to any of
                            the Terms and Conditions or with the experiences
                            related to or via Mazebaan.
                          </p>
                          <p>
                            The users agree to hold Mazebaan harmless from any
                            claims, liabilities, damages, losses, and expenses,
                            including legal and accounting fees arising out of
                            the violations of the Terms and Conditions and
                            policies or with the improper use of Mazebaan&nbsp;
                            including the interaction with any host or guest and
                            the arising damages and losses.
                          </p>
                          <p>
                            “ In order to comply with the law and prevent any
                            fraud or legal mishap, the guests must at all times
                            carry their CNICs and other legal documents(if
                            required) to prove and verify their identities to
                            the hosts at Check-in and it is the sole
                            responsibility of the hosts to ask for and check the
                            CNICs and other legal documents(if required) of the
                            guests to verify the identities at Check-in.”
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>Feedback</span>
                          </h1>
                          <p>
                            Mazebaan welcomes the feedback from its users for
                            improvements on Mazebaan.com. The feedback of the
                            users will be non-confidential and non-proprietary
                            and Mazebaan will have the right to the materials
                            without any compensation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default TermsandConditions;

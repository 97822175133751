import Footer from "../../Components/Main/Footer/Footer";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import blog_post_01 from "../../images/blog-post-01.jpg";
import blog_post_02 from "../../images/blog-post-02.jpg";
import blog_post_03 from "../../images/blog-post-03.jpg";

import { get } from "../../Components/Axios/get";
import {BlogEndPoint, BlogSearchEndPoint} from "../../Components/Axios/EndPoints";
import { useEffect, useState } from "react";
import {post} from "../../Components/Axios/post";

const Blog = () => {
  const [Blog, setBlog] = useState(null);
  const [BlogCount, setBlogCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Pagination, setPagintion] = useState(null);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [searchBlog, setSearchBlog] = useState("");

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  const handleSearchBlog = () =>
  {
    if(searchBlog !== "")
    {
      setLoading(true);
      post(
        BlogSearchEndPoint + "?q=" + searchBlog,
        "LOCAL",
        "",
        false
        // localStorage.getItem("token")
    )
        .then((res) => {
          console.log("response from search =", res.data);
          if (res.data.status === 200) {
            setPagintion(res.data.response.pagination);
            setBlog(res.data.response.posts);
            setBlogCount(res.data.response.posts.length);
            setSearchBlog("");
            setLoading(false);
            window.scrollTo({ behavior: "smooth", top: "0px" });
          } else if (res.data.status === 204) {
            setBlogCount(res.data.response.bookings.length);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setLoading(false);

  }

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    get(
      BlogEndPoint + "?page=" + CurrentPage,
      "LOCAL",
      "",
      false
      // localStorage.getItem("token")
    )
      .then((res) => {
        console.log("response =", res.data);
        if (res.data.status === 200) {
          setPagintion(res.data.response.pagination);
          setBlog(res.data.response.posts);
          setBlogCount(res.data.response.posts.length);
          setLoading(false);
          window.scrollTo({ behavior: "smooth", top: "0px" });
        } else if (res.data.status === 204) {
          setBlogCount(res.data.response.bookings.length);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, [CurrentPage]);

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <div>
      <NavBar />
      <div>
        {/* Titlebar
================================================== */}
        <div id="titlebar" className="gradient">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Blog</h2>
                <span>Latest News</span>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Blog</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Content
================================================== */}
        <div className="container">
          {/* Blog Posts */}
          <div className="blog-page">
            <div className="row">
              <div className="col-lg-9 col-md-8 padding-right-30">

                {BlogCount === 0 &&
                    <div className="post-content col-md-9">
                  <h3>
                    No Blogs Available
                  </h3>
                </div>
                }


                {Object.values(Blog).map((value,index) => (
                  <div
                      key={index}
                    className="blog-post post-5970 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized"
                    id="post-5970"
                  >
                    }
                    <a
                      className="post-img col-md-3"
                      href={"/blog-detail/" + value.slug}
                    >
                      <img src={value.image} />{" "}
                    </a>
                    {/* Content */}
                    <div className="post-content col-md-9">
                      <h3>
                        <a href={"/blog-detail/" + value.slug}>{value.title}</a>
                      </h3>
                      <ul className="post-meta" />
                      <p>
                        {value.description}
                        {/* Gone are those days where you had to spend hours
                        searching for hotels on your vacation trip. With the
                        digital */}
                      </p>{" "}
                      <a
                        href={"/blog-detail/" + value.uid}
                        className="read-more"
                      >
                        Read More <i className="fa fa-angle-right" />
                      </a>
                    </div>
                  </div>
                ))}
                {/*{Blog}*/}


                {/* Blog Post */}
                {/* <div className="blog-post">
                  <a href="pages-blog-post.html" className="post-img">
                    <img src={blog_post_01} alt="" />
                  </a>
                  <div className="post-content">
                    <h3>
                      <a href="pages-blog-post.html">Hotels for All Budgets </a>
                    </h3>
                    <ul className="post-meta">
                      <li>August 22, 2019</li>
                      <li>
                        <a href="#">Tips</a>
                      </li>
                      <li>
                        <a href="#">5 Comments</a>
                      </li>
                    </ul>
                    <p>
                      Nam nisl lacus, dignissim ac tristique ut, scelerisque eu
                      massa. Vestibulum ligula nunc, rutrum in malesuada vitae,
                      tempus sed augue. Curabitur quis lectus quis augue dapibus
                      facilisis.
                    </p>
                    <a href="pages-blog-post.html" className="read-more">
                      Read More <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div> */}
                {/* Blog Post / End */}
                {/* Blog Post */}
                {/* <div className="blog-post">
                  <a href="pages-pages-blog-post.html" className="post-img">
                    <img src={blog_post_03} alt="" />
                  </a>
                  <div className="post-content">
                    <h3>
                      <a href="pages-pages-blog-post.html">
                        The Best Cofee Shops In Sydney Neighborhoods
                      </a>
                    </h3>
                    <ul className="post-meta">
                      <li>August 10, 2019</li>
                      <li>
                        <a href="#">Tips</a>
                      </li>
                      <li>
                        <a href="#">12 Comments</a>
                      </li>
                    </ul>
                    <p>
                      Nam nisl lacus, dignissim ac tristique ut, scelerisque eu
                      massa. Vestibulum ligula nunc, rutrum in malesuada vitae,
                      tempus sed augue. Curabitur quis lectus quis augue dapibus
                      facilisis.
                    </p>
                    <a href="pages-blog-post.html" className="read-more">
                      Read More <i className="fa fa-angle-right" />
                    </a>
                  </div>
                </div> */}
                {/* Blog Post / End */}
                {/* Pagination */}
                {BlogCount.length > 0 && <div className="pagination-container margin-top-15 margin-bottom-40">
                  <nav className="pagination">
                    <ul>
                      <li className={CurrentPage > 1 ? "" : "hidden"}>
                        <a onClick={() => Handle_PreviousPageClick()}>
                          <i className="sl sl-icon-arrow-left"/>
                        </a>
                      </li>
                      <li>
                        <a
                            // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                            className={"current-page"}
                        >
                          {CurrentPage}
                        </a>
                      </li>
                      <li
                          className={
                            Pagination.last_page >= CurrentPage + 1
                                ? ""
                                : "hidden"
                          }
                      >
                        <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                          {CurrentPage + 1}
                        </a>
                      </li>
                      <li
                          className={
                            Pagination.last_page >= CurrentPage + 2
                                ? ""
                                : "hidden"
                          }
                      >
                        <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                          {CurrentPage + 2}
                        </a>
                      </li>
                      <li
                          className={
                            Pagination.last_page >= CurrentPage + 1
                                ? ""
                                : "hidden"
                          }
                      >
                        <a onClick={Handle_NextPageClick}>
                          <i className="sl sl-icon-arrow-right"/>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>}
                {/* Pagination / End */}
              </div>
              {/* Blog Posts / End */}
              {/* Widgets */}
              <div className="col-lg-3 col-md-4">
                <div className="sidebar right">
                  {/* Widget */}
                  <div className="widget">
                    <h3 className="margin-top-0 margin-bottom-25">
                      Search Blog
                    </h3>
                    <div className="search-blog-input">
                      <a onClick={handleSearchBlog}>
                      <div className="input">
                        <input
                          className="search-field"
                          type="text"
                          placeholder="Type and hit enter"
                          onChange={(e)=>{
                            setSearchBlog(e.target.value);
                          }}
                        />
                      </div>
                      </a>
                    </div>
                  </div>
                  {/* Widget / End */}
                  {/* Widget */}
                  <div className="widget margin-top-40">
                    <h3>Got any questions?</h3>
                    <div className="info-box margin-bottom-10">
                      <p>
                        If you are having any questions, please feel free to
                        ask.
                      </p>
                      <a
                        href="/contact-us"
                        className="button fullwidth margin-top-20"
                      >
                        <i className="fa fa-envelope-o" /> Drop Us a Line
                      </a>
                    </div>
                  </div>
                  {/* Widget / End */}
                  {/* Widget */}
                  {/* <div className="widget margin-top-40">
                    <h3>Popular Posts</h3>
                    <ul className="widget-tabs">
                      <li>
                        <div className="widget-content">
                          <div className="widget-thumb">
                            <a href="pages-blog-post.html">
                              <img src="images/blog-widget-03.jpg" alt="" />
                            </a>
                          </div>
                          <div className="widget-text">
                            <h5>
                              <a href="pages-blog-post.html">
                                Hotels for All Budgets{" "}
                              </a>
                            </h5>
                            <span>October 26, 2016</span>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </li>
                      <li>
                        <div className="widget-content">
                          <div className="widget-thumb">
                            <a href="pages-blog-post.html">
                              <img src="images/blog-widget-02.jpg" alt="" />
                            </a>
                          </div>
                          <div className="widget-text">
                            <h5>
                              <a href="pages-blog-post.html">
                                The 50 Greatest Street Arts In London
                              </a>
                            </h5>
                            <span>November 9, 2016</span>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </li>
                      <li>
                        <div className="widget-content">
                          <div className="widget-thumb">
                            <a href="pages-blog-post.html">
                              <img src="images/blog-widget-01.jpg" alt="" />
                            </a>
                          </div>
                          <div className="widget-text">
                            <h5>
                              <a href="pages-blog-post.html">
                                The Best Cofee Shops In Sydney Neighborhoods
                              </a>
                            </h5>
                            <span>November 12, 2016</span>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </li>
                    </ul>
                  </div> */}
                  {/* Widget / End*/}
                  {/* Widget */}
                  {/* <div className="widget margin-top-40">
                    <h3 className="margin-bottom-25">Social</h3>
                    <ul className="social-icons rounded">
                      <li>
                        <a className="facebook" href="#">
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#">
                          <i className="icon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a className="gplus" href="#">
                          <i className="icon-gplus" />
                        </a>
                      </li>
                      <li>
                        <a className="linkedin" href="#">
                          <i className="icon-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {/* Widget / End*/}
                  <div className="clearfix" />
                  <div className="margin-bottom-40" />
                </div>
              </div>
            </div>
            {/* Sidebar / End */}
          </div>
        </div>
      </div>
      <div id="backtotop" className="visible">
        <a href="#" />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;

import GoogleLogin from "react-google-login";
import { clientId } from "../GoogleKeys/GoogleKeys";

const GOOGLELOGIN = (props) => {

    let st = '';
  const responseGoogle = (response) => {
    console.log(response.profileObj);
    console.log(response);
    props.setGoogle(response.profileObj);
  };

  return (
  <>
  <GoogleLogin
    clientId={clientId}
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
  </>
  )
};

export default GOOGLELOGIN;

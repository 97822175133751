import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import "./styles/MyListings.css";
import { get } from "../../Components/Axios/get";
import {
  DashboardMyListingEndPoint,
  DashboardMyListingDeleteEndPoint,
} from "../../Components/Axios/EndPoints";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import Toast from "../../Components/Main/Toast/Toast";
import redirectToLogin from "../../utility/redirectToLogin";

const MyListing = (props) => {
  let param = useParams();
  let location = useLocation();
  let navigate = useNavigate();

  const [MyListing, setMyListing] = useState();
  const [MyListingCount, setMyListingCount] = useState(false);
  const [loading, setLoading] = useState(true);

  const [Pagination, setPagintion] = useState(null);
  const [CurrentPage, SetCurrentPage] = useState(1);

  const [Alert, setAlert] = useState(false);
  const [AlertData, setAlertData] = useState(null);

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  useEffect(() => {
    redirectToLogin(props);
    setLoading(true);
    let mounted = true;
    get(
      DashboardMyListingEndPoint + "?page=" + CurrentPage,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          setPagintion(res.data.response.pagination);
          setMyListing(res.data.response.listings);
          setMyListingCount(true);
          setLoading(false);
          window.scrollTo({ behavior: "smooth", top: "0px" });
        } else if (res.data.status === 204) {
          setMyListingCount(false);
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, [CurrentPage]);

  const HandleDeleteListing = (uid) => {
    console.log("res after del", uid);

    get(
      DashboardMyListingDeleteEndPoint + uid,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          setAlertData(res.data);
          // alert(res.data.message);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }
        setAlert(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleEditListing = (Data) => {
    console.log("Data to send =", Data);
    window.scrollTo(0, 0);
    navigate("/dashboard-add-listing", {
      state: {
        ListingDetail: Data,
      },
    });
  };

  if (loading) {
    return <div className="loading"></div>;
  }

  return MyListingCount ? (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {Alert == true ? (
          <Toast status={AlertData.status} message={AlertData.message} />
        ) : (
          ""
        )}
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-my-listings"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>My Listings</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-my-listings">My Listing</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                <h4>Active Listings</h4>
                <ul>
                  {Object.values(MyListing).map((value) => (
                    <li>
                      <div className="list-box-listing">
                        <div className="list-box-listing-img">
                          <a href="#">
                            <img src={value["image"]} alt="" />
                          </a>
                        </div>
                        <div className="list-box-listing-content">
                          <div className="inner">
                            <h3>
                              <a href={"/listing-detail/" + value["uid"]}>
                                {value["name"]}
                              </a>
                            </h3>
                            <span>{value["location"]}</span>
                            <div>
                              <span className="expiration-date">
                                Expiring: Never/not set
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="buttons-to-right">
                        <a
                          onClick={() => HandleEditListing(value)}
                          className="button mazebaan"
                        >
                          <i className="sl sl-icon-note" /> Edit
                        </a>
                        <a
                          onClick={() => HandleDeleteListing(value["uid"])}
                          className="button mazebaan"
                        >
                          <i className="sl sl-icon-close" /> Delete
                        </a>
                      </div>
                    </li>
                  ))}
                  {/* <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img src="images/listing-item-02.jpg" alt="" />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Sticky Band</h3>
                          <span>Bishop Avenue, New York</span>
                          <div className="star-rating" data-rating={5.0}>
                            <div className="rating-counter">(23 reviews)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-note" /> Edit
                      </a>
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Delete
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img src="images/listing-item-03.jpg" alt="" />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Hotel Govendor</h3>
                          <span>778 Country Street, New York</span>
                          <div className="star-rating" data-rating={2.0}>
                            <div className="rating-counter">(17 reviews)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-note" /> Edit
                      </a>
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Delete
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img src="images/listing-item-04.jpg" alt="" />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Burger House</h3>
                          <span>2726 Shinn Street, New York</span>
                          <div className="star-rating" data-rating={5.0}>
                            <div className="rating-counter">(31 reviews)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-note" /> Edit
                      </a>
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Delete
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img src="images/listing-item-05.jpg" alt="" />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Airport</h3>
                          <span>1512 Duncan Avenue, New York</span>
                          <div className="star-rating" data-rating="3.5">
                            <div className="rating-counter">(46 reviews)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-note" /> Edit
                      </a>
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Delete
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img src="images/listing-item-06.jpg" alt="" />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Think Coffee</h3>
                          <span>215 Terry Lane, New York</span>
                          <div className="star-rating" data-rating={5.0}>
                            <div className="rating-counter">(31 reviews)</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-note" /> Edit
                      </a>
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Delete
                      </a>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* Copyrights */}
          </div>
          {/* Pagination */}
          <div className="pagination-container margin-top-15 margin-bottom-40">
            <nav className="pagination">
              <ul>
                <li className={CurrentPage > 1 ? "" : "hidden"}>
                  <a onClick={() => Handle_PreviousPageClick()}>
                    <i className="sl sl-icon-arrow-left" />
                  </a>
                </li>
                <li>
                  <a
                    // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                    className={"current-page"}
                  >
                    {CurrentPage}
                  </a>
                </li>
                <li
                  className={
                    Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                  }
                >
                  <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                    {CurrentPage + 1}
                  </a>
                </li>
                <li
                  className={
                    Pagination.last_page >= CurrentPage + 2 ? "" : "hidden"
                  }
                >
                  <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                    {CurrentPage + 2}
                  </a>
                </li>
                <li
                  className={
                    Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                  }
                >
                  <a onClick={Handle_NextPageClick}>
                    <i className="sl sl-icon-arrow-right" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-my-listings"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>My Listings</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-my-listings">My Listing</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                <h4>Active Listings</h4>
                <ul>
                  <li>
                    <p>No Listing Found!</p>
                  </li>
                </ul>
              </div>
            </div>
            {/* Copyrights */}
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  );
};
export default MyListing;

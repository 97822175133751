import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import { useState, useEffect } from "react";
import {
  DashboardMyBookingEndPoint,
  DashboardDeleteBookingEndPoint,
  DashboardCancelBookingEndPoint,
  ChatSendMessageEndPoint,
  GetJazzCashMobileWalletEndPoint,
  GetJazzCashCreditCardEndPoint,
  GetJazzCashVoucherEndPoint,
} from "../../Components/Axios/EndPoints";
import { get } from "../../Components/Axios/get";
import { put } from "../../Components/Axios/put";
// import { post } from "../../Components/Axios/post";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import SendMessageModal from "../../Components/Main/SendMessage/SendMessage";
import { post } from "../../Components/Axios/post";
// import Modal from "react-modal";
import ReturnPolicyInfographic from "../../images/refund-policy.png";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import MODAL from "../../Components/Dashboard/Booking/Modal";
// Modal.setAppElement('#cancel-btn-mybooking')
// Modal.setAppElement(document.getElementById("cancel-btn-mybooking"));
import Pay from "../../Components/Dashboard/BookingRequest/PaymentModal";
import Toast from "../../Components/Main/Toast/Toast";
import redirectToLogin from "../../utility/redirectToLogin";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // background-image:
  },
  overlay: {
    zIndex: 1000,
    // opacity: 0.45,
  },
};
let runonce = false;
let StartDate = "",
  EndDate = "";


let tempMapData = [];
const Bookings = (props) => {
  const [id, setId] = useState('')
  const [amount, setAmount] = useState('')
  const [startDate, setStartDate] = useState(new Date());
  const [MyBooking, setMyBooking] = useState();
  const [MyBookingCount, setMyBookingCount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [Pagination, setPagintion] = useState(null);
  const [SendMessage, setSendMessage] = useState(false);
  const [Message, setMessage] = useState("");
  const [ModalIndex, setModalIndex] = useState(0);
  const [MessageToSendInfo, setMessageToSendInfo] = useState(null);
  const [CurrentPage, SetCurrentPage] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [PaymentmodalIsOpen, setPaymentIsOpen] = useState(false);
  const [JazzMobileAccountBTNID, setJazzMobileAccountBTNID] = useState(null);
  const [JazzCardBTNID, setJazzCardBTNID] = useState(null);
  const [JazzVoucherBTNID, setJazzVoucherBTNID] = useState(null);
  const [JazzMobileAccountResponse, setJazzMobileAccountResponse] =
    useState(false);
  const [JazzCardResponse, setJazzCardResponse] = useState(false);
  const [JazzVoucherResponse, setJazzVoucherResponse] = useState(false);
  const [Dates, setDates] = useState("");
  const [UID, setUID] = useState(null);
  const [JazzMWalletLinkToPostData, setMWalletJazzLinkToPostData] =
    useState(null);
  const [JazzCardLinkToPostData, setJazzCardLinkToPostData] = useState(null);
  const [JazzVoucherData, setJazzVoucherData] = useState(null);
  const [Alert, setAlert] = useState(false);
  const [AlertData, setAlertData] = useState(null);

  let subtitle;

  const openModal = () => {
    setIsOpen(true);
  };

  const openPaymentModal = () => {
    setPaymentIsOpen(true);
  };

  const closePaymentModal = () => {
    // console.log("close modal");
    setPaymentIsOpen(false);
  };

  const afterOpenModal = () => {};

  const closeModal = () => {
    setIsOpen(false);
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  if (Dates != "") {
    console.log("Dates = ", Dates);
    if (Dates != null) {
      StartDate = convert(Dates[0]);
      EndDate = convert(Dates[1]);
    }
  }

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  const HandleSendMessage = () => {
    if (Message != "") {
      let data = {
        message: Message,
        message_id:
          "message_" + localStorage.getItem("id") + "_" + MessageToSendInfo,
      };

      post(
        ChatSendMessageEndPoint,
        "LOCAL",
        data,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          console.log('res=', res)
          if (res.data.status === 200) {
            // alert(res.data.message);
            setAlertData(res.data);
            setSendMessage(false);
          } else if (res.data.status == 401) {
            HandleTokenExpired();
          } else {
            alert(res.data.message);
            setSendMessage(false);
          }
          setAlert(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please Enter Your Message");
    }

    // alert("message send");
    // setModalIndex(index);
  };

  const HandleModalCall = (value, index) => {
    setSendMessage(!SendMessage);
    setModalIndex(index);
    setMessageToSendInfo(value);
  };

  const JazzAppTempFunc = (amt, bid) => {
    getJazzCashMWalletPaymentData(amt, bid);
  };

  const getJazzCashMWalletPaymentData = (amount, bookingID) => {
    setLoading(true);
    let data = {
      amount: parseInt(amount),
      booking_id: bookingID,
    };

    post(
      GetJazzCashMobileWalletEndPoint,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("response from jazz get api = ", res.data.response);
          // setJazzLinkToPost(res.data.response.post_url);
          setMWalletJazzLinkToPostData(res.data.response);
          setJazzMobileAccountResponse(true);
        } else if (res.data.status === 204) {
          //   setBookingRequestCount(res.data.response.bookings.length);
          //   setLoading(false);
        } else if (res.data.status === 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const JazzVoucherTempFunc = (amt, bid) => {
    getJazzCashVoucher(amt, bid);
  };

  // if(runonce)
  // {
  // }

  const getJazzCashVoucher = (amount, bookingID) => {
    setLoading(true);
    let data = {
      amount: parseInt(amount),
      booking_id: bookingID,
    };
    // alert(JSON.stringify(data));

    post(
      GetJazzCashVoucherEndPoint,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("response from jazz get api = ", res.data.response);
          // setJazzLinkToPost(res.data.response.post_url);
          setJazzVoucherData(res.data.response);
          setJazzVoucherResponse(true);
        } else if (res.data.status === 204) {
          //   setBookingRequestCount(res.data.response.bookings.length);
          //   setLoading(false);
        } else if (res.data.status === 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const JazzCCTempFunc = (amt, bid) => {
    getJazzCashCCPaymentData(amt, bid);
  };

  const getJazzCashCCPaymentData = (amount, bookingID) => {
    setLoading(true);

    let data = {
      amount: parseInt(amount),
      booking_id: bookingID,
    };

    post(
      GetJazzCashCreditCardEndPoint,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("response from jazz get api = ", res.data.response);
          // setJazzLinkToPost(res.data.response.post_url);
          setJazzCardLinkToPostData(res.data.response);
          setJazzCardResponse(true);
        } else if (res.data.status === 204) {
          //   setBookingRequestCount(res.data.response.bookings.length);
          //   setLoading(false);
        } else if (res.data.status === 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  // console.log('messfweffwef = ', MessageToSendInfo)

  useEffect(() => {
    // getJazzCashMWalletPaymentData();
    // getJazzCashCCPaymentData();
    redirectToLogin(props);
    setLoading(true);
    let mounted = true;
    let endpoint = "?page=" + CurrentPage;

    if (Dates != "" && Dates != null) {
      endpoint = endpoint + "&date_from=" + StartDate + "&date_to=" + EndDate;
    }

    get(
      DashboardMyBookingEndPoint + endpoint,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          setPagintion(res.data.response.pagination);
          setMyBooking(res.data.response.bookings);
          setMyBookingCount(res.data.response.bookings.length);
          window.scrollTo({ behavior: "smooth", top: "0px" });
        } else if (res.data.status === 204) {
          setMyBookingCount(res.data.response.bookings.length);
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }

        if(res.data.status ==500)
        {
          setMyBooking(null)
        }

      })
      .catch((error) => {
        console.log(error);

      });
    setLoading(false);

    return () => (mounted = false);
  }, [CurrentPage, Dates]);

  if (loading) {
    return <div className="loading"></div>;
  }

  // console.log("my ====", JazzCardLinkToPostData);

  // console.log("Jazz card btn id =", JazzCardBTNID);

  // (JazzCardLinkToPostData && JazzMobileAccountBTNID != null
  //   ? ""
  //   : setJazzMobileAccountBTNID("Submit-JazzCash-Mobile-Account")
  // )

  if (JazzCardResponse) {
    // console.log('in setting mob account')
    setJazzCardBTNID("Submit-JazzCash-Credit-Debit");
    setJazzCardResponse(false);
  }

  if (JazzVoucherResponse) {
    setJazzVoucherBTNID("Submit-JazzCash-Voucher");
    setJazzVoucherResponse(false);
  }

  if (JazzMobileAccountResponse) {
    // console.log('in setting mob account')
    setJazzMobileAccountBTNID("Submit-JazzCash-Mobile-Account");
    setJazzMobileAccountResponse(false);
  }

  if (JazzVoucherBTNID == "Submit-JazzCash-Voucher") {
    document.getElementById("Submit-JazzCash-Voucher").click();
  }
  if (JazzMobileAccountBTNID == "Submit-JazzCash-Mobile-Account") {
    document.getElementById("Submit-JazzCash-Mobile-Account").click();
  }
  if (JazzCardBTNID == "Submit-JazzCash-Credit-Debit") {
    document.getElementById("Submit-JazzCash-Credit-Debit").click();
  }

  return MyBookingCount > 0 ? (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-bookings"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}{" "}
        {Alert == true ? (
          <Toast status={AlertData.status} message={AlertData.message} />
        ) : (
          ""
        )}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>My Bookings</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-bookings">My Bookings</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                {/* Booking Requests Filters  */}
                <div className="booking-requests-filter">
                  {/* Sort by */}
                  {/* <div className="sort-by">
                    <div className="sort-by-select">
                      <select
                        data-placeholder="Default order"
                        className="chosen-select-no-single"
                      >
                        <option>All Statuses</option>
                        <option>Unpaid</option>
                        <option>Paid</option>
                      </select>
                    </div>
                  </div>*/}
                  <div className="sort-by">
                    <div className="sort-by-select height-dashboard-mybooking">
                      {/* <select
                        data-placeholder="Default order"
                        className="chosen-select-no-single"
                      >
                        <option>All Listings</option>
                        <option>ABC Hotel</option>
                        <option>ABC Hotel</option>
                        <option>ABC Hotel</option>
                      </select> */}
                      <DateRangePickerComponent
                        value={Dates}
                        onChange={(e) => setDates(e.target.value)}
                        min={startDate}
                        placeholder="Pick a Date"
                      />{" "}
                    </div>
                  </div>
                  {/* Date Range */}
                  {/* <div id="booking-date-range">
                    <span>Pick a Date</span>
                  </div> */}
                </div>
                {/* Reply to review popup */}
                {SendMessage == true ? (
                  <>
                    <SendMessageModal
                      placeholder={"Your message"}
                      index={ModalIndex}
                      value={Message}
                      setvalue={setMessage}
                      sendmessage={HandleSendMessage}
                      closemodal={setSendMessage}
                    />
                  </>
                ) : (
                  ""
                )}
                <Pay
                    JazzCCTempFunc={JazzCCTempFunc}
                    JazzAppTempFunc={JazzAppTempFunc}
                    JazzVoucherTempFunc={JazzVoucherTempFunc}
                    bookingID={id}
                    amount={amount}
                    modalIsOpen={PaymentmodalIsOpen}
                    setLoading={setLoading}
                    Loading={loading}
                    CallJazzMobileAccount={
                      getJazzCashMWalletPaymentData
                    }
                    CallJazzCard={getJazzCashCCPaymentData}
                    CallJazzVoucher={getJazzCashVoucher}
                    JazzCashMobileAccountButtonID={
                      JazzMobileAccountBTNID
                    }
                    JazzCashCreditDebitButtonID={JazzCardBTNID}
                    JazzCashVoucherButtonID={JazzVoucherBTNID}
                    // afterOpenModal={afterOpenModal}
                    closeModal={closePaymentModal}
                    customStyles={customStyles}
                    // uid={value["uid"]}
                />
                <h4>My Bookings</h4>
                {/* {console.log("my booking =", MyBooking)} */}
                <ul>
                  {MyBooking && Object.values(MyBooking).map((value, index) => (
                    <li
                      className={
                        value["status_id"] === 1
                          ? "pending-booking"
                          : value["status_id"] === 2
                          ? "approved-booking"
                          : value["status_id"] === 3
                          ? "approved-booking"
                          : value["status_id"] === 4
                          ? "closed-booking"
                          : value["status_id"] === 5
                          ? "cancelled-booking"
                          : value["status_id"] === 6
                          ? "pending-booking"
                          : ""
                      }
                    >
                      <div className="list-box-listing bookings">
                        <div className="list-box-listing-img">
                          <img src={value["property_image"]} alt="" />
                        </div>
                        <div className="list-box-listing-content">
                          <div className="inner">
                            <h3>
                              <a
                                href={
                                  "/listing-detail/" + value["property_uid"]
                                }
                              >
                                {value["property_name"]}
                              </a>
                              <span
                                className={
                                  value["status_id"] === 1
                                    ? "booking-status"
                                    : value["status_id"] === 2
                                    ? "booking-status"
                                    : value["status_id"] === 3
                                    ? "booking-status"
                                    : value["status_id"] === 4
                                    ? "booking-status"
                                    : value["status_id"] === 5
                                    ? "booking-status"
                                    : value["status_id"] === 6
                                    ? "booking-status"
                                    : ""
                                }
                              >
                                {value["status"]}
                                {/* {console.log('status =', value['status'])} */}
                              </span>
                              {value["status_id"] === 2 ? (
                                <span className="booking-status unpaid">
                                  Unpaid
                                </span>
                              ) : (
                                <></>
                              )}
                              {value["status_id"] === 3 ? (
                                <span className="booking-status paid">
                                  Paid
                                </span>
                              ) : (
                                <></>
                              )}
                            </h3>
                            <div className="inner-booking-list">
                              <h5>Booking Date:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["booking_from"]} -{" "}
                                  {value["booking_to"]}
                                </li>
                              </ul>
                            </div>
                            <div className="inner-booking-list">
                              <h5>Booking Details:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["number_of_guests"]}
                                </li>
                              </ul>
                            </div>
                            <div className="inner-booking-list">
                              <h5>Booking Location:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["location"]}{" "}
                                </li>
                              </ul>
                            </div>
                            <div className="inner-booking-list">
                              <h5>Price:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["property_rate_type"]}{" "}
                                  {value["grand_total"]}
                                </li>
                              </ul>
                            </div>
                            <div className="inner-booking-list">
                              <h5>Client:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["property_vendor_name"]}
                                </li>
                                {/* <li>john@example.com</li>
                            <li>123-456-789</li> */}
                              </ul>
                            </div>
                            <div className="inner-booking-list">
                              <h5>Message:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["status_id"]}
                                </li>
                              </ul>
                            </div>
                            <div className="inner-booking-list">
                              <h5>Booking requested on:</h5>
                              <ul className="booking-list">
                                <li className="highlighted">
                                  {value["booking_created_date"]}
                                </li>
                              </ul>
                            </div>
                            {localStorage.getItem("id") !=
                            value["property_vendor_id"] ? (
                              <a
                                // href="#small-dialog"
                                onClick={() =>
                                  HandleModalCall(
                                    value["property_vendor_id"],
                                    index
                                  )
                                }
                                className="rate-review popup-with-zoom-anim"
                              >
                                <i className="sl sl-icon-envelope-open" /> Send
                                Message
                              </a>
                            ) : (
                              ""
                            )}{" "}
                          </div>
                        </div>
                      </div>
                      <div
                        // id="cancel-btn-mybooking"
                        className="buttons-to-right"
                      >
                        <div className="">
                          {
                            <form
                              name="jsform"
                              method="POST"
                              action={
                                JazzVoucherData != null
                                  ? JazzVoucherData.post_url
                                  : ""
                              }
                            >
                              <div>
                                <input
                                  type="hidden"
                                  name="pp_Version"
                                  defaultValue="1.1"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_Version
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnType"
                                  defaultValue="MWALLET"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_TxnType
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Language"
                                  defaultValue="EN"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_Language
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_MerchantID"
                                  defaultValue="MC29866"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_MerchantID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_SubMerchantID"
                                  defaultValue=""
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="pp_Password"
                                  defaultValue="gvbx0c3stz"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_Password
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_BankID"
                                  defaultValue="TBANK"
                                  value={
                                    JazzVoucherData && JazzVoucherData.pp_BankID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_ProductID"
                                  defaultValue="RETL"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_ProductID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnRefNo"
                                  id="pp_TxnRefNo"
                                  defaultValue="T20220201161519"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_TxnRefNo
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Amount"
                                  defaultValue={1000}
                                  value={
                                    JazzVoucherData && JazzVoucherData.pp_Amount
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnCurrency"
                                  defaultValue="PKR"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_TxnCurrency
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnDateTime"
                                  defaultValue={20220201161519}
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_TxnDateTime
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_BillReference"
                                  defaultValue="billRef"
                                  value={
                                    JazzVoucherData && JazzVoucherData.billRef
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Description"
                                  defaultValue="Description of transaction"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_Description
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnExpiryDateTime"
                                  defaultValue={20220202161519}
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_TxnExpiryDateTime
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_ReturnURL"
                                  defaultValue="http://127.0.0.1:8000/api/paymentSuccessful"
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_ReturnURL
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_SecureHash"
                                  defaultValue
                                  value={
                                    JazzVoucherData &&
                                    JazzVoucherData.pp_SecureHash
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_1"
                                  defaultValue={1}
                                  value={
                                    JazzVoucherData && JazzVoucherData.ppmpf_1
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_2"
                                  defaultValue={2}
                                  value={
                                    JazzVoucherData && JazzVoucherData.ppmpf_2
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_3"
                                  defaultValue={3}
                                  value={
                                    JazzVoucherData && JazzVoucherData.ppmpf_3
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_4"
                                  defaultValue={4}
                                  value={
                                    JazzVoucherData && JazzVoucherData.ppmpf_4
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_5"
                                  defaultValue={5}
                                  value={
                                    JazzVoucherData && JazzVoucherData.ppmpf_5
                                  }
                                />
                              </div>
                              <button
                                id="Submit-JazzCash-Voucher"
                                type="submit"
                                name={"mob-btn"}
                                value="Pay with Jazz Cash Mobile Account"
                                className="hidden"
                              >
                                Pay with Jazz Cash Voucher
                              </button>
                            </form>
                          }
                        </div>
                        <div className="">
                          {
                            <form
                              name="jsform"
                              method="POST"
                              action={
                                JazzMWalletLinkToPostData != null
                                  ? JazzMWalletLinkToPostData.post_url
                                  : ""
                              }
                            >
                              <div>
                                <input
                                  type="hidden"
                                  name="pp_Version"
                                  defaultValue="1.1"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_Version
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnType"
                                  defaultValue="MWALLET"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_TxnType
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Language"
                                  defaultValue="EN"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_Language
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_MerchantID"
                                  defaultValue="MC29866"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_MerchantID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_SubMerchantID"
                                  defaultValue=""
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="pp_Password"
                                  defaultValue="gvbx0c3stz"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_Password
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_BankID"
                                  defaultValue="TBANK"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_BankID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_ProductID"
                                  defaultValue="RETL"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_ProductID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnRefNo"
                                  id="pp_TxnRefNo"
                                  defaultValue="T20220201161519"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_TxnRefNo
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Amount"
                                  defaultValue={1000}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_Amount
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnCurrency"
                                  defaultValue="PKR"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_TxnCurrency
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnDateTime"
                                  defaultValue={20220201161519}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_TxnDateTime
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_BillReference"
                                  defaultValue="billRef"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.billRef
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Description"
                                  defaultValue="Description of transaction"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_Description
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnExpiryDateTime"
                                  defaultValue={20220202161519}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_TxnExpiryDateTime
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_ReturnURL"
                                  defaultValue="http://127.0.0.1:8000/api/paymentSuccessful"
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_ReturnURL
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_SecureHash"
                                  defaultValue
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.pp_SecureHash
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_1"
                                  defaultValue={1}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.ppmpf_1
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_2"
                                  defaultValue={2}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.ppmpf_2
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_3"
                                  defaultValue={3}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.ppmpf_3
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_4"
                                  defaultValue={4}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.ppmpf_4
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_5"
                                  defaultValue={5}
                                  value={
                                    JazzMWalletLinkToPostData &&
                                    JazzMWalletLinkToPostData.ppmpf_5
                                  }
                                />
                              </div>
                              <button
                                id="Submit-JazzCash-Mobile-Account"
                                type="submit"
                                name={"mob-btn"}
                                value="Pay with Jazz Cash Mobile Account"
                                className="hidden"
                              >
                                Pay with Jazz Cash Mobile Account
                              </button>
                            </form>
                          }{" "}
                        </div>
                        <div className="">
                          {
                            <form
                              name="jsform"
                              method="POST"
                              action={
                                JazzCardLinkToPostData != null
                                  ? JazzCardLinkToPostData.post_url
                                  : ""
                              }
                            >
                              <div>
                                <input
                                  type="hidden"
                                  name="pp_Version"
                                  defaultValue="1.1"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_Version
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnType"
                                  defaultValue="MWALLET"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_TxnType
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Language"
                                  defaultValue="EN"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_Language
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_MerchantID"
                                  defaultValue="MC29866"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_MerchantID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_SubMerchantID"
                                  defaultValue=""
                                  value=""
                                />
                                <input
                                  type="hidden"
                                  name="pp_Password"
                                  defaultValue="gvbx0c3stz"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_Password
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_BankID"
                                  defaultValue="TBANK"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_BankID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_ProductID"
                                  defaultValue="RETL"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_ProductID
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnRefNo"
                                  id="pp_TxnRefNo"
                                  defaultValue="T20220201161519"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_TxnRefNo
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Amount"
                                  defaultValue={1000}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_Amount
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnCurrency"
                                  defaultValue="PKR"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_TxnCurrency
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnDateTime"
                                  defaultValue={20220201161519}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_TxnDateTime
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_BillReference"
                                  defaultValue="billRef"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.billRef
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_Description"
                                  defaultValue="Description of transaction"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_Description
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_TxnExpiryDateTime"
                                  defaultValue={20220202161519}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_TxnExpiryDateTime
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_ReturnURL"
                                  defaultValue="http://127.0.0.1:8000/api/paymentSuccessful"
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_ReturnURL
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="pp_SecureHash"
                                  defaultValue
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.pp_SecureHash
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_1"
                                  defaultValue={1}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.ppmpf_1
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_2"
                                  defaultValue={2}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.ppmpf_2
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_3"
                                  defaultValue={3}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.ppmpf_3
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_4"
                                  defaultValue={4}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.ppmpf_4
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="ppmpf_5"
                                  defaultValue={5}
                                  value={
                                    JazzCardLinkToPostData &&
                                    JazzCardLinkToPostData.ppmpf_5
                                  }
                                />
                              </div>
                              <button
                                id="Submit-JazzCash-Credit-Debit"
                                type="submit"
                                value="Pay with Jazz Cash Mobile Account"
                                className="hidden"
                              >
                                Pay with Jazz Cash Credit / Debit
                              </button>
                            </form>
                          }{" "}
                        </div>
                        {value["status_id"] === 1 ||
                        value["status_id"] === 2 ? (
                          value["status_id"] === 2 ? (
                            <>
                              {value["status_id"] == 2 ? (
                                <>
                                  {console.log('in map value =', value["grand_total"],value["booking_id"])}

                                </>
                              ) : (
                                ""
                              )}
                              <a
                                id="pay-btn-bookingrequest"
                                onClick={() => {
                                  openPaymentModal()
                                  setId(value.booking_id)
                                  setAmount(value.grand_total)
                                }}
                                className="button mazebaan"
                              >
                                <i className="sl sl-icon-check" /> Pay
                              </a>
                              {/* {console.log("before payment modal")} */}
                              {/* {(
                                <Pay
                                  amount={value["property_rate"]}
                                  modalIsOpen={PaymentmodalIsOpen}
                                  CallJazzMobileAccount={
                                    getJazzCashMWalletPaymentData
                                  }
                                  CallJazzCard={getJazzCashCCPaymentData}
                                  JazzCashMobileAccountButtonID={
                                    document.getElementById(
                                      "Submit-JazzCash-Mobile-Account"
                                    ) == null
                                      ? null
                                      : "Submit-JazzCash-Mobile-Account"
                                  }
                                  JazzCashCreditDebitButtonID={
                                    document.getElementById(
                                      "Submit-JazzCash-Credit-Debit"
                                    ) == null
                                      ? null
                                      : "Submit-JazzCash-Credit-Debit"
                                  }
                                  // afterOpenModal={afterOpenModal}
                                  closeModal={closePaymentModal}
                                  customStyles={customStyles}
                                  // uid={value["uid"]}
                                />
                              )}{" "} */}
                              {/* <a className="button mazebaan cancel"> Pay </a> */}
                              <a
                                id="cancel-btn-mybooking"
                                // onClick={() => {
                                //   put(
                                //     DashboardCancelBookingEndPoint +
                                //       value["uid"],
                                //     "LOCAL",
                                //     true,
                                //     localStorage.getItem("token")
                                //   )
                                //     .then((res) => {
                                //       if (res.data.status === 200) {
                                //         alert(res.data.message);
                                //       }
                                //     })
                                //     .catch((error) => {
                                //       console.log(error);
                                //     });
                                // }}
                                className="button mazebaan cancel"
                                onClick={openModal}
                              >
                                <i className="sl sl-icon-close" /> Cancel
                                {/* <Modal
                                  isOpen={modalIsOpen}
                                  // ariaHideApp={true}
                                  onAfterOpen={afterOpenModal}
                                  onRequestClose={closeModal}
                                  onreq
                                  style={customStyles}
                                  contentLabel="Example Modal"
                                >
                                  <a className="img-box" onClick={closeModal}>
                                    <img className="img-box-background" src={ReturnPolicyInfographic}></img>
                                  </a>
                                </Modal> */}
                              </a>
                              <MODAL
                                modalIsOpen={modalIsOpen}
                                afterOpenModal={afterOpenModal}
                                closeModal={closeModal}
                                customStyles={customStyles}
                                uid={value["uid"]}
                              />
                            </>
                          ) : (
                            <>
                              <a
                                onClick={() => {
                                  put(
                                    DashboardCancelBookingEndPoint +
                                      value["uid"],
                                    "LOCAL",
                                    true,
                                    localStorage.getItem("token")
                                  )
                                    .then((res) => {
                                      if (res.data.status === 200) {
                                        // alert(res.data.message);
                                        setAlertData(res.data);
                                      } else if (res.data.status == 401) {
                                        HandleTokenExpired();
                                      }
                                      setAlert(true);
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }}
                                className="button mazebaan cancel"
                              >
                                <i className="sl sl-icon-close" /> Cancel
                              </a>
                            </>
                          )
                        ) : (
                          ""
                        )}
                        {value["status_id"] === 3 ||
                        value["status_id"] === 4 ? (
                          <a
                            onClick={() => {
                              put(
                                DashboardDeleteBookingEndPoint + value["uid"],
                                "LOCAL",
                                true,
                                localStorage.getItem("token")
                              )
                                .then((res) => {
                                  if (res.data.status === 200) {
                                    // alert(res.data.message);
                                    setAlertData(res.data);
                                  } else if (res.data.status == 401) {
                                    HandleTokenExpired();
                                  }
                                  setAlert(true);
                                })
                                .catch((error) => {
                                  console.log(error);
                                });
                            }}
                            className="button mazebaan cancel"
                          >
                            <i className="sl sl-icon-trash" /> Delete
                          </a>
                        ) : (
                          ""
                        )}
                        {/* <a href="#" className="button gray reject">
                          <i className="sl sl-icon-close" /> Reject
                        </a> */}
                      </div>
                    </li>
                  ))}

                  {/* <li className="approved-booking">
                    <div className="list-box-listing bookings">
                      <div className="list-box-listing-img">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=120"
                          alt=""
                        />
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>
                            <a href="listingdetail">ABC Hotel </a>
                            <span className="booking-status">Approved</span>
                          </h3>
                          <div className="inner-booking-list">
                            <h5>Booking Date:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">
                                10.12.2019 at 12:30 pm - 13:30 pm
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Booking Details:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">1 Guest</li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Booking Location:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">
                                I-8, Islamabad, Islamabad Capital Territory,
                                44000, Pakistan
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Price:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">PKR/day 25.00</li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Client:</h5>
                            <ul className="booking-list">
                              <li> Nabeel Abbasi</li>
                              <li>123-456-789</li> 
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Message:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">213fasf</li>
                            </ul>
                          </div>
                          <a
                            href="#small-dialog"
                            className="rate-review popup-with-zoom-anim"
                          >
                            <i className="sl sl-icon-envelope-open" /> Send
                            Message
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray reject">
                        <i className="sl sl-icon-close" /> Cancel
                      </a>
                    </div>
                  </li>
                  <li className="canceled-booking">
                    <div className="list-box-listing bookings">
                      <div className="list-box-listing-img">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=120"
                          alt=""
                        />
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>
                            <a href="listingdetail">ABC Hotel </a>
                            <span className="booking-status">Canceled</span>
                          </h3>
                          <div className="inner-booking-list">
                            <h5>Booking Date:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">
                                21.10.2019 at 9:30 am - 10:30 am
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Booking Details:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">2 Adults</li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Booking Location:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">
                                I-8, Islamabad, Islamabad Capital Territory,
                                44000, Pakistan
                              </li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Price:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">PKR/day 25.00</li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Client:</h5>
                            <ul className="booking-list">
                              <li> Nabeel Abbasi</li>
                              {/* <li>john@example.com</li>
                            </ul>
                          </div>
                          <div className="inner-booking-list">
                            <h5>Message:</h5>
                            <ul className="booking-list">
                              <li className="highlighted">213fasf</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* Content / End */}
          {/* Pagination */}
          <div className="pagination-container margin-top-15 margin-bottom-40">
            {MyBooking &&<nav className="pagination">
              <ul>
                <li className={CurrentPage > 1 ? "" : "hidden"}>
                  <a onClick={() => Handle_PreviousPageClick()}>
                    <i className="sl sl-icon-arrow-left"/>
                  </a>
                </li>
                <li>
                  <a
                      // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                      className={"current-page"}
                  >
                    {CurrentPage}
                  </a>
                </li>
                <li
                    className={
                      Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                    }
                >
                  <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                    {CurrentPage + 1}
                  </a>
                </li>
                <li
                    className={
                      Pagination.last_page >= CurrentPage + 2 ? "" : "hidden"
                    }
                >
                  <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                    {CurrentPage + 2}
                  </a>
                </li>
                <li
                    className={
                      Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                    }
                >
                  <a onClick={Handle_NextPageClick}>
                    <i className="sl sl-icon-arrow-right"/>
                  </a>
                </li>
              </ul>
            </nav>}
          </div>
        </div>
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-bookings"} />
        {/* Navigation / End */}
        {/* Content
================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>My Bookings</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-bookings">My Bookings</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                {/* Booking Requests Filters  */}
                <div className="booking-requests-filter">
                  {/* Sort by
                  <div className="sort-by">
                    <div className="sort-by-select">
                      <select
                        data-placeholder="Default order"
                        className="chosen-select-no-single"
                      >
                        <option>All Statuses</option>
                        <option>Unpaid</option>
                        <option>Paid</option>
                      </select>
                    </div>
                  </div>
                  <div className="sort-by">
                    <div className="sort-by-select">
                      <select
                        data-placeholder="Default order"
                        className="chosen-select-no-single"
                      >
                        <option>All Listings</option>
                        <option>ABC Hotel</option>
                        <option>ABC Hotel</option>
                        <option>ABC Hotel</option>
                      </select>
                    </div>
                  </div> */}
                  {/* Date Range */}
                  <div id="booking-date-range">
                    <DateRangePickerComponent
                      min={startDate}
                      showPopupButton={true}
                      // startDate={startDate}
                      // onClick={()=>{jQuery("#ej2-datetimepicker_0").trigger('click')}}
                      // showDropdowns={}
                      // showDropdowns={true}
                      // onClick={()=>eventFire(document.getElementById('ej2-datetimepicker_0'),'click')}
                      placeholder="Pick a Date"
                      // applyButtonClasses="main-search-input-item"
                      // buttonClasses="main-search-input-item"
                    />{" "}
                  </div>
                </div>
                {/* Reply to review popup */}
                <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                  <div className="small-dialog-header">
                    <h3>Send Message</h3>
                  </div>
                  <div className="message-reply margin-top-0">
                    <textarea
                      cols={40}
                      rows={3}
                      placeholder="Your Message to Kathy"
                      defaultValue={""}
                    />
                    <button className="button">Send</button>
                  </div>
                </div>
                <h4>My Bookings</h4>
                <ul>
                  <li>
                    <p>No Booking Found!</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Content / End */}
        </div>
      </div>
      {/* Dashboard / End */}
    </>
  );
};

export default Bookings;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import {
  DashboardMyBookmarkEndPoint,
  DELETEBookmarkListingPoint,
} from "../../Components/Axios/EndPoints";
import { useState, useEffect } from "react";
import { get } from "../../Components/Axios/get";
import { put } from "../../Components/Axios/put";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import Toast from "../../Components/Main/Toast/Toast";
import redirectToLogin from "../../utility/redirectToLogin";

const Bookmarks = (props) => {
  const [MyBookmark, setMyBookmark] = useState();
  const [MyBookmarkCount, setMyBookmarkCount] = useState(true);
  const [loading, setLoading] = useState(true);

  const [Alert, setAlert] = useState(false);
  const [AlertData, setAlertData] = useState(null);

  const [Pagination, setPagintion] = useState(null);
  const [CurrentPage, SetCurrentPage] = useState(1);

  const Handle_NextPageClick = () => {
    if (Pagination.last_page >= CurrentPage + 1) {
      SetCurrentPage((CurrentPage) => CurrentPage + 1);
    } else {
      alert("Already On Last Page!");
    }
  };

  const Handle_PreviousPageClick = () => {
    if (CurrentPage != 1) {
      SetCurrentPage((CurrentPage) => CurrentPage - 1);
    } else {
      alert("Already on Page 1");
    }
  };

  const Handle_SpecificNumber_PageClick = (index) => {
    const PageNumber = CurrentPage + index;
    SetCurrentPage(PageNumber);
  };

  useEffect(() => {
    redirectToLogin(props);
    setLoading(true);
    let mounted = true;
    get(
      DashboardMyBookmarkEndPoint + "?page=" + CurrentPage,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          setPagintion(res.data.response.pagination);
          setMyBookmark(res.data.response.bookmarks);
          setMyBookmarkCount(res.data.response.bookmarks.length);
          setLoading(false);
          window.scrollTo({ behavior: "smooth", top: "0px" });
        } else if (res.data.status === 204) {
          setMyBookmarkCount(res.data.response.bookmarks.length);
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, []);

  if (loading) {
    return <div className="loading"></div>;
  }

  return MyBookmarkCount ? (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-bookmarks"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        {Alert == true ? (
          <Toast status={AlertData.status} message={AlertData.message} />
        ) : (
          ""
        )}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Bookmarks</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-bookmarks">Bookmarks</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                <h4>Bookmarked Listings</h4>
                <ul>
                  {Object.values(MyBookmark).map((value) => (
                    <li>
                      <div className="list-box-listing">
                        <div className="list-box-listing-img">
                          <a href={"/listing-detail/" + value["uid"]}>
                            <img src={value["image"]} alt="" />
                          </a>
                        </div>
                        <div className="list-box-listing-content">
                          <div className="inner">
                            <h3>{value["name"]}</h3>
                            <span>{value["address"]}</span>
                          </div>
                        </div>
                      </div>
                      <div className="buttons-to-right">
                        <a
                          onClick={() => {
                            put(
                              DELETEBookmarkListingPoint + value["uid"],
                              "LOCAL",
                              true,
                              localStorage.getItem("token")
                            )
                              .then((res) => {
                                if (res.data.status === 200) {
                                  // alert(res.data.message);
                                  setAlertData(res.data);
                                } else if (res.data.status == 401) {
                                  HandleTokenExpired();
                                }
                                setAlert(true);
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          }}
                          className="button mazebaan"
                        >
                          <i className="sl sl-icon-close" /> Remove
                        </a>
                      </div>
                    </li>
                  ))}
                  {/* <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img
                            src="https://mazebaan.com/wp-content/uploads/2021/02/F9C29F10-FED3-4660-AF03-F23D4E44089F-200x200.jpeg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Burger House</h3>
                          <span>2726 Shinn Street, New York</span>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Remove
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="list-box-listing">
                      <div className="list-box-listing-img">
                        <a href="#">
                          <img
                            src="https://mazebaan.com/wp-content/uploads/2021/02/F9C29F10-FED3-4660-AF03-F23D4E44089F-200x200.jpeg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="list-box-listing-content">
                        <div className="inner">
                          <h3>Think Coffee</h3>
                          <span>215 Terry Lane, New York</span>
                        </div>
                      </div>
                    </div>
                    <div className="buttons-to-right">
                      <a href="#" className="button gray">
                        <i className="sl sl-icon-close" /> Remove
                      </a>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* Copyrights */}
          </div>
          {/* Pagination */}
          <div className="pagination-container margin-top-15 margin-bottom-40">
            <nav className="pagination">
              <ul>
                <li className={CurrentPage > 1 ? "" : "hidden"}>
                  <a onClick={() => Handle_PreviousPageClick()}>
                    <i className="sl sl-icon-arrow-left" />
                  </a>
                </li>
                <li>
                  <a
                    // onClick={Handle_SpecificNumber_PageClick(CurrentPage)}
                    className={"current-page"}
                  >
                    {CurrentPage}
                  </a>
                </li>
                <li
                  className={
                    Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                  }
                >
                  <a onClick={() => Handle_SpecificNumber_PageClick(1)}>
                    {CurrentPage + 1}
                  </a>
                </li>
                <li
                  className={
                    Pagination.last_page >= CurrentPage + 2 ? "" : "hidden"
                  }
                >
                  <a onClick={() => Handle_SpecificNumber_PageClick(2)}>
                    {CurrentPage + 2}
                  </a>
                </li>
                <li
                  className={
                    Pagination.last_page >= CurrentPage + 1 ? "" : "hidden"
                  }
                >
                  <a onClick={Handle_NextPageClick}>
                    <i className="sl sl-icon-arrow-right" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-bookmarks"} />
        {/* Navigation / End */}
        {/* Content
================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Bookmarks</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-bookmarks">Bookmarks</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                <h4>Bookmarked Listings</h4>
                <ul>
                  <li>No Bookmarked Listing Found!</li>
                </ul>
              </div>
            </div>
            {/* Copyrights */}
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  );
};

export default Bookmarks;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import "../Homepage/Homepage.css";
import React, { useEffect, useState } from "react";
import ReviewListing from "../../Components/Review/ReviewListing/ReviewListing";
import { GoogleMap, InfoBox, useJsApiLoader } from "@react-google-maps/api";
import FsLightbox from "fslightbox-react";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
// import { get } from "../../Components/Axios/get";
import AddAReview from "../../Components/Review/AddAReview/AddAReview";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADDBookmarkListingEndPoint,
  BookingCheckDatesEndPoint,
  DELETEBookmarkListingPoint,
  ListingDetailEndPoint,
} from "../../Components/Axios/EndPoints";
import { put } from "../../Components/Axios/put";
import { get } from "../../Components/Axios/get";
import { post } from "../../Components/Axios/post";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Footer from "../../Components/Main/Footer/Footer";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import Toast from "../../Components/Main/Toast/Toast";

let IMAGES = [];
let IMAGES_FOR_LIGHTBOX = [];

const containerStyle = {
  position: "static",
  width: "100%",
  height: "400px",
};

let StartDate = "",
  EndDate = "";

const ListingDetail = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const   [AlertData,setAlertData] = useState(null);
  const   [Alert,setAlert] = useState(false);


  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [height, setheight] = useState(
    window.screen.height >= 1080
      ? window.screen.height - 650
      : window.screen.height - 350
  );
  const [width, setwidth] = useState(window.screen.width);
  const [map, setMap] = useState(null);
  const [toggler, setToggler] = useState(false);
  const [listingDetail, setListingDetail] = useState([]);
  const [ListingImages, setListingImages] = useState();
  // const [latitude, setLatitude] = useState(33.659669);
  // const [longitude, setLongitude] = useState(73.0543899);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [Dates, setDates] = useState("");
  const [Number_Of_Rooms, setNumber_Of_Rooms] = useState(1);
  const [Coupon, setCoupon] = useState(false);
  const [CouponLoader, setCouponLoader] = useState(false);
  const [DatesAvaliable, setDatesAvaliable] = useState(false);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const CheckDates = () => {
    let data = {
      booking_from: StartDate,
      booking_to: EndDate,
    };

    // console.log('endpoint =', BookingCheckDatesEndPoint+params.id)
    // console.log('data =', data)

    let endpoint = BookingCheckDatesEndPoint + params.id;
    let val = 0;
    post(
      BookingCheckDatesEndPoint + params.id,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.response.available_rooms > 0) {
          console.log("aval room is gr8");
          setDatesAvaliable(true);
          // val =1;
          console.log("aval room >");
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          console.log("false response");
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    if (val != 0) {
      return true;
    }
  };

  if (Dates != "") {
    StartDate = convert(Dates[0]);
    EndDate = convert(Dates[1]);
    CheckDates();
  }

  useEffect(() => {
    let mounted = true;
    let listingId = window.location.href.split("/").pop();
    get(
      ListingDetailEndPoint + listingId,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("res =", res.data.response.images.length);
          setListingDetail(res.data.response);
          if (res.data.response.images.length > 0) {
            Object.values(res.data.response.images).map((value, index) => {
              IMAGES.push({ url: value });
              IMAGES_FOR_LIGHTBOX.push(value);
            });
            setListingImages(IMAGES);
          }
          // setListingImages(res.data.response.images);
          setLatitude(parseFloat(res.data.response.latitude));
          setLongitude(parseFloat(res.data.response.longitude));
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          setLoading(true);
        }
      })
      .catch((error) => {
        setLoading(true);
        console.log(error);
      });
    return () => (mounted = false);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCK7mxtbLJ5srlppcBbw-bTcKpDRfE_wHg",
  });

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // listingDetail.features.listing_feature.map((value) => {
  //   console.log(value['name'])
  // })

  const Navigate_To_Booking_With_Data = () => {
    if (Dates === "") {
      alert("Please Select A Date First!");
    } else {
      // console.log("cehckdatees = ", CheckDates());
      // console.log("dates avalaiable=", DatesAvaliable);
      if (DatesAvaliable) {
        // console.log("in else");
        if (Number_Of_Rooms > listingDetail.available_rooms) {
          alert("Please Select Valid Number Of Rooms or dates");
        } else {
          window.scrollTo(0, 0);
          navigate("/listing-detail/booking/" + params.id, {
            state: {
              Data: listingDetail,
              Date: Dates,
              NumberOfRoomsSELECTED: Number_Of_Rooms,
            },
          });
        }
      }
    }
  };

  const Navigate_To_Claim_Listing_With_Data = () => {
    window.scrollTo(0,0);
    navigate("/listing-detail/claim-this-listing/" + params.id, {
      state: {
        URL: params.id,
      },
    });
  };

  function onRenderDayCell(args: RenderDayCellEventArgs): void {
    // console.log('ARGS =', convert(args.date))
    for (let i = 0; i < listingDetail.property_unavailable_dates.length; i++) {
      if (convert(args.date) === listingDetail.property_unavailable_dates[i]) {
        // sets isDisabled to true to disable the date.
        args.isDisabled = true;
      }
    }
  }

  if (loading) {
    return (
      <>
        <p className="loading"></p>
      </>
    );
  }

  const presets = [
    { label: "Today", start: new Date(), end: new Date() },
    {
      label: "This Month",
      start: new Date(new Date().setDate(1)),
      end: new Date(),
    },
  ];

  const style = {
    textAlign: "center",
    background: "black",
    "background-size": "contain",
    // padding: "200px 0",
    fontSize: "30px",
    "max-height": height,
  };

  const properties = {
    duration: 0.5,
    slidesToShow: IMAGES.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    autoplay: false,
    indicators: true,
  };

  // const [toggle,settoggle] = React.useState(false);

  return (
    <>
      <NavBar />
      {/* Slider ================================================== */}
      {Alert == true ? (
          <Toast status={AlertData.status} message={AlertData.message} />
      ) : (
          ""
      )}

      <div className={"container-fluid"}>
        <div className="row">
          <div className="listing-slider mfp-gallery-container margin-bottom-0">
            {/* <button onClick={() => setToggler(!toggler)}>Toggle Lightbox</button> */}
            {IMAGES_FOR_LIGHTBOX.length > 0 ? (
              <FsLightbox toggler={toggler} sources={IMAGES_FOR_LIGHTBOX} />
            ) : (
              <FsLightbox toggler={toggler} sources={null} />
            )}

            {IMAGES.length > 0 ? (
              <div className="center-screen">
                <div className="listing-slider mfp-gallery-container margin-bottom-0 slick-initialized slick-slider">
                  {/* <button
                    type="button"
                    data-role="none"
                    className="slick-prev slick-arrow"
                    aria-label="Previous"
                    role="button"
                    style={{}}
                    onClick={() => {console.log('Prv is clicked')}}
                  >
                    Previous
                  </button>
                  <div
                    aria-live="polite"
                    className="slick-list draggable"
                    style={{ padding: "0px 15%" }}
                  >
                    <div
                      className="slick-track"
                      role="listbox"
                      style={{
                        opacity: 1,
                        width: "4730px",
                        transform: "translate3d(-946px, 0px, 0px)",
                        transition: "transform 500ms ease 0s",
                      }}
                    >
                      {IMAGES.map((value, index) => (
                        <>
                          <a
                            href={value.url}
                            data-background-image={value.url}
                            className="item mfp-gallery slick-slide slick-cloned slick-center"
                            title={"Title " + index}
                            style={{
                              backgroundImage: 'url("' + value.url + '")',
                              width: "473px",
                            }}
                            data-slick-index={-index}
                            id
                            aria-hidden="true"
                            tabIndex={-1}
                          />
                          {console.log("size =", index)}
                        </>
                      ))}
                      {IMAGES.map((value, index) => (
                        <>
                          <a
                            href={value.url}
                            data-background-image={value.url}
                            className={
                              index == 1
                                ? "item mfp-gallery slick-slide slick-current slick-center"
                                : "item mfp-gallery slick-slide slick-center"
                            }
                            title={"Title " + index}
                            style={{
                              backgroundImage: 'url("' + value.url + '")',
                              width: "473px",
                            }}
                            data-slick-index={index}
                            aria-hidden="false"
                            tabIndex={-1}
                            role="option"
                            aria-describedby="slick-slide00"
                          />
                        </>
                      ))}
                    </div>
                  </div>
                  <button
                    type="button"
                    data-role="none"
                    className="slick-next slick-arrow"
                    aria-label="Next"
                    role="button"
                    style={{}}
                    onClick={()=>{console.log('nect is clicked')}}
                  >
                    Next
                  </button> */}

                  {/* <SimpleImageSlider
                  onClick={() => setToggler(!toggler)}
                  width={width}
                  height={height}
                  images={IMAGES}
                  // showBullets={true}
                  showNavs={true}
                  navStyle={2}
                  loop={true}
                  // autoPlay={true}
                /> */}
                  <Slide {...properties}>
                    {IMAGES.map((value) => (
                      <>
                        {console.log("value -", value)}
                        <div style={style}>
                          <img
                            onClick={() => setToggler(!toggler)}
                            src={value.url}
                          ></img>
                        </div>
                      </>
                    ))}
                  </Slide>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Content
================================================== */}
      <div className="container">
        <div className="row sticky-wrapper">
          <div className="col-lg-8 col-md-8 padding-right-30">
            {/* Titlebar */}
            <div id="titlebar" className="listing-titlebar">
              <div className="listing-titlebar-title">
                <h2>
                  {listingDetail.name}
                  <span className="listing-tag">
                    {listingDetail.property_type}
                  </span>
                </h2>
                <span>
                  <a href="#listing-location" className="listing-address">
                    <i className="fa fa-map-marker" />
                    {listingDetail.location}
                  </a>
                </span>
                <div
                  className="star-rating"
                  data-rating={listingDetail.ratings}
                >
                  <div className="rating-counter">
                    <a href="#listing-reviews">
                      {listingDetail.review_count + " reviews"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Listing Nav */}
            <div id="listing-nav" className="listing-nav-container">
              <ul className="listing-nav">
                <li>
                  <a href="#listing-overview" className="active">
                    Overview
                  </a>
                </li>
                <li>
                  <a href="#listing-location">Location</a>
                </li>
                <li>
                  <a href="#listing-reviews">Reviews</a>
                </li>
              </ul>
            </div>
            {/* Overview */}
            <div id="listing-overview" className="listing-section">
              {/* Description */}
              <b>({listingDetail.number_of_beds}) Bed(s)</b>&nbsp;&nbsp;
              <b>
                {listingDetail.maximum_number_of_guests_per_reservation}{" "}
                Guests(Maximum)
              </b>
              <a className="button book-now margin-top-5 float-right">
                {"Price: " + parseFloat("" + listingDetail.rate) + " PKR"}
                {}
              </a>
              <h3 className="listing-desc-headline">Description</h3>
              <p>{listingDetail.description}</p>
              <div className="clearfix" />
              {/* Amenities */}
              <h3 className="listing-desc-headline">Features</h3>
              <ul className="listing-features checkboxes margin-top-0">
                {listingDetail.amenities ? (
                  Object.values(listingDetail.amenities).map((value) => (
                    <li key={value}>{value}</li>
                  ))
                ) : (
                  <li>No features added</li>
                )}
              </ul>
            </div>
            {/* Location */}
            <div id="listing-location" className="listing-section">
              <h3 className="listing-desc-headline margin-top-60 margin-bottom-30">
                Location
              </h3>
              <div id="singleListingMap-container">
                {/* {console.log("lat =", latitude)} */}
                {latitude != null && longitude != null ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                    // onUnmount={onUnmount}
                  >
                    <InfoBox
                      position={{
                        lat: latitude,
                        lng: longitude,
                      }}
                    >
                      <div class="marker-container">
                        <div class="marker-card">
                          <div class="front face">
                            <i class="im im-icon-Home-2"></i>
                          </div>
                          <div class="back face">
                            <i class="im im-icon-Home-2"></i>
                          </div>
                          <div class="marker-arrow"></div>
                        </div>
                      </div>
                    </InfoBox>
                  </GoogleMap>
                ) : (
                  <div className="loading"></div>
                )}
                {/* <a href="#" id="streetView">
                  Street View
                </a> */}
              </div>
            </div>
            {/* Reviews */}
            <ReviewListing
              Review={listingDetail.reviews}
              Rating={listingDetail.rating}
              ReviewCount={listingDetail.review_count}
            />
            {/* Add Review Box */}
            {localStorage.getItem("token") ? (
              <AddAReview UID={listingDetail.uid} />
            ) : (
              <></>
            )}
            {/* Add Review Box / End */}
          </div>
          {/* Sidebar
		================================================== */}
          <div className="col-lg-4 col-md-4 margin-top-75 sticky">
            {/* Verified Badge */}
            <div
              className="verified-badge with-tip"
              data-tip-content="Click to claim this listing."
            >
              {listingDetail.is_verified ? (
                <>
                  <i className="sl sl-icon-check"></i> Verified Listing
                </>
              ) : (
                <>
                  <a onClick={Navigate_To_Claim_Listing_With_Data}>
                    <i className="sl sl-icon-question" /> Not verified. Claim
                    this listing!
                  </a>
                </>
              )}
            </div>
            {/* Book Now */}
            {localStorage.getItem("token") ? (
              <div
                id="booking-widget-anchor"
                className="boxed-widget booking-widget margin-top-35"
              >
                <h3>
                  <i className="fa fa-calendar-check-o " /> Booking
                </h3>
                <div className="row with-forms  margin-top-0">
                  {/* Date Range Picker - docs: http://www.daterangepicker.com/ */}
                  <div className="col-lg-12">
                    {/* <select>
                    <option id='date-picker'>date picker</option>
                    <option id='date-picker'>date picker</option>
                    <option id='date-picker'>date picker</option>
                  </select> */}
                    {/* {console.log('u dates = ', listingDetail.property_unavailable_dates)} */}
                    <DateRangePickerComponent
                      min={startDate}
                      renderDayCell={onRenderDayCell}
                      showPopupButton={true}
                      // presets={presets}
                      // startDate={startDate}
                      // onClick={()=>{jQuery("#ej2-datetimepicker_0").trigger('click')}}
                      // showDropdowns={}
                      // showDropdowns={true}
                      // onClick={()=>eventFire(document.getElementById('ej2-datetimepicker_0'),'click')}
                      placeholder="Pick a Date"
                      value={Dates}
                      onChange={(e) => setDates(e.target.value)}
                      // applyButtonClasses="main-search-input-item"
                      // buttonClasses="main-search-input-item"
                    />{" "}
                    {/* <input
                    type="text"
                    id="date-picker"
                    placeholder="Date"
                    readOnly="readonly"
                  /> */}
                  </div>
                  {/* Panel Dropdown */}
                </div>
                <div className="booking-total-rooms" style={{}}>
                  <strong>Total Rooms</strong>
                  <span>{listingDetail.number_of_rooms}</span>
                </div>
                <div className="booking-ava-rooms" style={{}}>
                  <strong>Available Rooms</strong>
                  <span>{listingDetail.available_rooms}</span>
                </div>
                {/* Book Now */}
                {listingDetail.property_type == "Hotel" ? (
                  <div className="booking-book-rooms" style={{}}>
                    <strong>Book Rooms</strong>
                    <span>
                      <input
                        type="number"
                        name="_bookrooms"
                        id="_bookrooms"
                        value={Number_Of_Rooms}
                        onChange={(e) => setNumber_Of_Rooms(e.target.value)}
                        // data-ava={3}
                        // data-price={4000}
                      />
                    </span>
                    <span className="mra_desc description" />
                  </div>
                ) : (
                  ""
                )}
                <a id="listeo-coupon-link" href="#">
                  Have a coupon?
                </a>
                <div className="coupon-form">
                  <input
                    type="text"
                    name="apply_new_coupon"
                    className="input-text"
                    id="apply_new_coupon"
                    placeholder="Coupon code"
                  />
                  {/* <a
                        // href="#"
                        onClick={setTimeout(() => {
                          setCouponLoader(true);
                        }, 1000)}
                        className="button listeo-booking-widget-apply_new_coupon"
                      >
                        {CouponLoader && (
                          <a onClick={() => setCoupon(!Coupon)}>
                            <div className="loadingspinner">
                            </div>
                          </a>
                        )}
                        {!CouponLoader && (
                          <span className="apply-coupon-text">Apply</span>
                        )}
                      </a> */}
                </div>
                {/* <div id="coupon-widget-wrapper-output">
                    <div className="notification error closeable" />
                    <div
                      className="notification success closeable"
                      id="coupon_added"
                    >
                      This coupon was added
                    </div>
                  </div>
                  <div id="coupon-widget-wrapper-applied-coupons"></div> 
                    </>*/}
                <a
                  onClick={Navigate_To_Booking_With_Data}
                  // onClick={() => setCoupon(!Coupon)}
                  className="button book-now fullwidth margin-top-5"
                >
                  Request To Book
                </a>
              </div>
            ) : (
              <a href="/login">
                <div
                  id="booking-widget-anchor"
                  className="boxed-widget booking-widget margin-top-35 GREYED-OUT"
                >
                  {/* <input class="img__description" type='button'>Login To Continue</input> */}
                  <h3>
                    <i className="fa fa-calendar-check-o " /> Booking
                  </h3>
                  <p class="img__description">Login To Continue</p>
                  <div className="row with-forms  margin-top-0">
                    {/* Date Range Picker - docs: http://www.daterangepicker.com/ */}
                    <div className="col-lg-12">
                      {/* <select>
                    <option id='date-picker'>date picker</option>
                    <option id='date-picker'>date picker</option>
                    <option id='date-picker'>date picker</option>
                  </select> */}
                      <DateRangePickerComponent
                        min={startDate}
                        showPopupButton={true}
                        // startDate={startDate}
                        // onClick={()=>{jQuery("#ej2-datetimepicker_0").trigger('click')}}
                        // showDropdowns={}
                        // showDropdowns={true}
                        // onClick={()=>eventFire(document.getElementById('ej2-datetimepicker_0'),'click')}
                        placeholder="Pick a Date"
                        value={Dates}
                        onChange={(e) => setDates(e.target.value)}
                        // applyButtonClasses="main-search-input-item"
                        // buttonClasses="main-search-input-item"
                      />{" "}
                      {/* <input
                    type="text"
                    id="date-picker"
                    placeholder="Date"
                    readOnly="readonly"
                  /> */}
                    </div>
                    {/* Panel Dropdown */}
                  </div>
                  {/* Book Now */}
                  <a className="button book-now fullwidth margin-top-5">
                    Request To Book
                  </a>
                </div>
              </a>
            )}
            {/* Contact */}
            <div className="boxed-widget margin-top-35">
              <div className="hosted-by-title">
                <h4>
                  <span>Added by</span>{" "}
                  <a href="/dashboard-my-profile">
                    {listingDetail.vendor.name}
                  </a>
                </h4>
                <a href="/dashboard-my-profile" className="hosted-by-avatar">
                  <img src={listingDetail.vendor.avatar} alt="" />
                </a>
              </div>
              <ul className="listing-details-sidebar">
                <li
                  className={
                    listingDetail.vendor.phone_number === 0 ? "hidden" : ""
                  }
                >
                  <i className="sl sl-icon-phone" />{" "}
                  {listingDetail.vendor.phone_number}
                </li>
                {/* <li><i class="sl sl-icon-globe"></i> <a href="#">http://example.com</a></li> */}
                <li>
                  <i className="fa fa-envelope-o" />{" "}
                  <a href={"mailto:" + listingDetail.vendor.email}>
                    {listingDetail.vendor.email}
                  </a>
                </li>
              </ul>
              {/* <ul className="listing-details-sidebar social-profiles">
                <li>
                  <a href="#" className="facebook-profile">
                    <i className="fa fa-facebook-square" /> Facebook
                  </a>
                </li>
                <li>
                  <a href="#" className="twitter-profile">
                    <i className="fa fa-twitter" /> Twitter
                  </a>
                </li>
                <li><a href="#" class="gplus-profile"><i class="fa fa-google-plus"></i> Google Plus</a></li>
              </ul> */}
              {/* Reply to review popup */}
              <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                <div className="small-dialog-header">
                  <h3>Send Message</h3>
                </div>
                <div className="message-reply margin-top-0">
                  <textarea
                    cols={40}
                    rows={3}
                    placeholder="Your message to Tom"
                    defaultValue={""}
                  />
                  <button className="button">Send Message</button>
                </div>
              </div>
              <a
                href="#small-dialog"
                className="send-message-to-owner button popup-with-zoom-anim"
              >
                <i className="sl sl-icon-envelope-open" /> Send Message
              </a>
            </div>
            {/* Contact / End*/}
            {/* Share / Like */}
            <div className="listing-share margin-top-40 margin-bottom-40 no-border">
              <button
                className={
                  listingDetail.is_bookmarked === 1
                    ? "like-bookmarked-button"
                    : "like-button"
                }
                onClick={() => {
                  localStorage.getItem("token") &&
                  listingDetail.is_bookmarked === 0
                    ? put(
                        ADDBookmarkListingEndPoint + listingDetail.uid,
                        "LOCAL",
                        true,
                        localStorage.getItem("token")
                      )
                        .then((res) => {
                          if (res.data.status === 200) {
                            // alert(res.data.message);
                            setAlertData(res.data);
                          } else if (res.data.status == 401) {
                            HandleTokenExpired();
                          }
                          setAlert(true);
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                    : localStorage.getItem("token") &&
                      listingDetail.is_bookmarked === 1
                    ? put(
                        DELETEBookmarkListingPoint + listingDetail.uid,
                        "LOCAL",
                        true,
                        localStorage.getItem("token")
                      )
                        .then((res) => {
                          if (res.data.status === 200) {
                            // alert(res.data.message);
                            setAlertData(res.data);

                          } else if (res.data.status == 401) {
                            HandleTokenExpired();
                          }
                          setAlert(true);
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                    : (window.location.href = "/login");
                }}
              >
                <span
                  className={
                    listingDetail.is_bookmarked === 1
                      ? "like-icon liked"
                      : "like-icon"
                  }
                />{" "}
                {listingDetail.is_bookmarked === 0
                  ? "Bookmark this listing"
                  : "Listing Bookmarked"}
              </button>
              <span>
                {listingDetail.total_bookmarked} people bookmarked this place
              </span>
              {/* Share Buttons */}
              <ul className="share-buttons margin-top-40 margin-bottom-0">
                <li>
                  {/* <a className="fb-share" href="#">
                    <i className="fa fa-facebook" /> Share
                  </a> */}
                  <FacebookShareButton
                    url={window.location.href}
                    title={"Share This Listing On Facebook"}
                  >
                    <FacebookIcon round size={40} />
                  </FacebookShareButton>
                </li>
                <li>
                  {/* <a className="twitter-share" href="#">
                    <i className="fa fa-twitter" /> Tweet
                  </a> */}
                  <TwitterShareButton
                    url={window.location.href}
                    title={"Share This Listing On Twitter"}
                  >
                    <TwitterIcon round size={40} />
                  </TwitterShareButton>
                </li>
                {/* <li>
                  <a className="gplus-share" href="#">
                    <i className="fa fa-google-plus" /> Share
                  </a>
                </li> */}
                {/* <li><a class="pinterest-share" href="#"><i class="fa fa-pinterest-p"></i> Pin</a></li> */}
              </ul>
              <div className="clearfix" />
            </div>
          </div>
          {/* Sidebar / End */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ListingDetail;

const SignUp = () => 
{

return(
  
    <div>
        <p>
            this is SignUp page
        </p>
    </div>
    
);

};

export default SignUp;


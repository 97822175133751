import { useState, useEffect } from "react";
import Footer from "../../Components/Main/Footer/Footer";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import {
  BlogDetailEndPoint,
  AddCommentOnBlogEndPoint,
} from "../../Components/Axios/EndPoints";
import { useParams } from "react-router-dom";
import { get } from "../../Components/Axios/get";
import { post } from "../../Components/Axios/post";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
// import Toast from "../../Components/Main/Toast/Toast";

let RUNONCE = false;

const BlogDetail = () => {
  const params = useParams();
  const [Comment, setComment] = useState("");
  const [BlogDetail, setBlogDetail] = useState();
  const [BlogComments, setBlogComments] = useState(null);
  const [loading, setLoading] = useState(true);

  const [Response, setResponse] = useState(null);
  // const [Response, setResponse] = useState(false);
  const [ResponseError, setResponseError] = useState(false);

  const [Reply, setReply] = useState(false);
  const [ReplyTo, setReplyTo] = useState(null);
  const [ReplyToID, setReplyToID] = useState(null);

  //   const [Blog, setBlog] = useState();
  const navigate = useNavigate();

  const HandleReply = (args, id) => {
    setReply(!Reply);
    setReplyTo(args);
    setReplyToID(id);
  };

  const Toast = (props) => {
    if (props.status == 200) {
      toast.success(props.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (props.status == 404 || props.status == 429) {
      toast.warn(props.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (props.status == 500) {
      toast.error(props.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    RUNONCE = false;
    // setBookmarked(null);
    setTimeout(() => {
    
      window.location.reload();
    }, 2000);
  };

  const PostAComment = () => {
    if (localStorage.getItem("token")) {
      let data = {};

      if (Reply) {
        data = {
          comment: Comment,
          reply_to: ReplyToID,
        };
      } else {
        data = {
          comment: Comment,
        };
      }

      post(
        AddCommentOnBlogEndPoint + params.id,
        "LOCAL",
        data,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            // alert(res.data.message);
            setResponse(res.data);
            setResponseError(true);
            RUNONCE = true;
            Toast(res.data);
            // window.location.href = "/listing-detail/"+props.LOC.state.URL;
          } else {
            setResponseError(true);
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // alert('please login first!');
      window.scrollTo(0, 0);
      navigate("/login");
    }
  };

  if (RUNONCE) {
    Toast(Response);
  }

  useEffect(() => {
    setLoading(true);
    let mounted = true;
    get(
      BlogDetailEndPoint + params.id,
      "LOCAL",
      "",
      false
      // localStorage.getItem("token")
    )
      .then((res) => {
        console.log("response =", res.data);
        if (res.data.status === 200) {
          setBlogComments(res.data.response.comments);
          setBlogDetail(res.data.response);
          setLoading(false);
          window.scrollTo({ behavior: "smooth", top: "0px" });
        } else if (res.data.status === 204) {
          //   set(res.data.response.bookings.length);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, []);

  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <>
      <NavBar />
      {/* { ResponseError && (
        <>
        <Toast status={Response.status} message={Response.message} setError={setResponseError} />
        {
          // RUNONCE = 1,
          setTimeout(() => {
            window.location.reload()
            // ResponseError(false)
          }, 2000)
        }
        </>
      )} */}
      <div>
        {/* Titlebar
================================================== */}
        <div id="titlebar" className="gradient">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Blog</h2>
                <span>Latest News</span>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/blog">Blog</a>
                    </li>
                    <li>
                      <a href={"/blog-detail/" + BlogDetail.uid}>
                        {BlogDetail.title}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Content
================================================== */}
        <div className="container">
          {/* Blog Posts */}
          <div className="blog-page">
            <div className="row">
              {/* Post Content */}
              <div className="col-lg-9 col-md-8 padding-right-30">
                {/* Blog Post */}
                <div className="blog-post single-post blog-detail-img-adjust">
                  {/* Img */}
                  <img
                    className="post-img blog-detail-img-adjust-to"
                    src={BlogDetail.images[0]}
                    alt=""
                  />
                  {/* Content */}
                  <div className="post-content">
                    <h3>{BlogDetail.title}</h3>
                    <ul className="post-meta">
                      <li>{BlogDetail.posted_at}</li>
                      <li>
                        <a href="#">Tips</a>
                      </li>
                      <li>
                        <a href="#">{BlogDetail.comment_count} Comments</a>
                      </li>
                    </ul>
                    <p>{BlogDetail.description}</p>
                    {/* <div className="post-quote">
                      <span className="icon" />
                      <blockquote>
                        Mauris aliquet ultricies ante, non faucibus ante gravida
                        sed. Sed ultrices pellentesque purus, vulputate volutpat
                        ipsum hendrerit sed neque sed sapien rutrum.
                      </blockquote>
                    </div> */}
                    <p>
                      In ut odio libero, at vulputate urna. Nulla tristique mi a
                      massa convallis cursus. Nulla eu mi magna. Etiam suscipit
                      commodo gravida. Cras suscipit, quam vitae adipiscing
                      faucibus, risus nibh laoreet odio, a porttitor metus eros
                      ut enim. Morbi augue velit, tempus mattis dignissim nec,
                      porta sed risus. Donec eget magna eu lorem tristique
                      pellentesque eget eu dui. Fusce lacinia tempor malesuada.
                      Ut lacus sapien, placerat a ornare nec, elementum sit amet
                      felis. Maecenas pretium lorem hendrerit eros sagittis
                      fermentum.
                    </p>
                    <p>
                      Phasellus enim magna, varius et commodo ut, ultricies
                      vitae velit. Ut nulla tellus, eleifend euismod
                      pellentesque vel, sagittis vel justo. In libero urna,
                      venenatis sit amet ornare non, suscipit nec risus. Sed
                      consequat justo non mauris pretium at tempor justo
                      sodales. Quisque tincidunt laoreet malesuada. Cum sociis
                      natoque penatibus et magnis dis parturient montes,
                      nascetur ridiculus mus. Integer vitae ante enim. Fusce sed
                      elit est. Suspendisse sit amet mauris in quam pretium
                      faucibus et aliquam odio.{" "}
                    </p>
                    {/* Share Buttons */}
                    {/* <ul className="share-buttons margin-top-40 margin-bottom-0">
                      <li>
                        <a className="fb-share" href="#">
                          <i className="fa fa-facebook" /> Share
                        </a>
                      </li>
                      <li>
                        <a className="twitter-share" href="#">
                          <i className="fa fa-twitter" /> Tweet
                        </a>
                      </li>
                      <li>
                        <a className="gplus-share" href="#">
                          <i className="fa fa-google-plus" /> Share
                        </a>
                      </li>
                      <li>
                        <a className="pinterest-share" href="#">
                          <i className="fa fa-pinterest-p" /> Pin
                        </a>
                      </li>
                    </ul>
                    <div className="clearfix" /> */}
                  </div>
                </div>
                {/* Blog Post / End */}
                {/* Post Navigation */}
                {/* <ul id="posts-nav" className="margin-top-0 margin-bottom-45">
                  <li className="next-post">
                    <a href="#">
                      <span>Next Post</span>
                      The Best Cofee Shops In Sydney Neighborhoods
                    </a>
                  </li>
                  <li className="prev-post">
                    <a href="#">
                      <span>Previous Post</span>
                      Hotels for All Budgets
                    </a>
                  </li>
                </ul> */}
                {/* About Author */}
                {/* <div className="about-author">
                  <img src="images/user-avatar.jpg" alt="" />
                  <div className="about-description">
                    <h4>Tom Perrin</h4>
                    <a href="#">tom@example.com</a>
                    <p>
                      Nullam ultricies, velit ut varius molestie, ante metus
                      condimentum nisi, dignissim facilisis turpis ex in libero.
                      Sed porta ante tortor, a pulvinar mi facilisis nec. Proin
                      finibus dolor ac convallis congue.
                    </p>
                  </div>
                </div> */}
                {/* Related Posts */}
                {/* <div className="clearfix" />
                <h4 className="headline margin-top-25">Related Posts</h4>
                <div className="row"> */}
                {/* Blog Post Item */}
                {/* <div className="col-md-6">
                    <a href="#" className="blog-compact-item-container">
                      <div className="blog-compact-item">
                        <img src="images/blog-compact-post-01.jpg" alt="" />
                        <span className="blog-item-tag">Tips</span>
                        <div className="blog-compact-item-content">
                          <ul className="blog-post-tags">
                            <li>22 August 2019</li>
                          </ul>
                          <h3>Hotels for All Budgets</h3>
                          <p>
                            Sed sed tristique nibh iam porta volutpat finibus.
                            Donec in aliquet urneget mattis lorem. Pellentesque
                            pellentesque.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div> */}
                {/* Blog post Item / End */}
                {/* Blog Post Item */}
                {/* <div className="col-md-6">
                    <a href="#" className="blog-compact-item-container">
                      <div className="blog-compact-item">
                        <img src="images/blog-compact-post-03.jpg" alt="" />
                        <span className="blog-item-tag">Tips</span>
                        <div className="blog-compact-item-content">
                          <ul className="blog-post-tags">
                            <li>10 August 2019</li>
                          </ul>
                          <h3>The Best Cofee Shops In Sydney Neighborhoods</h3>
                          <p>
                            Sed sed tristique nibh iam porta volutpat finibus.
                            Donec in aliquet urneget mattis lorem. Pellentesque
                            pellentesque.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div> */}
                {/* Blog post Item / End */}
                {/* Related Posts / End */}
                <div className="margin-top-50" />
                {/* Reviews */}
                {BlogComments.length > 0 ? (
                  <section className="comments">
                    <h4 className="headline margin-bottom-35">
                      Comments{" "}
                      <span className="comments-amount">
                        ({BlogComments.length})
                      </span>
                    </h4>
                    <ul>
                      {Object.values(BlogComments).map((value, index) => (
                        <li>
                          <div className="user-name user-name-avatar">
                            <span>
                              <img src={value.comment_by.avatar} alt="" />
                            </span>
                          </div>
                          <div className="comment-content">
                            <div className="arrow-comment" />
                            <div className="comment-by">
                              {value.comment_by.name}
                              <span className="date"></span>
                              <a
                                onClick={() =>
                                  HandleReply(value.comment_by, value.id)
                                }
                                className="reply"
                              >
                                <i className="fa fa-reply" /> Reply
                              </a>
                            </div>
                            <p>{value.comment}</p>
                          </div>
                          {/* {console.log("value =", value.replies)} */}
                          {value.replies.length > 0 ? (
                            <ul>
                              {Object.values(value.replies).map((reply) => (
                                <li>
                                  <div className="user-name user-name-avatar">
                                    <span>
                                      <img
                                        src={reply.comment_by.avatar}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="comment-content">
                                    <div className="arrow-comment" />
                                    <div className="comment-by">
                                      {reply.comment_by.name}
                                      <span className="date"></span>
                                      {/* <a href="#" className="reply">
                                        <i className="fa fa-reply" /> Reply
                                      </a> */}
                                    </div>
                                    <p>{reply.comment}</p>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <></>
                          )}
                          {/* <ul>
                          <li>
                            <div className="avatar">
                              <img
                                src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                                alt=""
                              />
                            </div>
                            <div className="comment-content">
                              <div className="arrow-comment" />
                              <div className="comment-by">
                                Tom Smith
                                <span className="date">22 August 2019</span>
                                <a href="#" className="reply">
                                  <i className="fa fa-reply" /> Reply
                                </a>
                              </div>
                              <p>
                                Rrhoncus et erat. Nam posuere tristique sem, eu
                                ultricies tortor imperdiet vitae. Curabitur
                                lacinia neque.
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="avatar">
                              <img
                                src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                                alt=""
                              />
                            </div>
                            <div className="comment-content">
                              <div className="arrow-comment" />
                              <div className="comment-by">
                                Kathy Brown
                                <span className="date">20 August 2019</span>
                                <a href="#" className="reply">
                                  <i className="fa fa-reply" /> Reply
                                </a>
                              </div>
                              <p>
                                Nam posuere tristique sem, eu ultricies tortor.
                              </p>
                            </div>
                            <ul>
                              <li>
                                <div className="avatar">
                                  <img
                                    src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                                    alt=""
                                    />
                                </div>
                                <div className="comment-content">
                                  <div className="arrow-comment" />
                                  <div className="comment-by">
                                    John Doe
                                    <span className="date">18 August 2019</span>
                                    <a href="#" className="reply">
                                      <i className="fa fa-reply" /> Reply
                                    </a>
                                  </div>
                                  <p>Great template!</p>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul> */}
                        </li>
                      ))}
                      {/* <li>
                        <div className="avatar">
                          <img
                            src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                            alt=""
                          />{" "}
                        </div>
                        <div className="comment-content">
                          <div className="arrow-comment" />
                          <div className="comment-by">
                            John Doe<span className="date">18 August 2019</span>
                            <a href="#" className="reply">
                              <i className="fa fa-reply" /> Reply
                            </a>
                          </div>
                          <p>
                            Commodo est luctus eget. Proin in nunc laoreet justo
                            volutpat blandit enim. Sem felis, ullamcorper vel
                            aliquam non, varius eget justo. Duis quis nunc
                            tellus sollicitudin mauris.
                          </p>
                        </div>
                      </li> */}
                    </ul>
                  </section>
                ) : (
                  ""
                )}
                <div className="clearfix" />
                {/* Add Comment */}
                <div id="add-review" className="add-review-box">
                  {/* Add Review */}
                  <h3 className="listing-desc-headline margin-bottom-35">
                    Leave a Reply
                  </h3>
                  {/* Review Comment */}
                  {localStorage.getItem("token") == null ||
                  localStorage.getItem("token") == undefined ? (
                    ""
                  ) : (
                    <p class="logged-in-as">
                      <a
                      //   href="https://mazebaan.com/wp-admin/profile.php"
                      //   aria-label="Logged in as Nabeel. Edit your profile."
                      >
                        Logged in as {localStorage.getItem("name")}
                      </a>
                      . <a href={"/"}>Log out?</a>
                    </p>
                  )}
                  {Reply && (
                    <>
                      <h3 id="reply-title" className="comment-reply-title">
                        Reply to {ReplyTo.name}
                        {/* {console.log('resply = ',ReplyTo)} */}
                        <br />
                        <small>
                          <a
                            rel="nofollow"
                            id="cancel-comment-reply-link"
                            onClick={HandleReply}
                            style={{}}
                          >
                            Cancel reply
                          </a>
                        </small>
                      </h3>
                    </>
                  )}
                  <fieldset>
                    {/* <div className="row">
                        <div className="col-md-6">
                          <label>Name:</label>
                          <input type="text" defaultValue />
                        </div>
                        <div className="col-md-6">
                          <label>Email:</label>
                          <input type="text" defaultValue />
                        </div>
                      </div> */}
                    <div>
                      <label>Comment:</label>
                      <textarea
                        cols={40}
                        rows={3}
                        value={Comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  </fieldset>
                  <button onClick={PostAComment} className="button">
                    Post Comment
                  </button>
                  <div className="clearfix" />
                </div>
                {/* Add Review Box / End */}
              </div>
              {/* Content / End */}
              {/* Widgets */}
              <div className="col-lg-3 col-md-4">
                <div className="sidebar right">
                  {/* Widget */}
                  <div className="widget">
                    <h3 className="margin-top-0 margin-bottom-25">
                      Search Blog
                    </h3>
                    <div className="search-blog-input">
                      <div className="input">
                        <input
                          className="search-field"
                          type="text"
                          placeholder="Type and hit enter"
                          defaultValue
                        />
                      </div>
                    </div>
                    <div className="clearfix" />
                  </div>
                  {/* Widget / End */}
                  {/* Widget */}
                  <div className="widget margin-top-40">
                    <h3>Got any questions?</h3>
                    <div className="info-box margin-bottom-10">
                      <p>Having any questions? Feel free to ask!</p>
                      <a
                        href="/contact-us"
                        className="button fullwidth margin-top-20"
                      >
                        <i className="fa fa-envelope-o" /> Drop Us a Line
                      </a>
                    </div>
                  </div>
                  {/* Widget / End */}
                  {/* Widget */}
                  {/* <div className="widget margin-top-40">
                    <h3>Popular Posts</h3>
                    <ul className="widget-tabs">
                      <li>
                        <div className="widget-content">
                          <div className="widget-thumb">
                            <a href="pages-blog-post.html">
                              <img src="images/blog-widget-03.jpg" alt="" />
                            </a>
                          </div>
                          <div className="widget-text">
                            <h5>
                              <a href="pages-blog-post.html">
                                Hotels for All Budgets{" "}
                              </a>
                            </h5>
                            <span>October 26, 2016</span>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </li>
                      <li>
                        <div className="widget-content">
                          <div className="widget-thumb">
                            <a href="pages-blog-post.html">
                              <img src="images/blog-widget-02.jpg" alt="" />
                            </a>
                          </div>
                          <div className="widget-text">
                            <h5>
                              <a href="pages-blog-post.html">
                                The 50 Greatest Street Arts In London
                              </a>
                            </h5>
                            <span>November 9, 2016</span>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </li>
                      <li>
                        <div className="widget-content">
                          <div className="widget-thumb">
                            <a href="pages-blog-post.html">
                              <img src="images/blog-widget-01.jpg" alt="" />
                            </a>
                          </div>
                          <div className="widget-text">
                            <h5>
                              <a href="pages-blog-post.html">
                                The Best Cofee Shops In Sydney Neighborhoods
                              </a>
                            </h5>
                            <span>November 12, 2016</span>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </li>
                    </ul>
                  </div> */}
                  {/* Widget / End*/}
                  {/* Widget */}
                  {/* <div className="widget margin-top-40">
                    <h3 className="margin-bottom-25">Social</h3>
                    <ul className="social-icons rounded">
                      <li>
                        <a className="facebook" href="#">
                          <i className="icon-facebook" />
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#">
                          <i className="icon-twitter" />
                        </a>
                      </li>
                      <li>
                        <a className="gplus" href="#">
                          <i className="icon-gplus" />
                        </a>
                      </li>
                      <li>
                        <a className="linkedin" href="#">
                          <i className="icon-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  {/* Widget / End*/}
                  <div className="clearfix" />
                  <div className="margin-bottom-40" />
                </div>
              </div>
            </div>
            {/* Sidebar / End */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;

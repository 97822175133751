import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  InfoBox,
} from "@react-google-maps/api";
import React, {useEffect} from "react";
import Info_Box from "./Info_Box";
import {GoogleMapsKey} from "../../Components/GoogleKeys/GoogleKeys";

const GoogleMaps = (props) => {
  const [map, setMap] = React.useState(null);
  const [latitude, setLatitude] = React.useState(props.lat);
  const [longitude, setLongitude] = React.useState(props.lng);
  const [Lat, setLat] = React.useState(props.lat);
  const [Lng, setLng] = React.useState(props.lng);
  const [InfoCard, setInfoCard] = React.useState(false);
  // const [isLoaded, setIsLoaded] = React.useState(false);
  const isLoaded = props.isLoaded;

  // const isLoaded = false;
  // console.log('googleMapsIsLoaded', isLoaded);

  // console.log('props. lat =', props.lat)
  // console.log('props. lng =', props.lng)






  let containerStyle = {
    position: "static",
    width: props.loc == "addlisting" ? JSON.stringify(window.screen.width-450) +"px" : "100%",
    height:props.loc == "addlisting" ? JSON.stringify(400)+"px" : "100%",
    marginBottom: '2em'
  };

  let pos = {
    lat: props.lat,
    lng: props.lng,
  };

  const divStyle = {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
  };

  let center = {
    lat: parseFloat(props.lat),
    lng: parseFloat(props.lng),
  };


  useEffect(() => {
    center = {
      lat: props.lat,
      lng: props.lng,
    };
    pos = {
      lat: props.lat,
      lng: props.lng,
    };
    containerStyle = {
      position: "static",
      width: props.loc == "addlisting" ? JSON.stringify(window.screen.width-450) +"px" : "100%",
      height:props.loc == "addlisting" ? JSON.stringify(400)+"px" : "100%",
      marginBottom: '2em'
    };
  }, [props.Lat]);






  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  // console.log('map =', props)

  const Highlight_Listing = () => {
    window.location.href = "#infocard1";
    // windows.href='#listing1';
    // setInfoCard(!InfoCard);
  
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  // const infobox = () => {
  //   console.log('in infobox')
  //   return (
  //     <div
  //       className="infoBox"
  //       style={{
  //         width: "170px",
  //         transform: "translateZ(0px)",
  //         position: "absolute",
  //         visibility: "visible",
  //         left: "-134.25px",
  //         bottom: "-82.3px",
  //         cursor: "default",
  //       }}
  //     >
  //       <div className="map-box">
  //         <a href="listings-single-page.html" className="listing-img-container">
  //           <div className="infoBox-close">
  //             <i className="fa fa-times" />
  //           </div>
  //           <img src="images/listing-item-01.jpg" alt="" />
  //           <div className="listing-item-content">
  //             <h3>Tom's Restaurant</h3>
  //             <span>964 School Street, New York</span>
  //           </div>
  //         </a>
  //         <div className="listing-content">
  //           <div className="listing-title">
  //             <div className="star-rating" data-rating="3.5">
  //               <div className="rating-counter">(12 reviews)</div>
  //               <span className="star" />
  //               <span className="star" />
  //               <span className="star" />
  //               <span className="star half" />
  //               <span className="star empty" />
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={15}
        onUnmount={onUnmount}
      >
        {/* <InfoBox
          position={{
            lat: Lat + 0.001,
            lng: Lng + 0.002,
          }}
        >
          <div class="marker-container">
            <div class="marker-card">
              <div class="front face">
              <i class="im im-icon-Home-2"></i>
              </div>
              <div class="back face">
              <i class="im im-icon-Home-2"></i>
              </div>
              <div class="marker-arrow"></div>
            </div>
          </div>
        </InfoBox> */}
        <InfoBox
          position={pos}
        >
          <a onClick={Highlight_Listing}>
            <div class="marker-container">
              <div class="marker-card">
                <div class="front face">
                <i class="im im-icon-Home-2"></i>
                </div>
                <div class="back face">
                <i class="im im-icon-Home-2"></i>
                </div>
                <div class="marker-arrow"></div>
              </div>
            </div>
            {/* <div className={}>
              <Info_Box/>
            </div> */}
          </a>
        </InfoBox>          
        />
      </GoogleMap>
    </>
  ) : (
    <div className="loading"></div>
  );
};

export default GoogleMaps;

import "../../Homepage/dashboard.css";
import logo2 from "../../../images/mazebaan-logo.png";
import logo from "../../../images/logo2.png";
import {get} from '../../Axios/get';
import {UserLogoutEndPoint} from "../../Axios/EndPoints";

const Sidebar = (props) => {
    let activePage = props.activePage;

    const HandleLogout = () => {
        if (localStorage.getItem('token')) {
            get(UserLogoutEndPoint, "LOCAL", "", true, localStorage.getItem('token'))
                .then((res) => {
                    if (res.data.status === 200) {
                        alert(res.data.message);
                    }
                })
                .catch((err) => {
                    alert(err);
                })
            localStorage.setItem("tokenExpired", "true");
            localStorage.removeItem('item');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('avatar');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            localStorage.removeItem('unread_messages');
            localStorage.removeItem('token');
            localStorage.removeItem('settings');
            window.location.assign('/');
        } else {
            alert('User not logged in!');
        }
    }

    return (
        <div>
            {/*<a href="/" className="dashboard-responsive-nav-trigger">
        <i>
          <FontAwesomeIcon icon={faRecycle} />
        </i>{" "}
        Dashboard Navigation
      </a>*/}
            <div className="dashboard-nav">
                <div id={"logo"}>
                    <a href="/">
                        <img
                            src={logo2}
                            className={"img-fluid em-down"}
                            // data-sticky-logo={logo}
                            alt=""
                        />
                    </a>
                </div>
                <div className="dashboard-nav-inner">
                    <ul data-submenu-title="Main">
                        <li className={activePage === "dashboard" ? "active" : ""}>
                            <a href={"/dashboard"}>
                                <i className="sl sl-icon-settings"/> Dashboard
                            </a>
                        </li>
                        <li className={activePage === "dashboard-bookings" ? "active" : ""}>
                            <a href={"/dashboard-bookings"}>
                                <i className="fa fa-calendar-check-o"/> My Bookings
                            </a>
                        </li>
                        <li className={activePage === "dashboard-messages" ? "active" : ""}>
                            <a href={"/dashboard-messages"}>
                                <i className="sl sl-icon-envelope-open"/> Messages{" "}
                                <span className="nav-tag messages">{localStorage.getItem('unread_messages')}</span>
                            </a>
                        </li>
                        <li
                            className={
                                activePage === "dashboard-booking-requests" ? "active" : ""
                            }
                        >
                            {/* <a href={'/dashboard-booking-requests'}>
                                <i className="fa fa-calendar-check-o"/> Booking Request
                            </a> */}
                            <li
                                id={"bookingrequestparent"}
                                className={
                                    activePage === "dashboard-booking-requests" ? "active" : ""
                                }
                                onClick={() => {
                                    if (
                                        document.getElementById("bookingrequestparent")
                                            .className === "active"
                                    ) {
                                        document.getElementById("bookingrequestparent").className =
                                            "";
                                    } else {
                                        document.getElementById("bookingrequestparent").className =
                                            "active";
                                    }
                                }}
                            >
                                <a>
                                    <i className="fa fa-calendar-check-o"/> Booking Request
                                </a>
                                <ul id={"listingSubmenu"}>
                                    <li>
                                        <a href={"/dashboard-booking-requests-pending"}>
                                            Pending <span className="nav-tag green">2</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/dashboard-booking-requests-approved"}>
                                            Approved <span className="nav-tag yellow">4</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={"/dashboard-booking-requests-cancelled"}>
                                            Cancelled <span className="nav-tag red">11</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </li>
                    </ul>
                    <ul data-submenu-title="Listings">
                        <li
                            id={"listingParent"}
                            className={activePage === "dashboard-my-listings" ? "active" : ""}
                            onClick={() => {
                                if (
                                    document.getElementById("listingParent").className ===
                                    "active"
                                ) {
                                    document.getElementById("listingParent").className = "";
                                } else {
                                    document.getElementById("listingParent").className = "active";
                                }
                            }}
                        >
                            <a href={"#"}>
                                <i className="sl sl-icon-layers"/> My Listings
                            </a>
                            <ul id={"listingSubmenu"}>
                                <li>
                                    <a href={"/dashboard-my-listings"}>
                                        Active <span className="nav-tag green">6</span>
                                    </a>
                                </li>
                                {/* <li>
                  <a href={"/dashboard-my-listings"}>
                    Pending <span className="nav-tag yellow">1</span>
                  </a>
                </li>
                <li>
                  <a href={"/dashboard-my-listings"}>
                    Expired <span className="nav-tag red">2</span>
                  </a>
                </li> */}
                            </ul>
                        </li>
                        <li className={activePage === "dashboard-reviews" ? "active" : ""}>
                            <a href={"/dashboard-reviews"}>
                                <i className="sl sl-icon-star"/> Reviews
                            </a>
                        </li>
                        <li
                            className={activePage === "dashboard-bookmarks" ? "active" : ""}
                        >
                            <a href={"/dashboard-bookmarks"}>
                                <i className="sl sl-icon-heart"/> Bookmarks
                            </a>
                        </li>
                        <li
                            className={activePage === "dashboard-add-listing" ? "active" : ""}
                        >
                            <a href={"/dashboard-add-listing"}>
                                <i className="sl sl-icon-plus"/> Add Listing
                            </a>
                        </li>
                    </ul>
                    <ul data-submenu-title="Account">
                        <li
                            className={activePage === "dashboard-my-profile" ? "active" : ""}
                        >
                            <a href={"/dashboard-my-profile"}>
                                <i className="sl sl-icon-user"/> My Profile
                            </a>
                        </li>
                        <li className={activePage === "/" ? "active" : ""}>
                            <a onClick={HandleLogout}>
                                <i className="sl sl-icon-power"/> Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;

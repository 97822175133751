const HandleTokenExpired = () =>
{
    localStorage.setItem("tokenExpired", "true");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    localStorage.removeItem("avatar");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("unread_messages");
    window.location.href('/login');
}

export default HandleTokenExpired;
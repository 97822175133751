import "../../Pages/Homepage/Homepage.css";
import {useParams} from "react-router-dom";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
import Toast from "../../Components/Main/Toast/Toast";
import React, {useEffect, useState} from "react";
import {post} from "../../Components/Axios/post";
import {ResetPasswordEndPoint} from "../../Components/Axios/EndPoints";
import redirectToLogin from "../../utility/redirectToLogin";

export const ResetPassword = (props) => {
    const params = useParams();
    const [response, setResponse] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        redirectToLogin(props);
    },[]);

    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            "email": params.email,
            "password": event.target.password.value,
            "password_confirmation":event.target.password_confirmation.value,
        }
        post(ResetPasswordEndPoint,'LOCAL',data,false)
            .then((res) => {
                setResponseMessage(res.data);
                setResponse(true);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            })
    }

    return (
        <>
            <NavBar/>
            {loading ? (
                <>
                    {/* <section
                        className="fullwidth border-top margin-top-65 padding-top-75 padding-bottom-70 text-center"> */}
                        <div className="loading"></div>
                    {/* </section> */}
                </>
            ) : ""}

            <div className="container margin-top-100 width-25">
                {response && (
                    <>
                        <Toast status={responseMessage.status} message={responseMessage.message}
                               setError={setResponse}/>
                        {
                            setTimeout(() => {
                                setResponse(false)
                                window.location.replace('/login')
                            }, 3000)
                        }
                    </>
                )}
                {/* <a
                    className="button border margin-top-5 bg-yellow padding-left-40 padding-right-40 margin-bottom-15"
                    href="/login">Login
                </a> */}
                              <h3>Reset Password</h3>
              <div className="clearfix" />

                <form className="login" onSubmit={(e) => handleSubmit(e)}>
                    <div className="input-group">
                        <div className="input-group-icon"><i className="im im-icon-Lock-2"/></div>
                        <div className="input-group-area"><input type="text" name="password" placeholder="Enter New Password"/>
                        </div>
                    </div>
                    <div className="input-group">
                        <div className="input-group-icon"><i className="im im-icon-Lock-2"/></div>
                        <div className="input-group-area"><input type="text" name="password_confirmation" placeholder="Re-Enter New Password"/>
                        </div>
                    </div>
                    <div className="form-row">
                        {!loading && (<input
                            type="submit"
                            className="button border margin-top-5 bg-yellow padding-left-40 padding-right-40"
                            value={'Submit'}
                        />)}
                    </div>
                </form>
            </div>

            <Footer/>
        </>
    );

}
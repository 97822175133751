import Modal from "react-modal";
import ReturnPolicyInfographic from "../../../images/refund-policy.png";
import { put } from "../../Axios/put";
import React from 'react';
import HandleTokenExpired from "../../Main/TokenExpired/TokenExpired";
import { DashboardCancelBookingEndPoint } from "../../Axios/EndPoints";
import Toast from "../../Main/Toast/Toast";

Modal.setAppElement(document.getElementById("cancel-btn-mybooking"));

const MODAL = (props) => {
  const [Alert, setAlert] = React.useState(false);
  const [AlertData, setAlertData] = React.useState(null);

  return (
    <>
      {Alert == true ? (
        <Toast status={AlertData.status} message={AlertData.message} />
      ) : (
        ""
      )}
      <Modal
        isOpen={props.modalIsOpen}
        // ariaHideApp={true}
        onAfterOpen={props.afterOpenModal}
        onRequestClose={props.closeModal}
        onreq
        style={props.customStyles}
        // contentLabel="Example Modal"
      >
        <a onClick={props.closeModal}>
          <div className="row">
            <div className="col-md-6">
              <a
                id="cancel-btn-mybooking"
                class="button mazebaan cancel"
                onClick={() => {
                  console.log("uid =", props.uid);
                  put(
                    DashboardCancelBookingEndPoint + props.uid,
                    "LOCAL",
                    true,
                    localStorage.getItem("token")
                  )
                    .then((res) => {
                      if (res.data.status === 200) {
                        setAlertData(res.data);
                        // alert(res.data.message);
                        
                        window.location.href = "/dashboard-bookings";
                      } else if (res.data.status == 401) {
                        HandleTokenExpired();
                      }
                      setAlert(true);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                <i class="sl sl-icon-ban"></i> Cancel
              </a>
            </div>
            <div className="col-md-6">
              <a class="button mazebaan">
                <i class="sl sl-icon-close"></i>
                Close
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <img
                className="refund-policy-image"
                src={ReturnPolicyInfographic}
              ></img>
            </div>
          </div>
        </a>
      </Modal>
    </>
  );
};

export default MODAL;

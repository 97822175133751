import "./PreviewListing.css";
import GoogleMaps from "../../GoogleMaps/GoogleMaps";
import { useState } from "react";
import { Slide } from "react-slideshow-image";

const PreviewListing = (props) => {
  const [height, setheight] = useState(
    window.screen.height >= 1080
      ? window.screen.height - 650
      : window.screen.height - 350
  );
  const [toggler, setToggler] = useState(false);
  const properties = {
    duration: 0.5,
    slidesToShow: props.images.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    autoplay: false,
    indicators: true,
  };
  const style = {
    textAlign: "center",
    background: "white",
    "background-size": "contain",
    // padding: "200px 0",
    fontSize: "30px",
    "max-height": height,
  };

  console.log("props =", props.EditListing);
  return props === undefined ? (
    <>
      <p>props not coming</p>
    </>
  ) : (
    <>
      <div className="dashboard-content" id="post-69">
        {/* Titlebar */}
        <div id="titlebar">
          <div className="row">
            <div className="col-md-12">
              <h1>Add Listing</h1>
              {/* Breadcrumbs */}
              <nav id="breadcrumbs">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li>
                    <a href="/dashboard-add-listing">Add Listing</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <div className="notification closeable notice">
                      <p>
                        <strong>Notice!</strong> This is preview of listing
                        you've submitted, please confirm or edit your submission
                        using buttons at the end of that page.
                      </p>
                      <a className="close" />
                    </div>
                    <div className="listing_preview_container">
                      <div id="titlebar" className="listing-titlebar">
                        <div className="listing-titlebar-title">
                          <h2>
                            {props.name != ""
                              ? props.name
                              : "Listing Name Missing"}
                          </h2>
                          <span>
                            <a
                              href="#listing-location"
                              className="listing-address"
                            >
                              <i className="fa fa-map-marker" />
                              {props.address}
                            </a>
                          </span>
                        </div>
                      </div>
                      <div id="listing-nav" className="listing-nav-container">
                        <ul className="listing-nav">
                          <li>
                            <a href="#listing-overview" className>
                              Overview
                            </a>
                          </li>
                          <li>
                            <a href="#listing-gallery" className>
                              Gallery
                            </a>
                          </li>
                          <li>
                            <a href="#listing-location" className>
                              Location
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* Overview */}
                      <div id="listing-overview" className="listing-section">
                        {/* Main Details */}
                        <h4 className="show-price-on-single-listing">
                          Price: {props.price_per_day} PKR/day
                        </h4>
                        <ul className="apartment-details">
                          <li className="main-detail-_cnic">
                            <span>{props.cnic}</span> CNIC{" "}
                          </li>
                          <li className="main-detail-_number_of_beds">
                            <span>{props.number_of_beds}</span> Bed(s){" "}
                          </li>
                        </ul>
                        <span style={{ marginLeft: "5%", fontWeight: 600 }}>
                          {props.number_of_guests} Guests (Maximum)
                        </span>
                        <h3 className="listing-desc-headline">Description</h3>
                        {/* Description */}
                        <p>{props.description}</p>
                        <div className="listing-links-container">​</div>
                        <div className="clearfix" />
                        {/* Features */}
                        <h3 className="listing-desc-headline">Features</h3>
                        <h5 className="listing-desc-headline">Amenities</h5>
                        <ul className="listing-features checkboxes margin-top-0">
                          {props.amenities.map((value) => (
                            <li>
                              <a
                                // href="https://mazebaan.com/listing-feature/breakfast/"
                                rel="tag"
                              >
                                {console.log("value in prevew =")}
                                {value.name}
                              </a>
                            </li>
                          ))}
                        </ul>{" "}
                        <h5 className="listing-desc-headline">Languages</h5>
                        <ul className="listing-features checkboxes margin-top-0">
                          {props.languages.map((value) => (
                            <li>
                              <a
                                // href="https://mazebaan.com/listing-feature/breakfast/"
                                rel="tag"
                              >
                                {value.language}
                              </a>
                            </li>
                          ))}
                        </ul>{" "}
                        <h5 className="listing-desc-headline">House Rules</h5>
                        <ul className="listing-features checkboxes margin-top-0">
                          {props.house_rules.map((value) => (
                            <li>
                              <a
                                // href="https://mazebaan.com/listing-feature/breakfast/"
                                rel="tag"
                              >
                                {value.rule}
                              </a>
                            </li>
                          ))}
                        </ul>{" "}
                      </div>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            "\n\t\t#listing-gallery { width: calc(100vw -  460px) }\n\t\t@media (max-width: 992px) { #listing-gallery { width: calc(100vw -  160px) } }\n\t\t",
                        }}
                      />
                      {/* <div class="row">
			<div class="col-md-12"> */}
                      {/* Content
================================================== */}
                      {/* Slider */}
                      <div id="listing-gallery" className="listing-section">
                        <h3 className="listing-desc-headline margin-top-70">
                          Gallery
                        </h3>
                        <div className="listing-slider-small mfp-gallery-container margin-bottom-0 slick-initialized slick-slider">
                          <div
                            aria-live="polite"
                            className="slick-list draggable"
                            style={{ padding: "0px" }}
                          >
                            {/* <div
                              className="slick-track"
                              style={{
                                opacity: 1,
                                width: "604px",
                                transform: "translate3d(0px, 0px, 0px)",
                              }}
                              role="listbox"
                            > */}
                            <Slide {...properties}>
                              {props.images.map((value) => (
                                <>
                                  {/* {console.log("value -", value)} */}
                                  <div style={style}>
                                    <img
                                      style={{ width: "50%" }}
                                      onClick={() => setToggler(!toggler)}
                                      src={
                                        props.EditListing != null &&
                                        typeof value === "string"
                                          ? value
                                          : URL.createObjectURL(value)
                                      }
                                    ></img>
                                  </div>
                                </>
                              ))}
                            </Slide>

                            {/* {props.images.map((value, index) => (
                                <>
                                  {console.log("value in preview =", value)}
                                  <a
                                    // href={URL.createObjectURL(value)}
                                    // data-background-image={URL.createObjectURL(value)}
                                    className="item mfp-gallery slick-slide slick-cloned"
                                    style={{
                                      backgroundImage:
                                        props.EditListing == null
                                          ? "url(" +
                                            URL.createObjectURL(value) +
                                            ")"
                                          : "url(" + value + ")",
                                      width: "50%",
                                      // height:"10em",
                                    }}
                                    data-slick-index={index}
                                    id
                                    aria-hidden="true"
                                    tabIndex={-1}
                                  />
                                </>
                              ))} */}
                            {/* </div> */}
                          </div>{" "}
                        </div>
                      </div>
                      {/* Slider */}
                      {/* </div>
		</div> */}
                      {/* <a
                                data-background-image="https://mazebaan.com/wp-content/uploads/2022/01/Screenshot-1-1.png"
                                className="item mfp-gallery slick-slide slick-current slick-center"
                                style={{
                                  backgroundImage:
                                    'url("https://mazebaan.com/wp-content/uploads/2022/01/Screenshot-1-1.png")',
                                  width: "302px",
                                }}
                              />
                              <a
                                className="item mfp-gallery slick-slide"
                                style={{
                                  backgroundImage:
                                    'url("https://mazebaan.com/wp-content/uploads/2022/01/Screenshot-2-1-1200x675.png")',
                                  width: "302px",
                                }}
                              /> */}

                      {/* Location */}
                      {/* Location */}
                      <div id="listing-location" className="listing-section">
                        <h3 className="listing-desc-headline margin-top-60 margin-bottom-30">
                          Location
                        </h3>
                        <div id="singleListingMap-container" className=" ">
                          <GoogleMaps
                            isLoaded={props.isLoaded}
                            loc={"addlisting"}
                            lat={props.lat}
                            lng={props.lng}
                          />
                          <a
                            target="_blank"
                            href="https://www.google.com/maps/dir/?api=1&destination=33.6464237,73.036636"
                            id="getDirection"
                          >
                            Get Direction
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row margin-bottom-30">
                      <div className="col-md-12">
                        <button
                          type="submit"
                          name="edit_listing"
                          className="button border margin-top-20"
                          onClick={props.HandlePreviewListing}
                        >
                          <i className="fa fa-edit" /> Edit listing
                        </button>
                        {/* <input type="submit" name="continue"> */}
                        <button
                          type="submit"
                          onClick={
                            props.ErrorCNIC ||
                            props.ErrorAccountNumber ||
                            props.MessageError ||
                            props.CNICError ||
                            props.PriceError ||
                            props.ErrorNumberOfBeds ||
                            props.ErrorNumberOfGuests ||
                            props.ErrorAccountTitle
                              ? (
                                  alert("Please Check Invalid Fields"),
                                  props.HandlePreviewListing()
                              )
                              : props.APICALL
                          }
                          name="continue"
                          className="button margin-top-20"
                        >
                          <i className="fa fa-check" />
                          Submit Listing{" "}
                        </button>
                        <input
                          type="hidden"
                          name="listing_id"
                          defaultValue={6047}
                        />
                        <input type="hidden" name="step" defaultValue={1} />
                        <input
                          type="hidden"
                          name="listeo_core_form"
                          defaultValue="submit-listing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="wpb_raw_code wpb_raw_js">
                  <div className="wpb_wrapper"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Copyrights */}
        <div className="row">
          <div className="col-md-12">
            <div className="copyrights">
              {" "}
              © Mazebaan 2020. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewListing;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
import RefundPolicyInfographic from "../../images/refund-policy.png";
const PaymentPolicy = () => {
  return (
    <>
    <NavBar/>
      <div id="wrapper">
        {/* Header Container
================================================== */}
        <div className="clearfix" />
        {/* Header Container / End */}
        {/* Titlebar
================================================== */}
        <div id="titlebar" className="solid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Payment Policy</h2>
                <nav
                  id="breadcrumbs"
                //   xmlns:v="http://rdf.data-vocabulary.org/#"
                >
                  <ul>
                    {/* Breadcrumb NavXT 6.6.0 */}
                    <li className="home">
                      <span property="itemListElement" typeof="ListItem">
                        <a
                          property="item"
                          typeof="WebPage"
                          title="Go to Mazebaan."
                          href="/"
                          className="home"
                        >
                          <span property="name">Mazebaan</span>
                        </a>
                        <meta property="position" content={1} />
                      </span>
                    </li>
                    <li className="post post-page current-item">
                      <span property="itemListElement" typeof="ListItem">
                        <span
                          property="name"
                          className="post post-page current-item"
                        >
                          Payment Policy
                        </span>
                        <meta
                          property="url"
                          content="/payment-policy"
                        />
                        <meta property="position" content={2} />
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="container full-width">
          <div className="row">
            <article
              id="post-396"
              className="col-md-12 page-container-col post-396 page type-page status-publish hentry"
            >
              <div className="vc_row wpb_row vc_row-fluid">
                <div className="wpb_column vc_column_container vc_col-sm-12">
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>Thank you for using Mazebaan.</p>
                          <p>
                            The Payment Policy pertains to the user and Mazebaan
                            relating to one’s access and use of Mazebaan.com.
                          </p>
                          <p>
                            If any provision of the Payment Policy is held to be
                            invalid or unenforceable that provision will alone
                            be struck with no effect on the enforceability of
                            the remaining provisions.
                          </p>
                          <p>
                            These terms are intended to the parties to the
                            agreement only with no partnership or any joint
                            venture arising out of the agreement between the
                            parties.
                          </p>
                          <p>
                            When the Payment Policy uses words like&nbsp;
                            “Mazebaan”, “we” or “our” it refers to the Mazebaan
                            company.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              About and Modifications
                            </span>
                          </h1>
                          <p>
                            Mazebaan provides payments services to its users
                            which includes collection, payments and payouts
                            through Mazebaan.com with relation to the free
                            listings on the website.
                          </p>
                          <p>
                            Mazebaan offers multiple easy and secure payment
                            gateways to its customers to ensure secure money
                            transfers to the hosts by the guests
                            therefore,&nbsp; the users are advised to always pay
                            through Mazebaan and refrain from wiring the money
                            or paying someone directly outside Mazebaan in any
                            manner or by any means outside Mazebaan.
                          </p>
                          <p>
                            Mazebaan may at times restrict the availability of
                            the payment services in order to carry out
                            maintenance measures to ensure the proper
                            functioning of the payment services or to improve
                            the services as Mazebaan may modify them from time
                            to time.
                          </p>
                          <p>
                            The use of certain payments members may require the
                            user to accept some additional terms and conditions.
                          </p>
                          <p>
                            Mazebaan reserves the rights to make inquiries to
                            help verify the identity or prevent fraudulent
                            cases.
                          </p>
                          <p>
                            Mazebaan reserves all&nbsp; the right to modify the
                            Privacy Policy.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Account Registration
                            </span>
                          </h1>
                          <p>
                            In order to use the payment services, the users must
                            have an account on Mazebaan.com which if closed for
                            any reason will restrict the user from these
                            services.
                          </p>
                          <p>
                            Users need to provide accurate and complete
                            information during the registration process and keep
                            their information up-to-date in their profiles.
                          </p>
                          <p>
                            The users should not register more than one account
                            on Mazebaan.com unless authorized to do so by
                            Mazebaan.
                          </p>
                          <p>
                            The users should refrain from assigning their
                            account to another party since the users are
                            responsible for maintaining the confidentiality and
                            security of their account credentials.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Payment Methods
                            </span>
                          </h1>
                          <p>
                            When the users add the payment method on
                            Mazebaan.com, they will&nbsp; have to provide the
                            required billing information like the name, billing
                            address, and etc.
                          </p>
                          <p>
                            The users must provide accurate and complete
                            information while adding the payment method and are
                            obliged to keep the payment method and the details
                            updated. The information required for the payment
                            method may include the residential address, name and
                            type of the account, account number, email address,
                            ID number and other account information associated
                            with the payment method.
                          </p>
                          <p>
                            The users are solely responsible for the
                            authenticity and completeness of&nbsp; the payment
                            method and the associated information.
                          </p>
                          <p>
                            Mazebaan has the right&nbsp; to store the payment
                            method information of the users.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Terms for Hosts
                            </span>
                          </h1>
                          <p>
                            Mazebaan will collect the total fees from the guests
                            when the booking request is accepted by the host.
                          </p>
                          <p>
                            In order to receive the payments the users must have
                            a valid payment linked with Mazebaan.com as Mazebaan
                            will initiate the user transactions process to that
                            particular account after 24 hours of the guest’s
                            scheduled check-in time.
                          </p>
                          <p>
                            The hosts will receive the payments within&nbsp; 1
                            to 5 working days after 24 hours of the guest’s
                            scheduled check-in time.
                          </p>
                          <p>
                            The time it takes to receive payments once released
                            by&nbsp; Mazebaan can&nbsp; depend on the payout
                            method selected by the user.
                          </p>
                          <p>
                            The payments received by the hosts for a booking
                            will be the listing fee minus the host Fees and
                            taxes.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Terms for Guests
                            </span>
                          </h1>
                          <p>
                            The users of Mazebaan authorise the latter&nbsp; to
                            charge the user the total fees for the booking
                            requested through Mazebaan.com.
                          </p>
                          <p>
                            Mazebaan offers multiple options for the&nbsp;
                            manner of payment to its guests for the ease of
                            users. Different&nbsp; terms and conditions along
                            with some perks may apply for the use of one payment
                            option over the other.
                          </p>
                          <p>
                            The guests will receive a confirmation email
                            after&nbsp; the payment transaction for the&nbsp;
                            requested booking is successfully completed.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>
                              Prohibited Activities
                            </span>
                          </h1>
                          <p>
                            The users are solely responsible for the compliance
                            with&nbsp; all the rules and regulations that apply
                            to the use of Mazebaan.com.
                          </p>
                          <p>
                            The users of Mazebaan.com are not allowed to use the
                            payment methods provided by Mazebaan for any other
                            commercial purposes, take actions that hinder the
                            performance of the payment methods, and violate the
                            rights of other users by any means.
                          </p>
                          <p>
                            Mazebaan has all the rights to deny the access of
                            any users violating the terms of Payment Policy.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>Liabilities</span>
                          </h1>
                          <p>
                            When the users use the payment services provided by
                            Mazebaan the are at their own sole risk&nbsp;
                            without any kind of warranty from Mazebaan.
                          </p>
                          <p>
                            The guests should refrain from paying outside
                            Mazebaan as it may also put the customer bookings at
                            risk.
                          </p>
                          <p>
                            In case of any unethical text by any of the users to
                            the other, not only but especially regarding to
                            “Paying Outside Mazebaan”, the user should be
                            reported to Mazebaan by the other in order to
                            prevent any financial or ethical mishap. Mazebaan
                            reserves the rights to suspend the listings or
                            bookings of the user in any such case.
                          </p>
                          <p>
                            Mazebaan has the right to&nbsp; conduct identity
                            verification on any of the members, to the extent
                            permitted by the law.
                          </p>
                          <p>
                            The users&nbsp; agree that any risk arising out of
                            their use of the payment services remains with the
                            users only.
                          </p>
                          <p>
                            Mazebaan will not be liable for any damages,lost
                            profits, loss of data, service, interruption, system
                            or for any damages for any injury as a result of the
                            Payments Policy.
                          </p>
                          <p>
                            If Mazebaan is unable to collect any amounts owed to
                            it by the users under the Payment Policy, Mazebaan
                            has the right to engage in collection activities and
                            efforts to recover the payments.
                          </p>
                          <p>
                            The users agree that all communication related to
                            the payments owed will be made by Email or phone, as
                            provided on Mazebaan.com.
                          </p>
                          <p>&nbsp;</p>
                          <h1>
                            <span style={{ fontWeight: 400 }}>Feedback</span>
                          </h1>
                          <p>
                            Mazebaan welcomes the feedback from its users for
                            improvements on Mazebaan.com. The feedback of the
                            users will be non-confidential and non-proprietary
                            and Mazebaan will have the right to the materials
                            without any compensation.
                          </p>
                        </div>
                      </div>
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <h1>Refund Policy</h1>
                          <p>&nbsp;</p>
                          <p>
                            <img
                              loading="lazy"
                              className="alignleft wp-image-514 size-large"
                              src={RefundPolicyInfographic}
                              alt=""
                              width={748}
                              height={1024}
                              srcSet="https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-748x1024.png 748w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-600x822.png 600w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-219x300.png 219w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-768x1052.png 768w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-1122x1536.png 1122w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-1495x2048.png 1495w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-431x590.png 431w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-489x670.png 489w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-577x790.png 577w, https://mazebaan.com/wp-content/uploads/2020/05/refund-policy-1-1200x1643.png 1200w"
                              sizes="(max-width: 748px) 100vw, 748px"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
        <div className="clearfix" />
        <div className="margin-top-55" />
        {/* Back To Top Button */}
        <div id="backtotop" className>
          <a href="#" />
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default PaymentPolicy;

import Footer from "../../Components/Main/Footer/Footer";
import NavBar from "../../Components/Main/NavigationBar/NavBar";

const PageNotFound = () => {
  return (
    <>
      <NavBar/>
      <div id="wrapper">
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>404 Not Found</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>404 Not Found</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Content
          ================================================== */}
        {/* Container */}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <section id="not-found" className="center">
                <h2>
                  404 <i className="fa fa-question-circle" />
                </h2>
                <p>
                  We're sorry, but the page you were looking for doesn't exist.
                </p>
                {/* Search */}
                <div className="row">
                  <div className="col-lg-8 col-lg-offset-2">
                    <div className="main-search-input gray-style margin-top-50 margin-bottom-10">
                      <div className="main-search-input-item">
                        <input
                          type="text"
                          placeholder="What are you looking for?"
                          defaultValue
                        />
                      </div>
                      <button className="button">Search</button>
                    </div>
                  </div>
                </div>
                {/* Search Section / End */}
              </section>
            </div>
          </div>
        </div>
        {/* Container / End */}
        {/* Footer
          ================================================== */}
        <Footer/>
        {/* Footer / End */}
        {/* Back To Top Button */}
        <div id="backtotop">
          <a href="#" />
        </div>
      </div>
      {/* Wrapper / End */}
      {/* Scripts
          ================================================== */}
      {/* Style Switcher
          ================================================== */}
      <div id="style-switcher">
        <h2>
          Color Switcher{" "}
          <a href="#">
            <i className="sl sl-icon-settings" />
          </a>
        </h2>
        <div>
          <ul className="colors" id="color1">
            <li>
              <a href="#" className="main" title="Main" />
            </li>
            <li>
              <a href="#" className="blue" title="Blue" />
            </li>
            <li>
              <a href="#" className="green" title="Green" />
            </li>
            <li>
              <a href="#" className="orange" title="Orange" />
            </li>
            <li>
              <a href="#" className="navy" title="Navy" />
            </li>
            <li>
              <a href="#" className="yellow" title="Yellow" />
            </li>
            <li>
              <a href="#" className="peach" title="Peach" />
            </li>
            <li>
              <a href="#" className="beige" title="Beige" />
            </li>
            <li>
              <a href="#" className="purple" title="Purple" />
            </li>
            <li>
              <a href="#" className="celadon" title="Celadon" />
            </li>
            <li>
              <a href="#" className="red" title="Red" />
            </li>
            <li>
              <a href="#" className="brown" title="Brown" />
            </li>
            <li>
              <a href="#" className="cherry" title="Cherry" />
            </li>
            <li>
              <a href="#" className="cyan" title="Cyan" />
            </li>
            <li>
              <a href="#" className="gray" title="Gray" />
            </li>
            <li>
              <a href="#" className="olive" title="Olive" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;

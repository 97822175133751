import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import { useEffect, useState } from "react";
import {
  AllChatEndPoint,
  ChatDeleteEndPoint,
  ChatSendMessageEndPoint,
  ChatDetailEndPoint,
} from "../../Components/Axios/EndPoints";
import { get } from "../../Components/Axios/get";
import { post } from "../../Components/Axios/post";
import io from "socket.io-client";
import { SOCKET_URL } from "../../Components/Socket/URLtoListen";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { object } from "prop-types";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import {NUMBERS} from "../../Components/constants/DetailValidation";
import redirectToLogin from "../../utility/redirectToLogin";

let Numb = NUMBERS;

const socket = io.connect(SOCKET_URL);

const MessageConversations = (props) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [Message, setMessage] = useState({ message: "", type: "" });
  const [Chat, setChat] = useState([]); // stores object of messages
  const [ErrorMessage, setErrorMessage] = useState(false);

  const [ChatDetail, setChatDetail] = useState(null);
  const [Loading, setLoading] = useState(true);
  const [ActiveConversation, setActiveConversation] = useState(true);

  const [PropsAllChat, setPropsAllChat] = useState(
    location.state == null ? null : location.state.AllChat
  );
  const [PropsAllChatCount, setPropsAllChatCount] = useState(
    location.state == null ? 0 : location.state.AllChatCount
  );

  // if (params.id == PropsAllChat[0].uid) {
  //   console.log("props all chat = ", PropsAllChat[0].message_id);
  // }

  useEffect(() => {
    redirectToLogin(props);
    GetChat();
    let messageID = "";

    Object.values(PropsAllChat).map((value) =>
      value.uid == params.id ? (messageID = value.message_id) : ""
    );
    console.log("messageiD =", messageID);
    console.log("location.path =", location.pathname);
    console.log("params =", params);
    socket.on(messageID, ({ message, type }) => {
      console.log("messageID in SocketON =", messageID);
      console.log("message in setting chat = ", { message, type });
      // console.log('')
      setChat([...Chat, { message, type }]);
    });
  }, [params.id]);

  const NavigateToCoversations = (uid) => {
    // console.log('in nav method')
    setChat([]);
    window.scrollTo(0, 0);
    navigate("/dashboard-messages-conversations/" + uid, {
      state: {
        AllChat: PropsAllChat,
        AllChatCount: PropsAllChatCount,
      },
    });
  };

  const DeleteConversation = () => {
    let messageID = "";

    Object.values(PropsAllChat).map((value) =>
      value.uid == params.id ? (messageID = value.message_id) : ""
    );

    post(
      ChatDeleteEndPoint + messageID,
      "LOCAL",
      null,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          alert(res.data.message);
          window.location.href = "/dashboard-messages";
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetChat = () => {
    let messageID = "";

    Object.values(PropsAllChat).map((value) =>
      value.uid == params.id ? (messageID = value.message_id) : ""
    );
    // console.log("endpoint = ", messageID);

    get(
      ChatDetailEndPoint + messageID,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        console.log("res =", res.data);
        if (res.data.status === 200) {
          setChatDetail(res.data.response);
          setLoading(false);
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          console.log("false response");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const RenderChat = () => {
    return Chat.map(({ message, type }, index) => (
      <div
        className={type == "sender" ? "message-bubble me" : "message-bubble"}
        key={index}
      >
        {console.log("RenderChat Message =", message)}
        <div className="message-avatar">
          <img
            src={
              type == "sender"
                ? localStorage.getItem("avatar")
                : "http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
            }
            alt=""
          />
        </div>
        <div className="message-text">
          <p>
            {message == undefined ? "undefinded message from server" : message}
          </p>
        </div>
      </div>
    ));
  };

  const RenderChat2 = () => {
    return (
      <div className="message-bubble me">
        <div className="message-avatar">
          <img
            src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
            alt=""
          />
        </div>
        <div className="message-text">
          <p>message</p>
        </div>
      </div>
    );
  };

  const test = () => {
    RenderChat2();
  };

  // console.log("e = ", PropsAllChat);

  const OnSubmitMessage = (e) => {
    e.preventDefault();
    const { message, type } = Message;
    // console.log("e = ", e);

    let VendorID = "";
    for (let i = 0; i < PropsAllChatCount; i++) {
      if (params.id == PropsAllChat[i].uid) {
        VendorID = PropsAllChat[i].message_id;
      }
    }

    if (message != "") {
      let data = {
        message: message,
        // message_id: "message_" + localStorage.getItem("id") + "_" + VendorID,
        message_id: VendorID,
      };

      post(
        ChatSendMessageEndPoint,
        "LOCAL",
        data,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            // alert(res.data.message);
            let messageID = "";
            Object.values(PropsAllChat).map((value) =>
              value.uid == params.id ? (messageID = value.message_id) : ""
            );
            socket.emit(messageID, JSON.stringify({ message, type }));
            // console.log("message = ", { message, type });
            setMessage({ message: "", type: "" });
          } else if (res.data.status == 401) {
            HandleTokenExpired();
          } else {
            alert(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Please Enter Your Message");
    }
  };

  const ValidateMessage = () => {
    let message = Message.message;
    let counter = 0;
    let mess;

    for (let i = 0; i < message.length; i++) {
      mess = message[i];
      if (!isNaN(mess)) {
        counter++;
      } else if (isNaN(mess)) {
        counter = 0;
      }
    }

    let arr = [];
    Numb.map((numberCheck) => {
      if (Message.message.includes(numberCheck)) {
        arr.push(true);
      }
    });

    if (arr.length > 0 || counter > 4) {
      alert("You Cannot Enter Mobile Number in Message");
      setMessage({ message: "" });
    }
  };

  if (Loading) {
    return (
      <>
        <div className="loading" />
      </>
    );
  }

  return PropsAllChatCount > 0 ? (
    <>
      <NavBar />
      <div id="dashboard">
        {/* Navigation
    	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-messages"} />
        {/* Navigation / End */}
        {/* Content
    	================================================== */}
        {/* Content
	    ================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Messages</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-messages">Messages</a>
                    </li>
                    <li>
                      <a href="dashboard-messages-conversations">
                        Conversations
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="messages-container margin-top-0">
                <div className="messages-headline">
                  <h4>
                    {Object.values(PropsAllChat).map((value) => (
                      <>{value.uid == params.id ? value.message_to.name : ""}</>
                    ))}
                  </h4>
                  <a
                    onClick={DeleteConversation}
                    style={{ cursor: "pointer" }}
                    className="message-action"
                  >
                    <i className="sl sl-icon-trash" /> Delete Conversation
                  </a>
                </div>
                <div className="messages-container-inner">
                  {/* Messages */}
                  <div className="messages-inbox">
                    <ul>
                      {PropsAllChatCount > 0
                        ? Object.values(PropsAllChat).map((value) => (
                            <li>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  NavigateToCoversations(value.uid)
                                }
                              >
                                <div className="message-avatar">
                                  <img src={value.message_to.avatar} alt="" />
                                </div>
                                <div className="message-by">
                                  <div className="message-by-headline">
                                    <h5
                                      className={
                                        value.uid == params.id
                                          ? "activeConversation"
                                          : ""
                                      }
                                    >
                                      {value.message_to.name}
                                    </h5>
                                    <span
                                      className={
                                        value.uid == params.id
                                          ? "activeConversation"
                                          : ""
                                      }
                                    >
                                      {moment
                                        .utc(value.last_message_time)
                                        .local()
                                        .startOf("seconds")
                                        .fromNow()}
                                    </span>
                                  </div>
                                  <p
                                    className={
                                      value.uid == params.id
                                        ? "activeConversation"
                                        : ""
                                    }
                                  >
                                    {value.title}
                                  </p>
                                </div>
                              </a>
                            </li>
                          ))
                        : ""}
                      {/* <li>
                        <a href="/dashboard-messages-conversations">
                          <div className="message-avatar">
                            <img
                              src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                              alt=""
                            />
                          </div>
                          <div className="message-by">
                            <div className="message-by-headline">
                              <h5>Thomas Smith</h5>
                              <span>16.06.2019</span>
                            </div>
                            <p>
                              Hello, I want to talk about your great listing!
                              Morbi velit eros, sagittis in facilisis non,
                              rhoncus et erat. Nam posuere tristique sem, eu
                              ultricies tortor lacinia neque imperdiet vitae...
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/dashboard-messages-conversations">
                          <div className="message-avatar">
                            <img
                              src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                              alt=""
                            />
                          </div>
                          <div className="message-by">
                            <div className="message-by-headline">
                              <h5>Robert Baker</h5>
                              <span>17.05.2019</span>
                            </div>
                            <p>
                              Hello, I want to talk about your great listing!
                              Morbi velit eros, sagittis in facilisis non,
                              rhoncus et erat. Nam posuere tristique sem, eu
                              ultricies tortor lacinia neque imperdiet vitae...
                            </p>
                          </div>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  {/* Messages / End */}
                  {/* Message Content */}
                  <div className="message-content">
                    {Object.values(ChatDetail).map((value) => (
                      <div
                        className={
                          value.type == "sender"
                            ? "message-bubble me"
                            : "message-bubble"
                        }
                      >
                        {/* {console.log('value =', value)} */}
                        <div className="message-avatar">
                          <img
                            src={
                              value.message_from.avatar == ""
                                ? "http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                                : value.message_from.avatar
                            }
                            // src={value.message_from.avatar}
                            alt=""
                          />
                        </div>
                        <div className="message-text">
                          <p>{value.message}</p>
                        </div>
                      </div>
                    ))}
                    {/* <div className="message-bubble me">
                      <div className="message-avatar">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                          alt=""
                        />
                      </div>
                      <div className="message-text">
                        <p>Data I send</p>
                      </div>
                    </div> */}
                    {/* <div className="message-bubble">
                      <div className="message-avatar">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                          alt=""
                        />
                      </div>
                      <div className="message-text">
                        <p>
                          Donec eget consequat magna. Integer luctus neque vel
                          nulla malesuada imperdiet.{" "}
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="message-bubble me">
                      <div className="message-avatar">
                        <img src="images/dashboard-avatar.jpg" alt="" />
                      </div>
                      <div className="message-text">
                        <p>
                          Accumsan et porta ac, volutpat id ligula. Donec neque
                          neque, blandit eu pharetra in, tristique id enim nulla
                          magna interdum leo, sed tincidunt purus elit vitae
                          magna. Donec eget consequat magna. Integer luctus
                          neque vel nulla malesuada imperdiet. .
                        </p>
                      </div>
                    </div> */}
                    {/* <div className="message-bubble">
                      <div className="message-avatar">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                          alt=""
                        />
                      </div>
                      <div className="message-text">
                        <p>
                          Nulla eget erat consequat quam feugiat dapibus eget
                          sed mauris.
                        </p>
                      </div>
                    </div> */}
                    {RenderChat()}
                    {/* Reply Area */}
                    <div className="clearfix" />
                    <div className="message-reply">
                      <textarea
                        cols={40}
                        rows={3}
                        placeholder="Your Message"
                        value={Message.message}
                        onChange={(e) =>
                          setMessage({ message: e.target.value })
                        }
                        onKeyUp={ValidateMessage}
                      />
                      {/* {console.log('message =', Message.message)} */}
                      <button onClick={OnSubmitMessage} className="button">
                        Send Message
                      </button>
                    </div>
                  </div>
                  {/* Message Content */}
                </div>
              </div>
            </div>
            {/* Copyrights */}
            <div className="col-md-12">
              <div className="copyrights">
                © 2021 Mazebaan. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <>
      <NavBar />
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-messages"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Messages</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-messages">Messages</a>
                    </li>
                    <li>
                      <a href="dashboard-messages-conversations">
                        Conversations
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Listings */}
            <div className="col-lg-12 col-md-12">
              <div className="messages-container margin-top-0">
                <div className="messages-headline">
                  <h4>Kathy Brown</h4>
                  <a
                    onClick={DeleteConversation}
                    style={{ cursor: "pointer" }}
                    className="message-action"
                  >
                    <i className="sl sl-icon-trash" /> Delete Conversation
                  </a>
                </div>
                <div className="messages-container-inner">
                  {/* Messages */}
                  <div className="messages-inbox">
                    <ul>
                      {PropsAllChatCount > 0 ? (
                        Object.values(PropsAllChat).map((value) => (
                          <li>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => NavigateToCoversations(value.uid)}
                            >
                              <div className="message-avatar">
                                <img src={value.message_to.avatar} alt="" />
                              </div>
                              <div className="message-by">
                                <div className="message-by-headline">
                                  <h5>{value.message_to.name}</h5>
                                  <span>
                                    {moment
                                      .utc(value.last_message_time)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </span>
                                </div>
                                <p>{value.title}</p>
                              </div>
                            </a>
                          </li>
                        ))
                      ) : (
                        <>
                          <li>No Chats Found!</li>
                        </>
                      )}
                      {/* <li>
                        <a href="/dashboard-messages-conversations">
                          <div className="message-avatar">
                            <img
                              src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                              alt=""
                            />
                          </div>
                          <div className="message-by">
                            <div className="message-by-headline">
                              <h5>Thomas Smith</h5>
                              <span>16.06.2019</span>
                            </div>
                            <p>
                              Hello, I want to talk about your great listing!
                              Morbi velit eros, sagittis in facilisis non,
                              rhoncus et erat. Nam posuere tristique sem, eu
                              ultricies tortor lacinia neque imperdiet vitae...
                            </p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/dashboard-messages-conversations">
                          <div className="message-avatar">
                            <img
                              src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                              alt=""
                            />
                          </div>
                          <div className="message-by">
                            <div className="message-by-headline">
                              <h5>Robert Baker</h5>
                              <span>17.05.2019</span>
                            </div>
                            <p>
                              Hello, I want to talk about your great listing!
                              Morbi velit eros, sagittis in facilisis non,
                              rhoncus et erat. Nam posuere tristique sem, eu
                              ultricies tortor lacinia neque imperdiet vitae...
                            </p>
                          </div>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  {/* Messages / End */}
                  {/* Message Content */}
                  {/* <div className="message-content">
                    <div className="message-bubble">
                      <div className="message-avatar">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                          alt=""
                        />
                      </div>
                      <div className="message-text">
                        <p>Recived Data</p>
                      </div>
                    </div>
                    <div className="message-bubble me">
                      <div className="message-avatar">
                        <img
                          src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                          alt=""
                        />
                      </div>
                      <div className="message-text">
                        <p>Data I send</p>
                      </div>
                    </div>
                    {RenderChat()}
                    <div className="clearfix" />
                    <div className="message-reply">
                      <textarea
                        cols={40}
                        rows={3}
                        placeholder="Your Message"
                        value={Message.message}
                        onChange={(e) =>
                          setMessage({ message: e.target.value })
                        }
                      />
                      <button onClick={OnSubmitMessage} className="button">
                        Send Message
                      </button>
                    </div>
                  </div> */}
                  {/* Message Content */}
                </div>
              </div>
            </div>
            {/* Copyrights */}
            <div className="col-md-12">
              <div className="copyrights">
                © 2021 Mazebaan. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  );
};

export default MessageConversations;


import Homepage from "./Pages/Homepage/Homepage";
import AboutUs from "./Pages/AboutUs/AboutUs";
import BecomeAHost from "./Pages/BecomeAHost/BecomeAHost";
import Blog from "./Pages/Blog/Blog";
import ContactUs from "./Pages/ContactUs/ContactUs";
import SignUp from "./Pages/SignUp/SignUp";
import Login from "./Pages/Login/Login";
import PageNotFound from "./Pages/404/404";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Messages from "./Pages/Dashboard/Messages";
import MessageConversations from "./Pages/Dashboard/MessageConversations";
import Bookings from "./Pages/Dashboard/Bookings";
import Wallet from "./Pages/Dashboard/Wallet";
import MyListing from "./Pages/Dashboard/MyListing";
import AddListing from "./Pages/Dashboard/AddListings";
import Reviews from "./Pages/Dashboard/Reviews";
import Bookmarks from "./Pages/Dashboard/Bookmarks";
import MyProfile from "./Pages/Dashboard/MyProfile";
import Listing from "./Pages/ListingPage/Listing";
import ListingDetail from "./Pages/ListingDetail/ListingDetail";
import Booking from "./Pages/Booking/Booking";
import ClaimThisListing from "./Pages/ListingDetail/ClaimThisListing";
import PaymentPolicy from "./Pages/StaticPages/PaymentPolicy";
import PrivacyPolicy from "./Pages/StaticPages/PrivacyPolicy";
import TermsandCondition from "./Pages/StaticPages/Terms&Conditions";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BookingRequests from "./Pages/Dashboard/BookingRequests";
import BlogDetail from "./Pages/BlogDetail/BlogDetail";
import {id} from "react-date-range/dist/locale";
import {JazzCashReturn} from "./Pages/StaticPages/JazzCashReturn";
import {ResetPassword} from "./Pages/ResetPassword/ResetPassword";
import {ToastContainer} from "react-toastify";
import {useState} from "react";


const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  return (

    <Router>
      <ToastContainer/>
      <div>
        <Routes>
          <Route exact path="/" element={<Homepage/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/signup" element={<SignUp/>}/>
          <Route exact path="/about-us" element={<AboutUs/>}/>
          <Route exact path="/become-a-host" element={<BecomeAHost/>}/>
          <Route exact path="/blog" element={<Blog/>}/>
          <Route exact path="/blog-detail/:id" element={<BlogDetail/>}/>
          <Route exact path="/contact-us" element={<ContactUs/>}/>
          <Route exact path="/listing" element={<Listing/>}/>
          <Route exact path="/listings" element={<Listing/>}/>
          <Route exact path="/listing-detail/:id" element={<ListingDetail />}/>
          <Route exact path="/listing-detail/claim-this-listing/:id" element={<ClaimThisListing />}/>
          <Route exact path="/listing-detail/booking/:id" element={<Booking token={token} />}/>
          {/* <Route exact path="/booking" element={<Booking/>}/> */}
          <Route exact path="/dashboard" element={<Dashboard token={token}/>}/>
          <Route exact path="/dashboard-messages" element={<Messages token={token} />}/>
          <Route exact path="/dashboard-messages-conversations/:id" element={<MessageConversations token={token} />}/>
          <Route exact path="/dashboard-bookings" element={<Bookings  token={token} />}/>
          <Route exact path="/dashboard-booking-requests-pending" element={<BookingRequests token={token} />}/>
          <Route exact path="/dashboard-booking-requests-approved" element={<BookingRequests token={token}/>}/>
          <Route exact path="/dashboard-booking-requests-cancelled" element={<BookingRequests token={token}/>}/>
          <Route exact path="/dashboard-wallet" element={<Wallet token={token} />}/>
          <Route exact path="/dashboard-my-listings" element={<MyListing token={token}/>}/>
          <Route exact path="/dashboard-reviews" element={<Reviews token={token}/>}/>
          <Route exact path="/dashboard-bookmarks" element={<Bookmarks token={token}/>}/>
          <Route exact path="/dashboard-add-listing" element={<AddListing token={token} />}/>
          <Route exact path="/dashboard-my-profile" element={<MyProfile token={token}/>}/>
          <Route exact path="/terms-conditions" element={<TermsandCondition />}/>
          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route exact path="/payment-policy" element={<PaymentPolicy/>}/>
          <Route exact path="/jazzcash-return/:code/:message" element={<JazzCashReturn/>}/>
          <Route exact path="/reset-password/:email" element={<ResetPassword token={token}/>}/>
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import {post} from "../Components/Axios/post";
import {BlogSearchEndPoint, UserProfileEndPoint} from "../Components/Axios/EndPoints";

const redirectToLogin = (props) =>{
    const {token} = props;

    const checkExpireToken = async () =>
    {
      await  post(
            UserProfileEndPoint,
            "LOCAL",
            "",
            true,
            localStorage.getItem("token")
        )
            .then((res) => {
                console.log("response from search =", res.data);
                if (res.data.status === 401) {
                    localStorage.setItem("tokenExpired", "true");
                    window.location.replace( "/login");
                }
                else {
                    localStorage.setItem("tokenExpired", "false");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    if (token === undefined || token === null || localStorage.getItem("tokenExpired") === null || localStorage.getItem("tokenExpired") === "true")
    {
        window.location.replace( "/login");
    }

}
export default redirectToLogin;
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBed, faHistory, faLaptop, faLock, faMoneyBillWave, faNetworkWired, faNewspaper, faShieldAlt, faUserCheck, faUserCog, faUserShield, faWallet,} from "@fortawesome/free-solid-svg-icons";

import Footer from "../../Components/Main/Footer/Footer";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import "./AboutUs.css";

const AboutUs = () => {
    return (
        <div>
            <NavBar/>
            <div>
                <div className="clearfix"/>
                {/* Header Container / End */}
                {/* Titlebar================================================== */}
                <div id="titlebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>About Us</h2>
                                {/* Breadcrumbs */}
                                <nav id="breadcrumbs">
                                    <ul>
                                        <li>
                                            <a href="/">Home</a>
                                        </li>
                                        <li>About Us</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Container */}
                <div className="container">
                    <div className="row">
                        <p>
                            Pakistan’s 1st public steered online lodging company offering a
                            temporary residence, ranging from rooms, shared houses, flats,
                            cottages, apartments, and holiday homes to hotels and 5-star
                            luxury resorts, to travelers and tourists around the country. With
                            this digitized technology, Mezban aims to smoothen the
                            interactions between the hosts and the travelers and tourists
                            looking for a temporary residence for their stay in Pakistan.
                            Offering multiple options to the guests and hosts by providing
                            them with various categories of residences, multiple easy payment
                            options, user verification, secure payment gateways, and much
                            more, Mezban comforts the users with safe and secure bookings.
                        </p>
                        {/* Container / End */}
                    </div>
                    {/* Wrapper / End */}
                </div>
            </div>
            <div className={'container-fluid'}>
                <div className="row">
                    <div className="col-md-12 why-mazebaan">
                        <div className={'col-md-2 text-yellow'}>.</div>
                        <div className={'col-md-6'}>
                            <h2 className="text-white">Why Mazebaan ?</h2>
                        </div>
                        <div className={'col-md-3'}/>
                    </div>
                </div>
                <section
                    className="fullwidth padding-top-75 padding-bottom-70"
                    data-background-color="#f9f9f9"
                >
                    <div className="container">
                        <div className="row icons-container">
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faLaptop} className={'text-white'}/>
                                    </i>
                                    <h3>Digitalized Bookings</h3>
                                    <p>
                                        No Need to Stand in Ques Free of Cost Reservation No Hidden
                                        Charges{" "}
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faBed} className={'text-white'}/>
                                    </i>
                                    <h3>Multiple Residence Types</h3>
                                    <p>Rooms, Hotels, Apartments, Houses, Flats, Cottages etc. </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faMoneyBillWave} className={'text-white'}/>
                                    </i>
                                    <h3>Easy Payment Options</h3>
                                    <p>
                                        EasyPaisa (Voucher and Wallet) JazzCash (Voucher and Account)
                                        Debit or Credit Card{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row icons-container">
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faLock} className={'text-white'}/>
                                    </i>{" "}
                                    <h3>Easy, Safe and Secure</h3>
                                    <p>
                                        Sign-up and Sign-in Bookings and Payments Management and
                                        Handling{" "}
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faUserCheck} className={'text-white'}/>
                                    </i>{" "}
                                    <h3>Host Verification</h3>
                                    <p>
                                        Reviews from Past Trips Message Before Confirming the Booking{" "}
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faHistory} className={'text-white'}/>
                                    </i>{" "}
                                    <h3>Save Time</h3>
                                    <p>Looking for Stays At the Receptions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="row">
                    <div className="col-md-12 why-mazebaan">
                        <div className={'col-md-2 text-yellow'}>.</div>
                        <div className={'col-md-6'}>
                            <h2 className="text-white">Become a Host</h2>
                        </div>
                        <div className={'col-md-3'}/>
                    </div>
                </div>
                <section
                    className="fullwidth padding-top-75 padding-bottom-70"
                    data-background-color="#f9f9f9"
                >
                    <div className="container">
                        <div className="row icons-container">
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faWallet} className={'text-white'}/>
                                    </i>
                                    <h3>Earn Upto PKR 110,000/room (per month)</h3>
                                    <p>
                                        Expand Your Audience Boost up Booking Rate Quick and Secure
                                        Payment Methods
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faNewspaper} className={'text-white'}/>
                                    </i>
                                    <h3>Post your Ads for Free</h3>
                                    <p>
                                        No Sign-up Charges No Listing Fee Free Marketing Financial
                                        Security
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faUserShield} className={'text-white'}/>
                                    </i>
                                    <h3>Customer Verification</h3>
                                    <p>
                                        ID cards Legal Documents Reviews from Past Trips Message
                                        Before Confirming the Booking{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row icons-container">
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faUserCog} className={'text-white'}/>
                                    </i>{" "}
                                    <h3>Complete User Control</h3>
                                    <p>
                                        Availability Check-in and Check-out Timing Prices Requirements
                                        for guests House rules
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2 with-line">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faShieldAlt} className={'text-white'}/>
                                    </i>{" "}
                                    <h3>Easy, Safe and Secure</h3>
                                    <p>
                                        Sign-up and Sign-in Management and Handling Posting and
                                        Hosting
                                    </p>
                                </div>
                            </div>
                            {/* Stage */}
                            <div className="col-md-4">
                                <div className="icon-box-2">
                                    <i className="mazebaan-icon">
                                        <FontAwesomeIcon icon={faNetworkWired} className={'text-white'}/>
                                    </i>{" "}
                                    <h3>Safe and Secure Transactions</h3>
                                    <p>Online Payments Multiple Payment Gateways</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* Footer================================================== */}
            <Footer/>
            {/* Back To Top Button */}
            <div id="backtotop">
                <a href="#"/>
            </div>
        </div>
    );
};

export default AboutUs;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import React, { useEffect } from "react";
import { RegEmail, RegPhone } from "../../Components/Regex/Regex";
import { post } from "../../Components/Axios/post";
import { get } from "../../Components/Axios/get";
import {
  DashboardMyProfileEndPoint,
  UpdatePasswordEndPoint,
  UpdateProfileEndPoint,
} from "../../Components/Axios/EndPoints";
import InputMask from "react-input-mask";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import redirectToLogin from "../../utility/redirectToLogin";

const MyProfile = (props) => {
  const [ListingImage, setListingImage] = React.useState(null);

  const [MyProfile, setMyProfile] = React.useState(null);
  const [Loading, setLoading] = React.useState(false);

  const [EmailError, setEmailError] = React.useState(false);
  const [PhoneError, setPhoneError] = React.useState(false);
  const [WhatsAppError, setWhatsAppError] = React.useState(false);
  const [PasswordError, setPasswordError] = React.useState(false);

  const [FirstName, SetFirstName] = React.useState(MyProfile == null ? "" : MyProfile.first_name.toString());
  const [LastName, SetLastName] = React.useState(MyProfile == null ? "" : MyProfile.last_name.toString());
  const [Email, SetEmail] = React.useState(MyProfile == null ? "" : MyProfile.email.toString());
  const [Phone, SetPhone] = React.useState(MyProfile == null ? "" : MyProfile.phone_number);
  const [WhatsApp, SetWhatsApp] = React.useState(MyProfile == null ? "" : MyProfile.whats_app);
  const [AboutMe, SetAboutMe] = React.useState(MyProfile == null ? "" : MyProfile.description.toString());
  const [Image, setImage] = React.useState(null);

  const [OldPassword, setOldPassword] = React.useState("");
  const [NewPassword, SetNewPassword] = React.useState("");
  const [NewPasswordReEnter, SetNewPasswordReEnter] = React.useState("");

  const handleChangePhone = (e) =>{
    if(e.target.value.match(RegPhone)!=null) {
      SetPhone(e.target.value);
      setPhoneError(false);
    }
    else
    {
      SetPhone( e.target.value);
      setPhoneError(true);
    }
  }


  const NewPasswordValidation = () => {

    if (NewPassword != NewPasswordReEnter) {
      setPasswordError(true);
      // alert('Entered Password does not Match');
    } else if (NewPassword == NewPasswordReEnter) {
      setPasswordError(false);
    }
  };

  // const HandleProfileUpdate = () => {};

  const EmailValidation = () => {
    if (!RegEmail.test(Email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };



  const PhoneValidation = () => {
    if (!RegPhone.test(Phone)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const WhatsAppValidation = () => {
    if (!RegPhone.test(WhatsApp)) {
      setWhatsAppError(true);
    } else {
      setWhatsAppError(false);
    }
  };

  const EditProfile = () => {
    if (!PhoneError && !WhatsAppError && !EmailError) {
      let data = {
        first_name: FirstName,
        last_name: LastName,
        email: Email,
        phone_number: Phone,
        whats_app: WhatsApp,
        description: AboutMe,
        avatar: ListingImage,
      };

      let formData = new FormData(); //formdata object
      // console.log('array =', ARRAY_IMAGE);
      formData.append("first_name", FirstName);
      formData.append("last_name", LastName);
      formData.append("email", Email);
      formData.append("phone_number", Phone);
      formData.append("whats_app", WhatsApp);
      formData.append("description", AboutMe);
      formData.append("avatar", ListingImage);

      post(
        UpdateProfileEndPoint,
        "LOCAL",
        formData,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            console.log("message", res.data);
            alert(res.data.message);
            // window.location.href = "/";
          }
          else if (res.data.status == 401)
          {
            HandleTokenExpired(); 
          }
  
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      if (PhoneError) {
        alert("Check Phone Number");
      } else if (WhatsAppError) {
        alert("Check WhatsApp Number");
      } else if (EmailError) {
        alert("Check Email Address");
      } else {
        alert("Something Went Wrong!");
      }
    }
  };

  const ChangePassword = () => {
    if (!PasswordError) {
      let data = {
        old_password: OldPassword,
        password: NewPassword,
        password_confirmation: NewPasswordReEnter,
      };

      post(
        UpdatePasswordEndPoint,
        "LOCAL",
        data,
        true,
        localStorage.getItem("token")
      )
        .then((res) => {
          if (res.data.status === 200) {
            console.log("message", res.data);
            alert(res.data.message);
            // window.location.href = "/";
          }
          else if (res.data.status == 401)
          {
            HandleTokenExpired(); 
          }
  
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  useEffect(() => {
    redirectToLogin(props);
    setLoading(true);
    let mounted = true;
    get(
      DashboardMyProfileEndPoint,
      "LOCAL",
      "",
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          // console.log('res=',res.data.response)
          setMyProfile(res.data.response);
          console.log("image =", res.data.response.avatar);
          setImage(res.data.response.avatar)
          // setListingImage(res.data.response.avatar);
          setLoading(false);
          window.scrollTo({ behavior: "smooth", top: "0px" });
        } else if (res.data.status === 204) {
          setLoading(false);
        }
        else if (res.data.status == 401)
        {
          HandleTokenExpired(); 
        }

      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, []);





  if (Loading) {
    return <div className="loading"></div>;
  }

  return MyProfile != null ? (
    <>
      <NavBar hideHeaderLogoDisplay={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={"dashboard-my-profile"} />
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>My Profile</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="/dashboard-my-profile">Profile</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Profile */}
            <div className="col-lg-6 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                <h4 className="gray">Profile Details</h4>
                <div className="dashboard-list-box-static">
                  {/* Avatar */}
                  <div className="edit-profile-photo">
                    {ListingImage && (
                      <img src={URL.createObjectURL(ListingImage)} alt="" />
                    )}{" "}
                    {Image && (
                      <img src={Image} alt="" />
                    )}{" "}
                    <div className="change-photo-btn">
                      <div className="photoUpload">
                        <span>
                          <i className="fa fa-upload" /> Upload Photo
                        </span>
                        <input
                          type="file"
                          className="upload"
                          name="myImage"
                          onChange={(event) => {
                            setImage(false);
                            setListingImage(event.target.files[0]);
                            // setListingImage(false);
                            console.log(
                              "image uploaded =",
                              event.target.files[0]
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Details */}
                  <div className="my-profile">
                    <label>First Name</label>
                    <input
                      // defaultValue={MyProfile.first_name}
                      value={FirstName}
                      placeholder={MyProfile.first_name}
                      onChange={(e) => SetFirstName(e.target.value)}
                      type="text"
                    />
                    <label>Last Name</label>
                    <input
                      // defaultValue="Doe"
                      value={LastName}
                      placeholder={MyProfile.last_name}
                      onChange={(e) => SetLastName(e.target.value)}
                      type="tel"
                    />
                    <label>Phone</label>
                    {PhoneError && (
                      <span className="error">
                        Please Enter A Valid Phone Number
                      </span>
                    )}
                    <InputMask
                      mask="(+\9\2)999-999-9999"
                      maskChar=" "
                      value={Phone}
                      onChange={(e) => handleChangePhone(e)}
                      placeholder={MyProfile.phone_number}
                      // onInput={PhoneValidation}
                    />
                    {/* <input
                      defaultValue="(123) 123-456"
                      value={Phone}
                      placeholder={MyProfile.phone_number}
                      onInput={PhoneValidation}
                      onChange={(e) => SetPhone(e.target.value)}
                      type="email"
                    /> */}
                    <label>Email</label>
                    {EmailError && (
                      <span className="error">
                        Please Enter A Valid Email Address
                      </span>
                    )}
                    <input
                      // defaultValue={localStorage.getItem('email')}
                      onInput={EmailValidation}
                      placeholder={MyProfile.email}
                      value={Email}
                      onChange={(e) => SetEmail(e.target.value)}
                      type="text"
                    />
                    <label>About Me</label>
                    <textarea
                      name="notes"
                      id="notes"
                      cols={30}
                      rows={10}
                      placeholder={MyProfile.description}
                      value={AboutMe}
                      onChange={(e) => SetAboutMe(e.target.value)}
                    />
                    <label>
                      <i className="fa fa-whatsapp" /> Whatsapp
                    </label>
                    {WhatsAppError && (
                      <span className="error">
                        Please Enter A Valid WhatsApp Number
                      </span>
                    )}
                    <InputMask
                      mask="(+\9\2)999-999-9999"
                      onInput={() =>
                        WhatsApp <= 0 ? setWhatsAppError(true) : setWhatsAppError(false)
                      }
                      defaultValue={MyProfile.whats_app}
                      placeholder={MyProfile.whats_app}
                      value={WhatsApp}
                      // onInput={WhatsAppValidation}
                      onChange={(e) => SetWhatsApp(e.target.value)}
                    />
                    {/* <input
                      placeholder={MyProfile.whats_app}
                      value={WhatsApp}
                      onInput={WhatsAppValidation}
                      onChange={(e) => SetWhatsApp(e.target.value)}
                      type="text"
                    /> */}
                  </div>
                  <button
                    onClick={EditProfile}
                    className="button margin-top-15"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
            {/* Change Password */}
            <div className="col-lg-6 col-md-12">
              <div className="dashboard-list-box margin-top-0">
                <h4 className="gray">Change Password</h4>
                <div className="dashboard-list-box-static">
                  {/* Change Password */}
                  <div className="my-profile">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="notification notice margin-top-0 margin-bottom-0">
                          <p>
                            Your password should be at least 12 random
                            characters long to be safe
                          </p>
                        </div>
                      </div>
                    </div>
                    <br />
                    <label className="margin-top-0">Current Password</label>
                    <input
                      value={OldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      type="password"
                    />
                    <label>New Password</label>
                    <input
                      value={NewPassword}
                      onChange={(e) => SetNewPassword(e.target.value)}
                      // onInput={NewPasswordValidation}
                      type="password"
                    />
                    <label>Confirm New Password</label>
                    {PasswordError && (
                      <span className="error">Password Does Not Match</span>
                    )}
                    <input
                      value={NewPasswordReEnter}
                      onChange={(e) => SetNewPasswordReEnter(e.target.value)}
                      // onInput={NewPasswordValidation}
                      type="password"
                    />
                    <button
                      onClick={ChangePassword}
                      className="button margin-top-15"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Copyrights */}
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  ) : (
    <div className="loading"></div>
  );
};

export default MyProfile;

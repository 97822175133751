import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
import { useLocation } from "react-router-dom";
import React from "react";
import { BookListing } from "../../Components/Axios/EndPoints";
import { post } from "../../Components/Axios/post";
import { RegCNIC, RegEmail, RegPhone } from "../../Components/Regex/Regex";
import InputMask from "react-input-mask";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import { NUMBERS } from "../../Components/constants/DetailValidation";
import Toast from "../../Components/Main/Toast/Toast";
import redirectToLogin from "../../utility/redirectToLogin";
let Numb = NUMBERS;

let TOTAL_DAYS_OF_STAY = 0;

let errors = {
  status: false,
  message: "",
  type: "",
};

const Booking = (props) => {

  redirectToLogin(props);



  const [EmailError, setEmailError] = React.useState(false);
  const [PhoneError, setPhoneError] = React.useState(false);
  const [CNICError, setCNICError] = React.useState(false);
  const [MessageError, setMessageError] = React.useState(false);

  const [FirstName, SetFirstName] = React.useState("");
  const [LastName, SetLastName] = React.useState("");
  const [Email, SetEmail] = React.useState("");
  const [Phone, SetPhone] = React.useState("");
  const [CNIC, SetCNIC] = React.useState("");
  const [Message, SetMessage] = React.useState("");
  const [ConfirmationWaiting, setConfirmationWaiting] = React.useState(false);
  const [Alert, setAlert] = React.useState(false);
  const [AlertData, setAlertData] = React.useState(null);

  const SETTING = JSON.parse(localStorage.getItem("settings"));

  const location = useLocation();
  let StartDate = "",
    EndDate = "";

  const SetErrors = (ErrorType, ErrorMessage) => {
    errors.status = true;
    errors.message = ErrorMessage;
    errors.type = ErrorType;
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  if (location.state.Date) {
    StartDate = convert(location.state.Date[0]);
    EndDate = convert(location.state.Date[1]);
    TOTAL_DAYS_OF_STAY =
      (location.state.Date[1] - location.state.Date[0]) / (3600000 * 24);
  }

  const EmailValidation = () => {
    if (!RegEmail.test(Email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const PhoneValidation = () => {
    if (!RegPhone.test(Phone)) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  };

  const CNICValidation = () => {
    if (!RegCNIC.test(CNIC)) {
      setCNICError(true);
    } else {
      setCNICError(false);
    }
  };

  const MessageValidation = () => {
    let arr = [];
    let counter = 0;

    let message = Message;
    let mess;
    for (let i = 0; i < message.length; i++) {
      mess = message[i];
      if (!isNaN(mess)) {
        counter++;
      } else if (isNaN(mess)) {
        counter = 0;
      }
    }

    Numb.map((numberCheck) => {
      if (Message.includes(numberCheck)) {
        arr.push(true);
      } else {
        setMessageError(false);
      }
    });

    if (arr.length > 0 || counter > 4) {
      setMessageError(true);
    }
  };

  const HandleBookandPay = () => {
    if (!PhoneError && !CNICError && !EmailError && !MessageError) {
      let data = {
        booking_to: EndDate,
        booking_from: StartDate,
        number_of_rooms:
          location.state.Data.property_type == "Hotel"
            ? location.state.NumberOfRoomsSELECTED
            : 1,
        number_of_guests: 2,
        first_name: FirstName,
        last_name: LastName,
        email: Email,
        phone_number: Phone.replace(/[-+ )(]/g, ""),
        cnic: CNIC,
        message: Message,
        promocode: "promocode",
        property: location.state.Data.id,
        grand_total:
          TOTAL_DAYS_OF_STAY *
          location.state.Data.rate *
          location.state.NumberOfRoomsSELECTED,
      };

      post(BookListing, "LOCAL", data, true, localStorage.getItem("token"))
        .then((res) => {
          if (res.data.status === 200) {
            console.log("message", res.data);
            // alert(res.data.message);
            setAlertData(res.data);
            setConfirmationWaiting(true);
            window.scrollTo(0, 0);
            // window.location.href = "/";
          } else if (res.data.status == 401) {
            HandleTokenExpired();
          }
          setAlert(true);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      if (PhoneError) {
        alert("Check Phone Number");
      } else if (CNICError) {
        alert("Check CNIC");
      } else if (EmailError) {
        alert("Check Email Address");
      } else if (MessageError) {
        alert("Check Message");
      } else {
        alert("Something Went Wrong!");
      }
    }
  };

  // console.log('cnic = ', CNIC.replace(/-/g,""));
  // console.log('Phone =', Phone.replace(/[-+ )(]/g,''));

  return (
    <>
      <NavBar />
      <div>
        {/* Titlebar
================================================== */}
        <div id="titlebar">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Booking Confirmation</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/booking">Booking Confirmation</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {Alert == true ? (
          <Toast status={AlertData.status} message={AlertData.message} />
        ) : (
          ""
        )}
        {/* Content
================================================== */}
        {/* Container */}
        {ConfirmationWaiting == false ? (
          <div className="container">
            <div className="row">
              {/* Content
		================================================== */}
              <div className="col-lg-8 col-md-8 padding-right-30">
                <h3 className="margin-top-0 margin-bottom-30">
                  Personal Details
                </h3>
                <div className="row">
                  <div className="col-md-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={FirstName}
                      onChange={(e) => SetFirstName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={LastName}
                      onChange={(e) => SetLastName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <div className="input-with-icon medium-icons">
                      <label>E-Mail Address</label>
                      {EmailError && (
                        <span className="error">
                          Please Enter A Valid Email i.e abc@email.com
                        </span>
                      )}
                      <input
                        type="email"
                        value={Email}
                        onInput={() => EmailValidation()}
                        onChange={(e) => SetEmail(e.target.value)}
                      ></input>
                      <i class="sl sl-icon-envelope-open"></i>{" "}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-with-icon medium-icons">
                      <label>Phone</label>
                      {PhoneError && (
                        <span className="error">
                          Please Enter A Valid Phone Number starting with 923
                        </span>
                      )}
                      <InputMask
                        mask="(+\9\2)999-999-9999"
                        maskChar=" "
                        value={Phone}
                        onChange={(e) => SetPhone(e.target.value)}
                        // onInput={PhoneValidation}
                        // placeholder="_ _ _ - _ _ _ - _ _ _"
                        onInput={() =>
                          Phone <= 0
                            ? setPhoneError(true)
                            : setPhoneError(false)
                        }
                      />
                      {/* <input
                      type="tel"
                      required
                      pattern=""
                      name="phone"
                      id="phone"
                      max={929999999999}
                      min={920000000000}
                      maxLength={12}
                      value={Phone}
                      onInput={() => PhoneValidation()}
                      onChange={(e) =>
                        isNaN(e.target.value) === false
                          ? SetPhone(e.target.value)
                          : ""
                      }
                    /> */}
                      <i className="sl sl-icon-phone" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>CNIC</label>
                    {CNICError && (
                      <span className="error">
                        Please Enter A Valid CNIC without spaces or dashes
                      </span>
                    )}
                    <InputMask
                      mask="99999-9999999-9"
                      maskChar=" "
                      value={CNIC}
                      onChange={(e) => SetCNIC(e.target.value)}
                      // onInput={CNICValidation}
                      // placeholder="_ _ _ - _ _ _ - _ _ _"
                      onInput={() =>
                        CNIC <= 0 ? setCNICError(true) : setCNICError(false)
                      }
                    />

                    {/* <input
                    type="tel"
                    name="_cnic"
                    id="cnic"
                    maxLength={13}
                    required
                    defaultValue
                    placeholder="___ - ______-_"
                    value={CNIC}
                    onInput={() => CNICValidation()}
                    onChange={(e) =>
                      isNaN(e.target.value) === false
                        ? SetCNIC(e.target.value)
                        : ""
                    }
                  /> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="medium-icons">
                      <label>Message</label>
                      {MessageError && (
                        <span className="error">
                          Please do not Enter Mobile Number in Message i.e sifr,
                          zero, one ...
                        </span>
                      )}
                      <textarea
                        maxlength="200"
                        name="message"
                        placeholder="Your short message to the listing owner (optional)"
                        id="booking_message"
                        cols="20"
                        rows="3"
                        value={Message}
                        onKeyDown={() => MessageValidation()}
                        onChange={(e) =>
                          isNaN(e.target.value) === true || e.target.value == ""
                            ? SetMessage(e.target.value)
                            : ""
                        }
                      ></textarea>
                      {/* <i className="im im-icon-Phone-2" /> */}
                    </div>
                  </div>
                </div>
                {/* <h3 className="margin-top-55 margin-bottom-30">Payment Method</h3> */}
                {/* Payment Methods Accordion */}
                {/* <div className="payment">
                <div className="payment-tab payment-tab-active">
                  <div className="payment-tab-trigger">
                    <input
                      defaultChecked
                      id="paypal"
                      name="cardType"
                      type="radio"
                      defaultValue="paypal"
                    />
                    <label htmlFor="paypal">PayPal</label>
                    <img
                      className="payment-logo paypal"
                      src="https://i.imgur.com/ApBxkXU.png"
                      alt=""
                    />
                  </div>
                  <div className="payment-tab-content">
                    <p>You will be redirected to PayPal to complete payment.</p>
                  </div>
                </div>
                <div className="payment-tab">
                  <div className="payment-tab-trigger">
                    <input
                      type="radio"
                      name="cardType"
                      id="creditCart"
                      defaultValue="creditCard"
                    />
                    <label htmlFor="creditCart">Credit / Debit Card</label>
                    <img
                      className="payment-logo"
                      src="https://i.imgur.com/IHEKLgm.png"
                      alt=""
                    />
                  </div>
                  <div className="payment-tab-content">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card-label">
                          <label htmlFor="nameOnCard">Name on Card</label>
                          <input
                            id="nameOnCard"
                            name="nameOnCard"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card-label">
                          <label htmlFor="cardNumber">Card Number</label>
                          <input
                            id="cardNumber"
                            name="cardNumber"
                            placeholder="1234  5678  9876  5432"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-label">
                          <label htmlFor="expirynDate">Expiry Month</label>
                          <input
                            id="expiryDate"
                            placeholder="MM"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-label">
                          <label htmlFor="expiryDate">Expiry Year</label>
                          <input
                            id="expirynDate"
                            placeholder="YY"
                            required
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card-label">
                          <label htmlFor="cvv">CVV</label>
                          <input id="cvv" required type="text" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                {/* Payment Methods Accordion / End */}
                <a
                  onClick={HandleBookandPay}
                  className="button booking-confirmation-btn margin-top-40 margin-bottom-65"
                >
                  Confirm and Book
                </a>
              </div>
              {/* Sidebar
		================================================== */}
              <div className="col-lg-4 col-md-4 margin-top-0 margin-bottom-60">
                {/* Booking Summary */}
                <div className="listing-item-container compact order-summary-widget">
                  <div className="listing-item">
                    <img src={location.state.Data.images[0]} alt="" />
                    <div className="listing-item-content">
                      <div className="numerical-rating" data-rating={5.0} />
                      <h3>{location.state.Data.name}</h3>
                      <span>{location.state.Data.address}</span>
                    </div>
                  </div>
                </div>
                <div className="boxed-widget opening-hours summary margin-top-0">
                  <h3>
                    <i className="fa fa-calendar-check-o" /> Booking Summary
                  </h3>
                  <ul>
                    <li>
                      From <span>{StartDate}</span>
                    </li>
                    <li>
                      To <span>{EndDate}</span>
                    </li>
                    <li>
                      Days <span>{TOTAL_DAYS_OF_STAY}</span>
                    </li>
                    <li>
                      Rate <span>{location.state.Data.rate}</span>
                    </li>

                    {SETTING && <li>
                      Service Charges <span>{SETTING.SERVICE_CHARGES}</span>
                    </li>}
                    <li>
                      Rooms{" "}
                      <span>
                        {location.state.Data.property_type == "Hotel"
                          ? location.state.NumberOfRoomsSELECTED
                          : 1}
                      </span>
                    </li>
                    {/* <li>Guests <span>2 Adults</span></li> */}
                    <li className="total-costs">
                      Total Cost{" "}
                      <span>
                        PKR{" "}
                        {location.state.Data.property_type == "Hotel"
                          ? location.state.NumberOfRoomsSELECTED *
                              TOTAL_DAYS_OF_STAY *
                              location.state.Data.rate +
                            parseInt(SETTING.SERVICE_CHARGES)
                          : TOTAL_DAYS_OF_STAY * location.state.Data.rate + parseInt(SETTING.SERVICE_CHARGES)}
                      </span>
                    </li>
                  </ul>
                </div>
                {/* Booking Summary / End */}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="container full-width">
              <div className="row">
                <article
                  id="post-48"
                  className="col-md-12 page-container-col post-48 page type-page status-publish hentry"
                >
                  <div className="booking-confirmation-page booking-confrimation-error">
                    <i className="fa fa-check-circle" />
                    <h2 className="margin-top-30">Thanks for your booking!</h2>
                    <p className="text-center">
                      Your booking is waiting for confirmation.
                    </p>
                  </div>
                </article>
              </div>
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-2">
                  <a
                    href="/"
                    class="button border login-button-navbar with-icon"
                  >
                    <i class="sl sl-icon-home"></i> Home
                  </a>
                </div>
                <div className="col-md-3">
                  <a
                    href="/dashboard-bookings"
                    class="button border login-button-navbar with-icon"
                  >
                    <i class="sl sl-icon-list"></i> My Bookings
                  </a>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
            <div className="clearfix" />
            <div className="margin-top-55" />
          </>
        )}{" "}
        {/* Container / End */}
      </div>
      <Footer />
    </>
  );
};

export default Booking;

import "../../Pages/Homepage/Homepage.css";
import { post } from "../Axios/post";
import { ForgotPasswordEndPoint } from "../Axios/EndPoints";
import React, { useState } from "react";
import Toast from "../Main/Toast/Toast";

export const ForgotPassword = (props) => {
  const [response, setResponse] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let data = { email: event.target.email.value };
    post(ForgotPasswordEndPoint, "LOCAL", data, false)
      .then((res) => {
        setResponseMessage(res.data);
        setResponse(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  setTimeout(() => {
    setResponse(false);
  }, 5000);

  return (
    <>
      {loading ? (
        <>
          {/* <section className="fullwidth border-top margin-top-65 padding-top-75 padding-bottom-70 text-center"> */}
          <div className="loading"></div>
          {/* </section> */}
        </>
      ) : (
        ""
      )}
      <div className="tabs">
        <ul>
          <li>
            <div className="container margin-top-100  width-25">
              {response && (
                <>
                  <Toast
                    status={responseMessage.status}
                    message={responseMessage.message}
                    // setError={setResponse}
                  />
                </>
              )}
              <button
                className="button mazebaan top-left"
                onClick={() => props.backToLogin()}
              >
                Back
              </button>

              <h3>Enter Email To Reset Password</h3>
              <div className="clearfix" />
              <form className="login" onSubmit={(e) => handleSubmit(e)}>
                <div className="input-group">
                  <div className="input-group-icon">
                    <i className="im im-icon-Male" />
                  </div>
                  <div className="input-group-area">
                    <input type="text" name="email" placeholder="Email" />
                  </div>
                </div>
                {!loading && (
                  <div className="form-row">
                    <input
                      type="submit"
                      className="button mazebaan"
                      value={"Submit"}
                    />
                  </div>
                )}
              </form>
            </div>
          </li>
        </ul>
      </div>
    </>
  );

  //   return (
  //     <div className="tabs">
  //       <ul className="custom-tabs">
  //         <li>
  //           <div className="tab-content">
  //             <form className="login" onSubmit={(e) => handleSubmit(e)}>
  //               <div className="input-group">
  //                 <div className="input-group-icon">
  //                   <i className="im im-icon-Male" />
  //                 </div>
  //                 <div className="input-group-area">
  //                   <input type="text" name="email" placeholder="Email" />
  //                 </div>
  //               </div>
  //               <div className="input-group">
  //                 <div className="input-group-icon">
  //                   <i className="im im-icon-Lock-2" />
  //                 </div>
  //                 <div className="input-group-area">
  //                   <input
  //                     type="password"
  //                     name="password"
  //                     placeholder="Password"
  //                   />
  //                 </div>
  //               </div>
  //               <p className="form-row">
  //                 <span className="lost_password">
  //                   <a href="#" onClick={() => props.forgotPassword()}>
  //                     Lost Your Password?
  //                   </a>
  //                 </span>
  //               </p>
  //               {/* {Error && (
  //           <>
  //           <Toast status={LoginError.status} message={LoginError.message} setError={setError} />
  //           {
  //             setTimeout(() => {
  //               setError(false)
  //             }, 5000)
  //           }
  //           </>
  //         )} */}

  //               <div className="form-row">
  //                 {/*<div className="checkboxes margin-top-10">
  //               <input id="remember-me" type="checkbox" name="check" />
  //               <label htmlFor="remember-me">Remember Me</label>
  //             </div>*/}
  //                 <input
  //                   type="submit"
  //                   className="button border margin-top-5 bg-yellow padding-left-40 padding-right-40"
  //                   value={"Login"}
  //                 />
  //               </div>
  //               {/* <GOOGLELOGIN setGoogle={setGoogle}/> */}
  //             </form>
  //           </div>
  //         </li>
  //       </ul>
  //     </div>
  //   );
};

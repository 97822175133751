import React from "react";
// import {DayPicker} from "react-day-picker";
import DayPicker, { DateUtils } from "react-day-picker";

import "./DaySelector.css";
// import './DaySelector.css';
// import "react-day-picker/lib/style.css";

let Closed_Dates = [];
// let hover = false;
let propsClosedDates=[];
export default class BasicConcepts extends React.Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: props.SelectD == null ? [] : props.SelectD,
    };
  }

  handleDayClick(day, { selected }) {
    const selectedDays = this.state.selectedDays.concat();
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
      Closed_Dates.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
      Closed_Dates.push(day.toISOString());
    }
    this.setState({ selectedDays });
  }

  renderDay(day) {
    // const selectedDays = this.state.selectedDays;

    // console.log(
    //   "selected days =",
    //   Closed_Dates.length > 0 ? new Date(Closed_Dates[0]) : ''
    // );


    const date = day.getDate();
    const dateStyle = {
      position: "relative",
      // color: 'lightgray',
      // bottom: 0,
      // right: 0,
      fontSize: 20,
      hover: { color: "#ffb400" },
    };
    const daStyle = {
      position: "relative",
      color: "#de5959",
      background: "ffe9e9",
      fontSize: 20,
    };
    // const birthdayStyle = { fontSize: '0.8em', textAlign: 'left' };
    const cellStyle = {
      // height: '50%',
      // width: '50%',
      padding: "2% 25% 2% 25%",
      position: "relative",
      "text-align": "center",
      background: "#EBF6E0",
      color: "#5f9025",
      border: "1px solid #e0e0e0",
      "border-radius": "3px",
    };

    // console.log("Closed days from props", Closed_Dates, day);


    // function check() {
    //   // for (let i = 0; i < Closed_Dates.length; i++) {
    //   //   console.log('in for  =', new Date(Closed_Dates[i]))
    //     if (new Date(Closed_Dates[i]) == day) {
    //       return true;
    //     }
    //   }
    //   return false;
    // }

    // 
    return (
      <div style={cellStyle}>
        <div>
          {Closed_Dates.includes(day.toISOString()) == true ? (
            <span style={daStyle}>{date}</span>
          ) : (
            <span style={dateStyle}>{date}</span>
          )}
        </div>
        {/* {console.log("image =", day)} */}
      </div>
      // </div>
    );
  }

  // handleDayClick(day, { selected }) {
  //   console.log('selected = ', this.state.selectedDays)
  //   if (selected) {
  //     // Unselect the day if already selected
  //     this.setState({ selectedDay: undefined });
  //     return;
  //   }
  //   // this.setState({ selectedDay: day });
  //   Closed_Dates.push(day.toISOString());
  //   this.setState({ selectedDay: Closed_Dates });
  //   this.props.ClosedD(Closed_Dates);
  //   // this.props.ClosedD
  // }

  render(props) {
    // console.log("props in render=", this.props.ClosedDays);
    this.props.ClosedD(Closed_Dates);
    propsClosedDates = this.props.ClosedDays;

    return (
      <div>
        <DayPicker
          disabledDays={{ before: new Date() }}
          selectedDays={this.state.selectedDays}
          onDayClick={this.handleDayClick}
          renderDay={this.renderDay}
          renderWeek={this.renderWeek}
        />
      </div>
    );
  }
}

let Top = "50px";
const SendMessage = (props) => {
  console.log("props.index =", props.index);

  if (props.index == 0) {
    Top = "0%";
  } else if (props.index == 1) {
    Top = "10%";
  } else if (props.index == 2) {
    Top = "20%";
  } else if (props.index == 3) {
    Top = "30%";
  } else if (props.index == 4) {
    Top = "40%";
  } else if (props.index == 5) {
    Top = "50%";
  } else if (props.index == 6) {
    Top = "60%";
  } else if (props.index == 7) {
    Top = "70%";
  } else if (props.index == 8) {
    Top = "80%";
  } else if (props.index == 9) {
    Top = "90%";
  } else {
    Top = "100%";
  }

  return (
    <>
      <div class="mfp-bg my-mfp-zoom-in mfp-ready"></div>
      <div
        className="mfp-wrap mfp-close-btn-in mfp-auto-cursor my-mfp-zoom-in mfp-ready"
        tabIndex={-1}
        style={{
          top: Top,
          position: "absolute",
          height: "323px",
        }}
      >
        <div className="mfp-container mfp-inline-holder">
          <div className="mfp-content">
            <div id="small-dialog" className="zoom-anim-dialog">
              <div className="small-dialog-header">
                <h3>Send Message</h3>
              </div>
              <div className="message-reply margin-top-0">
                <textarea
                  cols={40}
                  rows={3}
                  placeholder={props.placeholder}
                  //   defaultValue={""}
                  value={props.value}
                  onChange={(e) => props.setvalue(e.target.value)}
                />
                <button onClick={() => props.sendmessage()} className="button">
                  Send
                </button>
              </div>
              <button
                onClick={() => props.closemodal(false)}
                title="Close (Esc)"
                type="button"
                className="mfp-close"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendMessage;

import React, { useEffect, useState } from "react";
import { get } from "../Axios/get";
import {
  ADDBookmarkListingEndPoint,
  DELETEBookmarkListingPoint,
  NewestListingsEndPoint,
} from "../Axios/EndPoints";
import { put } from "../Axios/put";
import "../../Components/Homepage/Homepage.css";
import StarRatings from "react-star-ratings";
import ReactStars from "react-rating-stars-component";
import HandleTokenExpired from "../Main/TokenExpired/TokenExpired";


export const NewListings = (props) => {
  const [newListings, setNewListings] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    get(NewestListingsEndPoint, "LOCAL", "", false)
      .then((res) => {
        if (res.data.status === 200) {
          setNewListings(res.data.response.listings);
          setLoading(false);

          console.log('newListings', res.data.response.listings);
        }
        else {
          console.log(res.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (mounted = false);
  }, []);
  return (
    <>
      <section
        className="fullwidth border-top margin-top-65 padding-top-75 padding-bottom-70"
        data-background-color="#fff"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="headline centered margin-bottom-45">
                <strong className="headline-with-separator">
                  Newest Listings
                </strong>
              </h3>
            </div>
            {!loading ? (
              Object.values(newListings).map((value, index) =>
                index < 6 ? (
                  <div className="col-lg-6 col-md-12">
                    <div className="listing-item-container compact">
                      <div className="listing-item">
                        <a href={"/listing-detail/" + value["uid"]}>
                          <img src={value["property_image"]} alt="" />
                          {/* <div className="listing-badge now-open">Now Open</div> */}
                          <div className="listing-item-content">
                            <span className="tag">{value["property_type"]}</span>
                            <h3>{value["name"]}</h3>
                            <span>
                              {value["city"]},{value["country"]}
                            </span>
                            <div className="listing-list-small-badges-container">
                              <div className="listing-small-badge pricing-badge">
                                <i className="fa fa-group"></i>
                                {
                                  value[
                                    "maximum_number_of_guests_per_reservation"
                                  ]
                                }{" "}
                                Guests (Maximum) &emsp;
                                <div className="listing-small-badge pricing-badge">
                                  <i className="fa fa-hotel"></i>
                                  {value["number_of_beds"]} Bed(s)
                                </div>
                              </div>
                            </div>
                            <div className="text-color-white">
                              {value['amenities'] === undefined || value['amenities'] === '' ? "" : value["amenities"].slice(0, 4).join(",")}
                            </div>
                            <div className="listing-list-small-badges-container">
                              <div className="listing-small-badge pricing-badge">
                                <i className="fa fa-tag"></i>
                                {parseFloat(" " + value["rate"])} {value["rate_type"]}
                              </div>
                            </div>
                          </div>
                        </a>
                        <a
                          className="AddBookmark"
                          onClick={() => {
                            localStorage.getItem("token") &&
                            value["is_bookmarked"] === 0
                              ? put(
                                  ADDBookmarkListingEndPoint + value["uid"],
                                  "LOCAL",
                                  true,
                                  localStorage.getItem("token")
                                )
                                  .then((res) => {
                                    if (res.data.status === 200) {
                                          console.log(res.data.message);
                                          props.setBookmarked(res.data);
                                    }
                                    else if (res.data.status == 401)
                                    {
                                      HandleTokenExpired(); 
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  })
                              : localStorage.getItem("token") &&
                                value["is_bookmarked"] === 1
                              ? put(
                                  DELETEBookmarkListingPoint + value["uid"],
                                  "LOCAL",
                                  true,
                                  localStorage.getItem("token")
                                )
                                  .then((res) => {
                                    if (res.data.status === 200) {
                                      console.log(res.data.message);
                                      props.setBookmarked(res.data);
                                    }
                                    else if (res.data.status == 401)
                                    {
                                      HandleTokenExpired(); 
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  })
                              : (window.location.href = "/login");
                          }}
                        >
                          <span
                            className={
                              value["is_bookmarked"] === 1
                                ? "save like-icon tooltip left liked"
                                : "save like-icon tooltip left"
                            }
                          />
                        </a>
                      </div>
                      <div className="star-rating" data-rating={5}>
                        <div className="">
                          <span>
                            {value["rating"] === 0 ? (
                              <>No Reviews Yet</>
                            ) : (
                              <div className="row">
                              <div className="col-md-6">
                              <ReactStars
                              count={5}
                              edit={false}
                              value={value["rating"]}
                              size={24}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffb400"
                              />
                              </div>
                              <div className="col-md-6 rating-left-homepage">
                              ({value["rating"]})
                              </div>
                                </div>
                            )}{" "}
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <span class="save like-icon tooltip left"></span> */}
                  </div>
                ) : (
                  <></>
                )
              )
            ) : (
              <section className="fullwidth border-top margin-top-65 padding-top-75 padding-bottom-70 text-center">
                <div className="loading"></div>
              </section>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

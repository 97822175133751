import StarRatings from "react-star-ratings";

const ReviewListing = (props) => {


  return props.ReviewCount === 0 ? (
    <>
    <div id="listing-reviews" className="listing-section">
      <h3 className="listing-desc-headline margin-top-75 margin-bottom-20">
        Reviews <span>({props.ReviewCount})</span>
      </h3>
      {/* Rating Overview */}
      <div className="rating-overview">
        <div className="rating-overview-box">
          <span className="rating-overview-box-total">N/A</span>
          <span className="rating-overview-box-percent">out of 5.0</span>
          <div className="star-rating" data-rating={5}>
            <StarRatings
              // rating={value["property"]["rating"]}
              rating={0}
              starRatedColor="#ffc600"
              numberOfStars={5}
              starDimension="18px"
              starSpacing="1px"
              name="rating"
            />
          </div>
        </div>
        <div className="rating-bars">
          <div className="rating-bars-item">
            <span className="rating-bars-name">
              Service{" "}
              <i
                className="tip"
                data-tip-content="Quality of customer service and attitude to work with you"
              />
            </span>
            <span className="rating-bars-inner">
              <span className="rating-bars-rating" data-rating={0}>
                <span
                  class="rating-bars-rating-inner"
                  style={{ width: (0/5 * 100)+"%" }}
                ></span>
              </span>
              <strong>{0}</strong>
            </span>
          </div>
          <div className="rating-bars-item">
            <span className="rating-bars-name">
              Value for Money{" "}
              <i
                className="tip"
                data-tip-content="Overall experience received for the amount spent"
              />
            </span>
            <span className="rating-bars-inner">
              <span className="rating-bars-rating" data-rating={0}>
                <span
                  className="rating-bars-rating-inner"
                  style={{ width: (0/5 * 100)+"%" }}
                />
              </span>
              <strong>{0}</strong>
            </span>
          </div>
          <div className="rating-bars-item">
            <span className="rating-bars-name">
              Location
              <i
                className="tip"
                data-tip-content="Visibility, commute or nearby parking spots"
              />
            </span>
            <span className="rating-bars-inner">
              <span className="rating-bars-rating" data-rating={0}>
                <span
                  className="rating-bars-rating-inner"
                  style={{ width: (0/5 * 100)+"%"}}
                />
              </span>
              <strong>{0}</strong>
            </span>
          </div>
          <div className="rating-bars-item">
            <span className="rating-bars-name">
              Cleanliness{" "}
              <i
                className="tip"
                data-tip-content="The physical condition of the business"
              />
            </span>
            <span className="rating-bars-inner">
              <span className="rating-bars-rating" data-rating={0}>
                <span
                  className="rating-bars-rating-inner"
                  style={{ width: (0/5 * 100)+"%" }}
                />
              </span>
              <strong>{0}</strong>
            </span>
          </div>
        </div>
      </div>
      {/* Rating Overview / End */}
      <div className="clearfix" />
      {/* Reviews */}
      <section className="comments listing-reviews">
        <ul>
          <li>
            No Reviews Found!
          </li>
        </ul>
      </section>
      {/* Pagination */}
      {/* <div className="clearfix" />
      <div className="row">
        <div className="col-md-12">
          <div className="pagination-container margin-top-30">
            <nav className="pagination">
              <ul>
                <li>
                  <a href="#" className="current-page">
                    1
                  </a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">
                    <i className="sl sl-icon-arrow-right" />
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="clearfix" /> */}
      {/* Pagination / End */}
    </div>
  </>

  )  : (
    <>
      <div id="listing-reviews" className="listing-section">
        <h3 className="listing-desc-headline margin-top-75 margin-bottom-20">
          Reviews <span>({props.ReviewCount})</span>
        </h3>
        {/* Rating Overview */}
        <div className="rating-overview">
          <div className="rating-overview-box">
            <span className="rating-overview-box-total">{props.Rating.rating}</span>
            <span className="rating-overview-box-percent">out of 5.0</span>
            <div className="star-rating" data-rating={5}>
              <StarRatings
                // rating={value["property"]["rating"]}
                rating={props.Rating.rating}
                starRatedColor="#ffc600"
                numberOfStars={5}
                starDimension="18px"
                starSpacing="1px"
                name="rating"
              />
            </div>
          </div>
          <div className="rating-bars">
            <div className="rating-bars-item">
              <span className="rating-bars-name">
                Service{" "}
                <i
                  className="tip"
                  data-tip-content="Quality of customer service and attitude to work with you"
                />
              </span>
              <span className="rating-bars-inner">
                <span className="rating-bars-rating" data-rating={props.Rating.service_rating}>
                  <span
                    class="rating-bars-rating-inner"
                    style={{ width: (props.Rating.service_rating/5 * 100)+"%" }}
                  ></span>
                </span>
                <strong>{props.Rating.service_rating}</strong>
              </span>
            </div>
            <div className="rating-bars-item">
              <span className="rating-bars-name">
                Value for Money{" "}
                <i
                  className="tip"
                  data-tip-content="Overall experience received for the amount spent"
                />
              </span>
              <span className="rating-bars-inner">
                <span className="rating-bars-rating" data-rating={props.Rating.value_for_money_rating}>
                  <span
                    className="rating-bars-rating-inner"
                    style={{ width: (props.Rating.value_for_money_rating/5 * 100)+"%" }}
                  />
                </span>
                <strong>{props.Rating.value_for_money_rating}</strong>
              </span>
            </div>
            <div className="rating-bars-item">
              <span className="rating-bars-name">
                Location
                <i
                  className="tip"
                  data-tip-content="Visibility, commute or nearby parking spots"
                />
              </span>
              <span className="rating-bars-inner">
                <span className="rating-bars-rating" data-rating={props.Rating.location_rating}>
                  <span
                    className="rating-bars-rating-inner"
                    style={{ width: (props.Rating.location_rating/5 * 100)+"%"}}
                  />
                </span>
                <strong>{props.Rating.location_rating}</strong>
              </span>
            </div>
            <div className="rating-bars-item">
              <span className="rating-bars-name">
                Cleanliness{" "}
                <i
                  className="tip"
                  data-tip-content="The physical condition of the business"
                />
              </span>
              <span className="rating-bars-inner">
                <span className="rating-bars-rating" data-rating={props.Rating.cleanliness_rating}>
                  <span
                    className="rating-bars-rating-inner"
                    style={{ width: (props.Rating.cleanliness_rating/5 * 100)+"%" }}
                  />
                </span>
                <strong>{props.Rating.cleanliness_rating}</strong>
              </span>
            </div>
          </div>
        </div>
        {/* Rating Overview / End */}
        <div className="clearfix" />
        {/* Reviews */}
        <section className="comments listing-reviews">
          <ul>
            {Object.values(props.Review).map((value) => (
              <li>
                <div className="avatar">
                  <img src={value["user"]["avatar"]} alt="" />
                </div>
                <div className="comment-content">
                  <div className="arrow-comment" />
                  <div className="comment-by">
                    {value["user"]["name"]}
                    <i
                      className={value["is_verified"] === 1 ? "tip" : ""}
                      data-tip-content="Person who left this review actually was a customer"
                    />{" "}
                    <span className="date">{value["review_date"]}</span>
                    <div className="star-rating" data-rating={5}>
                      <StarRatings
                        // rating={value["rating"]["rating"]}
                        rating={4.2}
                        starRatedColor="#ffc600"
                        numberOfStars={5}
                        starDimension="18px"
                        starSpacing="1px"
                        name="rating"
                      />
                    </div>
                  </div>
                  <p>{value["review"]}</p>
                  <div className="review-images mfp-gallery-container">
                    <a
                      href="images/review-image-01.jpg"
                      className="mfp-gallery"
                    >
                      <img src={"images/review-image-01.jpg"} alt="" />
                    </a>
                  </div>
                  {/* <a href="#" className="rate-review">
                    <i className="sl sl-icon-like" /> Helpful Review{" "}
                    <span>12</span>
                  </a> */}
                </div>
              </li>
            ))}
          </ul>
        </section>
        {/* Pagination */}
        {/* <div className="clearfix" />
        <div className="row">
          <div className="col-md-12">
            <div className="pagination-container margin-top-30">
              <nav className="pagination">
                <ul>
                  <li>
                    <a href="#" className="current-page">
                      1
                    </a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="sl sl-icon-arrow-right" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="clearfix" /> */}
        {/* Pagination / End */}
      </div>
    </>
  );
};

export default ReviewListing;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import {get} from "../../Components/Axios/get";
import {post} from "../../Components/Axios/post";
import {useEffect, useRef} from "react";
import {
    NewListingEndPoint,
    CreateNewListingEndPoint,
    ListingDetailEndPoint,
    EditListingEndPoint,
    UpdateListingEndPoint,
} from "../../Components/Axios/EndPoints";
import React from "react";
import GOOGLEMAPS from "../../Components/GoogleMaps/GoogleMaps";
import DaySelector from "../../Components/DaySelector/DaySelector";
import {ImageUploadPreviewComponent} from "../../Components/ImageUpload/ImageUpload";
import Geocode from "react-geocode";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles/RichtextEditor.css";
import {GoogleMapsKey} from "../../Components/GoogleKeys/GoogleKeys";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import PreviewListing from "../../Components/Dashboard/AddListing/PreviewListing";
// import '../../Components/Dashboard/AddListing/PreviewListing.css';
import {EditorState} from "draft-js";
// import MaskedInput from 'react-text-mask';
import InputMask from "react-input-mask";
import Autocomplete from "react-google-autocomplete";
import {useJsApiLoader} from "@react-google-maps/api";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import {NUMBERS} from "../../Components/constants/DetailValidation";
import MyProfile from "./MyProfile";
import {RegEmail, RegPhone} from "../../Components/Regex/Regex";
import redirectToLogin from "../../utility/redirectToLogin";

let ARRAY_AMENITIES = [];
let ARRAY_HOUSE_RULES = [];
let ARRAY_LANGUAGES = [];
let ARRAY_IMAGE = [];
let ARRAY_CLOSED_DATES = [];

let SELECTED_ARRAY_AMENITIES_NAME = [];
let SELECTED_ARRAY_LANGUAGES_NAME = [];
let SELECTED_ARRAY_HOUSERULES_NAME = [];

// let ii = 0,
//   jj = 0,
//   kk = 0;

const alphabet_regex = /^[0-9]+$/;

let Numb = NUMBERS;


const AddListing = (props) => {
    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GoogleMapsKey,
        libraries: ["places", "geometry", "drawing"],
    });

    const SETTING = JSON.parse(localStorage.getItem("settings"));

    const [PropertyTypes, setPropertyTypes] = React.useState(null);
    const [HouseRules, setHouseRules] = React.useState(null);
    const [Amenities, setAmenities] = React.useState(null);
    const [Banks, setBanks] = React.useState(null);
    const [Cities, setCities] = React.useState(null);
    const [PaymentMethod, setPaymentMethod] = React.useState(null);
    const [Languages, setLanguages] = React.useState(null);
    const [GettingAPIData, SetGettingAPIData] = React.useState(true);
    const [GettingEDITAPIData, SetEDITGettingAPIData] = React.useState(false);

    const [SelectedFile, setSelectedFile] = React.useState(null);

    const [Preview, setPreview] = React.useState(false);
    const [Refresh, setRefresh] = React.useState(false);

    const [ListingName, setListingName] = React.useState("");
    const [ListingDescription, setListingDescription] = React.useState(
        EditorState.createEmpty()
    );

    const [ListingDescriptionEDIT, setListingDescriptionEDIT] =
        React.useState("");

    const [ListingPropertyType, setListingPropertyType] = React.useState("");
    const [ListingRoomType, setListingRoomType] = React.useState("");
    const [ListingRooms, setListingRooms] = React.useState(0);
    const [ListingAmenities, setListingAmenities] = React.useState([]);
    const [ListingHouseRules, setListingHouseRules] = React.useState([]);
    const [ListingLanguages, setListingLanguages] = React.useState([]);
    const [ListingAddress, setListingAddress] = React.useState("");
    const [ListingAddressDetails, setListingAddressDetails] = React.useState("");
    const [ListingLatitude, setListingLatitude] = React.useState(33.659669);
    const [ListingLongitude, setListingLongitude] = React.useState(73.0543899);
    const [ListingImage, setListingImage] = React.useState([]);
    const [ListingEmail, setListingEmail] = React.useState("");
    const [ListingPhoneNumber, setListingPhoneNumber] = React.useState("(+92)");
    const [ListingWhatsAppNumber, setListingWhatsAppNumber] = React.useState("");
    const [ListingCNIC, setListingCNIC] = React.useState("");
    const [ListingCities, setListingCities] = React.useState();
    const [ListingOtherCities, setListingOtherCities] = React.useState("");
    const [ListingPaymentMethod, setListingPaymentMethod] = React.useState("");
    const [ListingAccountNumber, setListingAccountNumber] = React.useState("");
    const [ListingAccountTitle, setListingAccountTitle] = React.useState("");
    const [ListingBankName, setListingBankName] = React.useState("");
    const [ListingPricePerDay, setListingPricePerDay] = React.useState();
    const [ListingNumberOfBeds, setListingNumberOfBeds] = React.useState();
    const [ListingNumberOfGuests, setListingNumberOfGuests] = React.useState();
    const [ListingClosedDates, setListingClosedDates] = React.useState([]);

    const [APIERROR, setAPIERROR] = React.useState(null);
    const [MessageError, setMessageError] = React.useState(false);

    const [ErrorPropertyType, setErrorPropertyType] = React.useState(false);
    const [ErrorRoomType, setErrorRoomType] = React.useState(false);
    const [ErrorRooms, setErrorRooms] = React.useState(false);
    const [ErrorAmenities, setErrorAmenities] = React.useState(false);
    const [ErrorHouseRules, setErrorHouseRules] = React.useState(false);
    const [ErrorLanguages, setErrorLanguages] = React.useState(false);
    const [ErrorAddress, setErrorAddress] = React.useState(false);
    const [ErrorLatitude, setErrorLatitude] = React.useState(false);
    const [ErrorLongitude, setErrorLongitude] = React.useState(false);
    const [ErrorImage, setErrorImage] = React.useState(false);
    const [ErrorEmail, setErrorEmail] = React.useState(false);
    const [ErrorPhoneNumber, setErrorPhoneNumber] = React.useState(false);
    const [ErrorWhatsAppNumber, setErrorWhatsAppNumber] = React.useState(false);
    const [ErrorCNIC, setErrorCNIC] = React.useState(false);
    const [ErrorCities, setErrorCities] = React.useState(false);
    const [ErrorOtherCities, setErrorOtherCities] = React.useState(false);
    const [ErrorPaymentMethod, setErrorPaymentMethod] = React.useState(false);
    const [ErrorAccountNumber, setErrorAccountNumber] = React.useState(false);
    const [ErrorAccountTitle, setErrorAccountTitle] = React.useState(false);
    const [ErrorBankName, setErrorBankName] = React.useState(false);
    const [PriceError, setPriceError] = React.useState(false);
    const [ErrorNumberOfBeds, setErrorNumberOfBeds] = React.useState(false);
    const [ErrorNumberOfGuests, setErrorNumberOfGuests] = React.useState(false);
    const hiddenFileInput = React.useRef(null);

    const [EditListing, setEditListing] = React.useState(false);
    const [EditListingData, setEditListingData] = React.useState(null);
    // const []

    const navigate = useNavigate();
    const params = useParams();
    const loc = useLocation();


    const PhoneValidation = () => {
        if (!RegPhone.test(ListingPhoneNumber)) {
            setErrorPhoneNumber(true);
        } else {
            setErrorPhoneNumber(false);
        }
    };

    const WhatsAppValidation = () => {
        if (!RegPhone.test(ListingWhatsAppNumber)) {
            setErrorWhatsAppNumber(true);
        } else {
            setErrorWhatsAppNumber(false);
        }
    };

    const handleChangePhone = (e) => {
        if (e.target.value.match(RegPhone) != null) {
            setListingPhoneNumber(e.target.value);
            setErrorPhoneNumber(false);
        } else {
            setListingPhoneNumber(e.target.value);
            setErrorPhoneNumber(true);
        }

    }

    const handleChangeWhatsapp = (e) => {
        if (e.target.value.match(RegPhone) != null) {
            setListingWhatsAppNumber(e.target.value);
            setErrorWhatsAppNumber(false);
        } else {
            setListingWhatsAppNumber(e.target.value);
            setErrorWhatsAppNumber(true);
        }
    }
    const handleChangeEmail = (e) => {
        if (e.target.value.match(RegEmail) != null) {
            setListingEmail(e.target.value);
            setErrorEmail(false);
        } else {
            setListingEmail(e.target.value);
            setErrorEmail(true);
        }
    }


    // const Toast = (props) => {
    //   // console.log(
    //   //   "res props =",
    //   //   JSON.stringify(props.response)
    //   //     .replace(/[{()}]/g, "")
    //   //     .toString()
    //   // );
    //
    //   if (props.status === 200) {
    //     toast.success(props.message, {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       toastId:props.status,
    //     });
    //   }
    //   // else if (props.status === 404 || props.status === 429) {
    //   //   toast.warn(props.message, {
    //   //     position: "top-right",
    //   //     autoClose: 2000,
    //   //     hideProgressBar: false,
    //   //     closeOnClick: true,
    //   //     pauseOnHover: true,
    //   //     draggable: true,
    //   //     progress: undefined,
    //   //   });
    //   // }
    //   else if (props.status === 500) {
    //     toast.error(props.message, {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       toastId:props.status,
    //
    //     });
    //   }
    // };

    const handleClick = () => {
        if (ARRAY_IMAGE.length < SETTING.MAX_LISTING_IMAGES) {
            document.getElementById("file_input").click();
        } else {
            alert("You Have Already Selected Maximum Number Of Images");
        }
    };

    // console.log("amenities =", ARRAY_AMENITIES);
    // console.log("languages =", ARRAY_LANGUAGES);
    // console.log("house rules =", ARRAY_HOUSE_RULES);

    const APICALL = () => {
        // console.log("Create new listing API");

        let Location = {
            address: ListingAddress,
            address_detail: ListingAddressDetails,
            latitude: ListingLatitude,
            longitude: ListingLongitude,
            city: ListingCities,
            other_city: ListingOtherCities,
        };

        // console.log("location = ", Location);
        let payment = {
            payment_method: ListingPaymentMethod,
            account_number: ListingAccountNumber,
            account_title: ListingAccountTitle,
            bank_id: ListingBankName,
        };

        // console.log('images = ', ListingImage);
        let data = {
            name: ListingName,
            description: ListingDescription.getCurrentContent().getPlainText(),
            property_type: ListingPropertyType,
            amenities: ARRAY_AMENITIES,
            house_rules: ARRAY_HOUSE_RULES,
            languages: ARRAY_LANGUAGES,
            location: Location,
            images: ListingImage,
            email: ListingEmail,
            phone_number: ListingPhoneNumber,
            whatsapp_number: ListingWhatsAppNumber,
            cnic: ListingCNIC,
            payment: payment,
            price_per_day: ListingPricePerDay,
            number_of_beds: ListingNumberOfBeds,
            number_of_guests: ListingNumberOfGuests,
            closed_dates: JSON.stringify(ListingClosedDates),
        };

        let formData = new FormData(); //formdata object
        // console.log("array =", ARRAY_IMAGE);
        formData.append("name", ListingName);
        formData.append(
            "description",
            loc.state != null &&
            ListingDescription.getCurrentContent().getPlainText() == ""
                ? ListingDescriptionEDIT
                : ListingDescription.getCurrentContent().getPlainText()
        );
        formData.append("property_type", ListingPropertyType);
        formData.append("amenities", JSON.stringify(ARRAY_AMENITIES));
        formData.append("house_rules", JSON.stringify(ARRAY_HOUSE_RULES));
        formData.append("languages", JSON.stringify(ARRAY_LANGUAGES));
        formData.append("location", JSON.stringify(Location));
        // formData.append("images", ListingImage);
        for (let image in ARRAY_IMAGE) {
            formData.append("images[]", ARRAY_IMAGE[image]);
        }
        formData.append("email", ListingEmail);
        formData.append("phone_number", ListingPhoneNumber);
        formData.append("whatsapp_number", ListingWhatsAppNumber);
        formData.append(
            "cnic",
            loc.state != null ? ListingCNIC : ListingCNIC.replace(/-/g, "")
        );
        formData.append("payment", JSON.stringify(payment));
        formData.append("price_per_day", ListingPricePerDay);
        formData.append("number_of_beds", ListingNumberOfBeds);
        formData.append("number_of_guests", ListingNumberOfGuests);
        formData.append("closed_dates", JSON.stringify(ListingClosedDates));
        formData.append("number_of_rooms", ListingRooms);

        // console.log('loc state uid', loc.state.ListingDetail.uid);
        post(
            loc.state == null
                ? CreateNewListingEndPoint
                : UpdateListingEndPoint + loc.state.ListingDetail.uid,
            "LOCAL",
            formData,
            true,
            localStorage.getItem("token")
        )
            .then((res) => {
                // console.log("res", res.data.response);
                // Toast(res.data);
                if (res.data.status === 200) {
                    // alert(res.data.message);
                    // window.location.href = "/";
                    // navigate
                    window.scrollTo(0, 0);
                    navigate("/", {
                        state: {
                            message: res.data.message,
                            status: res.data.status,
                            exist: true,
                        },
                    });
                } else if (res.data.status === 429) {
                    setAPIERROR(res.data.response);
                    setPreview(false);
                    window.scrollTo(0, 0);
                } else if (res.data.status == 401) {
                    HandleTokenExpired();
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    const ValidateDescription = () => {
        // ListingDescription.getCurrentContent().getPlainText()
        // console.log(
        //   "key down =",
        //   ListingDescription.getCurrentContent().getPlainText()
        // );
        // console.log(
        //   "description: ",
        //   ListingDescription.getCurrentContent().getPlainText()
        // );
        let description = ListingDescription.getCurrentContent().getPlainText();
        let desc,
            counter = 0;
        for (let i = 0; i < description.length; i++) {
            desc = description[i];
            if (!isNaN(desc)) {
                counter++;
            } else if (isNaN(desc)) {
                counter = 0;
            }
        }
        // if (counter.length > 4) {
        //   setMessageError(true);
        //   console.log("counter =", counter);
        // }

        let arr = [];
        Numb.map((numberCheck) => {
            if (
                ListingDescription.getCurrentContent()
                    .getPlainText()
                    .includes(numberCheck)
            ) {
                arr.push(true);
            } else {
                setMessageError(false);
            }
        });
        if (arr.length > 0 || counter > 4) {
            setMessageError(true);
        }
    };

    const AMENITIES_SET = () => {
        let i = 0;
        console.log("array _ ammenities =", ARRAY_AMENITIES);

        for (i = 0; i < ARRAY_AMENITIES.length; i++) {
            Amenities.some((element) => {
                if (element.id == ARRAY_AMENITIES[i]) {
                    console.log("element.id =", ARRAY_AMENITIES[i],element.id);
                    if (
                        SELECTED_ARRAY_AMENITIES_NAME.indexOf(element) == -1
                    ) {
                        SELECTED_ARRAY_AMENITIES_NAME.push(element);
                    }

                }
            });
        }
    };

    const HOUSERULES_SET = () => {
        let k = 0;
        console.log("Array_house rules =", ARRAY_HOUSE_RULES);
        for (k = 0; k < ARRAY_HOUSE_RULES.length; k++) {
            HouseRules.some((element) => {
                // console.log("house rules.id =", element.rule);
                if (element.id == ARRAY_HOUSE_RULES[k]) {

                    if (
                        SELECTED_ARRAY_HOUSERULES_NAME.indexOf(element) == -1
                    ) {
                        SELECTED_ARRAY_HOUSERULES_NAME.push(element);
                    }
                }
            });
        }
    };

    const LANGUAGES_SET = () => {
        let j = 0;
        console.log("Array_languages =", ARRAY_LANGUAGES);
        for (j = 0; j < ARRAY_LANGUAGES.length; j++) {
            Languages.some((element) => {
                // console.log("house rules.id =", element.language);
                if (element.id == ARRAY_LANGUAGES[j]) {
                    if (
                        SELECTED_ARRAY_LANGUAGES_NAME.indexOf(element) == -1
                    ) {
                        SELECTED_ARRAY_LANGUAGES_NAME.push(element);
                    }
                }
            });
        }
    };

    const HandlePreviewListing = () => {
        AMENITIES_SET();
        HOUSERULES_SET();
        LANGUAGES_SET();

        // console.log(
        //   "SELECTED_ARRAY_AMENITIES_NAME =",
        //   SELECTED_ARRAY_AMENITIES_NAME
        // );
        // console.log(
        //   "SELECTED_ARRAY_HOUSERULES_NAME =",
        //   SELECTED_ARRAY_HOUSERULES_NAME
        // );
        // console.log(
        //   "SELECTED_ARRAY_LANGUAGES_NAME =",
        //   SELECTED_ARRAY_LANGUAGES_NAME
        // );

        setPreview(!Preview);
        window.scrollTo(0, 0);
    };

    // console.log('ListingDescription.getCurrentContent().getPlainText() =',ListingDescription.getCurrentContent().getPlainText())
    {
        // console.log("amenities =", ARRAY_AMENITIES);
    }

    useEffect(() => {
        redirectToLogin(props);
        if (!Preview) {
            if (loc.state != null) {
                setEditListing(true);
                // console.log("data recived = ", loc.state.ListingDetail);
                const ListingToEdit = loc.state.ListingDetail;
                get(
                    EditListingEndPoint + ListingToEdit.uid,
                    "LOCAL",
                    "",
                    true,
                    localStorage.getItem("token")
                )
                    .then((res) => {
                        if (res.data.status === 200) {
                            console.log("Edit res =", res.data.response);
                            setEditListingData(res.data.response);
                            setListingName(res.data.response.name);
                            // setListingAddress(res.data.response.)
                            setListingAccountTitle(
                                res.data.response.property_payment_method.account_title
                            );
                            setListingAccountNumber(
                                res.data.response.property_payment_method.account_number
                            );
                            setListingLatitude(res.data.response.latitude);
                            setListingLongitude(res.data.response.longitude);
                            setListingNumberOfBeds(res.data.response.number_of_beds);
                            setListingNumberOfGuests(
                                res.data.response.maximum_number_of_guests_per_reservation
                            );
                            setListingRooms(res.data.response.number_of_rooms);
                            setListingPricePerDay(Math.trunc(res.data.response.rate));
                            setListingCNIC(res.data.response.vendor.cnic);
                            setListingPhoneNumber(res.data.response.vendor.phone_number);
                            setListingPaymentMethod(
                                res.data.response.property_payment_method.payment_method_id
                            );
                            setListingEmail(res.data.response.vendor.email);
                            ARRAY_IMAGE = res.data.response.images;
                            console.log("res img =", ARRAY_IMAGE);
                            setListingImage(res.data.response.images);
                            GetLocation();
                            setListingClosedDates(
                                res.data.response.property_unavailable_dates
                            );
                            setListingBankName(res.data.response.payment_method_id);
                            setListingPropertyType(res.data.response.property_type_id);
                            Object.values(res.data.response.property_amenities).map((value) =>
                                ARRAY_AMENITIES.push(value)
                            );
                            Object.values(res.data.response.property_unavailable_dates).map(
                                (value) => ARRAY_CLOSED_DATES.push(value)
                            );
                            Object.values(res.data.response.property_rules).map((value) =>
                                ARRAY_HOUSE_RULES.push(value)
                            );
                            Object.values(res.data.response.host_languages).map((value) =>
                                ARRAY_LANGUAGES.push(value)
                            );
                            setListingDescriptionEDIT(res.data.response.description);
                            // setListingOtherCities(res.data.response.)

                            // setListingDescription(res.data.response.)
                            // setListingDescription(res.data.response.description);
                        } else if (res.data.status == 401) {
                            HandleTokenExpired();
                        } else {
                            // setLoading(true);
                        }
                    })
                    .catch((error) => {
                        // setLoading(true);
                        console.log(error);
                    });

                // setEditListingData(loc.state.ListingDetail)
            }

            get(NewListingEndPoint, "LOCAL", "", true, localStorage.getItem("token"))
                .then((res) => {
                    if (res.data.status === 200) {
                        setPropertyTypes(res.data.response.property_types);
                        setAmenities(res.data.response.amenities);
                        setHouseRules(res.data.response.house_rules);
                        setLanguages(res.data.response.languages);
                        setPaymentMethod(res.data.response.payment_methods);
                        setBanks(res.data.response.banks);
                        setCities(res.data.response.cities);
                        SetGettingAPIData(false);
                    } else if (res.data.status == 401) {
                        HandleTokenExpired();
                    } else {
                        // console.log("false response");
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);
    if (
        ListingPhoneNumber === "" ||
        ListingPhoneNumber === "(" ||
        ListingPhoneNumber === "(+" ||
        ListingPhoneNumber === "(+9" ||
        ListingPhoneNumber === "(+92"
    ) {
        setListingPhoneNumber("(+92)");
    }

    function validatePhoneNumber(e) {
        let slicedInputNumber = ListingPhoneNumber;
        let inputNumber = e.target.value;

        if (ListingPhoneNumber.length > 14) {
            slicedInputNumber = inputNumber.slice(0, -1);
            setListingPhoneNumber(slicedInputNumber);
        } else {
            setListingPhoneNumber(inputNumber);
        }
    }

    // console.log(
    //   "listing lat and lng =",
    //   ListingLatitude + " and " + ListingLongitude
    // );

    if (
        ListingWhatsAppNumber === "" ||
        ListingWhatsAppNumber === "(" ||
        ListingWhatsAppNumber === "(+" ||
        ListingWhatsAppNumber === "(+9" ||
        ListingWhatsAppNumber === "(+92"
    ) {
        setListingWhatsAppNumber("(+92)");
    }

    function validateWhatsAppNumber(e) {
        let slicedInputNumber = ListingWhatsAppNumber;
        let inputNumber = e.target.value;

        if (ListingWhatsAppNumber.length > 16) {
            slicedInputNumber = inputNumber.slice(0, -1);
            setListingWhatsAppNumber(slicedInputNumber);
        } else {
            setListingWhatsAppNumber(inputNumber);
        }
    }

    function validateCnic(e) {
        let inputNumber = e.target.value;
        let slicedInputNumber = ListingCNIC;
        // console.log("cnic_count:", ListingCNIC.length);
        if (ListingCNIC.length >= 13) {
            slicedInputNumber = inputNumber.slice(0, -1);
            // slicedInputNumber = slicedInputNumber.slice(0,5) + '-' + slicedInputNumber.slice(5, 12) + '-' + slicedInputNumber.slice(12,13)
            if (alphabet_regex.test(slicedInputNumber)) {
                setListingCNIC(slicedInputNumber);
            }
        } else {
            // if (alphabet_regex.test(inputNumber)){
            setListingCNIC(inputNumber);
            // }
        }
    }

    const GetLocation = () => {
        let lat = "",
            lng = "";

        navigator.geolocation.getCurrentPosition(function (position) {
            lat = position.coords.latitude;
            setListingLatitude(lat);
            lng = position.coords.longitude;
            setListingLongitude(lng);
            // console.log("rin");

            if (loc.state != null) {
                lat = ListingLatitude;
                lng = ListingLongitude;
            }

            // Geocode.setApiKey(GoogleMapsKey);

            Geocode.fromLatLng(lat, lng, GoogleMapsKey).then(
                (response) => {
                    console.log("geocode =", response);
                    const address = response.results[0].formatted_address;
                    let city, state, country;
                    for (
                        let i = 0;
                        i < response.results[0].address_components.length;
                        i++
                    ) {
                        for (
                            let j = 0;
                            j < response.results[0].address_components[i].types.length;
                            j++
                        ) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    state = response.results[0].address_components[i].long_name;
                                    break;
                                case "country":
                                    country = response.results[0].address_components[i].long_name;
                                    break;
                            }
                        }
                    }
                    setListingAddress(address);
                    setListingCities(city);

                    // console.log(city, state, country);

                    // console.log(address);
                },
                (error) => {
                    console.log("geocode error =", error);
                    console.error(error);
                }
            );
        });
    };

    return GettingAPIData ? (
        <>
            <div className="loading"></div>
        </>
    ) : (
        <>
            <NavBar hideHeaderLogoDisplay={true}/>
            <div id="dashboard">
                {/* Navigation
	================================================== */}
                {/* Responsive Navigation Trigger */}
                <Sidebar activePage={"dashboard-add-listing"}/>
                {/* Navigation / End */}
                {/* Content
	================================================== */}
                {Preview ? (
                    <PreviewListing
                        ErrorNumberOfGuests={ErrorNumberOfBeds}
                        ErrorNumberOfBeds={ErrorNumberOfBeds}
                        PriceError={PriceError}
                        ErrorAccountNumber={ErrorAccountNumber}
                        ErrorAccountTitle={ErrorAccountTitle}
                        MessageError={MessageError}
                        CNICError={ErrorCNIC}
                        isLoaded={isLoaded}
                        EditListing={loc.state != null ? loc.state : null}
                        HandlePreviewListing={HandlePreviewListing}
                        name={ListingName}
                        description={
                            loc.state != null &&
                            ListingDescription.getCurrentContent().getPlainText() == ""
                                ? ListingDescriptionEDIT
                                : ListingDescription.getCurrentContent().getPlainText()
                        }
                        property_type={ListingPropertyType}
                        amenities={SELECTED_ARRAY_AMENITIES_NAME}
                        house_rules={SELECTED_ARRAY_HOUSERULES_NAME}
                        languages={SELECTED_ARRAY_LANGUAGES_NAME}
                        address={ListingAddress}
                        lat={ListingLatitude}
                        lng={ListingLongitude}
                        city={ListingCities}
                        other_city={ListingOtherCities}
                        images={ARRAY_IMAGE}
                        email={ListingEmail}
                        phone_number={ListingPhoneNumber}
                        whatsapp_number={ListingWhatsAppNumber}
                        cnic={ListingCNIC}
                        payment_method={ListingPaymentMethod}
                        account_number={ListingAccountNumber}
                        account_title={ListingAccountTitle}
                        bank_id={ListingBankName}
                        price_per_day={ListingPricePerDay}
                        number_of_beds={ListingNumberOfBeds}
                        number_of_guests={ListingNumberOfGuests}
                        closed_dates={ListingClosedDates}
                        number_of_rooms={ListingRooms}
                        APICALL={APICALL}
                    />
                ) : (
                    <div className="dashboard-content">
                        {/* Titlebar */}
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Add Listing</h2>
                                    {/* Breadcrumbs */}
                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard">Dashboard</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard-add-listing">Add Listing</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            {loc.state != null ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="notification closeable notice">
                                            <p>
                                                You are currently editing:{" "}
                                                <a
                                                    href={"listing-detail/" + loc.state.ListingDetail.uid}
                                                >
                                                    {loc.state.ListingDetail.name}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}{" "}
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div id="add-listing">
                                    {/* Section */}
                                    <div className="add-listing-section">
                                        {/* Headline */}
                                        <div className="add-listing-headline">
                                            <h3>
                                                <i className="sl sl-icon-doc"/> Basic Informations
                                            </h3>
                                            {APIERROR == null ? (
                                                ""
                                            ) : (
                                                <span className="error">
                          {" "}
                                                    <ul>
                            {Object.values(APIERROR).map((value) => (
                                <li className="bg-red">{value}</li>
                            ))}
                          </ul>
                                                    {/* {JSON.stringify(APIERROR)
                            .replace(/[[\]]/g, "")
                            .toString()} */}
                        </span>
                                            )}
                                        </div>
                                        {/* Title */}
                                        {/* Row */}
                                        <div className="row with-forms">
                                            {/* Status */}
                                            <div className="col-md-4">
                                                <h5>Property types</h5>
                                                <select
                                                    onChange={(e) =>
                                                        setListingPropertyType(e.target.value)
                                                    }
                                                    className="chosen-select-no-single"
                                                >
                                                    <option
                                                        label={
                                                            loc.state == null
                                                                ? "Select Property Types"
                                                                : EditListingData == null
                                                                    ? "Getting Data"
                                                                    : EditListingData.property_type
                                                        }
                                                    >
                                                        {loc.state == null
                                                            ? "Select Property Types"
                                                            : EditListingData == null
                                                                ? "Getting Data"
                                                                : EditListingData.property_type}
                                                    </option>
                                                    {Object.values(PropertyTypes).map((value, index) => (
                                                        <option value={value.id}>
                                                            {value.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* Type */}
                                            {ListingPropertyType == 6 ? (
                                                <>
                                                    <div className="col-md-4">
                                                        <h5>
                                                            Room Type{" "}
                                                            {/* <i
                                            className="tip"
                                            data-tip-content="Maximum of 15 keywords related with your business"
                                          /> */}
                                                        </h5>
                                                        <input
                                                            placeholder={
                                                                loc.state == null
                                                                    ? "Standard, Deluxe"
                                                                    : EditListingData == null
                                                                        ? "Getting Data"
                                                                        : EditListingData.name
                                                            }
                                                            value={ListingName}
                                                            onChange={(e) => setListingName(e.target.value)}
                                                            type="text"
                                                        />
                                                        {/* {console.log('value in name = ', ListingName)} */}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <h5>
                                                            Rooms{" "}
                                                            {/* <i
                                            className="tip"
                                            data-tip-content="Maximum of 15 keywords related with your business"
                                          /> */}
                                                        </h5>
                                                        <input
                                                            value={ListingRooms}
                                                            placeholder={
                                                                loc.state == null
                                                                    ? "0"
                                                                    : EditListingData == null
                                                                        ? "Getting Data"
                                                                        : EditListingData.number_of_rooms
                                                            }
                                                            onChange={(e) =>
                                                                isNaN(e.target.value) === false
                                                                    ? setListingRooms(e.target.value)
                                                                    : ""
                                                            }
                                                            type="number"
                                                        />
                                                        {/* {console.log('value in name = ', ListingName)} */}
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="col-md-4">
                                                    <h5>
                                                        Listing Title{" "}
                                                        {/* <i
                          className="tip"
                          data-tip-content="Maximum of 15 keywords related with your business"
                        /> */}
                                                    </h5>
                                                    <input
                                                        placeholder={
                                                            loc.state == null
                                                                ? "Listing Name"
                                                                : EditListingData == null
                                                                    ? "Getting Data"
                                                                    : EditListingData.name
                                                        }
                                                        value={ListingName}
                                                        onChange={(e) => setListingName(e.target.value)}
                                                        type="text"
                                                    />
                                                    {/* {console.log('value in name = ', ListingName)} */}
                                                </div>
                                            )}
                                        </div>
                                        {/* Row / End */}
                                        {/* Checkboxes */}
                                        <h5 className="margin-top-30 margin-bottom-10">
                                            Amenities
                                            {/* <span>(optional)</span> */}
                                        </h5>
                                        <div className="checkboxes in-row margin-bottom-20">
                                            {Object.values(Amenities).map((value, index) => (
                                                <>
                                                    <input
                                                        value={value.id}
                                                        onClick={(e) => {
                                                            // console.log("clicked on =", e.target.value);
                                                            if (
                                                                ARRAY_AMENITIES.indexOf(e.target.value) == -1
                                                            ) {
                                                                ARRAY_AMENITIES.push(e.target.value);
                                                            } else {
                                                                ARRAY_AMENITIES = ARRAY_AMENITIES.filter(
                                                                    (item) => item != e.target.value
                                                                );
                                                            }
                                                        }}
                                                        checked={
                                                            (EditListingData?.property_amenities?.includes(value.id) == true || SELECTED_ARRAY_AMENITIES_NAME.includes(value) == true)
                                                                ? true
                                                                : null
                                                        }
                                                        id={value.name}
                                                        type="checkbox"
                                                        name="check"
                                                    />
                                                    <label htmlFor={value.name}>{value.name}</label>
                                                </>
                                            ))}
                                        </div>
                                        {/* Checkboxes / End */}
                                        {/* Checkboxes */}
                                        <h5 className="margin-top-30 margin-bottom-10">
                                            Host Language
                                            {/* <span>(optional)</span> */}
                                        </h5>
                                        <div className="checkboxes in-row margin-bottom-20">
                                            {Object.values(Languages).map((value, index) => (
                                                <>
                                                    <input
                                                        id={value.language}
                                                        value={value.id}
                                                        onClick={(e) => {
                                                            if (
                                                                ARRAY_LANGUAGES.indexOf(e.target.value) == -1
                                                            ) {
                                                                ARRAY_LANGUAGES.push(e.target.value);
                                                            } else {
                                                                ARRAY_LANGUAGES = ARRAY_LANGUAGES.filter(
                                                                    (item) => item != e.target.value
                                                                );
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        name="check"
                                                        checked={
                                                            (EditListingData?.host_languages?.includes(value.id) == true || SELECTED_ARRAY_LANGUAGES_NAME.includes(value) == true)
                                                                    ? true
                                                                    : null
                                                        }
                                                        // checked={

                                                        //   EditListingData != null
                                                        //     ? EditListingData.host_languages.includes(
                                                        //         value.id
                                                        //       )
                                                        //     : false
                                                        // }
                                                    />
                                                    <label htmlFor={value.language}>
                                                        {value.language}
                                                    </label>
                                                </>
                                            ))}
                                        </div>
                                        {/* Checkboxes / End */}
                                        {/* Checkboxes */}
                                        <h5 className="margin-top-30 margin-bottom-10">
                                            House Rules
                                            {/* <span>(optional)</span> */}
                                        </h5>
                                        <div className="checkboxes in-row margin-bottom-20">
                                            {Object.values(HouseRules).map((value, index) => (
                                                <>
                                                    <input
                                                        id={value.rule}
                                                        value={value.id}
                                                        onClick={(e) => {
                                                            if (
                                                                ARRAY_HOUSE_RULES.indexOf(e.target.value) == -1
                                                            ) {
                                                                ARRAY_HOUSE_RULES.push(e.target.value);
                                                            } else {
                                                                ARRAY_HOUSE_RULES = ARRAY_HOUSE_RULES.filter(
                                                                    (item) => item != e.target.value
                                                                );
                                                            }
                                                        }}
                                                        type="checkbox"
                                                        name="check"
                                                        checked={
                                                        (EditListingData?.host_languages?.includes(value.id) == true || SELECTED_ARRAY_HOUSERULES_NAME.includes(value) == true)
                                                                    ? true
                                                                    : null
                                                        }
                                                    />
                                                    <label htmlFor={value.rule}>{value.rule}</label>
                                                </>
                                            ))}
                                        </div>
                                        {/* Checkboxes / End */}
                                    </div>
                                    {/* Section / End */}
                                    {/* Section */}
                                    <div className="add-listing-section margin-top-45">
                                        {/* Headline */}
                                        <div className="add-listing-headline">
                                            <h3>
                                                <i className="fa fa-map-marker"/> Location
                                            </h3>
                                        </div>
                                        {/* {console.log("lat =", ListingLatitude)}
                    {console.log("lng =", ListingLongitude)} */}
                                        <GOOGLEMAPS
                                            loc={"addlisting"}
                                            lat={ListingLatitude}
                                            lng={ListingLongitude}
                                            isLoaded={isLoaded}
                                        />
                                        <div className="row">
                                            <div className="col-md-12"></div>
                                        </div>
                                        <div className="submit-section">
                                            {/* Row */}
                                            <div className="row with-forms">
                                                {/* Address */}
                                                <div className="col-md-6">
                                                    <h5>Address</h5>
                                                    <div className="input-with-icon location">
                                                        {/* <input
                              type="text"
                              value={ListingAddress}
                              onChange={(e) =>
                                setListingAddress(e.target.value)
                              }
                              placeholder="e.g. 964 School Street"
                            />

                            <a onClick={GetLocation}>
                              <i class="tooltip left fa fa-map-marker"></i>
                            </a> */}
                                                        <Autocomplete
                                                            // apiKey={GoogleMapsKey}
                                                            onPlaceSelected={(place) => {
                                                                // console.log("place =", place.formatted_address === undefined);
                                                                if (place.formatted_address !== undefined) {
                                                                    setListingAddress(place.formatted_address);
                                                                    setListingLatitude(
                                                                        place.geometry.location.lat()
                                                                    );
                                                                    setListingLongitude(
                                                                        place.geometry.location.lng()
                                                                    );

                                                                    let ob = Cities.find(
                                                                        (obj) =>
                                                                            obj.name ==
                                                                            place.address_components[0].short_name
                                                                    );

                                                                    if (ob != undefined) {
                                                                        setListingCities(ob.name);
                                                                    }
                                                                } else {
                                                                    alert("Please select correct location.");
                                                                }
                                                            }}
                                                        ></Autocomplete>
                                                        <a onClick={GetLocation}>
                                                            <i class="tooltip left fa fa-map-marker"></i>
                                                        </a>{" "}
                                                    </div>
                                                </div>
                                                {/* Address */}
                                                <div className="col-md-6">
                                                    <h5>Address Details (optional)</h5>
                                                    <input
                                                        type="text"
                                                        value={ListingAddress}
                                                        onChange={(e) => setListingAddress(e.target.value)}
                                                        placeholder="Enter details such as Street No., House No."
                                                    />
                                                </div>

                                                {/* City */}
                                                <div className="col-md-4">
                                                    <h5>City</h5>
                                                    <select
                                                        value={ListingCities}
                                                        onChange={(e) => setListingCities(e.target.value)}
                                                        className="chosen-select-no-single"
                                                    >
                                                        <option
                                                            label={
                                                                loc.state == null
                                                                    ? "Select City"
                                                                    : EditListingData == null
                                                                        ? "Getting Data"
                                                                        : EditListingData.city
                                                            }
                                                        >
                                                            {loc.state == null
                                                                ? "Select City"
                                                                : EditListingData == null
                                                                    ? "Getting Data"
                                                                    : EditListingData.city}
                                                        </option>
                                                        {Object.values(Cities).map((value, index) => (
                                                            <>
                                                                <option value={value.name}>{value.name}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* City */}
                                                <div className="col-md-4">
                                                    <h5>If any other city, specify (optional)</h5>
                                                    <input type="text"/>
                                                </div>
                                                {/* Longitude */}
                                                <div className="col-md-2">
                                                    <h5>Longitude</h5>
                                                    <input
                                                        value={ListingLongitude}
                                                        onChange={(e) =>
                                                            setListingLongitude(e.target.value)
                                                        }
                                                        type="number"
                                                    />
                                                </div>
                                                {/* Latitude */}
                                                <div className="col-md-2">
                                                    <h5>Latitude</h5>
                                                    <input
                                                        value={ListingLatitude}
                                                        onChange={(e) => setListingLatitude(e.target.value)}
                                                        type="number"
                                                    />
                                                </div>
                                            </div>
                                            {/* Row / End */}
                                        </div>
                                    </div>
                                    {/* Section / End */}
                                    {/* Section */}
                                    <div className="add-listing-section margin-top-45">
                                        {/* Headline */}
                                        <div className="add-listing-headline">
                                            <h3>
                                                <i className="sl sl-icon-picture"/> Gallery
                                            </h3>
                                        </div>
                                        <div className="notification closeable notice">
                                            <p className="description" id="_gallery-description">
                                                Upload a minimum of {SETTING.MIN_LISTING_IMAGES} pictures for better
                                                display.
                                            </p>
                                        </div>
                                        {/* {console.log("leng =", ARRAY_IMAGE.length)} */}

                                        <div onClick={handleClick} class="dropzone dz-clickable">
                                            <div
                                                class={
                                                    ARRAY_IMAGE.length > 0
                                                        ? "hidden"
                                                        : "dz-default dz-message"
                                                }
                                            >
                        <span>
                          <i class="sl sl-icon-plus"></i> Click here or drop
                          files to upload
                        </span>
                                            </div>
                                            {/* {console.log("lengt =", ARRAY_IMAGE.length)} */}
                                            {ARRAY_IMAGE.length > 0 ? (
                                                <div className="row">
                                                    {/* { let size = ARRAY_IMAGE.length} */}
                                                    {/* {console.log('img ===', SelectedFile)} */}
                                                    {ARRAY_IMAGE.map((value, index) => (
                                                        <>
                                                            {/* {console.log("value in images =", value)} */}
                                                            <div id={value} className="col-md-3">
                                                                <img
                                                                    alt={"Image"}
                                                                    width={"200px"}
                                                                    height={"150px"}
                                                                    src={
                                                                        loc.state != null &&
                                                                        EditListing &&
                                                                        typeof value === "string"
                                                                            ? value
                                                                            : URL.createObjectURL(value)
                                                                    }
                                                                />
                                                                <br/>
                                                                <button
                                                                    className="remove-btn"
                                                                    onClick={() => {
                                                                        // console.log("something worked = ",
                                                                        // ARRAY_IMAGE.splice(index, 1)[0]
                                                                        // );
                                                                        ARRAY_IMAGE.push(
                                                                            ARRAY_IMAGE.splice(index, 1)[0]
                                                                        );
                                                                        ARRAY_IMAGE.pop();
                                                                        document.getElementById(value).remove();
                                                                        // setRefresh(!Refresh);
                                                                    }}
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                            {/* <div className="col-md-3">
                                <i class="sl sl-icon-plus vertical-center"></i>
                            </div> */}
                                                        </>
                                                    ))}
                                                    <div className="col-md-3">
                            <span>
                              <i class="sl sl-icon-plus"></i> Click here or drop
                              files to upload
                            </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            <input
                                                id="file_input"
                                                multiple
                                                type="file"
                                                style={{display: "none"}}
                                                name="myImage"
                                                onChange={(event) => {
                                                    if (event.target.files[0]) {
                                                        // console.log(
                                                        //   "event.target.files =",
                                                        //   event.target.files
                                                        // );
                                                        setListingImage(event.target.files[0]);
                                                        for (
                                                            let i = 0;
                                                            i < event.target.files.length;
                                                            i++
                                                        ) {
                                                            // console.log(
                                                            //   "value in image target =",
                                                            //   event.target.files[i]
                                                            // );
                                                            ARRAY_IMAGE.push(event.target.files[i]);
                                                        }

                                                        setSelectedFile(event.target.files[0]);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {/* Dropzone */}
                                        {/* <div className="submit-section">
                    <form action="/file-upload" className="dropzone">
                      <div>
                        {SelectedFile && (
                          <div>
                            <img
                              alt="not fount"
                              width={"250px"}
                              src={URL.createObjectURL(SelectedFile)}
                            />
                            <br />
                            <button onClick={() => setSelectedFile(null)}>
                              Remove
                            </button>
                          </div>
                        )}
                        <br />

                        <br />
                        <input
                          multiple
                          type="file"
                          name="myImage"
                          onChange={(event) => {
                            console.log("imgae = ", event);
                            console.log(event.target.files[0]);
                            setListingImage(event.target.files[0]);
                            ARRAY_IMAGE.push(event.target.files[0]);
                            console.log("array image = ", ListingImage);
                            setSelectedFile(event.target.files[0]);
                          }}
                        />
                      </div>{" "}
                    </form>
                  </div> */}
                                        {/* <ImageUploadPreviewComponent /> */}
                                    </div>
                                    {/* Section / End */}
                                    {/* Section */}
                                    <div className="add-listing-section margin-top-45">
                                        {/* Headline */}
                                        <div className="add-listing-headline">
                                            <h3>
                                                <i className="sl sl-icon-docs"/> Details
                                            </h3>
                                        </div>
                                        {/* Description */}
                                        <div className="form">
                                            <h5>Description</h5>
                                            <div class="notification closeable notice">
                                                <p
                                                    class="description"
                                                    id="listing_description-description"
                                                >
                                                    Relevant description e.g Add some details about your
                                                    property listing.
                                                </p>
                                            </div>
                                            {MessageError && (
                                                <span className="error">
                          Please do not Enter Mobile Number in Message i.e sifr,
                          zero, one ...
                        </span>
                                            )}
                                            <Editor
                                                editorState={ListingDescription}
                                                onChange={() => ValidateDescription()}
                                                // toolbarClassName="toolbarClassName"
                                                // wrapperClassName="wrapperClassName"
                                                // editorClassName="editorClassName"
                                                onEditorStateChange={(e) => setListingDescription(e)}

                                                // onChange={(e)=>setListingDescription(e.target.value)}
                                            />
                                            {/* {console.log(
                        "Listing =",
                        ListingDescription.getCurrentContent().getPlainText()
                      )} */}
                                            {/* {console.log("listing description =", ListingDescription)} */}
                                            {/* ;
                    <textarea
                      className="WYSIWYG"
                      name="summary"
                      cols={40}
                      rows={3}
                      value={ListingDescription}
                      onChange={(e) => setListingDescription(e.target.value)}
                      id="summary"
                      spellCheck="true"
                      defaultValue={""}
                    /> */}
                                        </div>
                                        {/* Row */}
                                        <div className="row with-forms">
                                            {/* Phone */}
                                            <div className="col-md-3">
                                                <h5>
                                                    <span>Phone</span>
                                                </h5>
                                                {ErrorPhoneNumber && (
                                                    <span className="error">
                        Please Enter A Valid Phone Number
                      </span>
                                                )}
                                                <InputMask
                                                    placeholder="+92"
                                                    mask="(+\9\2)999-999-9999"
                                                    maskChar=" "
                                                    value={ListingPhoneNumber}
                                                    onChange={(e) => handleChangePhone(e)}
                                                />
                                            </div>
                                            {/* Email Address */}
                                            <div className="col-md-3">
                                                <h5>
                                                    <span>E-mail</span>
                                                </h5>
                                                {ErrorEmail && (
                                                    <span className="error">
                        Please Enter A Valid Email Address
                      </span>
                                                )}
                                                <input
                                                    value={ListingEmail}
                                                    onChange={(e) => handleChangeEmail(e)}
                                                    placeholder=""
                                                    type="email"
                                                />
                                            </div>
                                            {/* Whatsapp */}
                                            <div className="col-md-3">
                                                <h5>
                                                    <i className="fa fa-whatsapp"/>
                                                    <span>Whatsapp</span>
                                                </h5>
                                                {ErrorWhatsAppNumber && (
                                                    <span className="error">
                        Please Enter A Valid WhatsApp Number
                      </span>
                                                )}
                                                <InputMask
                                                    placeholder="+92"
                                                    mask="(+\9\2)999-999-9999"
                                                    maskChar=" "
                                                    value={ListingWhatsAppNumber}
                                                    onChange={(e) => handleChangeWhatsapp(e)}
                                                    // type="tel"
                                                />
                                            </div>
                                            {/* CNIC */}
                                            <div className="col-md-3">
                                                <h5>
                                                    <span>CNIC</span>
                                                </h5>
                                                {ErrorCNIC && (
                                                    <>
                                                        &nbsp;
                                                        <span className="error">Enter CNIC Number</span>
                                                    </>
                                                )}
                                                {/* <MaskedInput
                      mask={[/[1-9]/, /\d/, /\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,]}
                      /> */}
                                                {/* {console.log('lisintg cnic=', ListingCNIC)} */}
                                                <InputMask
                                                    mask="99999-9999999-9"
                                                    maskChar=" "
                                                    value={ListingCNIC}
                                                    onChange={(e) => setListingCNIC(e.target.value)}
                                                    onInput={validateCnic}
                                                    // placeholder="_ _ _ - _ _ _ - _ _ _"
                                                    onKeyUp={() =>
                                                        ListingCNIC <= 0
                                                            ? setErrorCNIC(true)
                                                            : setErrorCNIC(false)
                                                    }
                                                />
                                                {/* <input
                          value={ListingCNIC}
                          onChange={(e) =>
                            isNaN(e.target.value) === false
                              ? setListingCNIC(e.target.value)
                              : ""
                          }
                          onInput={validateCnic}
                          placeholder="_ _ _ - _ _ _ - _ _ _"
                          type="tel"
                          maxLength={13}
                          onKeyDown={() =>
                            ListingCNIC <= 0
                              ? setErrorCNIC(true)
                              : setErrorCNIC(false)
                          }
                          pattern="^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$"
                        /> */}
                                            </div>
                                        </div>
                                        {/* Row / End */}
                                        {/* Row */}
                                        <div className="row with-forms">
                                            {/* Payment Method */}
                                            <div className="col-md-3">
                                                <span>Payment Method</span>
                                                <select
                                                    onClick={(e) =>
                                                        setListingPaymentMethod(e.target.value)
                                                    }
                                                    className="chosen-select-no-single"
                                                >
                                                    <option
                                                        label={
                                                            loc.state == null
                                                                ? "Select Payment Method"
                                                                : EditListingData == null
                                                                    ? "Getting Data"
                                                                    : EditListingData.property_payment_method
                                                                        .payment_method
                                                        }
                                                    >
                                                        {loc.state == null
                                                            ? "Select Payment Method"
                                                            : EditListingData == null
                                                                ? "Getting Data"
                                                                : EditListingData.property_payment_method
                                                                    .payment_method}{" "}
                                                    </option>
                                                    {Object.values(PaymentMethod).map((value, index) => (
                                                        <>
                                                            <option value={value.id}>
                                                                {value.payment_method}
                                                            </option>
                                                        </>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* Bank */}
                                            {ListingPaymentMethod == 3 ? (
                                                <>
                                                    <div className="col-md-3">
                                                        <span>Choose Bank</span>
                                                        <select
                                                            onClick={(e) =>
                                                                setListingBankName(e.target.value)
                                                            }
                                                            className="chosen-select-no-single"
                                                        >
                                                            <option label="Choose Bank">Choose Bank</option>
                                                            {Object.values(Banks).map((value, index) => (
                                                                <>
                                                                    <option value={value.id}>{value.name}</option>
                                                                </>
                                                            ))}
                                                        </select>
                                                        {/* {console.log('bank id = ', ListingBankName)} */}
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                            {/* Account Number */}
                                            <div className="col-md-3">
                                                <span>Account Number</span>
                                                {ErrorAccountNumber && (
                                                    <>
                                                        &nbsp;
                                                        <span className="error">Must be Valid</span>
                                                    </>
                                                )}
                                                <input
                                                    type="number"
                                                    value={ListingAccountNumber}
                                                    onChange={(e) =>
                                                        isNaN(e.target.value) === false
                                                            ? setListingAccountNumber(e.target.value)
                                                            : ""
                                                    }
                                                    onKeyUp={() =>
                                                        ListingAccountNumber.length <= 10
                                                            ? setErrorAccountNumber(true)
                                                            : setErrorAccountNumber(false)
                                                    }
                                                    placeholder="Account Number"
                                                />
                                            </div>
                                            {/* Account Title */}
                                            <div className="col-md-3">
                                                <span>Account Title</span>
                                                {ErrorAccountTitle && (
                                                    <>
                                                        &nbsp;
                                                        <span className="error">Enter Account Title</span>
                                                    </>
                                                )}
                                                <input
                                                    value={ListingAccountTitle}
                                                    onChange={(e) =>
                                                        isNaN(e.target.value) == false
                                                            ? e.target.value == ""
                                                                ? setListingAccountTitle(e.target.value)
                                                                : ""
                                                            : setListingAccountTitle(e.target.value)
                                                    }
                                                    onKeyUp={() =>
                                                        ListingAccountTitle == ""
                                                            ? setErrorAccountTitle(true)
                                                            : setErrorAccountTitle(false)
                                                    }
                                                    type="text"
                                                    placeholder="Account Title"
                                                />
                                            </div>
                                        </div>
                                        {/* Row / End */}
                                    </div>
                                    {/* Section / End */}
                                    {/* Section */}
                                    <div className="add-listing-section margin-top-45">
                                        {/* Headline */}
                                        <div className="add-listing-headline">
                                            <h3>
                                                <i className="sl sl-icon-book-open"/> Booking prices
                                                and settings
                                            </h3>
                                        </div>
                                        {/* Switcher ON-OFF Content */}
                                        <div className="row with-forms">
                                            <div className="col-md-4">
                                                <h5>
                                                    <span>Price Per Night</span>
                                                    {PriceError && (
                                                        <>
                                                            &nbsp;
                                                            <span className="error">
                                should greater than <b>{SETTING.MIN_LISTING_PRICE}</b>
                              </span>
                                                        </>
                                                    )}
                                                </h5>
                                                <input
                                                    // placeholder="Price must be minimum of 750 PKR"
                                                    value={ListingPricePerDay}
                                                    onChange={(e) =>
                                                        isNaN(e.target.value) === false
                                                            ? setListingPricePerDay(e.target.value)
                                                            : ""
                                                    }
                                                    onKeyUp={() => {
                                                        ListingPricePerDay <= parseInt(SETTING.MIN_LISTING_PRICE)
                                                            ? setPriceError(true)
                                                            : setPriceError(false)
                                                    }
                                                    }
                                                    type="tel"
                                                    min="750"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <h5>
                                                    <span>Beds</span>
                                                    {ErrorNumberOfBeds && (
                                                        <>
                                                            &nbsp;
                                                            <span className="error">
                                Number of Beds (Must be greater than 0)
                              </span>
                                                        </>
                                                    )}
                                                </h5>
                                                <input
                                                    // placeholder="Beds should be greater than 0"
                                                    value={ListingNumberOfBeds}
                                                    onKeyUp={() =>
                                                        ListingNumberOfBeds <= 0
                                                            ? setErrorNumberOfBeds(true)
                                                            : setErrorNumberOfBeds(false)
                                                    }
                                                    onChange={(e) =>
                                                        setListingNumberOfBeds(e.target.value)
                                                    }
                                                    type="number"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <h5>
                                                    <span>Maximum Number of Guests</span>
                                                    {ErrorNumberOfGuests && (
                                                        <>
                                                            &nbsp;
                                                            <span className="error">Must be over 0</span>
                                                        </>
                                                    )}
                                                </h5>
                                                <input
                                                    // placeholder="Guests should be greater than 0"
                                                    value={ListingNumberOfGuests}
                                                    onKeyUp={() =>
                                                        ListingNumberOfGuests <= 0
                                                            ? setErrorNumberOfGuests(true)
                                                            : setErrorNumberOfGuests(false)
                                                    }
                                                    onChange={(e) =>
                                                        isNaN(e.target.value) === false
                                                            ? setListingNumberOfGuests(e.target.value)
                                                            : ""
                                                    }
                                                    type="tel"
                                                />
                                            </div>
                                        </div>
                                        {/* Switcher ON-OFF Content / End */}
                                    </div>
                                    {/* Section / End */}
                                    {/* Section */}
                                    <div className="add-listing-section margin-top-45 switcher-on">
                                        {/* Headline */}
                                        <div className="add-listing-headline">
                                            <h3>
                                                <i className="sl sl-icon-clock"/> Availability Calendar
                                            </h3>
                                        </div>
                                        {/* Switcher ON-OFF Content */}
                                        <div className="switcher-content ">
                                            <DaySelector
                                                ClosedD={setListingClosedDates}
                                                ClosedDays={ListingClosedDates}
                                                SelectD={
                                                    loc.state != null
                                                        ? EditListingData != null
                                                            ? EditListingData.property_unavailable_dates
                                                            : null
                                                        : null
                                                }
                                            />
                                        </div>
                                        {/* Switcher ON-OFF Content / End */}
                                    </div>
                                    {/* Section / End */}
                                    <a onClick={HandlePreviewListing} className="button preview">
                                        Preview <i className="fa fa-arrow-circle-right"/>
                                    </a>
                                </div>
                            </div>
                            {/* Copyrights */}
                        </div>
                    </div>
                )}
                {/* Content / End */}
            </div>
            {/* Dashboard / End */}
        </>
    );
};

export default AddListing;

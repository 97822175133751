import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
import ClaimThisListingForm from "../../Components/ListingDetail/ClaimThisListingForm";
import { useLocation } from "react-router-dom";

const ClaimThisListing = () => 
{

    let location = useLocation();
    return(
        <>
        <NavBar/>
        <ClaimThisListingForm LOC={location}/>
        <Footer/>
        </>
    );
}

export default ClaimThisListing;
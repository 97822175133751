import "../../App.css";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
// import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import { ContactUsEndpoint } from "../../Components/Axios/EndPoints";
import { post } from "../../Components/Axios/post";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import Toast from "../../Components/Main/Toast/Toast";

const containerStyle = {
  position: "static",
  width: "100%",
  height: "100%",
};

const center = {
  lat: 33.659669,
  lng: 73.0543899,
};

const ContactUs = () => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [MessageSend, setMessageSend] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCK7mxtbLJ5srlppcBbw-bTcKpDRfE_wHg",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const HandleSubmit = () => {
    let data = {
      name: Name,
      email: Email,
      subject: Subject,
      message: Message,
    };

    post(ContactUsEndpoint, "LOCAL", data, true, localStorage.getItem("token"))
      .then((res) => {
        setMessageSend(res.data);
        if (res.data.status == 200) {
          if (!isLoaded) {
            alert(res.data.message);
          }
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  setTimeout(() => {
    if (MessageSend != null) {
      setMessageSend(null);
    }
  }, 2000);

  return isLoaded ? (
    <>
      <div>
        {/* Wrapper */}
        <div id="wrapper">
          <NavBar />
          <div className="clearfix" />
          {/* Header Container / End */}
          {/* Content
    ================================================== */}
          {/* Map Container */}
          <div className="contact-map margin-bottom-60">
            {/* Google Maps */}

            <div id="singleListingMap-container">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                onUnmount={onUnmount}
              >
                <Marker
                  key="marker_1"
                  position={{
                    lat: 33.659669,
                    lng: 73.0543899,
                  }}
                />
              </GoogleMap>
              {/* <a href="#" id="streetView">
              Street View
            </a> */}
            </div>
            {/* Google Maps / End */}
            {MessageSend && (
              <Toast
                status={MessageSend.status}
                message={
                  MessageSend.message == ""
                    ? "Make Sure To Enter Valid Details"
                    : MessageSend.message
                }
              />
            )}
            {/* Office */}
            <div className="address-box-container">
              <div
                className="address-container"
                data-background-image="images/our-office.jpg"
              >
                <div className="office-address">
                  <h3>Our Office</h3>
                  <ul>
                    <li>I-9 Markaz, Islamabad</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Office / End */}
          </div>
          <div className="clearfix" />
          {/* Map Container / End */}
          {/* Container / Start */}
          <div className="container">
            <div className="row">
              {/* Contact Details */}
              <div className="col-md-4">
                <h4 className="headline margin-bottom-30">Find Us There</h4>
                {/* Contact Details */}
                <div className="sidebar-textbox">
                  <ul className="contact-details">
                    <li>
                      <i className="im im-icon-Envelope" />{" "}
                      <strong>E-Mail:</strong>{" "}
                      <span>
                        <a className='Mazebaan-color' href="mailto:contact@test.mazebaan.com">contact@test.mazebaan.com</a>
                      </span>
                    </li>
                    <li>
                      <i className="im im-icon-Phone-2" />{" "}
                      <strong>Phone:</strong>
                      <span className={"text-yellow"}><a className="Mazebaan-color" href="tel:0515163122">(051) 516-3122</a></span>
                    </li>
                    {/* <li><i className="im im-icon-Fax" /> <strong>Fax:</strong> <span>(123) 123-456 </span></li>
                        <li><i className="im im-icon-Globe" /> <strong>Web:</strong> <span><a href="#">www.example.com</a></span></li> */}
                  </ul>
                </div>
              </div>
              {/* Contact Form */}
              <div className="col-md-8">
                <section id="contact">
                  <h4 className="headline margin-bottom-35">Contact Form</h4>
                  <div id="contact-message" />
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <input
                          name="name"
                          type="text"
                          id="name"
                          value={Name}
                          onChange={(name) => setName(name.target.value)}
                          placeholder="Your Name"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <input
                          name="email"
                          type="email"
                          id="email"
                          value={Email}
                          onChange={(email) => setEmail(email.target.value)}
                          placeholder="Email Address"
                          pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      name="subject"
                      type="text"
                      id="subject"
                      value={Subject}
                      onChange={(subject) => setSubject(subject.target.value)}
                      placeholder="Subject"
                      required="required"
                    />
                  </div>
                  <div>
                    <textarea
                      name="comments"
                      cols={40}
                      rows={3}
                      id="comments"
                      placeholder="Message"
                      value={Message}
                      onChange={(message) => setMessage(message.target.value)}
                      spellCheck="true"
                      required="required"
                      defaultValue={""}
                    />
                  </div>
                  <input
                    type="submit"
                    onClick={HandleSubmit}
                    className="submit button"
                    id="submit"
                    defaultValue="Submit Message"
                  />
                </section>
              </div>
              {/* Contact Form / End */}
            </div>
          </div>
          {/* Container / End */}
          {/* Footer
    ================================================== */}
          <Footer />
          {/* Footer / End */}
          {/* Back To Top Button */}
          <div id="backtotop">
            <a href="#" />
          </div>
        </div>
        {/* Wrapper / End */}
        {/* Scripts
    ================================================== */}
        {/* Maps */}
      </div>
    </>
  ) : (
    // <GoogleMap
    //   mapContainerStyle={containerStyle}
    //   center={center}
    //   zoom={10}
    //   onLoad={onLoad}
    //   onUnmount={onUnmount}
    // ></GoogleMap>
    <>
      <div>
        {/* Wrapper */}
        <div id="wrapper">
          <NavBar />
          <div className="clearfix" />
          {/* Header Container / End */}
          {/* Content
    ================================================== */}
          {/* Map Container */}
          <div className="contact-map margin-bottom-60">
            {/* Google Maps */}

            <div id="singleListingMap-container">
              {/* <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
            ></GoogleMap> */}
              <a href="#" id="streetView">
                Street View
              </a>
            </div>
            {/* Google Maps / End */}
            {/* Office */}
            <div className="address-box-container">
              <div
                className="address-container"
                data-background-image="images/our-office.jpg"
              >
                <div className="office-address">
                  <h3>Our Office</h3>
                  <ul>
                    <li>I-9 Markaz, Islamabad</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Office / End */}
          </div>
          <div className="clearfix" />
          {/* Map Container / End */}
          {/* Container / Start */}
          <div className="container">
            <div className="row">
              {/* Contact Details */}
              <div className="col-md-4">
                <h4 className="headline margin-bottom-30">Find Us There</h4>
                {/* Contact Details */}
                <div className="sidebar-textbox">
                  <ul className="contact-details">
                    <li>
                      <i className="im im-icon-Envelope" />{" "}
                      <strong>E-Mail:</strong>{" "}
                      <span>
                        <a className="text-yellow" href="#">
                          contact@test.mazebaan.com
                        </a>
                      </span>
                    </li>
                    <li>
                      <i className="im im-icon-Phone-2" />{" "}
                      <strong>Phone:</strong>
                      <span className={"text-yellow"}>(051) 516-3122</span>
                    </li>
                    {/* <li><i className="im im-icon-Fax" /> <strong>Fax:</strong> <span>(123) 123-456 </span></li>
                        <li><i className="im im-icon-Globe" /> <strong>Web:</strong> <span><a href="#">www.example.com</a></span></li> */}
                  </ul>
                </div>
              </div>
              {/* Contact Form */}
              <div className="col-md-8">
                <section id="contact">
                  <h4 className="headline margin-bottom-35">Contact Form</h4>
                  <div id="contact-message" />
                  <div className="row">
                    <div className="col-md-6">
                      <div>
                        <input
                          name="name"
                          type="text"
                          id="name"
                          value={Name}
                          onChange={(name) => setName(name.target.value)}
                          placeholder="Your Name"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <input
                          name="email"
                          type="email"
                          id="email"
                          value={Email}
                          onChange={(email) => setEmail(email.target.value)}
                          placeholder="Email Address"
                          pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <input
                      name="subject"
                      type="text"
                      id="subject"
                      value={Subject}
                      onChange={(subject) => setSubject(subject.target.value)}
                      placeholder="Subject"
                      required="required"
                    />
                  </div>
                  <div>
                    <textarea
                      name="comments"
                      cols={40}
                      rows={3}
                      id="comments"
                      placeholder="Message"
                      value={Message}
                      onChange={(message) => setMessage(message.target.value)}
                      spellCheck="true"
                      required="required"
                      defaultValue={""}
                    />
                  </div>
                  <input
                    type="submit"
                    onClick={HandleSubmit}
                    className="submit button"
                    id="submit"
                    defaultValue="Submit Message"
                  />
                </section>
              </div>
              {/* Contact Form / End */}
            </div>
          </div>
          {/* Container / End */}
          {/* Footer
    ================================================== */}
          <Footer />
          {/* Footer / End */}
          {/* Back To Top Button */}
          <div id="backtotop">
            <a href="#" />
          </div>
        </div>
        {/* Wrapper / End */}
        {/* Scripts
    ================================================== */}
        {/* Maps */}
      </div>
    </>
  );

  // return (
  //   <div>
  //     {/* Wrapper */}
  //     <div id="wrapper">
  //       <NavBar />
  //       <div className="clearfix" />
  //       {/* Header Container / End */}
  //       {/* Content
  //     ================================================== */}
  //       {/* Map Container */}
  //       <div className="contact-map margin-bottom-60">
  //         {/* Google Maps */}

  //         <div id="singleListingMap-container">
  //           <div
  //           className='imgformap'
  //             id="singleListingMap"
  //             data-latitude="40.70437865245596"
  //             data-longitude="-73.98674011230469"
  //             data-map-icon="im im-icon-Map2"
  //           />
  //           <a href="#" id="streetView">
  //             Street View
  //           </a>
  //         </div>
  //         {/* Google Maps / End */}
  //         {/* Office */}
  //         <div className="address-box-container">
  //           <div
  //             className="address-container"
  //             data-background-image="images/our-office.jpg"
  //           >
  //             <div className="office-address">
  //               <h3>Our Office</h3>
  //               <ul>
  //                 <li>I-9 Markaz, Islamabad</li>
  //               </ul>
  //             </div>
  //           </div>
  //         </div>
  //         {/* Office / End */}
  //       </div>
  //       <div className="clearfix" />
  //       {/* Map Container / End */}
  //       {/* Container / Start */}
  //       <div className="container">
  //         <div className="row">
  //           {/* Contact Details */}
  //           <div className="col-md-4">
  //             <h4 className="headline margin-bottom-30">Find Us There</h4>
  //             {/* Contact Details */}
  //             <div className="sidebar-textbox">
  //               <ul className="contact-details">
  //                 <li>
  //                   <i className="im im-icon-Envelope" />{" "}
  //                   <strong>E-Mail:</strong>{" "}
  //                   <span>
  //                     <a className="text-yellow" href="#">
  //                       contact@test.mazebaan.com
  //                     </a>
  //                   </span>
  //                 </li>
  //                 <li>
  //                   <i className="im im-icon-Phone-2" /> <strong>Phone:</strong>
  //                   <span className={'text-yellow'}>(051) 516-3122</span>
  //                 </li>
  //                 {/* <li><i className="im im-icon-Fax" /> <strong>Fax:</strong> <span>(123) 123-456 </span></li>
  //                         <li><i className="im im-icon-Globe" /> <strong>Web:</strong> <span><a href="#">www.example.com</a></span></li> */}
  //               </ul>
  //             </div>
  //           </div>
  //           {/* Contact Form */}
  //           <div className="col-md-8">
  //             <section id="contact">
  //               <h4 className="headline margin-bottom-35">Contact Form</h4>
  //               <div id="contact-message" />
  //               <form
  //                 method="post"
  //                 action="contact.php"
  //                 name="contactform"
  //                 id="contactform"
  //                 autoComplete="on"
  //               >
  //                 <div className="row">
  //                   <div className="col-md-6">
  //                     <div>
  //                       <input
  //                         name="name"
  //                         type="text"
  //                         id="name"
  //                         placeholder="Your Name"
  //                         required="required"
  //                       />
  //                     </div>
  //                   </div>
  //                   <div className="col-md-6">
  //                     <div>
  //                       <input
  //                         name="email"
  //                         type="email"
  //                         id="email"
  //                         placeholder="Email Address"
  //                         pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
  //                         required="required"
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div>
  //                   <input
  //                     name="subject"
  //                     type="text"
  //                     id="subject"
  //                     placeholder="Subject"
  //                     required="required"
  //                   />
  //                 </div>
  //                 <div>
  //                   <textarea
  //                     name="comments"
  //                     cols={40}
  //                     rows={3}
  //                     id="comments"
  //                     placeholder="Message"
  //                     spellCheck="true"
  //                     required="required"
  //                     defaultValue={""}
  //                   />
  //                 </div>
  //                 <input
  //                   type="submit"
  //                   className="submit button"
  //                   id="submit"
  //                   defaultValue="Submit Message"
  //                 />
  //               </form>
  //             </section>
  //           </div>
  //           {/* Contact Form / End */}
  //         </div>
  //       </div>
  //       {/* Container / End */}
  //       {/* Footer
  //     ================================================== */}
  //       <Footer/>
  //       {/* Footer / End */}
  //       {/* Back To Top Button */}
  //       <div id="backtotop">
  //         <a href="#" />
  //       </div>
  //     </div>
  //     {/* Wrapper / End */}
  //     {/* Scripts
  //     ================================================== */}
  //     {/* Maps */}
  //     {/* Style Switcher
  //     ================================================== */}
  //     <div id="style-switcher">
  //       <h2>
  //         Color Switcher{" "}
  //         <a href="#">
  //           <i className="sl sl-icon-settings" />
  //         </a>
  //       </h2>
  //       <div>
  //         <ul className="colors" id="color1">
  //           <li>
  //             <a href="#" className="main" title="Main" />
  //           </li>
  //           <li>
  //             <a href="#" className="blue" title="Blue" />
  //           </li>
  //           <li>
  //             <a href="#" className="green" title="Green" />
  //           </li>
  //           <li>
  //             <a href="#" className="orange" title="Orange" />
  //           </li>
  //           <li>
  //             <a href="#" className="navy" title="Navy" />
  //           </li>
  //           <li>
  //             <a href="#" className="yellow" title="Yellow" />
  //           </li>
  //           <li>
  //             <a href="#" className="peach" title="Peach" />
  //           </li>
  //           <li>
  //             <a href="#" className="beige" title="Beige" />
  //           </li>
  //           <li>
  //             <a href="#" className="purple" title="Purple" />
  //           </li>
  //           <li>
  //             <a href="#" className="celadon" title="Celadon" />
  //           </li>
  //           <li>
  //             <a href="#" className="red" title="Red" />
  //           </li>
  //           <li>
  //             <a href="#" className="brown" title="Brown" />
  //           </li>
  //           <li>
  //             <a href="#" className="cherry" title="Cherry" />
  //           </li>
  //           <li>
  //             <a href="#" className="cyan" title="Cyan" />
  //           </li>
  //           <li>
  //             <a href="#" className="gray" title="Gray" />
  //           </li>
  //           <li>
  //             <a href="#" className="olive" title="Olive" />
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //     {/* Style Switcher / End */}
  //   </div>
  // );
};

export default ContactUs;

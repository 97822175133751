import "./ClaimThisListingForm.css";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { post } from "../Axios/post";
import { ClaimListingEndPoint } from "../Axios/EndPoints";
import HandleTokenExpired from "../Main/TokenExpired/TokenExpired";
import Toast from "../Main/Toast/Toast";
import React from 'react';

const ClaimThisListingForm = (props) => {
  const params = useParams();
  // console.log('props =', props.LOC.state.URL);
  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [UserName, setUserName] = useState();
  const [ListingURL, setListingURL] = useState(params.id);
  const [Message, setMessage] = useState();

  const [Alert, setAlert] = React.useState(false);
  const [AlertData, setAlertData] = React.useState(null);

  const APICALL_Claim_This_Listing_Form = () => {
    let data = {
      name: Name,
      username: UserName,
      email: Email,
      message: Message,
    };

    post(
      ClaimListingEndPoint + props.LOC.state.URL,
      "LOCAL",
      data,
      true,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.data.status === 200) {
          setAlertData(res.data);
          window.location.href = "/listing-detail/" + props.LOC.state.URL;
        } else if (res.data.status == 401) {
          HandleTokenExpired();
        } else {
          setAlertData(res.data);
        }
        setAlert(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {Alert == true ? (
        <Toast status={AlertData.status} message={AlertData.message} />
      ) : (
        ""
      )}
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-2">
          <div className="vc_column-inner">
            <div className="wpb_wrapper" />
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-8">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="vc_message_box vc_message_box-solid vc_message_box-rounded vc_color-info vc_custom_1548154464002">
                {/* <div className="vc_message_box-icon">
                  <i className="fas fa-info-circle" />
                </div> */}
                <p>Fill out this form to claim your business listing.</p>
              </div>
              <div
                role="form"
                className="wpcf7"
                id="wpcf7-f36-p46-o1"
                lang="en-US"
                dir="ltr"
              >
                <div className="screen-reader-response">
                  <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                  <ul />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div>
                      <span className="wpcf7-form-control-wrap name">
                        <input
                          type="text"
                          name="name"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                          size={40}
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Your Name"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <span className="wpcf7-form-control-wrap email">
                        <input
                          type="email"
                          name="email"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          size={40}
                          className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Email Address"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <span className="wpcf7-form-control-wrap username">
                        <input
                          type="text"
                          name="username"
                          value={UserName}
                          onChange={(e) => setUserName(e.target.value)}
                          size={40}
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Username"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <span className="wpcf7-form-control-wrap subject">
                        <input
                          type="text"
                          name="subject"
                          // value={ListingURL}
                          // onChange={(e)=>setListingURL(e.target.value)}
                          size={40}
                          className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                          aria-required="true"
                          aria-invalid="false"
                          placeholder="Listing URL"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="wpcf7-form-control-wrap textarea-265">
                    <textarea
                      name="textarea-265"
                      cols={40}
                      rows={7}
                      value={Message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Please provide additional details for your claim here"
                    />
                  </span>
                </div>
                <p>
                  <input
                    type="submit"
                    defaultValue="Send"
                    onClick={APICALL_Claim_This_Listing_Form}
                    className="wpcf7-form-control wpcf7-submit"
                  />
                  <span className="ajax-loader" />
                </p>
                <div className="wpcf7-response-output" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-2">
          <div className="vc_column-inner">
            <div className="wpb_wrapper" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimThisListingForm;

import "../../Pages/Homepage/Homepage.css";
import {UserLoginEndPoint} from "../Axios/EndPoints";
import {post} from "../Axios/post";
import {useState} from "react";
import GOOGLELOGIN from "../Login/GoogleLogin";
import Toast from "../Main/Toast/Toast";
import { useNavigate } from "react-router";


const LoginTab = (props) => {
  const navigate = useNavigate();

  const [Google,setGoogle] = useState(null);
  const [LoginError, setLoginError] = useState(null);
  const [Error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    
    let data = {"email":event.target.email.value,"password":event.target.password.value}
    post(UserLoginEndPoint,'LOCAL',data,false)
        .then((res) => {
          if (res.data.status === 200){
            console.log(res.data)
            localStorage.setItem("tokenExpired", "false");
            localStorage.setItem("token", res.data.response.token);
            localStorage.setItem("name", res.data.response.name);
            localStorage.setItem("email", res.data.response.email);
            localStorage.setItem("avatar", res.data.response.avatar);
            localStorage.setItem("username", res.data.response.username);
            localStorage.setItem("id", res.data.response.id);
            localStorage.setItem("unread_messages", res.data.response.unread_messages);
            navigate("/", {
              state: {
                message: res.data.message,
                status: res.data.status,
                exist: true,
              },
            });
            } else {
            // alert(res.data.message);
            setLoginError(res.data);
            setError(true);
          }
        })
        .catch((error) => {
          console.log(error);
          // setError(true);
          // setError()
        });

  }

  if (Google!=null)
  {

    let formData = new FormData();


    formData.append("login_by","google");
    formData.append("response_object", JSON.stringify(Google));


    let data ={
      "login_by": "google",
      "response_object": JSON.stringify(Google),
    }

    // console.log('Google =',Google);
    // console.log('data =', data);

    // console.log('formData =',formData);

    post(UserLoginEndPoint,'LOCAL',formData,false)
    .then((res) => {
      console.log('res =', res);
      if (res.data.status === 200){
        localStorage.setItem("tokenExpired", "false");
        localStorage.setItem("token", res.data.response.token);
        localStorage.setItem("name", res.data.response.name);
        localStorage.setItem("email", res.data.response.email);
        localStorage.setItem("avatar", res.data.response.avatar);
        localStorage.setItem("username", res.data.response.username);
        localStorage.setItem("id", res.data.response.id);
        localStorage.setItem("unread_messages", res.data.response.unread_messages);
        window.location.href = '/';
      } else {
        setLoginError(res.data);
        setError(true);
  }
    })
    .catch((error) => {
      console.log(error);
    });

  }
  

  return (
    <div className="tab-content" id="tab1">
      <form className="login" onSubmit={(e) => handleSubmit(e)}>
        <div className="input-group">
          <div className="input-group-icon"><i className="im im-icon-Male" /></div>
          <div className="input-group-area"><input type="text" name="email" placeholder="Email" /></div>
        </div>
        <div className="input-group">
          <div className="input-group-icon"><i className="im im-icon-Lock-2" /></div>
          <div className="input-group-area"><input type="password" name="password" placeholder="Password" /></div>
        </div>
        <p className="form-row">
          <span className="lost_password">
            <a href="#" onClick={() => props.forgotPassword()}>Lost Your Password?</a>
          </span>
        </p>
        {Error && (
        <>
        <Toast status={LoginError.status} message={LoginError.message} setError={setError} />
        {
          setTimeout(() => {
            setError(false)
          }, 5000)
        }
        </>
      )}

        <div className="form-row">
          {/*<div className="checkboxes margin-top-10">
            <input id="remember-me" type="checkbox" name="check" />
            <label htmlFor="remember-me">Remember Me</label>
          </div>*/}
          <input
            type="submit"
            className="button border margin-top-5 bg-yellow padding-left-40 padding-right-40"
            value={'Login'}
          />
        </div>
        <GOOGLELOGIN setGoogle={setGoogle}/>
      </form>
    </div>
  );
};

export default LoginTab;

import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import {get} from "../../Components/Axios/get";
import {DashboardEndpoint} from "../../Components/Axios/EndPoints";
import {useEffect, useState} from "react";
import redirectToLogin from "../../utility/redirectToLogin";

const Dashboard = (props) => {


  const [userListingStats,setUserListingStats] = useState(null)

  const getUserListingStats = () => {
    get(DashboardEndpoint, "LOCAL", "", true, localStorage.getItem("token"))
        .then((res) => {
          if(res?.data?.status === 200)
          {
            console.log('res =',res?.data.response)
            setUserListingStats(res?.data?.response);
          }
        })
        .catch((error) =>{
          console.log('error in getting dashboard api=',error)
        })
  }

  useEffect(() => {
    redirectToLogin(props);
    getUserListingStats();
  },[])


  return (
    <>
      <NavBar hideHeaderLogoDisplay={true} dashboardNavigation={true} />
      {/* Dashboard */}
      <div id="dashboard">
        {/* Navigation
	================================================== */}
        {/* Responsive Navigation Trigger */}
        <Sidebar activePage={'dashboard'}/>
        {/* Navigation / End */}
        {/* Content
	================================================== */}
        <div className="dashboard-content">
          {/* Titlebar */}
          <div id="titlebar">
            <div className="row">
              <div className="col-md-12">
                <h2>Howdy, {localStorage.getItem('name')?localStorage.getItem('name'):''}!</h2>
                {/* Breadcrumbs */}
                <nav id="breadcrumbs">
                  <ul>
                  <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {/* Notice */}
          {/*<div className="row">*/}
          {/*  <div className="col-md-12">*/}
          {/*    <div className="notification success closeable margin-bottom-30">*/}
          {/*      <p>*/}
          {/*        Your listing <strong>Hotel Govendor</strong> has been*/}
          {/*        approved!*/}
          {/*      </p>*/}
          {/*      <a className="close" href="#" />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* Content */}
          <div className="row">
            {/* Item */}
            <div className="col-lg-3 col-md-6">
              <div className="dashboard-stat color-1">
                <div className="dashboard-stat-content">
                  <h4>{userListingStats == null ?  <div class="spinloader"></div>  : userListingStats?.propertyCount}</h4> <span>Active Listings</span>
                </div>
                <div className="dashboard-stat-icon">
                  <i className="im im-icon-Map2" />
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col-lg-3 col-md-6">
              <div className="dashboard-stat color-2">
                <div className="dashboard-stat-content">
                  <h4>{userListingStats == null ? <div
                      className="spinloader"></div> : userListingStats?.propertyVisitCount}</h4>  <span>Total Views</span>
                </div>
                <div className="dashboard-stat-icon">
                  <i className="im im-icon-Line-Chart" />
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col-lg-3 col-md-6">
              <div className="dashboard-stat color-3">
                <div className="dashboard-stat-content">
                  <h4>{userListingStats == null ? <div
                      className="spinloader"></div> : userListingStats?.propertyReviewCount}</h4>  <span>Total Reviews</span>
                </div>
                <div className="dashboard-stat-icon">
                  <i className="im im-icon-Add-UserStar" />
                </div>
              </div>
            </div>
            {/* Item */}
            <div className="col-lg-3 col-md-6">
              <div className="dashboard-stat color-4">
                <div className="dashboard-stat-content">
                  <h4>{userListingStats == null ? <div
                      className="spinloader"></div> : userListingStats?.propertyBookmarkCount}</h4>  <span>Times Bookmarked</span>
                </div>
                <div className="dashboard-stat-icon">
                  <i className="im im-icon-Heart" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Recent Activity */}
            <div className="col-lg-12 col-md-12">
              <div className="dashboard-list-box with-icons margin-top-20">
                <h4>Recent Activities {userListingStats === null && <span><div style={{ "margin-top": "-2.3rem",
                  "margin-left": "15rem"}} className="spinloader"></div></span>}</h4>
                <ul>

                  {userListingStats?.propertyActivity.map((value,index)=>
                          (
                              <li key={value?._id} href={"/listing-detail/" +value?.property_id}>
                                <i   className={value.status == 1? "list-box-icon sl sl-icon-heart" : (value.status == 2? "list-box-icon sl sl-icon-star" : "list-box-icon sl sl-icon-layers") }/>
                                  {value?.activity}
                                {/*<a href="#" className="close-list-item">*/}
                                {/*  <i className="fa fa-close"/>*/}
                                {/*</a>*/}
                              </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Invoices */}
            {/*<div className="col-lg-6 col-md-12">*/}
            {/*  <div className="dashboard-list-box invoices with-icons margin-top-20">*/}
            {/*    <h4>Invoices</h4>*/}
            {/*    <ul>*/}
            {/*      <li>*/}
            {/*        <i className="list-box-icon sl sl-icon-doc" />*/}
            {/*        <strong>Professional Plan</strong>*/}
            {/*        <ul>*/}
            {/*          <li className="unpaid">Unpaid</li>*/}
            {/*          <li>Order: #00124</li>*/}
            {/*          <li>Date: 20/07/2019</li>*/}
            {/*        </ul>*/}
            {/*        <div className="buttons-to-right">*/}
            {/*          <a href="dashboard-invoice.html" className="button gray">*/}
            {/*            View Invoice*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <i className="list-box-icon sl sl-icon-doc" />*/}
            {/*        <strong>Extended Plan</strong>*/}
            {/*        <ul>*/}
            {/*          <li className="paid">Paid</li>*/}
            {/*          <li>Order: #00108</li>*/}
            {/*          <li>Date: 14/07/2019</li>*/}
            {/*        </ul>*/}
            {/*        <div className="buttons-to-right">*/}
            {/*          <a href="dashboard-invoice.html" className="button gray">*/}
            {/*            View Invoice*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <i className="list-box-icon sl sl-icon-doc" />*/}
            {/*        <strong>Extended Plan</strong>*/}
            {/*        <ul>*/}
            {/*          <li className="paid">Paid</li>*/}
            {/*          <li>Order: #00097</li>*/}
            {/*          <li>Date: 10/07/2019</li>*/}
            {/*        </ul>*/}
            {/*        <div className="buttons-to-right">*/}
            {/*          <a href="dashboard-invoice.html" className="button gray">*/}
            {/*            View Invoice*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*      <li>*/}
            {/*        <i className="list-box-icon sl sl-icon-doc" />*/}
            {/*        <strong>Basic Plan</strong>*/}
            {/*        <ul>*/}
            {/*          <li className="paid">Paid</li>*/}
            {/*          <li>Order: #00091</li>*/}
            {/*          <li>Date: 30/06/2019</li>*/}
            {/*        </ul>*/}
            {/*        <div className="buttons-to-right">*/}
            {/*          <a href="dashboard-invoice.html" className="button gray">*/}
            {/*            View Invoice*/}
            {/*          </a>*/}
            {/*        </div>*/}
            {/*      </li>*/}
            {/*    </ul>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* Copyrights */}
            <div className="col-md-12">
              {/* <div className="copyrights">
                © 2021 Mazebaan. All Rights Reserved.
              </div> */}
            </div>
          </div>
        </div>
        {/* Content / End */}
      </div>
      {/* Dashboard / End */}
    </>
  );
};

export default Dashboard;

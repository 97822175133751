import { useParams } from "react-router-dom";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Footer from "../../Components/Main/Footer/Footer";
export const JazzCashReturn = () => {
  const params = useParams();
  console.log(params);
  return params.code == '000' || params.code == '124' ? (
    <>
      <NavBar />
      <div className="container full-width">
        <div className="row">
          <article
            id="post-48"
            className="col-md-12 page-container-col post-48 page type-page status-publish hentry"
          >
            <div className="booking-confirmation-page booking-confrimation-error">
              <i className="fa fa-check-circle" />
              <h2 className="margin-top-30">{params.code == '124' ? "Payment Pending!" : "Payment Sucessful!"}</h2>
              <p className="text-center">{params.message}</p>
            </div>
          </article>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-2">
            <a href="/" class="button border login-button-navbar with-icon">
              <i class="sl sl-icon-home"></i> Home
            </a>
          </div>
          <div className="col-md-3">
            <a
              href="/dashboard-bookings"
              class="button border login-button-navbar with-icon"
            >
              <i class="sl sl-icon-list"></i> My Bookings
            </a>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <div className="clearfix" />
      <div className="margin-top-55" />
      <Footer />
    </>
  ) : (
    <>
      <NavBar />
      <div className="container full-width">
        <div className="row">
          <article
            id="post-48"
            className="col-md-12 page-container-col post-48 page type-page status-publish hentry"
          >
            <div className="booking-confirmation-page booking-confrimation-error">
              <i className="fa fa-times-circle" />
              <h2 className="margin-top-30">Your transaction failed!</h2>
              <p className="text-center">{params.message}</p>
            </div>
          </article>
        </div>
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-2">
            <a href="/" class="button border login-button-navbar with-icon">
              <i class="sl sl-icon-home"></i> Home
            </a>
          </div>
          <div className="col-md-3">
            <a
              href="/dashboard-bookings"
              class="button border login-button-navbar with-icon"
            >
              <i class="sl sl-icon-list"></i> My Bookings
            </a>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      <div className="clearfix" />
      <div className="margin-top-55" />
      <Footer />
    </>
  );
};

import Footer from "../../Components/Main/Footer/Footer";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import '../Homepage/Homepage.css';
import { useState } from "react";
import LoginTab from "../../Components/Login/LoginTab";
import SignUpTab from "../../Components/SignUp/SignUpTab";
const BecomeAHost = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  //  Functions to handle Tab Switching
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };

  return (
    <div>
      <NavBar />
      <div className="Tabs">
          {/* Tab nav */}
          <ul className="custom-tabs">
            <li
              className={activeTab === "tab1" ? "active" : ""}
              onClick={handleTab1}
            >
              Login
            </li>
            <li
              className={activeTab === "tab2" ? "active" : ""}
              onClick={handleTab2}
            >
              Register
            </li>
          </ul>
          <div className="outlet">
            {/* content will be shown here */}
            {activeTab === "tab1" ? <LoginTab /> : <SignUpTab />}
          </div>
        </div>
      <Footer/>
    </div>
  );
};

export default BecomeAHost;

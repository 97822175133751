import NavBar from "../../Components/Main/NavigationBar/NavBar";
import Sidebar from "../../Components/Main/NavigationBar/Sidebar";
import {useEffect, useState} from "react";
import {AllChatEndPoint} from "../../Components/Axios/EndPoints";
import {get} from "../../Components/Axios/get";
import {useNavigate} from 'react-router-dom';
import moment from "moment";
import HandleTokenExpired from "../../Components/Main/TokenExpired/TokenExpired";
import redirectToLogin from "../../utility/redirectToLogin";

const Messages = (props) => {

// from tut
    const navigate = useNavigate();
    const [MyChat, setMyChat] = useState();
    const [MyChatCount, setMyChatCount] = useState(true);
    const [loading, setLoading] = useState(true);

    const [Pagination, setPagintion] = useState(null);
    const [CurrentPage, SetCurrentPage] = useState(1);

    const Handle_NextPageClick = () => {
        if (Pagination.last_page >= CurrentPage + 1) {
            SetCurrentPage((CurrentPage) => CurrentPage + 1);
        } else {
            alert("Already On Last Page!");
        }
    };

    const Handle_PreviousPageClick = () => {
        if (CurrentPage != 1) {
            SetCurrentPage((CurrentPage) => CurrentPage - 1);
        } else {
            alert("Already on Page 1");
        }
    };

    const Handle_SpecificNumber_PageClick = (index) => {
        const PageNumber = CurrentPage + index;
        SetCurrentPage(PageNumber);
    };

    const NavigateToCoversations = (uid) => {
        window.scrollTo(0, 0);
        navigate("/dashboard-messages-conversations/" + uid,
            {
                state: {
                    AllChat: MyChat,
                    AllChatCount: MyChatCount,
                },
            })
    }

    useEffect(() => {
        redirectToLogin(props);
        setLoading(true);
        let mounted = true;
        get(
            // AllChatEndPoint + "?page=" + CurrentPage,
            AllChatEndPoint,
            "LOCAL",
            "",
            true,
            localStorage.getItem("token")
        )
            .then((res) => {
                if (res.data.status === 200) {
                    // setPagintion(res.data.response.pagination);
                    setMyChat(res.data.response);
                    setMyChatCount(res.data.response.length);
                    setLoading(false);
                    window.scrollTo({behavior: "smooth", top: "0px"});
                } else if (res.data.status === 204 || res.data.status === 219) {
                    setMyChatCount(res.data.response.length);
                    setLoading(false);
                }
                else if (res.data.status == 401)
                {
                  HandleTokenExpired(); 
                }
            })
            .catch((error) => {
                console.log(error);
            });
        return () => (mounted = false);
    }, []);

    // console.log(res)

    if (loading) {
        return <div className="loading"></div>;
    }

    return MyChatCount > 0 ? (
            <>
                <NavBar hideHeaderLogoDisplay={true}/>
                {/* Dashboard */}
                <div id="dashboard">
                    {/* Navigation
	================================================== */}
                    {/* Responsive Navigation Trigger */}
                    <Sidebar activePage={"dashboard-messages"}/>
                    {/* Navigation / End */}
                    {/* Content
	================================================== */}
                    <div className="dashboard-content">
                        {/* Titlebar */}
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Messages</h2>
                                    {/* Breadcrumbs */}
                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard">Dashboard</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard-messages">Messages</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Listings */}
                            <div className="col-lg-12 col-md-12">
                                <div className="messages-container margin-top-0">
                                    <div className="messages-headline">
                                        <h4>Inbox</h4>
                                    </div>
                                    <div className="messages-inbox">
                                        <ul>
                                            {Object.values(MyChat).map((value) => (
                                                <li className="unread">
                                                    {/* {console.log('value = ', value)} */}
                                                    <a style={{cursor: "pointer"}}
                                                       onClick={() => NavigateToCoversations(value.uid)}>
                                                        <div className="message-avatar">
                                                            <img
                                                                src={value.message_to.avatar == "" ? "http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70": value.message_to.avatar}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="message-by">
                                                            <div className="message-by-headline">
                                                                <h5>
                                                                    {value.message_to.name}
                                                                    {
                                                                        value.unread_count > 0 && <i>Unread</i>
                                                                    }
                                                                </h5>
                                                                <span className={value.unread_count > 0 ? "activeConversation" :""} >{moment.utc(value.last_message_time).local().startOf('seconds').fromNow()}</span>
                                                            </div>
                                                            <p>{value.title}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                            {/* <li className="unread">
                      <a href="/dashboard-messages-conversations">
                        <div className="message-avatar">
                          <img
                            src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                            alt=""
                          />
                        </div>
                        <div className="message-by">
                          <div className="message-by-headline">
                            <h5>
                              Syed Waqas266 <i>Unread</i>
                            </h5>
                            <span>4 hours ago</span>
                          </div>
                          <p>please approve my request </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/dashboard-messages-conversations">
                        <div className="message-avatar">
                          <img
                            src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                            alt=""
                          />
                        </div>
                        <div className="message-by">
                          <div className="message-by-headline">
                            <h5>Nabeel Abbasi</h5>
                            <span>Yesterday</span>
                          </div>
                          <p>order id #951 </p>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="/dashboard-messages-conversations">
                        <div className="message-avatar">
                          <img
                            src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mm&s=70"
                            alt=""
                          />
                        </div>
                        <div className="message-by">
                          <div className="message-by-headline">
                            <h5>Nabeel Abbasi</h5>
                            <span>28.06.2017</span>
                          </div>
                          <p>hello </p>
                        </div>
                      </a>
                    </li> */}
                                        </ul>
                                    </div>
                                </div>
                                {/* Pagination */}
                                {/* <div className="clearfix" />
              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    <li>
                      <a href="#" className="current-page">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="sl sl-icon-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
                                {/* Pagination / End */}
                            </div>
                            {/* Copyrights */}
                            {/* <div className="col-md-12">
              <div className="copyrights">
                © 2017 Listeo. All Rights Reserved.
              </div>
            </div> */}
                        </div>
                    </div>
                    {/* Content / End */}
                </div>
                {/* Dashboard / End */}
            </>
        )
        :
        (
            <>
                <NavBar hideHeaderLogoDisplay={true}/>
                {/* Dashboard */}
                <div id="dashboard">
                    {/* Navigation
	================================================== */}
                    {/* Responsive Navigation Trigger */}
                    <Sidebar activePage={"dashboard-messages"}/>
                    {/* Navigation / End */}
                    {/* Content
	================================================== */}
                    <div className="dashboard-content">
                        {/* Titlebar */}
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Messages</h2>
                                    {/* Breadcrumbs */}
                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard">Dashboard</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard-messages">Messages</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Listings */}
                            <div className="col-lg-12 col-md-12">
                                <div className="messages-container margin-top-0">
                                    <div className="messages-headline">
                                        <h4>Inbox</h4>
                                    </div>
                                    <div className="messages-inbox">
                                        <ul>
                                            <li>
                                                <p>
                                                    No Chats Found
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Pagination */}
                                {/* <div className="clearfix" />
              <div className="pagination-container margin-top-30 margin-bottom-0">
                <nav className="pagination">
                  <ul>
                    <li>
                      <a href="#" className="current-page">
                        1
                      </a>
                    </li>
                    <li>
                      <a href="#">2</a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="sl sl-icon-arrow-right" />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div> */}
                                {/* Pagination / End */}
                            </div>
                            {/* Copyrights */}
                            {/* <div className="col-md-12">
              <div className="copyrights">
                © 2017 Listeo. All Rights Reserved.
              </div>
            </div> */}
                        </div>
                    </div>
                    {/* Content / End */}
                </div>
                {/* Dashboard / End */}
            </>
        )
};

export default Messages;

import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let runonce = true;

const Toast = (props) => {

  // console.log('Messaage= ', props.message.email[0] == undefined ? true : false)


    if (props.status === 200)
    {
        toast.success(props.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:props.status,
            });
    }
    else if (props.status === 404 || props.status == 204 || props.status == 429)  
    {
        toast.warn(props.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:props.status,
            });
    }
    else if (props.status === 500)
    {
        toast.error(props.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId:props.status,
            }); 
    }

    // window.location.reload();

  return (
    <>
        {/*<ToastContainer*/}
        {/*  position="top-right"*/}
        {/*  autoClose={3000}*/}
        {/*  hideProgressBar={false}*/}
        {/*  newestOnTop={false}*/}
        {/*  closeOnClick*/}
        {/*  rtl={false}*/}
        {/*  pauseOnFocusLoss*/}
        {/*  draggable*/}
        {/*  pauseOnHover*/}
        {/*/>*/}
    </>

  );
};

export default Toast;

import logo2 from '../../../images/mazebaan-logo.png';


const Footer = () => {
    return (
        <div id="footer" className="sticky-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        <img className="footer-logo" src={logo2} alt=""/>
                        <br/><br/>
                        <p>© Mazebaan 2020. All Rights Reserved.</p>
                    </div>
                    <div className="col-md-3 col-sm-6 ">
                        <h4>About Us</h4>
                        <ul className="footer-links">
                            <li><a className={'text-yellow'} href={"/about-us"}>About Us</a></li>
                            <li><a className={'text-yellow'} href={"/blog"}>Blog</a></li>
                            <li><a className={'text-yellow'} href={"/payment-policy"}>Payment Policy</a></li>
                            <li><a className={'text-yellow'} href={"/privacy-policy"}>Privacy Policy</a></li>
                            <li><a className={'text-yellow'} href="/terms-conditions">Terms & Conditions</a></li>
                        </ul>
                        <div className="clearfix"/>
                    </div>
                    <div className="col-md-3 col-sm-6 ">
                        <h4>Helpful Links</h4>
                        <ul className="footer-links">
                            <li><a href={'/dashboard-my-profile'}>My Profile</a></li>
                            <li><a href={'/dashboard-reviews'}>Reviews</a></li>
                            <li><a href={'/dashboard-bookings'}>My Bookings</a></li>
                            <li><a href={'/dashboard-add-listing'}>Add Listing</a></li>
                            <li><a href={"/contact-us"}>Contact Us</a></li>
                        </ul>
                        <div className="clearfix"/>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <h4>Contact Us</h4>
                        <div className="text-widget">
                            <span>I-9 Markaz, Islamabad</span> <br/>
                            E-Mail:<span> <a className={'text-yellow'} href="mailto:contact@mazebaan.com">contact@mazebaan.com</a> </span><br/>
                        </div>
                        {/*<ul className="social-icons margin-top-20">
                            <li><a className="facebook" href="#"><i className="icon-facebook"/></a></li>
                            <li><a className="twitter" href="#"><i className="icon-twitter"/></a></li>
                            <li><a className="gplus" href="#"><i className="icon-gplus"/></a></li>
                            <li><a className="vimeo" href="#"><i className="icon-vimeo"/></a></li>
                        </ul>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;
import "./Homepage.css";

import jQuery from "jquery";
import NavBar from "../../Components/Main/NavigationBar/NavBar";
import home_main_bg from "../../images/home_main_bg.jpeg";
import Footer from "../../Components/Main/Footer/Footer";
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState,useEffect } from "react";
import { Range } from "react-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { Calendar } from "react-date-range";
import {NewListings} from "../../Components/Homepage/NewListings";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import MultiRangeSlider from "multi-range-slider-react";
import './daterangepicker.css';
import './Multi-Range-Slider-React.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Geocode from "react-geocode";
import { GoogleMapsKey } from "../../Components/GoogleKeys/GoogleKeys";
import {get} from "../../Components/Axios/get";
import {SettingsEndpoint} from "../../Components/Axios/EndPoints";
import Autocomplete from "react-google-autocomplete";

let RUNONCE = true;


const Homepage = () => {

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setSEndDate] = useState(new Date());
  const [values, setValues] = useState([50]);
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(10000);

  const [Location,setLocation]= useState(null);
  const [Guests,setGuests]= useState(null);
  const [Bookmarked, setBookmarked] = useState(null);
  
  const location = useLocation();
    const [locationField,setLocationField] = useState(null);
    const [lat,setLat] = useState(null);
  const [lng,setLng] = useState(null);



  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const Toast = (props) => {
    if (props.status == 200)
    {
        toast.success(props.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }
    else if (props.status == 404 || props.status == 429)
    {
      toast.warn(props.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
    }
    else if (props.status == 500)
    {
        toast.error(props.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            }); 
    }
    
      RUNONCE = false;
      setBookmarked(null);
}

  console.log('state =', location.state)

  if (location.state !=null && RUNONCE)
  {
  Toast(location.state);
  }

  if (Bookmarked != null)
  {
    Toast(Bookmarked);
  }

  const GetLocation = () => {
    let lat = "",
      lng = "";

    navigator.geolocation.getCurrentPosition(function (position) {
      lat = position.coords.latitude;
      lng = position.coords.longitude;

      Geocode.setApiKey(GoogleMapsKey);

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          console.log("geocode =", response);
          const address = response.results[0].formatted_address;
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].long_name;
                  break;
                case "country":
                  country = response.results[0].address_components[i].long_name;
                  break;
              }
            }
          }
          setLocation(address);
          console.log(address);
        },
        (error) => {
          console.log("geocode error =", error);
          console.error(error);
        }
      );
    });
  };

  console.log('settings =', localStorage.getItem('settings'))

  useEffect(() => {
    if(localStorage.getItem('settings') == null)
    {
      get(SettingsEndpoint,"LOCAL","",false,"")
      .then((res)=>{
        if(res.data.status == 200)
        {
            console.log('res =', res.data);
          localStorage.setItem('settings',JSON.stringify(res.data.response));
          // res.data.response
        }
      })
    }
  }, []);

  console.log('location =', Location)

  const Search = () =>
  {
    navigate("/listing", {
      state: {
        location: Location,
        // date: res.data.status,
        guest: Guests,
        min: minValue,
        max: maxValue,
      },
    });
  }


  return (
    <div>
      {/* Wrapper */}
      <div id="wrapper">
        <NavBar />
        <div className="clearfix" />

        {/* Banner
      ================================================== */}
        <div
          className="main-search-container full-height alt-search-box centered"
          data-background-image={home_main_bg}
        >
          <div className="main-search-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-search-input">
                    <div className="main-search-input-headline">
                      <h2>Find the Perfect Stay</h2>
                      <h4 className={"text-white"}>Hunting comfort living!</h4>
                    </div>
                    <div className="main-search-input-item location">
                      <div id="autocomplete-container">
                          <Autocomplete
                              value={Location}
                              onChange={(e)=>setLocation(e.target.value)}
                              apiKey={GoogleMapsKey}
                              onPlaceSelected={
                                  (place) => {
                                      if (place.formatted_address !== undefined) {
                                          setLocation(place.formatted_address);
                                          setLat(place.geometry.location.lat());
                                          setLng(place.geometry.location.lng());
                                      } else {
                                          alert("Please select correct location.");
                                      }
                                  }
                              }
                          />
                        {/*<input*/}
                        {/*  id="autocomplete-input"*/}
                        {/*  type="text"*/}
                        {/*  placeholder="Location"*/}
                        {/*  value={Location}*/}
                        {/*  onChange={(e)=>setLocation(e.target.value)}*/}
                        {/*/>*/}
                      </div>
                      <a onClick={GetLocation}>
                        <i className="fa fa-map-marker" />
                      </a>
                    </div>
                    <div className="main-search-input-item">
                      <DateRangePickerComponent
                        min={startDate}
                        showPopupButton={true}
                        // startDate={startDate}
                        // onClick={()=>{jQuery("#ej2-datetimepicker_0").trigger('click')}}
                        // showDropdowns={}
                        // showDropdowns={true}
                        // onClick={()=>eventFire(document.getElementById('ej2-datetimepicker_0'),'click')}
                        placeholder="Pick a Date"
                        // applyButtonClasses="main-search-input-item"
                        // buttonClasses="main-search-input-item"
                      />{" "}
                    </div>
                    <div className="main-search-input-item">
                      <input
                        id="guest-input"
                        type="text"
                        placeholder="Guests"
                        value={Guests}
                        onChange={(e)=>setGuests(e.target.value)}
                      />
                    </div>
                    <div className="main-search-input-item">
                      <div className="main-search-input-headline">
                        <p className={"text-white"}>
                          Price Filter (per day)
                          <br />
                          Select min and max price range
                        </p>
                        <MultiRangeSlider
                          min={0}
                          max={10000}
                          step={500}
                          ruler={false}
                          label={false}
                          preventWheel={false}
                          minValue={minValue}
                          maxValue={maxValue}
                          onInput={(e) => {
                            handleInput(e);
                          }}
                        />
                      </div>
                    </div>
                    <a
                      className={"button fullwidth border-radius-5 bg-yellow"}
                        onClick={Search}
>
                      Search
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewListings setBookmarked={setBookmarked} />
        {/* Fullwidth Section / End */}
        {/* Info Section */}
        <section
          className="fullwidth padding-top-75 padding-bottom-70"
          data-background-color="#f9f9f9"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <h3 className="headline centered headline-extra-spacing">
                  <strong className="headline-with-separator">
                    Hunt the Residence of Your Choice
                  </strong>
                  <span className="margin-top-25">
                    Explore some of the best residences, including rooms, shared
                    houses, apartments, guest houses, luxury hotels, cottages
                    and much more.{" "}
                  </span>
                </h3>
              </div>
            </div>
            <div className="row icons-container">
              {/* Stage */}
              <div className="col-md-4">
              <a href='/listing' >
                <div className="icon-box-2 with-line pointer">
                  <i className="im im-icon-Map2 text-yellow font-weight-900" />
                  <h3 className="text-yellow">Make a Reservation</h3>
                </div>
              </a>
              </div>
              {/* Stage */}
              <div className="col-md-4">
                <a href='/dashboard-reviews'>
                <div className="icon-box-2 with-line pointer">
                  <i className="im im-icon-Add-UserStar text-yellow" />
                  <h3 className="text-yellow">Check Reviews</h3>
                </div>
                </a>
              </div>
              {/* Stage */}
              <div className="col-md-4">
                <a href="/become-a-host">
                <div className="icon-box-2 pointer">
                  <i className="im im-icon-Checked-User text-yellow" />
                  <h3 className="text-yellow">Become a Host</h3>
                </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <a
          // href="listings-half-screen-map-list.html"
          className="flip-banner"
          href="/listing"
          // data-background={slider_bg_02}
          // data-color="#f91942"
          // data-color-opacity="0.85"
          // data-img-width={2500}
          // data-img-height={1666}
        >
          <div className="flip-banner-content">
            <h2 className="flip-visible">Explore Stays Near You</h2>
            <h2 className="flip-hidden">
              Browse Listings <i className="sl sl-icon-arrow-right" />
            </h2>
          </div>
        </a>
        <Footer />
        <div id="backtotop">
          <a href="#" />
        </div>
      </div>
      {/* Wrapper / End */}
    </div>
  );
};

export default Homepage;
